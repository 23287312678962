import React from 'react';
import { Modal, Box, Typography } from '@mui/material';
import PeddingApprovalIcon from '../../../assets/dashboard/peddningApprovalmodalIcon.svg';
import CloseIcon from '@mui/icons-material/Close';

interface PenddingApprovalProps {
  user?: any;
  open: boolean;
  handleClose: () => void;
}

const PenddingApproval: React.FC<PenddingApprovalProps> = ({ open, handleClose, user }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="copy-link-modal-title"
      BackdropProps={{
        sx: {
          backgroundColor: 'var(#0C111D)',
          opacity: 0.7,
          backdropFilter: 'blur(8px)',
        },
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 408,
          bgcolor: 'background.paper',
          borderRadius: '12px',
          boxShadow: 24,
          padding: '24px 32px 32px 32px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          outline: 'none',
          '@media (max-width: 500px)': {
            width: '90%',
          },
        }}
      >
        <Box width={'100%'} display={'flex'} justifyContent={'flex-end'}>
          <CloseIcon
            onClick={handleClose}
            sx={{ cursor: 'pointer', color: 'rgba(152, 162, 179, 1)' }}
          />
        </Box>
        <Box>
          <img src={PeddingApprovalIcon} alt="." />
        </Box>
        <Typography
          fontWeight={800}
          fontFamily={'SF UI Display'}
          fontSize={'24px'}
          lineHeight={'28.64px'}
          color={'rgba(0, 0, 0, 1)'}
          marginTop={'30px'}
          marginBottom={'15px'}
          sx={{
            '@media (max-width: 500px)': {
              fontSize: '18px',
              lineHeight: '20.64px',
            },
          }}
        >
          {!user.company?.companyInfo.verification
            ? 'Your new company page has been created!'
            : 'Your claim request has been submitted!'}
        </Typography>
        <Typography
          fontWeight={400}
          fontFamily={'SF UI Display'}
          fontSize={'16px'}
          lineHeight={'20px'}
          color={'rgba(0, 0, 0, 1)'}
        >
          {!user.company?.companyInfo.verification
            ? 'We have received your new company page request and will review it. This approval can take up to a few days so please be patient.'
            : 'We have received your company page claim and will review it. This approval can take up to a few days so please be patient.'}
        </Typography>
      </Box>
    </Modal>
  );
};

export default PenddingApproval;
