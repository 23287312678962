import React, { FC } from 'react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { MuiOtpInput } from 'mui-one-time-password-input';
interface confirmAccountProps {
  account: any;
  loading?: any;
  otpLoader?: any;
  setAccount: React.Dispatch<React.SetStateAction<any>>;
  otp?: any;
  setOtp?: React.Dispatch<React.SetStateAction<any>>;
  onNextClick?: () => void;
  handleOtp?: () => void;
  handleCancelClick?: () => void;
}

// Custom Toast Component
const ConfirmAccount: FC<confirmAccountProps> = ({
  otp,
  setOtp,
  loading,
  account,
  setAccount,
  onNextClick,
  handleOtp,
  otpLoader,
  handleCancelClick,
}) => {
  // const [otp, setOtp] = React.useState('');
  const handleChange = (newValue: string): void => {
    const numericValue = newValue.replace(/\D/g, '');
    setOtp?.(numericValue);
  };
  return (
    <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
      <Box
        width={'50%'}
        borderRadius={'10px'}
        bgcolor={'#FFF'}
        boxShadow={'0px 1px 4px 0px rgba(148, 148, 148, 0.25)'}
        padding={'3%'}
        display={'flex'}
        justifyContent={'center'}
        sx={{
          '@media (max-width: 1200px)': {
            width: '60%',
          },
          '@media (max-width: 900px)': {
            width: '70%',
          },
          '@media (max-width: 768px)': {
            width: '85%',
            padding: '20px',
          },
          '@media (max-width: 600px)': {
            width: '100%',
          },
        }}
      >
        <Box width={'100%'} display={'flex'} flexDirection={'column'} gap={'10px'}>
          <Typography
            color={'#00BD57'}
            fontFamily={'SF UI Display'}
            fontSize={'16px'}
            fontWeight={'700'}
            lineHeight={'normal'}
            textTransform={'uppercase'}
          >
            Account
          </Typography>
          <Typography
            color={'#000000'}
            fontFamily={'SF UI Display'}
            fontSize={'24px'}
            fontWeight={'800'}
            lineHeight={'normal'}
            sx={{
              '@media (max-width: 600px)': {
                fontSize: '22px',
              },
            }}
          >
            Great! Confirm your account.
          </Typography>
          <Typography
            color={'#000000'}
            fontFamily={'SF UI Display'}
            fontSize={'16px'}
            fontWeight={'400'}
            lineHeight={'20px'}
            sx={{
              '@media (max-width: 600px)': {
                fontSize: '15px',
              },
            }}
          >
            Please check your email. We&apos;ve sent a code to{' '}
            <span style={{ fontWeight: '700' }}>{account?.user?.email}</span>{' '}
          </Typography>
          <Box
            width={'100%'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            margin={'15px 0'}
          >
            <Box
              width={'50%'}
              margin={'30px 0 20px 0'}
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
              gap={'30px'}
              sx={{
                '@media (max-width: 900px)': {
                  width: '100%',
                },
              }}
            >
              <MuiOtpInput
                TextFieldsProps={{
                  inputProps: {
                    inputMode: 'numeric',
                    sx: {
                      // Applying styles directly to the input field
                      borderRadius: '12px',
                      border: '2px solid #00BD57',
                      width: '50px',
                      height: '50px',
                      boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                      color: '#00BD57 !important',
                      fontSize: '48px',
                      fontWeight: '500',
                      lineHeight: '60px',
                      letterSpacing: '-0.96px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center',
                      '&:focus-within': {
                        outline: 'none',
                        border: '5px double #00BD57 !important', // Double border on focus
                      },
                    },
                  },
                }}
                length={4}
                value={otp}
                onChange={handleChange}
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none !important', // Ensure no extra borders from MUI
                  },
                  '@media (max-width: 900px)': {
                    '& input': {
                      width: '40px',
                      height: '40px',
                      fontSize: '38px',
                      lineHeight: '48px',
                    },
                  },
                  '@media (max-width: 520px)': {
                    gap: '15px',
                    '& input': {
                      width: '30px',
                      height: '30px',
                      fontSize: '32px',
                      lineHeight: '42px',
                    },
                  },
                }}
              />
              <Typography
                color={'#000000'}
                fontFamily={'SF UI Display'}
                fontSize={'14px'}
                fontWeight={'500'}
                lineHeight={'20px'}
                whiteSpace={'nowrap'}
              >
                Didn’t get a code?{' '}
                <span
                  style={{
                    color: otpLoader ? 'lightgray' : '#00BD57',
                    textDecoration: 'underline',
                    cursor: otpLoader ? 'default' : 'pointer',
                  }}
                  onClick={() => {
                    if (otpLoader) return;
                    handleOtp?.();
                  }}
                >
                  Click to resend.
                </span>
                {otpLoader && <CircularProgress size={10} color="inherit" />}
              </Typography>
            </Box>
          </Box>
          <Box
            width={'100%'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{
              '@media (max-width: 520px)': {
                gap: '10px',
              },
            }}
          >
            <Button
              sx={{
                width: '25%',
                display: 'flex',
                padding: '10px 16px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '6px',
                borderRadius: '8px',
                border: '1px solid D0D5DD',
                background: '#fff',
                color: '#000000',
                fontFamily: 'SF UI Display',
                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '24px',
                margin: '10px 0',
                boxShadow:
                  'inset 0 0 0 1px rgba(16, 24, 40, 0.18), inset 0 -2px 0 rgba(16, 24, 40, 0.05), 0 1px 2px rgba(16, 24, 40, 0.05)',
                textTransform: 'capitalize',
                '&:hover': {
                  bgcolor: '#fff',
                },
                '@media (max-width: 520px)': {
                  width: '100%',
                },
              }}
              onClick={handleCancelClick}
            >
              Cancel
            </Button>
            <Button
              sx={{
                width: '25%',
                display: 'flex',
                padding: '10px 16px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '6px',
                borderRadius: '8px',
                bgcolor: otp?.length < 4 ? '#F7F7F7' : '#00BD57',
                // background: '#00BD57',
                color: otp?.length < 4 ? '#B8B8B8' : '#fff',
                // color: '#FFF',
                fontFamily: 'SF UI Display',
                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '24px',
                margin: '10px 0',
                textTransform: 'capitalize',
                '&:hover': {
                  bgcolor: otp?.length < 4 ? '#F7F7F7' : '#00BD57',
                },
                '@media (max-width: 520px)': {
                  width: '100%',
                },
              }}
              disabled={otp?.length < 4}
              onClick={onNextClick}
            >
              {loading ? <CircularProgress size={15} color="inherit" /> : 'Confirm'}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ConfirmAccount;
