import React from 'react';
import { Modal, Box, Typography } from '@mui/material';
// import ClaimedListingIcon from '../../../assets/Phase1a/DesignUpdate/ClaimedListingIcon.svg';
// import { Link } from 'react-router-dom';
import ReportReviewIcon from '../../../assets/Phase1a/DesignUpdate/ReportReviewIcon.svg';
import Close from '@mui/icons-material/Close';

interface SubmitReviewProps {
  open: boolean;
  handleClose: () => void;
}

const SubmitReview: React.FC<SubmitReviewProps> = ({ open, handleClose }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      BackdropProps={{
        sx: {
          backgroundColor: 'var(#0C111D)',
          opacity: 0.7,
          backdropFilter: 'blur(8px)', // Adjust the blur level as needed
        },
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: '10px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: '12px',
          '@media (max-width: 500px)': {
            width: '90%',
          },
        }}
      >
        <Box
          width={'100%'}
          display={'flex'}
          justifyContent={'flex-end'}
          position={'absolute'}
          top={'43px'}
          right={'25px'}
          onClick={handleClose}
          sx={{ cursor: 'pointer' }}
        >
          <Close />
        </Box>
        <Box width={'84px'} height={'84px'}>
          <img
            src={ReportReviewIcon}
            alt="Report Review"
            style={{ width: '100%', height: '100%' }}
          />
        </Box>
        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          flexDirection={'column'}
          gap={'10px'}
          mt={1}
        >
          <Typography
            fontFamily={'SF UI Display'}
            fontSize={'24px'}
            fontStyle={'normal'}
            fontWeight={700}
            color={'#101828'}
            lineHeight={'28px'}
          >
            Review Submitted!
          </Typography>
          <Typography
            fontFamily={'SF UI Display'}
            fontSize={'14px'}
            fontStyle={'normal'}
            fontWeight={400}
            color={'#000'}
            lineHeight={'20px'}
          >
            Thank you for taking the time to share your experience with us. All reviews are screened
            and verified before posting.
            <br />
            <br />
            If your review does not meet our guidelines for acceptance we will reach out by email.
          </Typography>
        </Box>{' '}
      </Box>
    </Modal>
  );
};

export default SubmitReview;
