import React from 'react';
import { Modal, Box, Typography, Button, TextField, CircularProgress } from '@mui/material';
import CardTopIcon from '../../../assets/Phase1a/DesignUpdate/DesignUpdateCardIcon.svg';

interface ResponsePopupProps {
  open: boolean;
  handleClose: () => void;
  response?: any;
  setResponse?: (dat: string) => void;
  handleReply?: (data: any) => void;
  item?: any;
  loading?: boolean;
}

const ResponsePopup: React.FC<ResponsePopupProps> = ({
  open,
  handleClose,
  response,
  setResponse,
  handleReply,
  item,
  loading,
}) => {
  // const [message, setMessage] = useState('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setResponse?.(event.target.value);
  };

  const isSubmitDisabled = response?.trim() === '';

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      BackdropProps={{
        sx: {
          backgroundColor: 'var(#0C111D)',
          opacity: 0.7,
          backdropFilter: 'blur(8px)', // Adjust the blur level as needed
        },
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 580,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: '10px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: '12px',
          '@media (max-width: 500px)': {
            width: '90%',
          },
        }}
      >
        <Box
          width={'100%'}
          display={'flex'}
          justifyContent={'flex-start'}
          alignItems={'flex-start'}
        >
          <img
            src={CardTopIcon}
            alt="."
            style={{
              width: '52px',
              height: '46px',
              flexShrink: 0,
            }}
          />
        </Box>
        <Box width={'100%'}>
          <Typography
            fontFamily={'SF UI Display'}
            fontSize={'18px'}
            fontStyle={'normal'}
            fontWeight={600}
            color={'#000'}
            lineHeight={'28px'}
          >
            Company Response
          </Typography>
          <Typography
            fontFamily={'SF UI Display'}
            fontSize={'14px'}
            fontStyle={'normal'}
            fontWeight={400}
            color={'#000'}
            lineHeight={'20px'}
          >
            Thank your customer for a positive review, share your perspective on a negative review
            or correct any claims you find to be inaccurate in a professional way.{' '}
          </Typography>
        </Box>
        <Box width={'100%'} mt={1}>
          <TextField
            rows={4}
            multiline
            fullWidth
            sx={{
              width: '100%',
              fontSize: '16px',
              fontWeight: '400',
              lineHeight: '24px',
              color: '#667085',
              borderRadius: '8px',
              '.MuiOutlinedInput-input': {
                padding: '0px',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: '1px solid #D0D5DD',
                  outline: 'none',
                },
                '&:hover fieldset': {
                  borderColor: '#D0D5DD',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#D0D5DD',
                },
                '&.MuiInputBase-root:focus-visible': {
                  borderColor: '#D0D5DD',
                },
              },
            }}
            id="description"
            variant="outlined"
            placeholder="Write your response here..."
            name="description"
            value={response}
            onChange={handleInputChange}
          />
        </Box>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          width={'100%'}
          pt={1}
        >
          <Button
            sx={{
              width: '48%',
              padding: '8px 16px',
              height: '45px',
              fontFamily: 'SF UI Display',
              textTransform: 'capitalize',
              fontSize: '16px',
              color: '#000',
              border: 'solid 1px #D0D5DD',
              borderRadius: '8px',
              fontWeight: 500,
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleReply?.(item);
            }}
            sx={{
              width: '48%',
              padding: '8px 16px',
              height: '45px',
              fontFamily: 'SF UI Display',
              textTransform: 'capitalize',
              fontSize: '16px',
              color: '#fff',
              border: 'solid 1px #D0D5DD',
              borderRadius: '8px',
              bgcolor: isSubmitDisabled ? '#F7F7F7' : '#00BD57',
              fontWeight: 500,
              '&:hover': {
                bgcolor: isSubmitDisabled ? '#F7F7F7' : '#00BD57',
              },
            }}
            disabled={isSubmitDisabled || loading}
            // onClick={handleButtonClick}
          >
            {loading ? <CircularProgress size={15} color="inherit" /> : 'Submit'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ResponsePopup;
