import React, { FC } from 'react';
import { Box, Typography, TextField } from '@mui/material';

interface ReviewSection2Props {
  step5Ref: any;
  companyData: any;
  review: any;
  handleFeedbackChange: (index: number, newAnswer: string) => void;
}

const reviewSection2: FC<ReviewSection2Props> = ({
  step5Ref,
  companyData,
  review,
  handleFeedbackChange,
}) => {
  return (
    <Box ref={step5Ref} width={'100%'} margin={'40px 0'}>
      <Typography
        fontSize={'20px'}
        fontWeight={'800'}
        fontFamily={'SF UI Display'}
        color={'#000000'}
        sx={{
          '@media (max-width: 500px)': {
            fontSize: '18px',
            lineHeight: 'normal',
          },
        }}
      >
        Give {companyData?.companyInfo?.name} your feedback.
      </Typography>
      <Typography
        fontSize={'16px'}
        fontWeight={'400'}
        fontFamily={'SF UI Display'}
        color={'#000000'}
        margin={'10px 0'}
        sx={{
          '@media (max-width: 500px)': {
            lineHeight: '18px',
          },
        }}
      >
        Feedback helps our community make better choices and purchases. Let us know whats working
        well and what could be improved.
      </Typography>
      <Box width={'100%'} margin={'40px 0'} display={'flex'} flexDirection={'column'} gap={'40px'}>
        {review?.feedback?.map((item: any, index: number) => (
          <Box key={index} width={'100%'} display={'flex'} flexDirection={'column'} gap={'15px'}>
            <Typography
              fontSize={'18px'}
              fontWeight={'400'}
              fontFamily={'SF UI Display'}
              color={'#000000'}
              sx={{
                '@media (max-width: 500px)': {
                  fontSize: '16px',
                  fontWeight: '500',
                  lineHeight: 'normal',
                },
              }}
            >
              {item?.question} [Optional]
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={4}
              placeholder={`Write your ${
                item?.question.toLowerCase().split(' ')[0] as string
              } here...`}
              value={item?.answer}
              onChange={(e) => {
                handleFeedbackChange(index, e.target.value);
              }}
              sx={{
                borderRadius: '8px',
                border: '1px solid #D0D5DD',
                background: '#FFF',
                boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
              }}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default reviewSection2;
