import React, { FC, useEffect, useRef, useState } from 'react';
import { Box, Button, Divider, Rating, Typography } from '@mui/material';
import CrossIcon from '../../../assets/Phase1a/DesignUpdate/toastClose.svg';
import Error from '../../../assets/Phase1a/DesignUpdate/toastError.svg';
import Success from '../../../assets/Phase1a/DesignUpdate//toastSuccess.svg';
import VerticalLinearStepper from '../../../components/phase1a/common/ReviewStepper.component';
import { mediaUrl } from '../../../config';
import Dummyimage from '../../../assets/dummyimg.png';
import { useLocation, useNavigate } from 'react-router-dom';
import request from '../../../api/request';
import { toast } from 'react-toastify';
import StarIcon from '@mui/icons-material/Star';
import DoneIcon from '@mui/icons-material/Done';
import CustomToast from '../../../components/phase1a/common/CustomToast.component';
import ReviewSection1 from './reviewSection1';
import ReviewSection2 from './reviewSection2';
import ReviewSection3 from './reviewSection3';
import ReviewSection4 from './reviewSection4';
import ReviewSection5 from './reviewSection5';

interface CheckboxUserData {
  avatarSrc?: string;
  name: string;
  designation: string;
  companyInfo: string;
  job_title?: string;
}

interface Guideline {
  src: string;
  text: string;
}

const Review: FC = () => {
  const step1Ref = useRef(null);
  const step2Ref = useRef(null);
  const step3Ref = useRef(null);
  const step4Ref = useRef(null);
  const step5Ref = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { companyData } = location.state || {};
  // console.log('companyData review', companyData);
  const userJSON = localStorage.getItem('user');
  const data = userJSON ? JSON.parse(userJSON) : '';
  // console.log('loggedInUser', data);
  const initialReviewState = {
    company: companyData?._id,
    review_type: '',
    services: [],
    job_start_date: '',
    job_completion_date: '',
    job_due_date: '',
    budget_adherence: '',
    reliability: 0,
    communication: 0,
    work_quality: 0,
    lead_time: 0,
    feedback: [
      /* eslint-disable @typescript-eslint/restrict-template-expressions */
      { question: `Pros of working with ${companyData?.companyInfo?.name}.`, answer: '' },
      /* eslint-disable @typescript-eslint/restrict-template-expressions */
      { question: `Cons of working with ${companyData?.companyInfo?.name}.`, answer: '' },
      {
        /* eslint-disable @typescript-eslint/restrict-template-expressions */
        question: `Recommendations for others considering ${companyData?.companyInfo?.name}.`,
        answer: '',
      } /* eslint-disable @typescript-eslint/restrict-template-expressions */,
      {
        question: `Warnings for others considering ${companyData?.companyInfo?.name}.`,
        answer: '',
      },
    ],
    is_anonymous: !data,
    user: {
      avatar: '',
      name: '',
      designation: '',
      company: '',
      email: data?.email ?? '',
      job_title: '',
    },
  };
  const [review, setReview] = React.useState(initialReviewState);
  const [activeStep, setActiveStep] = React.useState<number | null>(0);
  const [selectedBox, setSelectedBox] = React.useState<number | null>(null);
  const [isChecked, setIsChecked] = useState(false);
  const [guidelines, setGuidelines] = useState<Guideline[]>([]);
  // const [erroredStep, setErroredStep] = useState<number[] | undefined>(undefined);
  const [validator, setValidator] = useState({
    0: { completed: false, error: false },
    1: { completed: false, error: false },
    2: { completed: false, error: false },
    3: { completed: false, error: false },
    4: { completed: false, error: false },
  });

  const grayStar = <StarIcon style={{ color: '#A8A8A8' }} fontSize="inherit" />;
  // Function to toggle the checkbox
  const toggleCheckbox = (): void => {
    setIsChecked((prev) => !prev);
  };

  const handleBoxClick = (index: number, item: CheckboxUserData): void => {
    setSelectedBox((prevSelectedBox) => {
      if (prevSelectedBox === index) {
        // If the same box is clicked, uncheck it by resetting `selectedBox` and `user` fields
        setReview((prevReview) => ({
          ...prevReview,
          user: {
            avatar: '',
            name: '',
            designation: '',
            company: '',
            email: data?.email ?? '',
            job_title: '',
          },
        }));
        return null;
      } else {
        // If a different box is clicked, select it and update the `user` fields
        setReview((prevReview) => ({
          ...prevReview,
          user: {
            avatar: item?.avatarSrc ?? '',
            name: item?.name ?? '',
            designation: item?.designation ?? '',
            company: item?.companyInfo ?? '',
            email: data?.email ?? '',
            job_title: item?.job_title ?? '',
          },
        }));
        return index;
      }
    });
  };

  const handleFeedbackChange = (index: number, newAnswer: string): void => {
    setReview((prevReview) => ({
      ...prevReview,
      feedback: prevReview.feedback.map((item, i) =>
        i === index ? { ...item, answer: newAnswer } : item,
      ),
    }));
  };

  const updateRating = (fieldName: string, newValue: number): void => {
    setReview((prevReview) => ({
      ...prevReview,
      [fieldName]: newValue,
    }));
  };

  const handleSubmit = (): void => {
    request('post', `/review`, review)
      .then((res: any) => {
        console.log('res searcchh', res);
        setReview(initialReviewState);
        setIsChecked(false);
        setSelectedBox(null);
        localStorage.setItem('reviewStatus', res);
        navigate(-1);
      })
      .catch((err) => {
        toast(
          <CustomToast message={"You don't have permission to post this review."} type="error" />,
        );

        console.log('err', err);
      });
  };
  // const screenWidth = window.innerWidth;
  // const handleNextStep = (stepRef: any): void => {
  //   if (activeStep === 5) return;
  //   stepRef?.current?.scrollIntoView({ behavior: 'smooth' });
  //   const headerHeight = 120; // Height of your fixed header in pixels
  //   const stepPosition = stepRef?.current?.getBoundingClientRect().top;
  //   const offsetPosition = stepPosition - headerHeight;

  //   // Smoothly scroll by the calculated offset
  //   window.scrollBy({ top: offsetPosition, behavior: 'smooth' });
  // };

  // Automatically move to the next step based on state changes
  // useEffect(() => {
  //   // Check if Step 1 (Rules) is not completed
  //   if (!isChecked) {
  //     setActiveStep(0);
  //     // setErroredStep(0);
  //     handleNextStep(step1Ref); // Scroll to Step 1 if not completed
  //   } else if (!review.user?.designation) {
  //     // Check if Step 2 (Visibility) is not completed
  //     setActiveStep(1);
  //     // setErroredStep(1);
  //     handleNextStep(step2Ref); // Scroll to Step 2 if not completed
  //   } else {
  //     // Check if Step 3 (Rating) is not completed
  //     const hasCompletedRatings =
  //       review.reliability > 0 && review.communication > 0 && review.work_quality > 0;
  //     const hasLeadTimeRating = review.lead_time > 0;

  //     if (
  //       companyData?.companyInfo?.companyType === 'supplier' ||
  //       companyData?.companyInfo?.companyType === 'distributor'
  //     ) {
  //       // If the company is a supplier or distributor, check all 4 ratings
  //       if (hasCompletedRatings && hasLeadTimeRating) {
  //         setActiveStep(5); // Skip Step 4 and go directly to Step 5
  //         // setErroredStep(5);
  //         handleNextStep(step5Ref);
  //       } else {
  //         setActiveStep(2); // Stay on Step 3
  //         // setErroredStep(2);

  //         handleNextStep(step3Ref);
  //       }
  //     } else {
  //       // If the company is not a supplier or distributor, check only the first 3 ratings
  //       if (!hasCompletedRatings) {
  //         setActiveStep(2); // Stay on Step 3
  //         // setErroredStep(2);

  //         handleNextStep(step3Ref);
  //       } else {
  //         // Move to Step 4 after completing Step 3
  //         setActiveStep(3);
  //         // setErroredStep(3);

  //         handleNextStep(step4Ref);

  //         // Check Step 4 (Projects) - specifically for the lead time
  //         if (hasLeadTimeRating) {
  //           setActiveStep(5); // Move to Step 5 if lead time is provided
  //           // setErroredStep(5);

  //           handleNextStep(step5Ref);
  //         }
  //       }
  //     }
  //   }
  // }, [review, activeStep, isChecked, companyData]);

  const moveToStep = (step: number, ref: React.RefObject<HTMLDivElement>): void => {
    console.log('🚀 ~ moveToStep ~ step:', step);
    setActiveStep(step);
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      const headerHeight = 120; // Adjust based on your fixed header height
      const stepPosition = ref.current.getBoundingClientRect().top;
      const offsetPosition = stepPosition - headerHeight;

      window.scrollBy({ top: offsetPosition, behavior: 'smooth' });
    }
  };

  const updateStepStatus = (): void => {
    const updateValidator = (stepIndex: number, completed: boolean, error: boolean): void => {
      setValidator((prev) => ({
        ...prev,
        [stepIndex]: { completed, error },
      }));
    };

    // Condition 1: isChecked is true
    if (isChecked) {
      updateValidator(0, true, false);
    } else if (!isChecked && validator[0]?.completed) {
      updateValidator(0, false, true);
    }

    // Condition 2: User designation
    if (review.user?.designation) {
      updateValidator(1, true, false);
    } else if (validator[1]?.completed) {
      updateValidator(1, false, true);
    }

    // Common conditions for ratings and lead time
    const hasCompletedRatings =
      review.reliability > 0 && review.communication > 0 && review.work_quality > 0;
    const hasLeadTimeRating = review.lead_time > 0;

    const companyType = companyData?.companyInfo?.companyType;
    const isSupplierOrDistributor = companyType === 'supplier' || companyType === 'distributor';

    // Supplier/Distributor Logic
    if (isSupplierOrDistributor) {
      if (hasCompletedRatings) {
        updateValidator(2, true, false);
      } else if (validator[2]?.completed) {
        updateValidator(2, false, true);
      }
      if (hasLeadTimeRating) {
        updateValidator(3, true, false);
      } else if (validator[3]?.completed) {
        updateValidator(3, false, true);
      }
    } else {
      if (hasCompletedRatings) {
        updateValidator(2, true, false);
      } else if (validator[2]?.completed) {
        updateValidator(2, false, true);
      }

      if (hasLeadTimeRating) {
        updateValidator(3, true, false);
      } else if (!hasLeadTimeRating && validator[3]?.completed) {
        updateValidator(3, false, true);
      }

      if (hasCompletedRatings && hasLeadTimeRating) {
        updateValidator(4, true, false);
      } else if (validator[4]?.completed) {
        updateValidator(4, false, true);
      }
    }
  };

  useEffect(() => {
    updateStepStatus();
  }, [isChecked, review, companyData]);

  useEffect(() => {
    if (!isChecked) {
      moveToStep(0, step1Ref); // Step 1: Rules not completed
    } else if (!review.user?.designation) {
      moveToStep(1, step2Ref); // Step 2: Visibility not completed
    } else {
      const hasCompletedRatings =
        review.reliability > 0 && review.communication > 0 && review.work_quality > 0;
      const hasLeadTimeRating = review.lead_time > 0;

      const companyType = companyData?.companyInfo?.companyType;
      if (companyType === 'supplier' || companyType === 'distributor') {
        if (hasCompletedRatings && hasLeadTimeRating) {
          moveToStep(5, step5Ref); // Step 5: Final step for suppliers/distributors
        } else {
          moveToStep(2, step3Ref); // Step 3: Ratings step
        }
      } else {
        if (!hasCompletedRatings) {
          moveToStep(2, step3Ref); // Step 3: Ratings step
        } else if (!hasLeadTimeRating) {
          moveToStep(3, step4Ref); // Step 4: Lead time step
        } else {
          moveToStep(5, step5Ref); // Step 5: Final step
        }
      }
    }
  }, [review, isChecked, companyData]);

  // useEffect(() => {
  //   // Check if Step 1 (Rules) is not completed
  //   if (!isChecked) {
  //     setActiveStep(0);
  //     handleNextStep(step1Ref); // Scroll to Step 1 if not completed
  //     return;
  //   }

  //   // Check if Step 2 (Visibility) is not completed
  //   if (!review.user?.designation) {
  //     setActiveStep(1);
  //     handleNextStep(step2Ref); // Scroll to Step 2 if not completed
  //     return;
  //   }

  //   // Check if Step 3 (Rating) is not completed
  //   const hasCompletedRatings =
  //     review.reliability > 0 && review.communication > 0 && review.work_quality > 0;
  //   const hasLeadTimeRating = review.lead_time > 0;

  //   if (
  //     companyData?.companyInfo?.companyType === 'supplier' ||
  //     companyData?.companyInfo?.companyType === 'distributor'
  //   ) {
  //     // If the company is a supplier or distributor, check all 4 ratings
  //     if (hasCompletedRatings && hasLeadTimeRating) {
  //       setActiveStep(5); // Skip Step 4 and go directly to Step 5
  //       handleNextStep(step5Ref);
  //     } else {
  //       setActiveStep(2); // Stay on Step 3
  //       handleNextStep(step3Ref);
  //     }
  //   } else {
  //     // If the company is not a supplier or distributor, check only the first 3 ratings
  //     if (hasCompletedRatings) {
  //       setActiveStep(3); // Move to Step 4
  //       handleNextStep(step4Ref);
  //     } else {
  //       setActiveStep(2); // Stay on Step 3
  //       handleNextStep(step3Ref);
  //     }
  //   }

  //   // For companies that are not suppliers or distributors, check Step 4 (Projects)
  //   if (
  //     companyData?.companyInfo?.companyType !== 'supplier' &&
  //     companyData?.companyInfo?.companyType !== 'distributor'
  //   ) {
  //     if (hasLeadTimeRating) {
  //       setActiveStep(5); // Move to Step 5 if lead time is provided
  //       handleNextStep(step5Ref);
  //     } else {
  //       setActiveStep(3); // Stay on Step 4
  //       handleNextStep(step4Ref);
  //     }
  //   }
  // }, [review, activeStep, isChecked, companyData]);
  // useEffect(() => {
  //   // Move from 'Rules' to 'Visibility' when 'company' or 'review_type' is filled
  //   if (isChecked) {
  //     setActiveStep(1);
  //     handleNextStep(step2Ref);
  //   } else {
  //     setActiveStep(0);
  //     return;
  //   }
  //   // Move from 'Visibility' to 'Rating' when user makes a visibility choice (is_anonymous is set)
  //   if (review.user?.designation !== '') {
  //     setActiveStep(2);
  //     handleNextStep(step3Ref);
  //   } else {
  //     setActiveStep(1);
  //     return;
  //   }
  //   // // Move from 'Rating' to 'Projects' when all ratings are provided
  //   if (
  //     companyData?.companyInfo?.companyType === 'supplier' ||
  //     companyData?.companyInfo?.companyType === 'distributor'
  //   ) {
  //     if (
  //       review.reliability > 0 &&
  //       review.communication > 0 &&
  //       review.work_quality > 0 &&
  //       review.lead_time > 0
  //     ) {
  //       setActiveStep(3);
  //       handleNextStep(step5Ref);
  //     } else {
  //       setActiveStep(2);
  //       return;
  //     }
  //   } else {
  //     if (review.reliability > 0 && review.communication > 0 && review.work_quality > 0) {
  //       setActiveStep(3);
  //       handleNextStep(step4Ref);
  //     } else {
  //       setActiveStep(2);
  //       return;
  //     }
  //   }
  //   if (review.lead_time > 0) {
  //     setActiveStep(5);
  //     handleNextStep(step5Ref);
  //   }
  //   // // Move from 'Projects' to 'Feedback' when the project details are filled
  // }, [review, activeStep, isChecked]);

  const debounce = (func: (...args: any[]) => void, wait: number): any => {
    let timeout: NodeJS.Timeout;
    return (...args: any[]) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func(...args);
      }, wait);
    };
  };

  useEffect(() => {
    const largeScreenGuidelines = [
      { src: Success, text: 'Base your review on your own experiences only.' },
      { src: Error, text: 'Do not post false, inappropriate, or unrelated reviews.' },
      { src: Success, text: 'Only review companies you do business with now or in the past.' },
      { src: Error, text: 'Do not use reviews to advertise or promote yourself.' },
    ];

    const smallScreenGuidelines = [
      { src: Success, text: 'Base your review on your own experiences only.' },
      { src: Success, text: 'Only review companies you do business with now or in the past.' },
      { src: Error, text: 'Do not post false, inappropriate, or unrelated reviews.' },
      { src: Error, text: 'Do not use reviews to advertise or promote yourself.' },
    ];

    const updateGuidelines = (): void => {
      const screenWidth = window.innerWidth;
      if (screenWidth > 1024) {
        setGuidelines(largeScreenGuidelines);
      } else {
        setGuidelines(smallScreenGuidelines);
      }
    };

    const debouncedResize = debounce(updateGuidelines, 200);
    window.addEventListener('resize', debouncedResize);

    // Initialize guidelines on mount
    updateGuidelines();

    return () => {
      window.removeEventListener('resize', debouncedResize);
    };
  }, []);

  // useEffect(() => {
  //   const screenWidth = window.innerWidth;

  //   const largeScreenGuidelines = [
  //     {
  //       src: Success,
  //       text: 'Base your review on your own experiences only.',
  //     },
  //     {
  //       src: Error,
  //       text: 'Do not post false, inappropriate or unrelated reviews.',
  //     },
  //     {
  //       src: Success,
  //       text: 'Only review companies you do business with now or in the past.',
  //     },
  //     {
  //       src: Error,
  //       text: 'Do not use reviews to advertise or promote yourself.',
  //     },
  //   ];

  //   const smallScreenGuidelines = [
  //     {
  //       src: Success,
  //       text: 'Base your review on your own experiences only.',
  //     },
  //     {
  //       src: Success,
  //       text: 'Only review companies you do business with now or in the past.',
  //     },
  //     {
  //       src: Error,
  //       text: 'Do not post false, inappropriate or unrelated reviews.',
  //     },
  //     {
  //       src: Error,
  //       text: 'Do not use reviews to advertise or promote yourself.',
  //     },
  //   ];

  //   if (screenWidth > 1024) {
  //     setGuidelines(largeScreenGuidelines);
  //   } else {
  //     setGuidelines(smallScreenGuidelines);
  //   }

  //   // Optional: Add a listener to handle window resize
  //   const handleResize = (): any => {
  //     const newScreenWidth = window.innerWidth;
  //     if (newScreenWidth > 1024) {
  //       setGuidelines(largeScreenGuidelines);
  //     } else {
  //       setGuidelines(smallScreenGuidelines);
  //     }
  //   };

  //   window.addEventListener('resize', handleResize);

  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);

  return (
    <Box width="100%">
      <Box
        position={'fixed'}
        top={'0'}
        width={'100%'}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        padding={'0 10%'}
        height={'81px'}
        borderBottom={'1px solid #EEEEEE'}
        bgcolor={'#fff'}
        zIndex={2}
        sx={{
          '@media (max-width: 1366px)': {
            padding: '0 5%',
          },
        }}
      >
        <Box
          width={'44px'}
          height={'44px'}
          onClick={() => {
            navigate(-1); // Go back to the previous page
          }}
          sx={{ cursor: 'pointer' }}
        >
          <img style={{ width: '100%', height: '100%' }} src={CrossIcon} />
        </Box>
        <Typography
          color="#000"
          textAlign="center"
          font-family="SF UI Display"
          fontSize="16px"
          fontWeight="700"
          sx={{
            '@media (max-width: 500px)': {
              fontWeight: '600',
            },
          }}
        >
          Write A Review
        </Typography>
        <Button
          disabled={activeStep !== 5}
          onClick={handleSubmit}
          sx={{
            borderRadius: '8px',
            border: '2px solid  rgba(255, 255, 255, 0.12)',
            background: activeStep !== 5 ? '#F7F7F7' : '#00BD57',
            color: '#FFF',
            fontFamily: 'SF UI Display',
            fontSize: '16px',
            fontWeight: '600',
            padding: '5px 40px',
            textTransform: 'capitalize',
            '&:hover': {
              bgcolor: '#00BD57',
            },
            '@media (max-width: 768px)': {
              display: 'none',
            },
          }}
        >
          Submit
        </Button>
        <Box
          onClick={activeStep !== 5 ? undefined : handleSubmit}
          sx={{
            width: '31px',
            height: '31px',
            display: 'none',
            borderRadius: '8px',
            background: activeStep !== 5 ? '#F7F7F7' : '#00BD57',
            fontFamily: 'SF UI Display',
            fontSize: '16px',
            fontWeight: '600',
            cursor: activeStep !== 5 ? 'default' : 'pointer',
            '@media (max-width: 768px)': {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            },
          }}
        >
          <DoneIcon sx={{ color: activeStep !== 5 ? '#B8B8B8' : '#ffffff' }} />
        </Box>
      </Box>
      <Box
        width={'100%'}
        display={'flex'}
        padding={'150px 10% 50px 10%'}
        justifyContent={'space-between'}
        sx={{
          '@media (max-width: 1366px)': {
            padding: '150px 5% 50px 5%',
          },
          '@media (max-width: 992px)': {
            padding: '130px 5% 50px 5%',
          },
          '@media (max-width: 500px)': {
            padding: '120px 5% 50px 5%',
          },
        }}
      >
        <Box
          width={'55%'}
          sx={{
            '@media (max-width: 1366px)': {
              width: '60%',
            },
            '@media (max-width: 992px)': {
              width: '100%',
            },
          }}
        >
          <Box
            ref={step1Ref}
            width={'100%'}
            display={'flex'}
            gap={'30px'}
            sx={{
              '@media (max-width: 500px)': {
                gap: '20px',
              },
            }}
          >
            <Box
              width={'100px'}
              height={'100px'}
              sx={{
                '@media (max-width: 500px)': {
                  width: '75px',
                  height: '75px',
                },
              }}
            >
              <img
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius: '10px',
                  background: '#FFF',
                }}
                src={
                  companyData?.companyInfo?.logo
                    ? /* eslint-disable @typescript-eslint/restrict-template-expressions */
                      `${mediaUrl}/${companyData?.companyInfo?.logo}`
                    : Dummyimage
                }
              />
            </Box>
            <Box width={'fit-content'}>
              <Box width={'100%'} display={'flex'} alignItems={'center'} gap={'10px'}>
                <Rating
                  name="hover-feedback"
                  value={
                    typeof companyData?.averageReviewRating?.overall === 'number'
                      ? companyData?.averageReviewRating.overall
                      : parseFloat(companyData?.averageReviewRating?.overall) || 0
                  }
                  precision={0.1}
                  sx={{
                    color: !companyData?.averageReviewRating
                      ? '#9E9E9E'
                      : companyData?.averageReviewRating?.overall >= 4
                      ? '#00BD57'
                      : companyData?.averageReviewRating?.overall >= 3
                      ? '#FFB800'
                      : '#FD3E14',
                    fontSize: '31px',
                    '@media (max-width: 500px)': {
                      fontSize: '25px',
                    },
                  }}
                  readOnly
                  emptyIcon={grayStar} // Custom gray star for empty rating
                  icon={
                    !companyData?.averageReviewRating ? (
                      grayStar
                    ) : (
                      <StarIcon
                        fontSize="inherit"
                        sx={{
                          color: !companyData?.averageReviewRating
                            ? '#9E9E9E'
                            : companyData?.averageReviewRating?.overall >= 4
                            ? '#00BD57'
                            : companyData?.averageReviewRating?.overall >= 3
                            ? '#FFB800'
                            : '#FD3E14',
                        }}
                      />
                    )
                  } // Gray filled stars if rating is 0
                />
                {companyData?.averageReviewRating?.overall > 0 ? (
                  <Typography
                    fontSize={'32px'}
                    fontWeight={'700'}
                    fontFamily={'SF UI Display'}
                    color={'#000'}
                    sx={{
                      '@media (max-width: 500px)': {
                        fontSize: '20px',
                      },
                    }}
                  >
                    {(Math.floor(companyData?.averageReviewRating?.overall * 10) / 10).toFixed(1)}
                  </Typography>
                ) : (
                  ''
                )}
              </Box>
              <Typography
                fontSize={'20px'}
                fontWeight={'400'}
                fontFamily={'SF UI Display'}
                color={'#777'}
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '18px',
                  },
                }}
              >{`(${companyData?.averageReviewRating?.count ?? 0} reviews)`}</Typography>
            </Box>
          </Box>
          <ReviewSection1
            companyData={companyData}
            isChecked={isChecked}
            toggleCheckbox={toggleCheckbox}
            guidelines={guidelines}
          />
          <ReviewSection2
            handleBoxClick={handleBoxClick}
            step2Ref={step2Ref}
            selectedBox={selectedBox}
          />
          <ReviewSection3
            step3Ref={step3Ref}
            companyData={companyData}
            review={review}
            updateRating={updateRating}
          />
          <ReviewSection4
            step4Ref={step4Ref}
            companyData={companyData}
            updateRating={updateRating}
            review={review}
          />

          <ReviewSection5
            step5Ref={step5Ref}
            companyData={companyData}
            review={review}
            handleFeedbackChange={handleFeedbackChange}
          />
        </Box>
        <Box
          width={'28%'}
          bgcolor={'#F7F7F7'}
          position={'fixed'}
          top={'150px'}
          right={'10%'}
          borderRadius={'10px'}
          sx={{
            '@media (max-width: 1366px)': {
              right: '5%',
              width: '33%',
            },
            '@media (max-width: 992px)': {
              display: 'none',
            },
          }}
        >
          <Box width={'100%'} padding={'20px 40px'}>
            <Typography
              color={'#000'}
              fontFamily="SF UI Display"
              fontSize="20px"
              fontWeight="800"
              sx={{
                '@media (max-width: 1024px)': {
                  fontSize: '18px',
                },
              }}
            >
              Review Steps
            </Typography>
          </Box>
          <Divider />
          <Box width={'100%'} padding={'20px 25px 20px 35px'}>
            <VerticalLinearStepper
              activeStep={activeStep}
              companyData={companyData}
              validator={validator}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Review;
