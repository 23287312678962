import React, { FC, useEffect, useState } from 'react';
import { Grid, Box } from '@mui/material';
import SideBar from '../../components/phase1a/local/LocalPageSidebar.component';
import Header from '../../components/Header/LocalSignHeader.component';
import { Route, Routes, useLocation } from 'react-router-dom';

import { LocalSignRoutes } from '../../routes/routes';
import request from '../../api/request';

const LocalPageOwner: FC = () => {
  const location = useLocation();
  const showSidebar = !location.pathname.includes('SettingPagesetup');
  const [userDetail, setUserDetail] = useState<any>(null);
  const [claimStatus, setClaimStatus] = useState(false);
  const token = localStorage.getItem('token');
  const getUserById = (): void => {
    request('get', `/auth/userDetails`)
      .then((res: any) => {
        console.log('setUserDetail res', res);
        setUserDetail(res?.user);
      })
      .catch((err: any) => {
        console.log('err', err);
      });
  };
  useEffect(() => {
    // Assuming you fetch userDetail from an API or some other source
    // setUserDetail(fetchedUserDetail);

    if (userDetail?.company?.companyInfo?.verification?.status) {
      localStorage.setItem('user', JSON.stringify(userDetail));
    }
  }, [userDetail]);
  useEffect(() => {
    if (token) {
      getUserById();
    }
  }, [token]);
  useEffect(() => {
    if (token?.length) {
      request('get', `/companies/claim/checkRequest`)
        .then((res: any) => {
          if (res?.claim?.status === 'pending') {
            setClaimStatus(true);
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
  }, [token?.length]);
  return (
    <Box width="100%" height="100%">
      <Header claimStatus={claimStatus} />
      <Grid
        sx={{
          overflowX: 'hidden',
          display: 'flex',
          height: '90vh',
          flexDirection: 'row',
          bgcolor: '#FFFFFF',
          gap: '35px',
          '@media (max-width: 1024px)': {
            flexDirection: 'column',
            overflowY: 'hidden',
            height: '100%',
          },
        }}
      >
        {showSidebar && <SideBar claimStatus={claimStatus} />}
        <Box
          width={'100%'}
          height={'100%'}
          display="flex"
          bgcolor="#FFFFFF"
          sx={{
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'start',
            flexDirection: 'row',
            bgcolor: '#FFFFFF',
            // gap: '35px',
            '@media (max-width: 1024px)': {
              flexDirection: 'column',
              // padding: '0 3%',
            },
          }}
        >
          <Routes>
            {LocalSignRoutes?.map((item) => {
              return <Route path={item.path} element={<item.component />} key={item.name} />;
            })}
          </Routes>
        </Box>
      </Grid>
    </Box>
  );
};

export default LocalPageOwner;
