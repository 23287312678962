import React, { FC, useState } from 'react';
import Box from '@mui/material/Box';
import { Checkbox, Typography } from '@mui/material';
// import InstallationServiceIcon from '../../../../assets/popups/serviceRightArrow.svg';
import { DataStructure } from '../../../../unAuth/phase1a/PersonalInformation.unAuth';

interface CompanyTypeProps {
  data: DataStructure;
  setData: React.Dispatch<React.SetStateAction<DataStructure>>;
}

const options = [
  { name: 'local', label: 'Local Sign Company', color: '#FF5722' },
  { name: 'national', label: 'National Sign Company', color: '#E91E63' },
  // { name: 'wholesaler', label: 'Wholesale Sign Company', color: '#2196F3' },
  { name: 'supplier', label: 'Supplier', color: '#2196F3' },
  { name: 'distributor', label: 'Distributor', color: '#2196F3' },
];

const style = {
  width: '100%',
  height: '100%',
  bgcolor: 'background.paper',
  borderRadius: 5,
  p: 4,
  paddingRight: 3,
  paddingLeft: 3,
};

const CompanyType: FC<CompanyTypeProps> = ({ data, setData }) => {
  const [selectedOption, setSelectedOption] = useState('');

  const handleCheckboxChange = (option: any): void => {
    setSelectedOption(option?.label);
    setData((prevData) => ({
      ...prevData,
      companyInfo: {
        ...prevData.companyInfo,
        companyType: option?.name,
      },
    }));
  };

  return (
    <Box sx={style}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          {/* <img src={PersonalInfoIcon} alt="" style={{ width: '77.5px', height: '77.5px' }} /> */}
          <Typography
            fontSize="24px"
            fontWeight="800"
            lineHeight="28.64px"
            fontFamily="SF UI Display"
            color=" #000000"
            id="modal-modal-title"
            mb={8}
            sx={{
              '@media (max-width: 500px)': {
                fontSize: '20px',
                mb: 5,
                textAlign: 'center',
              },
            }}
          >
            What type of sign company are you?
          </Typography>
        </Box>
      </Box>
      <Box width="100%" sx={{ marginTop: '2%' }}>
        {options.map((option, index) => (
          <Box
            key={index}
            borderRadius="10px"
            border="solid 1px #E8E8E8"
            padding="2%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            marginBottom="1rem"
            sx={{
              cursor: 'pointer',
              '&:hover': {
                bgcolor: '#DFFFED',
              },
            }}
            onClick={() => {
              handleCheckboxChange(option);
            }}
          >
            <Box width="50%" display="flex" alignItems="center" gap="15px">
              <Checkbox
                sx={{
                  '&.Mui-checked': {
                    color: '#00BD57',
                  },
                }}
                checked={selectedOption === option.label}
                disabled={option.label === 'Wholesale Sign Company'}
                onChange={() => {
                  handleCheckboxChange(option);
                }}
              />
              <Typography
                fontFamily={'SF UI Display'}
                fontWeight="700"
                fontSize="16px"
                lineHeight="19.09px"
                sx={{
                  '@media (max-width: 580px)': {
                    whiteSpace: 'pre !important',
                  },
                }}
              >
                {option.label}
              </Typography>
            </Box>
            {/* <Box width="50%" display="flex" justifyContent="flex-end" paddingRight="2%">
              <img src={InstallationServiceIcon} alt="" style={{ width: '24px', height: '24px' }} />
            </Box> */}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default CompanyType;
