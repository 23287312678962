import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Button, Typography } from '@mui/material';
import CustomToast from '../../components/phase1a/common/CustomToast.component';
import { toast } from 'react-toastify';
import request from '../../api/request';
interface ReviewModalProps {
  open: boolean;
  onClose: () => void;
  handleFlag: (a: string) => void;
  reviewId?: any;
}

const style = {
  position: 'absolute',
  maxHeight: '70vh',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '500px',
  bgcolor: '#ffffff',
  boxShadow: 24,
  borderRadius: '10px',
  p: 4,
  overflowY: 'auto',

  '@media (max-width: 768px)': {
    width: '90%',
    p: 1,
  },
};

const FlagModal: React.FC<ReviewModalProps> = ({ open, onClose, handleFlag, reviewId }) => {
  console.log('reviewId', reviewId);
  const handleDelete = (): void => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    request('delete', `/review/${reviewId}`)
      .then((res: any) => {
        console.log('delete user res', res);
        // toast.success('User deleted successfully');
        toast(<CustomToast message={'Review delete successfully'} type="success" />);
        onClose();
      })
      .catch((err: any) => {
        console.log('err', err);
      });
  };

  return (
    <Box>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <>
            <Box width={'100%'}>
              <Typography
                fontFamily={'SF UI Display'}
                fontSize={'24px'}
                fontStyle={'normal'}
                fontWeight={700}
                color={'#101828'}
                lineHeight={'28px'}
              >
                Are you sure?
              </Typography>
              <Typography
                fontFamily={'SF UI Display'}
                fontSize={'14px'}
                fontStyle={'normal'}
                fontWeight={400}
                color={'#000'}
                lineHeight={'20px'}
                mt={2}
              >
                Do you really want to delete this review? This process cannot be undone
              </Typography>
            </Box>
            <Box
              mt={2}
              width={'100%'}
              display={'flex'}
              justifyContent="space-between"
              alignItems={'center'}
            >
              <Button
                sx={{
                  width: '49%',
                  padding: '8px 16px',
                  height: '45px',
                  fontFamily: 'SF UI Display',
                  textTransform: 'capitalize',
                  fontSize: '16px',
                  color: '#000',
                  border: 'solid 1px #D0D5DD',
                  borderRadius: '8px',
                  fontWeight: 500,
                }}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                onClick={handleDelete}
                sx={{
                  width: '49%',
                  padding: '8px 16px',
                  height: '45px',
                  fontFamily: 'SF UI Display',
                  textTransform: 'capitalize',
                  fontSize: '16px',
                  color: '#fff',
                  border: 'solid 1px #D0D5DD',
                  borderRadius: '8px',
                  fontWeight: 500,
                  bgcolor: 'red',
                  '&:hover': {
                    bgcolor: 'red',
                  },
                }}
              >
                Delete
              </Button>
            </Box>
          </>
        </Box>
      </Modal>
    </Box>
  );
};

export default FlagModal;
