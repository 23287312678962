import React, { FC, useEffect, useState } from 'react';
import { Box, Button, Checkbox, Collapse, Typography } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import request from '../../../api/request';

interface companyCategoryProps {
  account: any;
  companyId?: any;
  setAccount: React.Dispatch<React.SetStateAction<any>>;
  onNextClick?: () => void;
  handleCancelClick?: () => void;
}

// Custom Toast Component
const CompanyCategory: FC<companyCategoryProps> = ({
  account,
  companyId,
  setAccount,
  onNextClick,
  handleCancelClick,
}) => {
  const [open, setOpen] = React.useState('');
  const [category, setCategory] = useState<any>([]);
  console.log('category', category);

  // Check if a subcategory is selected in the state
  const isSubCategorySelected = (categoryName: string, subCategoryName: string): boolean => {
    const category = account?.company?.companyInfo?.category?.find(
      (cat: any) => cat?.name === categoryName,
    );

    // If subcategories are empty or don't exist, set default subcategory
    if (companyId?.length && (!category?.subCategory || category.subCategory.length === 0)) {
      const defaultSubCategory =
        category?.name === 'Materials'
          ? 'Adhesives'
          : category?.name === 'Equipment'
          ? '3D'
          : category?.name === 'Services'
          ? 'Accounting'
          : category?.name === 'Software'
          ? 'Accounting'
          : '';

      return defaultSubCategory === subCategoryName; // Select default subcategory
    }

    // Existing functionality: Check if the category and subcategory match
    return category?.subCategory?.includes(subCategoryName) ?? false;
  };

  const handleCheckboxChange = (categoryName: string, subCategoryName: string): void => {
    const updatedCategories = account?.company?.companyInfo?.category.map((cat: any) => {
      if (cat?.name === categoryName) {
        // If the subcategory is already selected, remove it
        if (cat?.subCategory.includes(subCategoryName)) {
          return {
            ...cat,
            subCategory: cat.subCategory.filter((sub: string) => sub !== subCategoryName),
          };
        }
        // Otherwise, add the new subcategory
        return {
          ...cat,
          subCategory: [...cat.subCategory, subCategoryName],
        };
      }
      return cat;
    });

    // If the category doesn't exist, add it
    if (!account?.company?.companyInfo?.category.some((cat: any) => cat.name === categoryName)) {
      updatedCategories.push({ name: categoryName, subCategory: [subCategoryName] });
    }

    setAccount({
      ...account,
      company: {
        ...account.company,
        companyInfo: {
          ...account?.company?.companyInfo,
          category: updatedCategories.filter((cat: any) => cat.subCategory.length > 0), // Remove empty categories
        },
      },
    });
  };
  const handleClick = (item: any): void => {
    setOpen(item);
  };
  const getCategories = (): void => {
    request('get', `/companies/categories`)
      .then((res: any) => {
        console.log('resv', res);
        setCategory(res?.categories);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };
  useEffect(() => {
    getCategories();
  }, []);

  return (
    <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
      <Box
        width={'50%'}
        borderRadius={'10px'}
        bgcolor={'#FFF'}
        boxShadow={'0px 1px 4px 0px rgba(148, 148, 148, 0.25)'}
        padding={'3%'}
        display={'flex'}
        justifyContent={'center'}
        sx={{
          '@media (max-width: 1200px)': {
            width: '60%',
          },
          '@media (max-width: 1024px)': {
            width: '70%',
          },
          '@media (max-width: 900px)': {
            width: '85%',
          },
          '@media (max-width: 768px)': {
            width: '100%',
            padding: '20px',
          },
        }}
      >
        <Box width={'100%'} display={'flex'} flexDirection={'column'} gap={'10px'}>
          <Typography
            color={'#00BD57'}
            fontFamily={'SF UI Display'}
            fontSize={'16px'}
            fontWeight={'700'}
            lineHeight={'normal'}
            textTransform={'uppercase'}
          >
            Page Setup (2 of 4)
          </Typography>
          <Typography
            color={'#000000'}
            fontFamily={'SF UI Display'}
            fontSize={'24px'}
            fontWeight={'800'}
            lineHeight={'normal'}
            sx={{
              '@media (max-width: 600px)': {
                fontSize: '22px',
              },
            }}
          >
            Got it! Now, add your company categories.
          </Typography>
          <Typography
            color={'#000000'}
            fontFamily={'SF UI Display'}
            fontSize={'16px'}
            fontWeight={'400'}
            lineHeight={'20px'}
            sx={{
              '@media (max-width: 600px)': {
                fontSize: '15px',
              },
            }}
          >
            Help others’ find your products or services by adding all the areas of the industry you
            supply or distribute to.
          </Typography>
          <Box
            width={'100%'}
            display={'flex'}
            flexDirection={'column'}
            gap={'30px'}
            maxHeight={'30vh'}
            margin={'30px 0'}
            sx={{
              overflowY: 'scroll',
              scrollbarWidth: 'none', // For Firefox
              '&::-webkit-scrollbar': {
                display: 'none', // For Chrome, Safari, and Edge
              },
            }}
          >
            {category?.map((item: any, index: number) => {
              return (
                <>
                  <Box key={index} width={'100%'}>
                    <Box
                      width={'100%'}
                      display={'flex'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        if (open !== item.category) {
                          handleClick(item.category);
                        } else {
                          handleClick('');
                        }
                      }}
                    >
                      <Typography
                        color={'#101828'}
                        fontFamily={'SF UI Display'}
                        fontSize={'18px'}
                        fontWeight={'600'}
                        lineHeight={'32px'}
                        whiteSpace={'nowrap'}
                        sx={{
                          '@media (max-width: 600px)': {
                            fontSize: '16px',
                          },
                        }}
                      >
                        {item.category}
                      </Typography>
                      <Box>
                        {open === item.category ? (
                          <ExpandLess fontSize="medium" />
                        ) : (
                          <ExpandMore fontSize="medium" />
                        )}
                      </Box>
                    </Box>

                    <Collapse in={open === item.category} timeout="auto" unmountOnExit>
                      {item?.subCategories?.map((subCategory: any, index: number) => {
                        return (
                          <Box
                            key={index}
                            display={'flex'}
                            alignItems={'center'}
                            gap={'15px'}
                            margin={'10px 0'}
                          >
                            <Checkbox
                              disabled={companyId?.length}
                              sx={{
                                padding: '0',
                                color: '#D0D5DD', // Unchecked state color
                                '&.Mui-checked': {
                                  color: '#00BD57', // Checked state color
                                },
                              }}
                              color="success"
                              checked={isSubCategorySelected(item?.category, subCategory)}
                              onChange={() => {
                                handleCheckboxChange(item?.category, subCategory);
                              }}
                            />
                            <Typography
                              color={'#101828'}
                              fontFamily={'SF UI Display'}
                              fontSize={'16px'}
                              fontWeight={'400'}
                              lineHeight={'32px'}
                              whiteSpace={'nowrap'}
                            >
                              {subCategory}
                            </Typography>
                          </Box>
                        );
                      })}
                    </Collapse>
                  </Box>
                </>
              );
            })}
          </Box>
          <Box
            width={'100%'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{
              '@media (max-width: 600px)': {
                gap: '10px',
              },
            }}
          >
            <Button
              sx={{
                width: '25%',
                display: 'flex',
                padding: '10px 16px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '6px',
                borderRadius: '8px',
                border: '1px solid D0D5DD',
                background: '#fff',
                color: '#000000',
                fontFamily: 'SF UI Display',
                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '24px',
                margin: '10px 0',
                boxShadow:
                  'inset 0 0 0 1px rgba(16, 24, 40, 0.18), inset 0 -2px 0 rgba(16, 24, 40, 0.05), 0 1px 2px rgba(16, 24, 40, 0.05)',
                textTransform: 'capitalize',
                '&:hover': {
                  bgcolor: '#fff',
                },
                '@media (max-width: 600px)': {
                  width: '100%',
                },
              }}
              onClick={handleCancelClick}
            >
              Back
            </Button>
            <Button
              sx={{
                width: '25%',
                display: 'flex',
                padding: '10px 16px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '6px',
                borderRadius: '8px',
                bgcolor: !account?.company?.companyInfo?.category?.length ? '#F7F7F7' : '#00BD57',
                // background: '#00BD57',
                color: !account?.company?.companyInfo?.category?.length ? '#B8B8B8' : '#fff',
                // color: '#FFF',
                fontFamily: 'SF UI Display',
                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '24px',
                margin: '10px 0',
                textTransform: 'capitalize',
                '&:hover': {
                  bgcolor: !account?.company?.companyInfo?.category?.length ? '#F7F7F7' : '#00BD57',
                },
                '@media (max-width: 600px)': {
                  width: '100%',
                },
              }}
              disabled={!account?.company?.companyInfo?.category?.length}
              onClick={onNextClick}
            >
              Next
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CompanyCategory;
