import React, { FC } from 'react';
import { Box, Button } from '@mui/material';
import logo from '../../../assets/SignWiselogo .svg';

const Header: FC = () => {
  return (
    <Box
      width="100vw"
      display="flex"
      justifyContent="center"
      alignItems="center"
      alignSelf="center"
      padding="1.5% 0"
    >
      <Box width="95.4%">
        <Box width="100%" display="flex" gap="20px" alignItems="center">
          <Box width="100px">
            <img src={logo} alt="Logo" style={{ width: '100%', cursor: 'pointer' }} />
          </Box>
          <Box width="90%" display="flex" justifyContent="space-between" alignItems="center">
            <Button
              sx={{
                bgcolor: '#00BD57',
                borderRadius: '50px',
                color: 'white',
                fontFamily: 'SF UI Display',
                fontWeight: '700',
                fontSize: '14px',
                lineHeight: '21px',
                textAlign: 'center',
                textTransform: 'capitalize',
                padding: '5px 1.5%',
                '&:hover': {
                  bgcolor: '#00BD57',
                },
              }}
            >
              Setting
            </Button>
            <Button
              sx={{
                bgcolor: 'transparent',
                borderRadius: '8px',
                color: '#00BD57',
                fontFamily: 'Poppins',
                fontWeight: '700',
                fontSize: '14px',
                lineHeight: '16.71px',
                textAlign: 'center',
                textTransform: 'capitalize',
                border: 'solid #00BD57 1px',
                padding: '10px 1.5%',
                '&:hover': {
                  bgcolor: 'transparent',
                },
              }}
            >
              Save & Exit
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
