import React, { FC } from 'react';
import { Box, Grid, MenuItem, ListItemIcon, Typography } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Sidebarlogo from '../../../assets/Settings..svg';

const SettingSideBar: FC = () => {
  return (
    <Box width="25%" bgcolor=" #F5F3F3">
      <Grid container height="100%" display="flex" flexDirection="row" position="relative">
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          flexDirection="column"
          bgcolor="F5F3F3"
          padding={'55px 11px 11px 11px'}
          gap={'10px'}
        >
          <Box display="flex" flexDirection="column" alignItems="flex-start" gap={'10px'}>
            <Box width={'80%'}>
              <img src={Sidebarlogo} alt="Logo" />
            </Box>
            <Box marginBottom="20px">
              <Typography
                fontSize={'16px'}
                fontWeight={'400'}
                color={'#000000'}
                lineHeight={'19px'}
                fontFamily={'SF UI Display'}
              >
                Edit or update any of your account and team <br />
                information here.
              </Typography>
            </Box>
          </Box>

          <Box width="86%" textAlign="left" display={'flex'} flexDirection={'column'}>
            <MenuItem
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottom: '1px solid #ccc',
                fontSize: '16px',
                fontWeight: '700',
                color: '#000000',
                padding: '30px 0px ',
                lineHeight: '19.09px',
              }}
            >
              <Box
                display={'flex'}
                gap={'20px'}
                lineHeight={'19.09px'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <span>Account Info</span>
              </Box>

              <ListItemIcon
                sx={{
                  minWidth: '12px !important',
                }}
              >
                <ArrowForwardIosIcon
                  sx={{
                    color: '#000000 ',
                    width: '21.07px',
                    height: '17.39px',
                  }}
                />{' '}
              </ListItemIcon>
            </MenuItem>
            <MenuItem
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottom: '1px solid #ccc',
                fontSize: '16px',
                fontWeight: '700',
                padding: '30px 0px ',

                color: '#000000',
                lineHeight: '19.09px',
              }}
            >
              <Box
                display={'flex'}
                gap={'20px'}
                lineHeight={'19.09px'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <span>Billing</span>
              </Box>

              <ListItemIcon
                sx={{
                  minWidth: '12px !important',
                }}
              >
                <ArrowForwardIosIcon
                  sx={{
                    color: '#000000 ',
                    width: '21.07px',
                    height: '17.39px',
                  }}
                />{' '}
              </ListItemIcon>
            </MenuItem>
            <MenuItem
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottom: '1px solid #ccc',
                fontSize: '16px',
                fontWeight: '700',
                padding: '30px 0px ',

                color: '#000000',
                lineHeight: '19.09px',
              }}
            >
              <Box
                display={'flex'}
                gap={'20px'}
                lineHeight={'19.09px'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <span>Team Members</span>
              </Box>

              <ListItemIcon
                sx={{
                  minWidth: '12px !important',
                }}
              >
                <ArrowForwardIosIcon
                  sx={{
                    color: '#000000 ',
                    width: '21.07px',
                    height: '17.39px',
                  }}
                />{' '}
              </ListItemIcon>
            </MenuItem>
            <MenuItem
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottom: '1px solid #ccc',
                fontSize: '16px',
                fontWeight: '700',
                padding: '25px 0px ',

                color: '#000000',
                lineHeight: '19.09px',
              }}
            >
              <Box
                display={'flex'}
                gap={'20px'}
                lineHeight={'19.09px'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <span>Membership Benefits</span>
              </Box>

              <ListItemIcon
                sx={{
                  minWidth: '12px !important',
                }}
              >
                <ArrowForwardIosIcon
                  sx={{
                    color: '#000000 ',
                    width: '21.07px',
                    height: '17.39px',
                  }}
                />{' '}
              </ListItemIcon>
            </MenuItem>
            <MenuItem
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottom: '1px solid #ccc',
                fontSize: '16px',
                fontWeight: '700',
                padding: '25px 0px ',

                color: '#000000',
                lineHeight: '19.09px',
              }}
            >
              <Box
                display={'flex'}
                gap={'20px'}
                lineHeight={'19.09px'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <span>Notification Settings </span>
              </Box>

              <ListItemIcon
                sx={{
                  minWidth: '12px !important',
                }}
              >
                <ArrowForwardIosIcon
                  sx={{
                    color: '#000000 ',
                    width: '21.07px',
                    height: '17.39px',
                  }}
                />{' '}
              </ListItemIcon>
            </MenuItem>
          </Box>
        </Box>
      </Grid>
    </Box>
  );
};

export default SettingSideBar;
