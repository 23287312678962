import React, { FC, useEffect, useState } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import SideBar from '../../components/phase1a/local/LocalSetUpSidebar.component';
// import Header from '../../components/phase1a/local/Header.component';
import AccountsInfo from './settingComponents/AccountsInfo';
import TeamMembers from './settingComponents/TeamMembers';
import request from '../../api/request';
import NotificationImg from '../../assets/Push notifications.svg';
import { IResponseTeamMembers, teamMember } from '../../types/user';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import { toast } from 'react-toastify';
import CustomToast from '../../components/phase1a/common/CustomToast.component';

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const LocalPageSetUp: FC = () => {
  const [selected, setSelected] = useState('accountinfo');
  const userJson = localStorage.getItem('user');
  const parsedUser = userJson ? JSON.parse(userJson) : undefined;
  const [teamMembers, setTeamMembers] = useState<teamMember[]>();
  const [checked, setChecked] = useState<boolean>(parsedUser?.notification);

  const getTeamMembersById = (): void => {
    request<IResponseTeamMembers>(
      'get',
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `/users/getFellowMembers/${parsedUser?.company._id}`,
    )
      .then((res) => {
        console.log(res);
        setTeamMembers(res?.users);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const allowNotifications = (status: boolean): void => {
    request(
      'patch',
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `/companies/notification/${parsedUser.company._id}`,
      {
        notification: status,
      },
    )
      .then((res) => {
        setChecked(status);
        parsedUser.notification = status;
        localStorage.setItem('user', JSON.stringify(parsedUser));
        window.dispatchEvent(new Event('notify'));
        // toast.success('Status Updated.');
        toast(<CustomToast message={'Status Updated.'} type="success" />);
        console.log(res);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        console.log(err);
      });
  };

  useEffect(() => {
    getTeamMembersById();
  }, []);

  return (
    <Box overflow="hidden" width="100%">
      {/* <Header noAccount /> */}
      <Grid
        container
        sx={{
          display: 'flex',
          flexDirection: 'row',
          bgcolor: '#F5F3F3',
        }}
      >
        <SideBar setSelected={setSelected} selected={selected} />
        <Box
          width={window.innerWidth < 1000 ? '100%' : '75%'}
          bgcolor="#F5F3F3"
          padding={'10px'}
          flexDirection="column"
          sx={{
            '@media (max-width: 1024px)': {
              width: '100%',
              padding: '0',
            },
          }}
        >
          <Box
            width="96%"
            mt="3%"
            padding="1% 2.5% 2.5% 2.5%"
            minHeight="90vh"
            bgcolor="white"
            // overflow="auto"
            borderRadius="10px"
            display="flex"
            justifyContent="space-between"
            sx={{
              '@media (max-width: 1024px)': {
                width: '100%',
                padding: '5% 0',
              },
              '@media (max-width: 768px)': {
                marginTop: '0%',
              },
            }}
          >
            {selected === 'accountinfo' && <AccountsInfo parsedUser={parsedUser} />}
            {selected === 'teamMember' && (
              <TeamMembers
                teamMembers={teamMembers}
                parsedUser={parsedUser}
                getTeamMembersById={getTeamMembersById}
              />
            )}
            {selected === 'notification' && (
              <Box padding={'5%'} display={'flex'} width={'100%'} flexDirection={'column'}>
                <Box display={'flex'} width={'100%'}>
                  <Typography
                    fontFamily="SF UI Display"
                    fontWeight={700}
                    fontSize="32px"
                    lineHeight="19.09px"
                    textAlign="right"
                    color="#000000"
                    marginBottom={'5%'}
                  >
                    Notification Settings
                  </Typography>
                </Box>
                <Box
                  display={'flex'}
                  width={'100%'}
                  justifyContent={'space-between'}
                  sx={{
                    '@media (max-width: 500px)': {
                      marginTop: '15px',
                    },
                  }}
                >
                  <Typography
                    fontFamily={'SF UI Display'}
                    fontSize={'24px'}
                    fontWeight={700}
                    marginBottom={'1%'}
                    sx={{
                      '@media (max-width: 500px)': {
                        fontSize: '20px',
                      },
                    }}
                  >
                    Allow Review Notifications
                  </Typography>
                  <IOSSwitch
                    checked={checked}
                    onChange={(e) => {
                      allowNotifications(e.target.checked);
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </Box>

                <Box
                  display={'flex'}
                  width={'100%'}
                  sx={{
                    '@media (max-width: 500px)': {
                      justifyContent: 'center',
                      alignItems: 'center',
                    },
                  }}
                >
                  <Typography
                    fontFamily="SF UI Display"
                    fontWeight={500}
                    fontSize="16px"
                    lineHeight="19.09px"
                    // textAlign="right"
                    color="#000000"
                    sx={{
                      '@media (max-width: 500px)': {
                        margin: '15px 0',
                        textAlign: 'center',
                        width: '95%',
                      },
                    }}
                  >
                    If this is on, you will receive notifications when somebody submits a review
                    about your company.
                  </Typography>
                </Box>

                <Box display={'flex'} width={'100%'} height={'50vh'}>
                  <img
                    src={NotificationImg}
                    alt="Notification Image"
                    style={{ width: '100%', height: 'auto' }}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Grid>
    </Box>
  );
};

export default LocalPageSetUp;
