import React, { FC, useEffect, useState } from 'react';

import { Box, Avatar, Popover, Typography, Divider, Button } from '@mui/material';
import Notification from '../../assets/notifications.svg';
import logo from '../../assets/beta/signwise-logo-beta.svg';
// import search from '../../assets/search.svg';
import SettingIcon from '../../assets/header/settingIcon.svg';
import SignOutIcon from '../../assets/header/signoutIcon.svg';
import { Link, useNavigate } from 'react-router-dom';
import DashBoardIcon from '../../assets/header/DashboardIcon.svg';
import io from 'socket.io-client';
import { mediaUrl } from '../../config';
import request from '../../api/request';
import { UpdateStore } from '../../context/store';
interface HeaderProps {
  claimStatus?: any;
}
const Header: FC<HeaderProps> = ({ claimStatus }) => {
  const updateStore = UpdateStore();
  const nav = useNavigate();
  const userJSON = localStorage.getItem('user');
  const signIn = userJSON ? JSON.parse(userJSON) : '';
  const [count, setCount] = useState<any>(0);
  const [notifications, setNotification] = useState<any>([]);
  const [openPopover, setOpenPopover] = useState<HTMLDivElement | null>(null);
  const [profilePopupAnchor, setProfilePopupAnchor] = useState<HTMLDivElement | null>(null);
  // const [user, setUser] = useState<any>();
  const [allowNotification, setAllowNotification] = useState(signIn);
  const pathname = window.location.pathname;
  const getNotificationCount = (): void => {
    request('get', '/notifications/unreadCount')
      .then((res: any) => {
        setCount(res?.unread);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };
  const getNotification = (): void => {
    request('get', '/notifications/')
      .then((res: any) => {
        setNotification(res?.notifications);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };
  // const getUserData = (): void => {
  //   request('get', '/auth/userDetails')
  //     .then((res: any) => {
  //       setUser(res?.user);
  //     })
  //     .catch((err) => {
  //       console.log('err', err);
  //     });
  // };
  const handleAvatarClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    setProfilePopupAnchor(profilePopupAnchor ? null : event.currentTarget);
  };
  const handleNotificationClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    setOpenPopover(profilePopupAnchor ? null : event.currentTarget);
    request('patch', '/notifications/:id')
      .then((res) => {
        console.log('err', res);
        getNotificationCount();
      })
      .catch((err) => {
        console.log('err', err);
      });
  };
  useEffect(() => {
    getNotificationCount();
    getNotification();
  }, []);
  // useEffect(() => {
  //   getUserData();
  // }, []);
  console.log(count);
  useEffect(() => {
    const socket = io(mediaUrl); // Replace with your server URL

    // Listen for the specific notification event
    const userId = signIn?._id; // Replace with the actual user ID
    /* eslint-disable @typescript-eslint/restrict-template-expressions */
    const eventName = `notification_${userId}`;
    socket.on(eventName, (notification) => {
      getNotification();
      getNotificationCount();
      console.log(notification);
    });

    // Cleanup on unmount
    return () => {
      socket.off(eventName);
      socket.close();
    };
  }, []);
  useEffect(() => {
    const handleStorageEvent = (): void => {
      const userJSON = localStorage.getItem('user');
      if (userJSON) {
        setAllowNotification(JSON.parse(userJSON));
      } else {
        setAllowNotification(signIn);
      }
    };

    // Add event listener for 'storage' event
    window.addEventListener('notify', handleStorageEvent);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('notify', handleStorageEvent);
    };
  }, []);
  const checkUserCompany = (): any => {
    if (
      signIn?.type === 'member' &&
      (signIn?.company?.companyInfo?.companyType === 'distributor' ||
        signIn?.company?.companyInfo?.companyType === 'supplier')
    ) {
      return false;
    } else {
      return true;
    }
  };

  // Cleanup on unmountreturn () => { newSocket.close(); }; }, [url]);
  return (
    <Box
      width="100vw"
      display="flex"
      justifyContent="center"
      alignItems="center"
      alignSelf="center"
      padding="1.5% 0 12px 0"
      borderBottom={'1px solid #E8E8E8'}
    >
      <Box width="93%">
        <Box width="100%">
          <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
            <Box display={'flex'} gap={'20px'}>
              <Box width="145px">
                <Link to="/">
                  <img
                    src={logo}
                    alt="Logo"
                    style={{ width: '100%', cursor: claimStatus ? 'default' : 'pointer' }}
                  />
                </Link>
              </Box>
              {pathname === '/localpageowner/SettingPagesetup' ? (
                <Button
                  sx={{
                    width: '103px',
                    height: '33px',
                    borderRadius: '50px',
                    bgcolor: '#00BD57',
                    fontSize: '14px',
                    lineHeight: '16.71px',
                    textAlign: 'center',
                    fontWeight: '700',
                    textTransform: 'unset',
                    fontFamily: 'SF UI Display',
                    color: 'white',
                    '&:hover': {
                      bgcolor: '#00BD57',
                    },
                  }}
                >
                  Settings
                </Button>
              ) : (
                ''
              )}
            </Box>
            <Box display="flex" alignItems="center" gap="25px">
              {/* <Box
                width="25px"
                onClick={() => {
                  nav('/');
                }}
              >
                <img src={search} alt="Search" style={{ width: '100%', cursor: 'pointer' }} />
              </Box> */}
              {allowNotification?.notification &&
              signIn?.company?.companyInfo?.verification?.status &&
              checkUserCompany() ? (
                <Box
                  width="25px"
                  position="relative"
                  onClick={(e) => {
                    if (claimStatus) return;
                    handleNotificationClick(e);
                  }}
                >
                  <img
                    src={Notification}
                    alt="Notification"
                    style={{
                      width: '100%',
                      cursor: claimStatus ? 'default' : 'pointer',
                      opacity: claimStatus ? 0.5 : 1,
                    }}
                  />
                  {count !== 0 && (
                    <>
                      {' '}
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        bgcolor="#00BD57"
                        color="#FFFFFF"
                        fontSize="9px"
                        fontWeight="500"
                        fontFamily="Inter"
                        width="14px"
                        height="14px"
                        borderRadius="50%"
                        position="absolute"
                        top="0"
                        right="0"
                      >
                        {count ?? '0'}
                      </Box>
                    </>
                  )}
                </Box>
              ) : (
                ''
              )}
              {openPopover && (
                <Popover
                  open={Boolean(openPopover)}
                  anchorEl={openPopover}
                  onClose={() => {
                    setOpenPopover(null);
                    getNotification();
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  sx={{
                    '& .MuiPaper-root': {
                      borderRadius: '10px',
                      border: 'solid 1px #EAECF0',
                    },
                  }}
                >
                  <Box
                    width="350px"
                    display="flex"
                    // justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    borderRadius="10px"
                    maxHeight="60vh"
                    padding={'13px'}
                  >
                    {notifications?.length ? (
                      notifications.map((item: any, index: number) => {
                        return (
                          <>
                            <Box
                              key={index}
                              bgcolor={item?.read === false ? '#DFFFED' : '#f8f8f8'}
                              padding={'10px'}
                            >
                              <Typography
                                fontSize={'18px'}
                                fontWeight={'600'}
                                textTransform={'capitalize'}
                              >
                                {/* {item?.data?.type} */}
                                Notification
                              </Typography>
                              <Typography fontSize={'16px'} fontWeight={'400'} padding={'0% 4%'}>
                                {item?.message}
                              </Typography>
                            </Box>
                            <Divider sx={{ bgcolor: '#EBEEF1', width: '100%' }} />
                          </>
                        );
                      })
                    ) : (
                      <Typography textAlign={'left'} width={'100%'}>
                        No Notification Found
                      </Typography>
                    )}
                  </Box>
                </Popover>
              )}

              <Box width="34px" onClick={handleAvatarClick} sx={{ cursor: 'pointer' }}>
                <Avatar
                  /* eslint-disable @typescript-eslint/restrict-template-expressions */
                  // src={signIn?.photo ? `${mediaUrl}/${signIn?.photo}` : ''}
                  // alt="User Avatar"
                  src={signIn?.photo ? `${mediaUrl}/${signIn?.photo}` : `${signIn?.first_name}`}
                  alt={signIn?.first_name}
                  sx={{ bgcolor: 'green' }}
                />
              </Box>
              {profilePopupAnchor && (
                <Popover
                  open={Boolean(profilePopupAnchor)}
                  anchorEl={profilePopupAnchor}
                  onClose={() => {
                    setProfilePopupAnchor(null);
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  sx={{
                    '& .MuiPaper-root': {
                      borderRadius: '10px',
                      border: 'solid 1px #EAECF0',
                    },
                  }}
                >
                  <Box
                    width="263px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    borderRadius="10px"
                  >
                    <Box
                      width="100%"
                      p="0 4%"
                      m="4% 0"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        if (checkUserCompany()) {
                          nav('/localpageowner/ReviewsDashoard');
                        } else {
                          nav('/localpageowner/SettingPagesetup');
                        }
                        setProfilePopupAnchor(null);
                      }}
                    >
                      <Box width="20%">
                        <Avatar
                          /* eslint-disable @typescript-eslint/restrict-template-expressions */
                          src={
                            signIn?.photo ? `${mediaUrl}/${signIn?.photo}` : `${signIn?.first_name}`
                          }
                          alt={signIn?.first_name}
                          sx={{ bgcolor: 'green' }}
                        />
                      </Box>
                      <Box width="80%">
                        <Typography
                          fontFamily="SF UI Display"
                          fontSize="14px"
                          fontWeight="600"
                          lineHeight="20px"
                          color="#344054"
                          textTransform={'capitalize'}
                        >
                          {signIn?.first_name} {signIn?.last_name}
                        </Typography>
                        <Typography
                          fontFamily="SF UI Display"
                          fontSize="14px"
                          fontWeight="400"
                          lineHeight="20px"
                          color="#475467"
                          textTransform={'capitalize'}
                        >
                          {signIn?.company?.companyInfo?.name}
                        </Typography>
                      </Box>
                    </Box>
                    <Divider sx={{ bgcolor: '#EBEEF1', width: '100%' }} />
                    <Box
                      width="100%"
                      p="0 4%"
                      m="4% 0"
                      display="flex"
                      alignItems="center"
                      gap="5px"
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        if (checkUserCompany()) {
                          nav('/localpageowner/ReviewsDashoard');
                        } else {
                          nav('/localpageowner/SettingPagesetup');
                        }
                        setProfilePopupAnchor(null);
                      }}
                    >
                      <img
                        src={DashBoardIcon}
                        alt=".."
                        style={{ width: '13.33px', height: '13.33px' }}
                      />
                      <Typography
                        fontFamily="SF UI Display"
                        fontWeight="500"
                        lineHeight="20px"
                        fontSize="14px"
                      >
                        Dashboard
                      </Typography>
                    </Box>
                    <Divider sx={{ bgcolor: '#EBEEF1', width: '100%' }} />
                    <Box
                      width="100%"
                      p="0 4%"
                      m="4% 0"
                      display="flex"
                      alignItems="center"
                      gap="5px"
                      sx={{
                        cursor:
                          !signIn?.company?.companyInfo?.verification?.status || claimStatus
                            ? 'default'
                            : 'pointer',
                        opacity:
                          !signIn?.company?.companyInfo?.verification?.status || claimStatus
                            ? 0.5
                            : 1,
                      }}
                      onClick={() => {
                        if (signIn?.company?.companyInfo?.verification?.status && !claimStatus) {
                          nav('/localpageowner/SettingPagesetup');
                          setProfilePopupAnchor(null);
                        }
                      }}
                    >
                      <img
                        src={SettingIcon}
                        alt=".."
                        style={{ width: '13.33px', height: '13.33px' }}
                      />
                      <Typography
                        fontFamily="SF UI Display"
                        fontWeight="500"
                        lineHeight="20px"
                        fontSize="14px"
                      >
                        Account Settings
                      </Typography>
                    </Box>
                    <Divider sx={{ bgcolor: '#EBEEF1', width: '100%' }} />
                    <Box
                      width="100%"
                      p="0 4%"
                      m="4% 0"
                      display="flex"
                      alignItems="center"
                      gap="5px"
                      onClick={(): void => {
                        localStorage.removeItem('user');
                        localStorage.removeItem('token');
                        window.location.href = '/';
                        if (updateStore) {
                          updateStore({ user: null });
                        }
                      }}
                      sx={{
                        cursor: 'pointer',
                      }}
                    >
                      <img
                        src={SignOutIcon}
                        alt=".."
                        style={{ width: '13.33px', height: '13.33px' }}
                      />
                      <Typography
                        fontFamily="SF UI Display"
                        fontWeight="500"
                        lineHeight="20px"
                        fontSize="14px"
                        sx={{ cursor: 'pointer' }}
                      >
                        Sign Out
                      </Typography>
                    </Box>
                  </Box>
                </Popover>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default Header;
