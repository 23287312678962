import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import Always from '../../../assets/Phase1a/DesignUpdate/reviewAlways.svg';
import Often from '../../../assets/Phase1a/DesignUpdate/reviewOften.svg';
import Sometimes from '../../../assets/Phase1a/DesignUpdate/reviewSometimes.svg';
import Rarely from '../../../assets/Phase1a/DesignUpdate/reviewRarely.svg';
import Never from '../../../assets/Phase1a/DesignUpdate/reviewNever.svg';

interface ReviewSection4Props {
  step4Ref: any;
  companyData: any;
  updateRating: (fieldName: string, newValue: number) => void;
  review: any;
}

const deliveryOptions = [
  {
    id: 1,
    label: 'Always',
    description: 'They deliver on time and finish what they start.',
    imageSrc: Always,
    rating: 5,
  },
  {
    id: 2,
    label: 'Often',
    description: 'They deliver most projects on time and finish what they start.',
    imageSrc: Often,
    rating: 4,
  },
  {
    id: 3,
    label: 'Sometimes',
    description: 'They deliver on some projects and miss on others.',
    imageSrc: Sometimes,
    rating: 3,
  },
  {
    id: 4,
    label: 'Rarely',
    description: 'They have a hard time delivering in general.',
    imageSrc: Rarely,
    rating: 2,
  },
  {
    id: 5,
    label: 'Never',
    description: 'They don’t seem capable of delivering on any projects.',
    imageSrc: Never,
    rating: 1,
  },
];

const reviewSection4: FC<ReviewSection4Props> = ({
  step4Ref,
  companyData,
  updateRating,
  review,
}) => {
  const companyType = companyData?.companyInfo?.companyType;
  return (
    <Box
      ref={step4Ref}
      width={'100%'}
      margin={'40px 0'}
      display={companyType === 'supplier' || companyType === 'distributor' ? 'none' : 'block'}
    >
      <Typography
        fontSize={'20px'}
        fontWeight={'800'}
        fontFamily={'SF UI Display'}
        color={'#000000'}
        sx={{
          '@media (max-width: 500px)': {
            fontSize: '18px',
            lineHeight: 'normal',
          },
        }}
      >
        {`Does ${companyData?.companyInfo?.name as string} ${
          companyData?.companyInfo?.companyType === 'local' ? 'deliver' : 'pay'
        } on time?`}
      </Typography>
      <Box width={'100%'} margin={'40px 0'} display={'flex'} flexDirection={'column'} gap={'20px'}>
        {deliveryOptions.map((option) => (
          <Box
            key={option.id}
            width={'80%'}
            borderRadius={'12px'}
            border={review.lead_time === option.rating ? '2px solid #00BD57' : '2px solid #E4E7EC'}
            bgcolor={'#FFF'}
            padding={'15px 20px'}
            sx={{
              cursor: 'pointer',
              '@media (max-width: 1024px)': {
                width: '100%',
              },
              '@media (max-width: 992px)': {
                width: '70%',
              },
              '@media (max-width: 768px)': {
                width: '90%',
              },
              '@media (max-width: 500px)': {
                width: '100%',
              },
            }}
            onClick={() => {
              updateRating('lead_time', option.rating);
            }}
          >
            <Box display={'flex'} gap={'10px'}>
              <Box width={'34px'} height={'34px'}>
                <img
                  style={{ width: '100%', height: '100%' }}
                  src={option.imageSrc}
                  alt={option.label}
                />
              </Box>
              <Box>
                <Typography
                  fontSize={'16px'}
                  fontWeight={'600'}
                  fontFamily={'SF UI Display'}
                  color={'#000000'}
                  lineHeight={'20px'}
                >
                  {option.label}
                </Typography>
                <Typography
                  fontSize={'14px'}
                  fontWeight={'400'}
                  fontFamily={'SF UI Display'}
                  color={'#000000'}
                  lineHeight={'20px'}
                  letterSpacing={'-0.24px'}
                  sx={{
                    '@media (max-width: 500px)': {
                      lineHeight: '15px',
                    },
                  }}
                >
                  {option.description}
                </Typography>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default reviewSection4;
