import React, { FC } from 'react';
import Box from '@mui/material/Box';
import { TextField, Typography } from '@mui/material';
import Companyinfo from '../../../../assets/serviceArea.svg';
import { DataStructure } from '../../../../unAuth/phase1a/PersonalInformation.unAuth';
import { CustomizedHook } from '../../../../components/Autocomplete/autoComplete';

interface ServiceAreaProps {
  data: DataStructure;
  setData: React.Dispatch<React.SetStateAction<DataStructure>>;
}

const ServiceArea: FC<ServiceAreaProps> = ({ data, setData }) => {
  // const [selectedStates, setSelectedStates] = useState<string[]>([]);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setData((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };
  // const handleSelectedStatesChange = (states: string[]): void => {
  //   setSelectedStates(states);
  //   setData((prevData) => ({
  //     ...prevData,
  //     serviceArea: {
  //       ...prevData.serviceArea,
  //       state: states,
  //     },
  //   }));
  // };
  const handleSelectedStatesChange = (states: string[]): void => {
    const formattedServiceArea = states.map((state) => ({ name: state }));
    setData((prevData) => ({
      ...prevData,
      serviceArea: formattedServiceArea,
    }));
  };

  const serviceAreas = [{ id: 'service-states', label: 'Service States' }];

  // const handleAreaChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
  //   const { value } = event.target;
  //   setData((prevData) => ({
  //     ...prevData,
  //     serviceArea: {
  //       ...prevData.serviceArea,
  //       area: value,
  //     },
  //   }));
  // };

  // const handleSelectedStatesChange = (states: string[]): void => {
  //   const formattedServiceArea = states.map((state) => ({ name: state }));
  //   setData((prevData) => ({
  //     ...prevData,
  //     serviceArea: formattedServiceArea,
  //   }));
  // };

  return (
    <>
      <Box
        width="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection={'column'}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <img
            src={Companyinfo}
            alt="Company Logo"
            style={{ width: '100px', marginBottom: '15px' }}
          />

          <Typography
            fontSize="24px"
            fontWeight="800"
            lineHeight="28.64px"
            fontFamily="SF UI Display"
            color=" #000000"
            id="modal-modal-title"
          >
            Service Area
          </Typography>
          <Typography
            fontFamily="SF UI Display"
            fontSize="16px"
            fontWeight="400"
            lineHeight="19.09px"
            color=" #000000"
            marginTop="4px"
            id="modal-modal-title"
            mb={5}
            mt={2}
            sx={{
              '@media (max-width: 425px)': {
                fontSize: '14px',
                textAlign: 'center',
              },
            }}
          >
            Define all the service areas you do work in to show up on all relevant searches for
            buyers and nationals.
          </Typography>
        </Box>
        <Box
          width={'80%'}
          marginTop={'1%'}
          display={'flex'}
          flexDirection={'column'}
          gap="10px"
          sx={{
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <Box
            width={'100%'}
            display={'flex'}
            gap={'10px'}
            flexDirection={'column'}
            sx={{
              overflowY: 'auto',
            }}
          >
            {serviceAreas.map((area) => (
              <Box
                key={area.id}
                display={'flex'}
                flexDirection={'column'}
                gap={'10px'}
                width={'100%'}
                padding={'2% 4%'}
              >
                <Typography
                  fontSize={'16px'}
                  fontWeight={'500'}
                  fontFamily={'SF UI Display'}
                  lineHeight={'19.09px'}
                  color={'#000000'}
                >
                  {area.label}
                </Typography>
                <CustomizedHook
                  selectedStates={data.serviceArea.map((area) => area?.name)}
                  onChange={handleSelectedStatesChange}
                />
              </Box>
            ))}
          </Box>
          <Box padding="2% 4%">
            <Typography
              fontSize={'16px'}
              fontWeight={'500'}
              fontFamily={'SF UI Display'}
              lineHeight={'19.09px'}
              color={'#000000'}
              mb={'1.5%'}
            >
              Service Area Details
            </Typography>
            <TextField
              multiline
              rows={4}
              sx={{
                width: '100%',
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '24px',
                color: '#667085',
                borderRadius: '8px',
                '.MuiOutlinedInput-input': {
                  padding: '0px',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '1px solid #D0D5DD',
                    outline: 'none',
                  },
                  '&:hover fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.MuiInputBase-root:focus-visible': {
                    borderColor: '#D0D5DD',
                  },
                },
              }}
              id="serviceAreaDescription"
              variant="outlined"
              placeholder="Enter details here"
              name="serviceAreaDescription"
              value={data.serviceAreaDescription}
              onChange={handleChange}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ServiceArea;
