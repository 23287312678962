import React, { FC } from 'react';
import { Box, Typography, Modal } from '@mui/material';
import ReviewSubmittedIcon from '../../../assets/Frame.svg';
import CloseIcon from '../../../assets/CloseIcon.svg';

const style = {
  position: 'absolute',
  maxHeight: '90vh',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '700px',
  bgcolor: '#ffffff',
  boxShadow: 24,
  borderRadius: '10px',
  p: 4,
  // overflowY: 'auto',
  '@media (max-width: 780px)': {
    width: '580px',
    padding: '20px',
  },
  '@media (max-width: 650px)': {
    width: '400px',
  },
  '@media (max-width: 450px)': {
    width: '380px',
  },
  '@media (max-width: 420px)': {
    width: '320px',
  },
};

interface ReviewCompanyProps {
  open: boolean;
  claimStatus?: boolean;
  onClose: () => void;
}

const ReviewCompany: FC<ReviewCompanyProps> = ({ open, onClose, claimStatus }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <>
          <Box
            width="100%"
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            position="relative"
            mb={-3}
            mr={-3}
          >
            <img
              src={CloseIcon}
              alt="Close"
              onClick={onClose}
              style={{
                cursor: 'pointer',
                position: 'absolute',
                right: '3%',
                top: '5%',
                zIndex: 1,
              }}
            />
          </Box>
          <Box
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="relative"
            marginTop={'50px'}
            marginBottom={'30px'}
          >
            <img
              src={ReviewSubmittedIcon}
              alt="Verification Logo"
              style={{ width: '100px', height: '100px' }}
            />
          </Box>
          <Box width="100%" display="flex" justifyContent="center" alignItems="center">
            <Typography
              fontSize={'24px'}
              fontWeight={'800'}
              fontFamily={'SF UI Display'}
              color={'#000000'}
              lineHeight={'28px'}
              id="modal-modal-title"
            >
              {claimStatus
                ? 'Your claim request is under review.'
                : 'This company is under review.'}
            </Typography>
          </Box>
          <Typography sx={{ mt: 2, mb: 4, textAlign: 'center', fontFamily: 'SF UI Display' }}>
            Once {claimStatus ? 'your claim' : 'this company'} is approved by an admin, you’ll be
            able to submit reviews, please check again later.
          </Typography>
        </>
      </Box>
    </Modal>
  );
};

export default ReviewCompany;
