import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PersonalInfoIcon from '../../assets/Phase1a/SignUp/signUpiIcon.svg';

interface SuccessModalProps {
  open: boolean;
  handleClose: () => void;
}

const SuccessModal: React.FC<SuccessModalProps> = ({ open, handleClose }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 670,
          bgcolor: 'background.paper',
          //   border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          borderRadius: '10px',
        }}
      >
        <Box
          display={'flex'}
          justifyContent={'center'}
          flexDirection={'column'}
          alignItems={'center'}
          gap={'10px'}
        >
          <Box width={'100%'} display={'flex'} justifyContent={'flex-end'}>
            <CloseIcon
              onClick={handleClose}
              sx={{
                cursor: 'pointer',
              }}
            />
          </Box>
          <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            flexDirection={'column'}
            gap={'20px'}
          >
            <Box width={'93px'} height={'93px'}>
              <img src={PersonalInfoIcon} alt="." style={{ width: '100%', height: '100%' }} />
            </Box>

            <Typography
              fontFamily={'SF UI Display'}
              fontWeight={800}
              fontSize={'24px'}
              lineHeight={'28.64px'}
              textAlign={'center'}
            >
              Profile Update
            </Typography>
          </Box>

          <Typography
            fontFamily={'SF UI Display'}
            mt={1}
            fontWeight={400}
            fontSize={'16px'}
            lineHeight={'19.09px'}
            textAlign={'center'}
            color={'rgba(0, 0, 0, 1)'}
          >
            Your profile update request is under review. Please wait for approval. <br />
            You will be notified once admin approves your request.
          </Typography>
          <Box display={'flex'} justifyContent={'flex-end'} alignItems={'flex-end'} width={'100%'}>
            <Button
              onClick={handleClose}
              variant="contained"
              sx={{
                mt: 2,
                bgcolor: 'rgba(0, 189, 87, 1)',
                '&:hover': {
                  bgcolor: 'rgba(0, 189, 87, 1)', // Same background color on hover
                },
                width: '90px',
                height: '41px',
                borderRadius: '5px',
                fontFamily: 'SF UI Display',
                fontWeight: 700,
                lineHeight: '19.09px',
                color: 'rgba(255, 255, 255, 1)',
              }}
            >
              OK
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default SuccessModal;
