import React, { FC, useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import Success from '../../../assets/Phase1a/DesignUpdate/toastSuccess.svg';
import { ReactComponent as CompanyLogo } from '../../../assets/Phase1a/addBusiness/CompanyIconClear.svg';
import HelpCircle from '../../../assets/Phase1a/addBusiness/help-circle.svg';
import { useNavigate } from 'react-router-dom';
import request from '../../../api/request';
import { mediaUrl } from '../../../config';
import CompanyDefault from '../../../assets/dummyimg.png';
import usaStatesAndCanadaProvinces from '../../../utils/states';

interface createCompanyProps {
  account: any;
  companyId: any;
  setAccount: React.Dispatch<React.SetStateAction<any>>;
  setCompanyId?: React.Dispatch<React.SetStateAction<any>>;
  onNextClick?: () => void;
}
// Custom Toast Component
const CreateCompany: FC<createCompanyProps> = ({
  account,
  companyId,
  setAccount,
  onNextClick,
  setCompanyId,
}) => {
  const nav = useNavigate();
  const [companiesNames, setCompaniesNames] = useState<any>([]);
  console.log('companiesNames', companiesNames);
  const getCompaniesName = (): void => {
    request('get', `companies/searchAutoCompleteList/local`)
      .then((response: any) => {
        console.log(response, 'companiesNames res');
        // const { uniqueNames } = response;
        // setCompaniesNames(uniqueNames);
      })
      .catch((error) => {
        console.log(error, 'companiesNames err');
      });
  };
  const getCompaniesByName = (): void => {
    if (account?.company?.companyInfo?.name?.length) {
      /* eslint-disable @typescript-eslint/restrict-template-expressions */
      request('get', `/companies/searchByName/${account?.company?.companyInfo?.name}?type=local`)
        .then((response: any) => {
          console.log('getCompaniesByName', response?.companies);
          setCompaniesNames(response?.companies);
        })
        .catch((error) => {
          console.log(error, 'companiesNames err');
        });
    }
  };
  function getStateAbbreviation(additional: string): string {
    if (!additional) return '';

    // Split the 'additional' string by comma and trim any whitespace
    const parts = additional.split(',').map((part: string) => part.trim());

    // Extract the city name (first part) and the state name (last part)
    const cityName = parts[0];
    const stateName = parts[parts?.length - 1];

    // Find the state or province in the array and get its abbreviation
    const state = usaStatesAndCanadaProvinces.find(
      (item: any) => item?.name?.toLowerCase() === stateName?.toLowerCase(),
    );

    // Return formatted string as "City, State Abbreviation"
    return state ? `${cityName}, ${state?.abbreviation}` : additional;
  }
  useEffect(() => {
    getCompaniesName();
  }, []);
  useEffect(() => {
    getCompaniesByName();
  }, [account?.company?.companyInfo?.name?.length]);
  return (
    <Box
      width={'100%'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'space-between'}
      sx={{
        '@media (max-width: 900px)': {
          flexDirection: 'column',
          gap: '50px',
        },
      }}
    >
      <Box
        width={'43%'}
        display={'flex'}
        flexDirection={'column'}
        gap={'20px'}
        sx={{
          '@media (max-width: 1200px)': {
            width: '47%',
          },
          '@media (max-width: 900px)': {
            width: '80%',
          },
          '@media (max-width: 768px)': {
            width: '90%',
          },
          '@media (max-width: 600px)': {
            width: '100%',
          },
        }}
      >
        <Typography
          color={'#00BD57'}
          fontFamily={'SF UI Display'}
          fontSize={'16px'}
          fontWeight={'700'}
          lineHeight={'normal'}
          textTransform={'uppercase'}
          sx={{
            '@media (max-width: 1200px)': {
              fontSize: '15px',
            },
          }}
        >
          Join our Network today!
        </Typography>
        <Typography
          color={'#000000'}
          fontFamily={'SF UI Display'}
          fontSize={'40px'}
          fontWeight={'900'}
          lineHeight={'normal'}
          marginBottom={'40px'}
          sx={{
            '@media (max-width: 1200px)': {
              fontSize: '32px',
            },
            '@media (max-width: 768px)': {
              fontSize: '28px',
            },
            '@media (max-width: 600px)': {
              fontSize: '24px',
            },
          }}
        >
          {companyId?.length ? 'Claim' : 'Create'} a free local company page in 5 minutes
          <span style={{ color: '#00BF63' }}>.</span>
          {/* {getStateAbbreviation('ohio')} */}
        </Typography>
        <Box
          width={'80%'}
          display={'flex'}
          flexDirection={'column'}
          gap={'30px'}
          sx={{
            '@media (max-width: 600px)': {
              width: '100%',
            },
          }}
        >
          <Box display={'flex'} alignItems={'center'} gap={'15px'}>
            <Box
              width={'33px'}
              height={'33px'}
              sx={{
                '@media (max-width: 1200px)': {
                  width: '28px',
                  height: '28px',
                },
              }}
            >
              <img style={{ width: '100%', height: '100%' }} src={Success} />
            </Box>
            <Typography
              width={'90%'}
              color={'#000000'}
              fontFamily={'SF UI Display'}
              fontSize={'16px'}
              fontWeight={'400'}
              lineHeight={'normal'}
              sx={{
                '@media (max-width: 1200px)': {
                  width: '100%',
                  fontSize: '15px',
                },
              }}
            >
              <span style={{ fontWeight: '700' }}>Add Your Details. </span>Let others search for you
              by your services and service area without paying for it.
              {/* <span style={{ fontWeight: '700' }}>Boost Your Reputation. </span>Establish trust and
              build more confidence in your business. */}
            </Typography>
          </Box>
          <Box display={'flex'} alignItems={'center'} gap={'15px'}>
            <Box
              width={'33px'}
              height={'33px'}
              sx={{
                '@media (max-width: 1200px)': {
                  width: '28px',
                  height: '28px',
                },
              }}
            >
              <img style={{ width: '100%', height: '100%' }} src={Success} />
            </Box>
            <Typography
              width={'90%'}
              color={'#000000'}
              fontFamily={'SF UI Display'}
              fontSize={'16px'}
              fontWeight={'400'}
              lineHeight={'normal'}
              sx={{
                '@media (max-width: 1200px)': {
                  width: '100%',
                  fontSize: '15px',
                },
              }}
            >
              <span style={{ fontWeight: '700' }}>Showcase your work. </span>
              Gain more visibility and increase your access to new opportunities.
              {/* <span style={{ fontWeight: '700' }}>Drive New Growth. </span>
              Gain more visibility and increase your access to new opportunities. */}
            </Typography>
          </Box>{' '}
          <Box display={'flex'} alignItems={'center'} gap={'15px'}>
            <Box
              width={'33px'}
              height={'33px'}
              sx={{
                '@media (max-width: 1200px)': {
                  width: '28px',
                  height: '28px',
                },
              }}
            >
              <img style={{ width: '100%', height: '100%' }} src={Success} />
            </Box>
            <Typography
              width={'90%'}
              color={'#000000'}
              fontFamily={'SF UI Display'}
              fontSize={'16px'}
              fontWeight={'400'}
              lineHeight={'normal'}
              sx={{
                '@media (max-width: 1200px)': {
                  width: '100%',
                  fontSize: '15px',
                },
              }}
            >
              <span style={{ fontWeight: '700' }}>Connect with clients. </span>
              Let potential customers contact you directly and choose whether they are a good fit.
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        width={'50%'}
        borderRadius={'10px'}
        bgcolor={'#FFF'}
        boxShadow={'0px 1px 4px 0px rgba(148, 148, 148, 0.25)'}
        padding={'3% 0'}
        display={'flex'}
        justifyContent={'center'}
        sx={{
          '@media (max-width: 900px)': {
            width: '80%',
          },
          '@media (max-width: 768px)': {
            width: '90%',
          },
          '@media (max-width: 600px)': {
            width: '100%',
          },
        }}
      >
        <Box
          width={'65%'}
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          gap={'20px'}
          sx={{
            '@media (max-width: 1200px)': {
              width: '75%',
            },
            '@media (max-width: 1024px)': {
              width: '85%',
            },
            '@media (max-width: 768px)': {
              width: '85%',
            },
            '@media (max-width: 600px)': {
              width: '100%',
              padding: '20px',
            },
          }}
        >
          {/* <Box width={'54px'} height={'50px'}>
            <img
              style={{ width: '100%', height: '100%', imageRendering: '' }}
              src={CompanyLogo}
              alt="company icon"
            />
          </Box> */}
          <CompanyLogo width={'54px'} height={'50px'} />
          <Box
            width={'100%'}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            gap={'10px'}
          >
            <Typography
              color={'#000000'}
              fontFamily={'SF UI Display'}
              fontSize={'24px'}
              fontWeight={'800'}
              lineHeight={'28px'}
              textAlign={'center'}
              sx={{
                '@media (max-width: 1200px)': {
                  fontSize: '22px',
                },
              }}
            >
              Let’s Get Started.
            </Typography>
            <Typography
              color={'#000000'}
              fontFamily={'SF UI Display'}
              fontSize={'16px'}
              fontWeight={'400'}
              lineHeight={'20px'}
              textAlign={'center'}
              sx={{
                '@media (max-width: 1500px)': {
                  fontSize: '15px',
                },
              }}
            >
              {companyId?.length
                ? 'Make sure you have permission to claim this page. We may ask for verification before approving your claim.'
                : 'Make sure you enter your name correctly. If you’re already in our network, you can simply sign in.'}
            </Typography>
          </Box>
          <Autocomplete
            fullWidth
            freeSolo
            disableClearable
            // options={account?.company?.companyInfo?.name?.length ? companiesNames : []}
            options={companiesNames}
            getOptionLabel={(
              option: string | any,
              // IAddress
            ) => {
              if (typeof option === 'object') {
                return option.companyInfo?.name;
              } else {
                return option;
              }
            }}
            value={account?.company?.companyInfo?.name}
            onChange={(event, value) => {
              setAccount({
                ...account,
                company: {
                  ...account.company,
                  companyInfo: {
                    ...account.company.companyInfo,
                    name: value?.companyInfo?.name,
                  },
                },
              });
              setCompanyId?.(value?._id);
            }}
            // getOptionLabel={(option: any) => option?.name || ''}
            renderOption={(props: any, option: any) => (
              <Box
                component="li"
                {...props}
                sx={{
                  marginLeft: '20px',
                  display: 'flex',
                  gap: '16px',
                  // flexDirection: 'column',
                  alignItems: 'center !important',
                  justifyContent: 'flex-start !important',
                }}
              >
                <Box
                  width={'42px'}
                  height={'42px'}
                  borderRadius={'10px'}
                  sx={{ overflow: 'hidden' }}
                >
                  <img
                    style={{ width: '100%', height: '100%' }}
                    src={
                      option?.companyInfo?.logo
                        ? `${mediaUrl}/${option?.companyInfo?.logo}`
                        : CompanyDefault
                    }
                  />
                </Box>
                <Box width={'80%'}>
                  <Typography
                    color={'#5B5B5B'}
                    fontFamily={'SF UI Display'}
                    fontSize={'16px'}
                    fontWeight={'600'}
                    lineHeight={'normal'}
                    whiteSpace={'nowrap'}
                    overflow={'hidden'} // Hide overflowed text
                    textOverflow={'ellipsis'}
                  >
                    {option?.companyInfo?.name}
                  </Typography>
                  <Typography
                    color={'#9E9E9E'}
                    fontFamily={'SF UI Display'}
                    fontSize={'14px'}
                    fontWeight={'500'}
                    lineHeight={'normal'}
                    whiteSpace={'nowrap'}
                    overflow={'hidden'} // Hide overflowed text
                    textOverflow={'ellipsis'}
                  >
                    {getStateAbbreviation(
                      `${
                        option?.companyInfo?.address?.city ??
                        option?.companyInfo?.address?.place ??
                        option?.companyInfo?.address?.street
                      }, ${option?.companyInfo?.address?.state}`,
                    )}
                  </Typography>
                </Box>
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Enter your company name"
                sx={{
                  width: '100%',
                  fontFamily: 'Inter',
                  fontSize: '16px !important',
                  fontWeight: '400',
                  marginTop: '10px',
                  lineHeight: '24px',
                  textOverflow: 'ellipsis',
                  color: '#C0C0C0',
                  borderRadius: '8px',

                  boxShadow: '0px 1px 4px 0px rgba(148, 148, 148, 0.25)',
                  '.MuiOutlinedInput-input': {
                    padding: '8px 12px !important',
                    fontSize: '15px ',
                    '::placeholder': {
                      color: '#C0C0C0', // Set placeholder color here
                      opacity: 1, // Ensure the color is fully applied (some browsers reduce opacity by default)
                    },
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: '1px solid #D0D5DD',
                      borderRadius: '8px',
                      outline: 'none',
                    },
                  },
                  '@media (max-width: 900px)': {
                    fontSize: '14px',
                  },
                }}
                InputProps={{
                  ...params.InputProps, // Ensure merging of InputProps from params
                  endAdornment: (
                    <InputAdornment position="start">
                      {companyId?.length ? (
                        <img
                          src={Success}
                          alt="Trade Icon"
                          style={{
                            width: '23px',
                            height: '23px',
                          }}
                        />
                      ) : (
                        <Tooltip
                          title="Please ensure your company name matches your legal business name. As you type, we will display a list of already registered companies for easy selection."
                          arrow
                        >
                          <img
                            src={HelpCircle}
                            alt="Help Icon"
                            style={{
                              width: '16px',
                              height: '16px',
                              cursor: 'default',
                            }}
                          />
                        </Tooltip>
                      )}
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  ...params.inputProps,
                  maxLength: 70, // Character limit set here
                }}
                onChange={(e) => {
                  setAccount({
                    ...account,
                    company: {
                      ...account.company,
                      companyInfo: {
                        ...account.company.companyInfo,
                        name: e.target.value,
                      },
                    },
                  });
                  setCompanyId?.('');
                }}
                type="search"
              />
            )}
          />
          <Button
            fullWidth
            sx={{
              display: 'flex',
              padding: '10px 16px',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '6px',
              borderRadius: '8px',
              border: '2px solid rgba(255, 255, 255, 0.12)',
              background: '#00BD57',
              // background: !account?.company?.companyInfo?.name?.length ? 'lightgray' : '#00BD57',
              color: '#FFF',
              fontFamily: 'SF UI Display',
              fontSize: '16px',
              fontWeight: '600',
              lineHeight: '24px',
              margin: '10px 0',
              // boxShadow:
              //   'inset 0 0 0 1px rgba(16, 24, 40, 0.18), inset 0 -2px 0 rgba(16, 24, 40, 0.05), 0 1px 2px rgba(16, 24, 40, 0.05)',
              textTransform: 'capitalize',
              '&:hover': {
                // bgcolor: !account?.company?.companyInfo?.name?.length ? 'lightgray' : '#00BD57',
                bgcolor: '#00BD57',
              },
              cursor: 'pointer !important',
            }}
            onClick={onNextClick}
            // disabled={!account?.company?.companyInfo?.name?.length}
          >
            {companyId?.length ? 'Claim Your Page' : 'Add Your Business'}
          </Button>
          <Typography
            color={'#000000'}
            fontFamily={'SF UI Display'}
            fontSize={'12px'}
            fontWeight={'400'}
            lineHeight={'15px'}
          >
            By continuing you indicate that you agree to SignWise{' '}
            <span style={{ color: '#00BD57', textDecoration: 'underline' }}>
              Terms & Conditions
            </span>{' '}
            and{' '}
            <span style={{ color: '#00BD57', textDecoration: 'underline' }}>Privacy Policy</span>.{' '}
            {!companyId?.length &&
              'We may send you emails about products, services or events. Unsubscribe at any time.'}
          </Typography>
          <Typography
            color={'#000000'}
            fontFamily={'SF UI Display'}
            fontSize={'14px'}
            fontWeight={'400'}
            lineHeight={'20px'}
          >
            Already have {companyId?.length ? 'an account' : 'a page'}?{' '}
            <span
              style={{
                color: '#00BD57',
                textDecoration: 'underline',
                fontWeight: '600',
                cursor: 'pointer',
              }}
              onClick={() => {
                nav('/login');
              }}
            >
              Sign In
            </span>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateCompany;
