import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Pagination,
  Rating,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import HeaderSignCompany from '../../../components/phase1a/local/Header.component';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import defaultCompany from '../../../assets/defaultCompany.png';
import CheckmarkFilled from '../../../assets/nationalSignCompany/checkmarkFilled.svg';
import Complaint from '../../../assets/complaint.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { ILocal } from '../../../types/user';
import request from '../../../api/request';
import { mediaUrl } from '../../../config';
import MapWithMultipleMarkers from '../../../components/phase1a/local/MapMultipleMarkers';
import serviceFilter1 from '../../../assets/serviceFilter/serviceFilter1.svg';
import serviceFilter2 from '../../../assets/serviceFilter/serviceFilter2.svg';
import serviceFilter3 from '../../../assets/serviceFilter/serviceFilter3.svg';
import serviceFilter4 from '../../../assets/serviceFilter/serviceFilter4.svg';
import serviceFilter5 from '../../../assets/serviceFilter/serviceFilter5.svg';

const LocalMapView: FC = () => {
  const nav = useNavigate();
  const location = useLocation();
  // const { nearData } = location?.state || {};
  const { myCurrLoc, nearData, selectedAdd, companyType, searchData, locationsParam, searchText } =
    location.state || {};
  // const queryParams = new URLSearchParams(location?.search);
  // const paramType = queryParams?.get('type') ?? undefined;
  // const paramSearch = queryParams?.get('search') ?? undefined;
  // const paramAdditional = queryParams.get('additional') ?? undefined;
  // const paramlocation = queryParams?.get('location') ?? undefined;
  const [type, setType] = useState<string | undefined>(companyType);
  console.log('🚀 ~ setType:', setType);
  const [selectedServices, setSelectedServices] = useState<string[]>([]);
  const [search, setSearch] = useState<any>(searchData);
  const [additional, setAdditional] = useState<string | undefined>(nearData);
  const [coordinates, setCoordinates] = useState<string | undefined>(locationsParam);
  const [selectedAddress, setSelectedAddress] = useState<any>(selectedAdd ?? null);
  const [loadingTopCompanies, setLoadingTopCompanies] = useState(true);
  const [searchTypo, setSearchTypo] = useState<any>(searchText);

  const [near, setNear] = useState<string>(nearData ?? '');

  const [companyData, setCompanyData] = useState<ILocal[] | null>(null);
  const [complaint, setComplaint] = useState<boolean>(false);
  const [rating, setRating] = useState<boolean>(false);
  const [myLocation, setMyLocation] = useState<any>({
    city: '',
    state: '',
    country: '',
  });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage] = useState<number>(10);
  const filters = {
    distance: ['10', '50', '100', '200', '300', 'Distance'],
    signType: ['Awnings', 'Banners', 'Cabinet Signs', 'Channel Letters', 'SignType'],
  };
  console.log('setType', setType, setCoordinates);
  console.log('companyData', companyData);
  const distanceRanges = ['0-10', '10-50', '50-100', '100-200', '200-300'];
  const [selectedValues, setSelectedValues] = useState({
    distance: 'Distance',
    signType: 'SignType',
  });
  const [sort, setSort] = useState('');
  console.log('sort', sort);
  const handleChange = (event: SelectChangeEvent<typeof selectedServices>): void => {
    setSelectedServices(event.target.value as string[]);
  };
  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number): void => {
    setCurrentPage(value);
  };
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = companyData?.slice(indexOfFirstItem, indexOfLastItem);
  const sortCompanyData = (data: any[], sort: string): any[] => {
    return data?.sort((a, b) => {
      const nameA = a?.companyInfo?.name || '';
      const nameB = b?.companyInfo?.name || '';
      if (sort === 'A-Z') {
        return nameA?.localeCompare(nameB);
      } else if (sort === 'Z-A') {
        return nameB?.localeCompare(nameA);
      }
      return 0;
    });
  };
  const findType =
    type === 'local'
      ? search?.name === 'Service' ||
        search?.name === 'Fabricators' ||
        search?.name === 'Installers' ||
        search?.name === 'Surveys' ||
        search?.name === 'Full Service Companies' ||
        searchTypo.name === 'Service' ||
        searchTypo.name === 'Fabricators' ||
        searchTypo.name === 'Installers' ||
        searchTypo.name === 'Surveys' ||
        searchTypo.name === 'Full Service Companies'
        ? 'service'
        : 'company'
      : 'company';

  const handleSortChange = (event: any): void => {
    const newSort = event?.target?.value;
    setSort(newSort);

    if (companyData) {
      const sortedData = sortCompanyData([...companyData], newSort);
      setCompanyData(sortedData);
    }
  };

  const handleGetData = (): void => {
    const encodedType = encodeURIComponent(type ?? '');
    const encodedSearch = encodeURIComponent(search?.name ?? searchTypo?.name ?? '');
    const encodedLocation = coordinates
      ? encodeURIComponent(coordinates)
      : encodeURIComponent(locationsParam as string);
    console.log('🚀 ~ handleGetData ~ encodedLocation:', encodedLocation);
    const encodedAdditional = encodeURIComponent(additional ? additional.split(',')[0] : '');
    const filter = {
      rating: rating,
      no_complaint: complaint,
    };
    const distanceFilter = {
      distance: selectedValues?.distance === 'Distance' ? '' : selectedValues?.distance,
      no_complaint: complaint,
      services: selectedServices, // Add selected services here
    };

    const encodedFilter = encodeURIComponent(
      JSON.stringify(
        type === 'local'
          ? // By Awais
            distanceFilter && !distanceFilter?.no_complaint
            ? { distance: distanceFilter?.distance, services: selectedServices }
            : distanceFilter
          : filter && !filter?.no_complaint
          ? { rating: filter?.rating }
          : filter,
      ),
    );
    // const url = `/localCompany/unAuthenticatedDashboard?search=${searchEncoded}&filter=${filterEncoded}&location=${locationEncoded}`;
    const decodedAdditional = decodeURIComponent(encodedAdditional);

    let additionalParam = '';

    if (decodedAdditional) {
      const splitAdditional = decodedAdditional?.split(',');
      console.log(splitAdditional); // Debugging: Print the split array
      additionalParam = splitAdditional[0]?.trim(); // Trim to remove any leading/trailing spaces
    } else {
      additionalParam = decodedAdditional;
    }
    // let url;
    // if (encodedAdditional) {
    //   const splitAdditional = encodedAdditional.split(',');
    //   url = `/companies/search/${encodedType}?search=${encodedSearch}&location=${encodedLocation}&additional=${splitAdditional[0]}&filter=${encodedFilter}`;
    // } else {
    //   url = `/companies/search/${encodedType}?search=${encodedSearch}&location=${encodedLocation}&additional=${encodedAdditional}&filter=${encodedFilter}`;
    // }
    let url = `/companies/search/${encodedType}?search=${encodedSearch}&location=${encodedLocation}&additional=${additionalParam}&filter=${encodedFilter}`;
    if (encodedType === 'local') url += '&sort=distance';
    console.log('url9987', url);
    request('get', url)
      .then((response: any) => {
        console.log('response 321', response);

        if (sort?.length) {
          const sortedData = sortCompanyData([...(response?.companies ?? [])], sort);
          setCompanyData(sortedData);
        } else {
          setCompanyData(response?.companies ?? []);
        }
        setLoadingTopCompanies(false);
      })
      .catch((error: any) => {
        console.log(error);
        setLoadingTopCompanies(false);
      });
  };
  const tabs =
    companyType === 'local'
      ? [
          {
            title: 'List View',
          },
          {
            title: 'Distance',
            Icon: <KeyboardArrowDownIcon />,
          },
          {
            title: 'No Complaints',
          },
          {
            title: 'Services',
            Icon: <KeyboardArrowDownIcon />,
          },
        ]
      : [
          {
            title: 'List View',
          },
          {
            title: 'Rating',
          },
          {
            title: 'No Complaints',
          },
        ];
  const handleChangeFilter = (value: string, title: string): void => {
    if (title === 'Distance') {
      setSelectedValues((prev) => ({ ...prev, distance: value }));
    }
  };
  const handleAddressSelect = (address: any): void => {
    setSelectedAddress(address);
  };

  useEffect(() => {
    handleGetData();
  }, [
    searchData,
    search,
    type,
    additional,
    complaint,
    rating,
    selectedValues?.distance,
    selectedServices,
  ]);
  useEffect(() => {
    if (
      selectedAddress &&
      Array.isArray(selectedAddress.coordinates) &&
      selectedAddress.coordinates.length === 2
    ) {
      const [lan, lat] = selectedAddress.coordinates;
      /* eslint-disable @typescript-eslint/restrict-template-expressions */
      const cor = `[${lan}, ${lat}]`;
      setCoordinates(cor);
    } else if (locationsParam) {
      setCoordinates(locationsParam);
    } else {
      setCoordinates('');
    }
  }, [selectedAddress, locationsParam]);
  useEffect(() => {
    if (searchData) {
      setSearch(searchData);
    }
  }, [searchData]);
  console.log('companyData', companyData);
  const serviceOptions = [
    { name: 'Full Service Companies', image: serviceFilter1 },
    { name: 'Fabricators', image: serviceFilter2 },
    { name: 'Installers', image: serviceFilter3 },
    { name: 'Service', image: serviceFilter4 },
    { name: 'Surveys', image: serviceFilter5 },
  ];
  const formatSearchString = (str: string): string => {
    return str?.toLowerCase()?.replace(/\s+/g, '-')?.trim();
  };
  useEffect(() => {
    setMyLocation(myCurrLoc);
  }, [myCurrLoc]);
  return (
    <Box width="100%">
      <HeaderSignCompany
        listingGrid
        search={search}
        searchTypo={searchTypo}
        setSearchTypo={setSearchTypo}
        setSearch={setSearch}
        additional={additional}
        setAdditional={setAdditional}
        type={type}
        handleAddressSelect={handleAddressSelect}
        handleGetData={handleGetData}
        setNear={setNear}
        near={near}
        setCoordinates={setCoordinates}
        coordinates={coordinates}
        myLocation={myLocation}
        setMyLocation={setMyLocation}
      />
      <Box
        width={'100%'}
        display={'flex'}
        alignItems={'flex-start'}
        borderTop={'1px solid #EEEEEE'}
        marginTop={'80px'}
        sx={{
          '@media (max-width: 1024px)': {
            flexDirection: 'column-reverse',
          },
        }}
      >
        <Box
          width={'30%'}
          padding={'1% 2%'}
          sx={{
            '@media (max-width: 1024px)': {
              width: '100%',
              padding: '3%',
            },
          }}
        >
          <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'right'}>
            <Typography
              fontSize={'14px'}
              fontWeight={'500'}
              fontFamily={'SF UI Display'}
              color={'#000000'}
            >
              Sort by:
            </Typography>
            <Select
              value={sort}
              onChange={handleSortChange}
              displayEmpty
              IconComponent={KeyboardArrowDownIcon}
              MenuProps={{
                disableScrollLock: true,
              }}
              sx={{
                marginLeft: '8px',
                fontFamily: 'SF UI Display',
                '& .MuiSelect-select': {
                  padding: '8px 0px',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
              }}
            >
              <MenuItem value="">Select</MenuItem>
              <MenuItem value="A-Z">A-Z</MenuItem>
              <MenuItem value="Z-A">Z-A</MenuItem>
            </Select>
          </Box>
          <Typography
            fontSize={'20px'}
            marginTop={'5px'}
            fontWeight={'800'}
            fontFamily={'SF UI Display'}
            color={'#000000'}
          >
            {type === 'local'
              ? search?.name && additional
                ? `${search?.name} ${findType === 'company' ? 'in' : 'Near'} ${additional}`
                : `Sign Companies ${additional ? `Near ${additional}` : ''}`
              : type === 'national'
              ? 'National Sign Company'
              : type === 'supplier'
              ? additional !== ''
                ? `${additional} Companies`
                : 'Supplier Companies'
              : type === 'distributor'
              ? additional !== ''
                ? `${additional} Companies`
                : 'Distributor Companies'
              : additional !== undefined && additional !== null
              ? `${additional} Companies`
              : ''}
          </Typography>
          <Box
            width={'100%'}
            display={'flex'}
            alignItems={'center'}
            gap={'10px'}
            marginTop={'4%'}
            sx={{
              overflowX: 'scroll',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
              '-ms-overflow-style': 'none',
              'scrollbar-width': 'none',
            }}
          >
            {tabs?.map((item, index) => (
              <React.Fragment key={index}>
                {item?.title === 'Distance' || item?.title === 'Sign Type' ? (
                  <Select
                    labelId={`select-${item?.title?.toLowerCase()}-label`}
                    id={`select-${item?.title?.toLowerCase()}`}
                    value={
                      item?.title === 'Distance'
                        ? selectedValues?.distance
                        : selectedValues?.signType
                    }
                    onChange={(e) => {
                      handleChangeFilter(e?.target?.value, item?.title);
                    }}
                    className="Select_Box"
                    name={item?.title === 'Distance' ? 'distance' : 'signType'}
                    sx={{
                      '&.MuiInputBase-root': {
                        borderRadius: '50px',
                      },
                      textTransform: 'capitalize',
                      color: '#000000',
                      fontSize: '14px',
                      fontWeight: '600',
                      lineHeight: '20px',
                      fontFamily: 'SF UI Display',
                      backgroundColor: '#FFFFFF',
                      '& .MuiSelect-select': {
                        padding: '6px 12px',
                        border: 'none',
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: '1px solid #D0D5DD',
                          outline: 'none',
                        },
                        '&:hover fieldset': {
                          borderColor: '#D0D5DD',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#D0D5DD',
                        },
                        '&.MuiInputBase-root:focus-visible': {
                          borderColor: '#D0D5DD',
                        },
                      },
                    }}
                    IconComponent={(props) => (
                      <KeyboardArrowDownIcon
                        {...props}
                        style={{ color: '#000000', cursor: 'pointer' }}
                      />
                    )}
                  >
                    {item.title === 'Distance'
                      ? filters?.distance?.map((value, idx) => {
                          const range = distanceRanges[idx];
                          const isDistance = value === 'Distance';
                          return (
                            <MenuItem
                              key={idx}
                              sx={{
                                color: '#344054',
                                fontSize: '14px',
                                fontFamily: 'SF UI Display',

                                fontWeight: '600',
                                lineHeight: '20px',
                                textTransform: 'capitalize',
                              }}
                              value={value}
                              selected={isDistance}
                            >
                              {isDistance ? 'Distance' : range}
                            </MenuItem>
                          );
                        })
                      : filters?.signType?.map((value, idx) => (
                          <MenuItem
                            key={idx}
                            sx={{
                              color: '#344054',
                              fontSize: '14px',
                              fontWeight: '600',
                              fontFamily: 'SF UI Display',

                              lineHeight: '20px',
                              textTransform: 'capitalize',
                            }}
                            value={value}
                          >
                            {value}
                          </MenuItem>
                        ))}
                  </Select>
                ) : item.title === 'Services' ? (
                  <Select
                    disabled={findType === 'service'}
                    className="ServicesMuiListning"
                    key={item.title}
                    multiple
                    displayEmpty
                    value={selectedServices}
                    onChange={handleChange}
                    renderValue={() => 'Services'}
                    // IconComponent={item.Icon ? () => item.Icon : undefined}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          borderRadius: '16px',
                        },
                      },
                    }}
                    sx={{
                      '&.MuiInputBase-root': {
                        borderRadius: '50px',
                      },
                      textTransform: 'capitalize',
                      color: '#000000',
                      fontSize: '14px',
                      fontWeight: '600',
                      width: '105px',
                      lineHeight: '20px',
                      fontFamily: 'SF UI Display',
                      backgroundColor: '#FFFFFF',
                      '& .MuiSelect-select': {
                        padding: '6px 12px',
                        border: 'none',
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: '1px solid #D0D5DD',
                          outline: 'none',
                        },
                        '&:hover fieldset': {
                          borderColor: '#D0D5DD',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#D0D5DD',
                        },
                        '&.MuiInputBase-root:focus-visible': {
                          borderColor: '#D0D5DD',
                        },
                      },
                    }}
                    IconComponent={(props) => (
                      <KeyboardArrowDownIcon
                        {...props}
                        style={{
                          color: item?.title === 'Services' ? 'black' : '#000000',
                          cursor: 'pointer',
                        }}
                      />
                    )}
                  >
                    {serviceOptions.map((service) => (
                      <MenuItem
                        key={service.name}
                        value={service.name}
                        sx={{
                          paddingRight: '18px',
                          fontSize: '12px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Checkbox
                          checked={selectedServices.includes(service?.name)}
                          sx={{
                            color: 'rgba(233, 233, 233, 1)',
                            '&.Mui-checked': {
                              color: '#00BD57',
                            },
                          }}
                        />
                        <ListItemIcon>
                          <img
                            src={service?.image}
                            style={{ width: '40px', height: '40px', margin: '0 5px' }}
                          />
                        </ListItemIcon>
                        <ListItemText primary={service?.name} />
                      </MenuItem>
                    ))}
                  </Select>
                ) : (
                  <Box
                    key={index}
                    onClick={() => {
                      if (item?.title === 'No Complaints') {
                        setComplaint(!complaint);
                      }
                      if (item?.title === 'Rating') {
                        setRating(!rating);
                      }
                      if (item?.title === 'List View') {
                        // nav(
                        //   `/companylisting?type=${type}&location=${
                        //     coordinates ?? ''
                        //   }&search=${search}&additional=${additional?.split(',')[0] ?? ''}`,
                        //   { state: { nearData: additional } },
                        // );
                        let url = '/search';
                        const formattedSearch =
                          search || searchTypo
                            ? formatSearchString(search?.name ?? searchTypo.name)
                            : '';
                        // let find = '';
                        const country =
                          selectedAddress?.contexts?.country === 'United States'
                            ? 'us'
                            : selectedAddress?.contexts?.country === 'canada'
                            ? 'canada'
                            : !selectedAddress
                            ? formatSearchString(myLocation?.country)
                            : '';
                        if (type === 'local') {
                          const searchName = search?.name?.toLowerCase();
                          const searchTypoName = searchTypo?.name?.toLowerCase();

                          const serviceNames = [
                            'service',
                            'fabricators',
                            'installers',
                            'surveys',
                            'full service companies',
                          ];
                          const find =
                            serviceNames.includes(searchName) ||
                            serviceNames.includes(searchTypoName)
                              ? 'service'
                              : 'company';
                          if (near && formattedSearch) {
                            url += `?${find}=${encodeURIComponent(
                              formattedSearch,
                            )}&location=${country}&city=${encodeURIComponent(
                              formatSearchString(near?.split(',')[0]),
                            )}&state=${encodeURIComponent(
                              formatSearchString(near?.split(', ')[1]),
                            )}`;
                          } else if (!formattedSearch && (near || coordinates)) {
                            url +=
                              near !== ''
                                ? `?location=${country}&city=${encodeURIComponent(
                                    formatSearchString(near?.split(',')[0]),
                                  )}&state=${encodeURIComponent(
                                    formatSearchString(near?.split(', ')[1]),
                                  )}`
                                : `?location=${encodeURIComponent(
                                    formatSearchString(myLocation?.country),
                                  )}&city=${encodeURIComponent(
                                    formatSearchString(myLocation?.city),
                                  )}&state=${encodeURIComponent(
                                    formatSearchString(myLocation?.state),
                                  )}
                                  `;
                          } else if (formattedSearch) {
                            url += `?${find}=${encodeURIComponent(
                              formattedSearch,
                            )}&location=${encodeURIComponent(
                              formatSearchString(myLocation?.country),
                            )}&city=${encodeURIComponent(
                              formatSearchString(myLocation?.city),
                            )}&state=${encodeURIComponent(formatSearchString(myLocation?.state))}
                                  `;
                          }
                        }
                        if (type === 'national') {
                          const formattedNear = near ? formatSearchString(near) : '';
                          const locations =
                            near === 'Canada'
                              ? 'canada'
                              : near === 'United States'
                              ? 'us'
                              : formattedNear;
                          if (near && formattedSearch) {
                            url += `?company=${encodeURIComponent(
                              formattedSearch,
                            )}&location=${encodeURIComponent(locations)}`;
                          } else if (!near && !formattedSearch) {
                            url += '?national-sign-companies';
                          } else if (near) {
                            url += `?location=${encodeURIComponent(locations)}`;
                          } else if (formattedSearch) {
                            url += `?company=${encodeURIComponent(formattedSearch)}`;
                          }
                        }
                        if (type === 'supplier' || type === 'distributor') {
                          const formattedNear = near ? formatSearchString(near) : '';
                          if (near && formattedSearch) {
                            url += `?company=${encodeURIComponent(
                              formattedSearch,
                            )}&category=${encodeURIComponent(formattedNear)}`;
                          } else if (near) {
                            url += `?category=${encodeURIComponent(formattedNear)}`;
                          } else if (formattedSearch) {
                            url += `?company=${encodeURIComponent(formattedSearch)}`;
                          }
                        }
                        nav(url, {
                          state: {
                            nearData: additional,
                            companyType: type,
                            locationsParam: `${coordinates ?? ''}`,
                            searchData: search,
                            searchText: searchTypo,
                            myCurrLoc: myLocation,
                          },
                        });
                      }
                    }}
                    border={'1px solid #CCCCCC'}
                    borderRadius={'30px'}
                    padding={'3px 15px'}
                    whiteSpace={'nowrap'}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    gap={'5px'}
                    bgcolor={
                      complaint && item?.title === 'No Complaints'
                        ? 'green'
                        : rating && item?.title === 'Rating'
                        ? 'green'
                        : '#ffffff'
                    }
                    sx={{ cursor: 'pointer' }}
                  >
                    <Typography
                      fontSize={'14px'}
                      fontWeight={'600'}
                      fontFamily="SF UI Display"
                      color={
                        complaint && item?.title === 'No Complaints'
                          ? '#ffffff'
                          : rating && item?.title === 'Rating'
                          ? '#ffffff'
                          : '#000000'
                      }
                    >
                      {item?.title}
                    </Typography>
                    {item?.Icon && <KeyboardArrowDownIcon />}
                  </Box>
                )}
              </React.Fragment>
            ))}
          </Box>
          {companyData?.length ? (
            <Box
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'space-between'}
              height={'67vh'}
            >
              <Box
                width={'100%'}
                marginTop={'3%'}
                display={'flex'}
                flexDirection={'column'}
                gap={'20px'}
                maxHeight={'60vh'}
                sx={{
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  '@media (max-width: 1024px)': {
                    overflowY: 'unset',
                    maxHeight: '100%',
                  },
                }}
              >
                {currentData?.map((item, index) => (
                  <Box
                    key={index}
                    onClick={() => {
                      const paramType =
                        type === 'local'
                          ? 'local-sign-company'
                          : type === 'national'
                          ? 'national-sign-company'
                          : type === 'supplier'
                          ? 'supplier'
                          : 'distributor';
                      nav(`/${paramType}/${formatSearchString(item?.companyInfo?.name)}`, {
                        state: {
                          item,
                          type,
                          search,
                          additional,
                          coords: coordinates ?? '',
                          near,
                          searchTypo,
                          myCurrLoc: myLocation,
                        },
                      });
                    }}
                    width={'100%'}
                    padding={'2%'}
                    border={'1px solid #E8E8E8'}
                    borderRadius={'10px'}
                    display={'flex'}
                    // height={'200px'}
                    alignItems={'center'}
                    flexWrap={'wrap'}
                    gap={'3%'}
                    position={'relative'}
                    // overflow={'hidden'}
                    sx={{
                      cursor: 'pointer',
                      transition: 'all 0.3s ease-in-out',
                      '&:hover': {
                        border: '1px solid transparent',
                        bgcolor: '#DFFFED',
                      },
                    }}
                  >
                    {/* <Box
                    position={'absolute'}
                    right={'0'}
                    top={'0'}
                    bgcolor={item.statusBg}
                    padding={'2% 0'}
                    borderRadius={'0 5px 0 5px'}
                    display={'flex'}
                    alignItems={'center'}
                    flexDirection={'column'}
                    width={'23%'}
                  >
                    <Typography
                      fontSize={'14px'}
                      fontWeight={'500'}
                      fontFamily={'SF UI Display'}
                      color={'#ffffff'}
                    >
                      {item.status}
                    </Typography>
                  </Box> */}
                    <Box
                      width={'60px'}
                      height={'60px'}
                      borderRadius={'10px'}
                      overflow={'hidden'}
                      sx={{
                        '@media (max-width: 1200px)': {
                          width: '55px',
                          height: '55px',
                        },
                      }}
                    >
                      <img
                        src={
                          /* eslint-disable @typescript-eslint/restrict-template-expressions */
                          item?.companyInfo?.logo
                            ? `${mediaUrl}/${item?.companyInfo?.logo}`
                            : defaultCompany
                        }
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                        alt="logo"
                      />
                    </Box>
                    <Box width={'75%'}>
                      <Box width={'100%'}>
                        <Typography
                          fontSize={'16px'}
                          fontWeight={'800'}
                          fontFamily={'Poppins'}
                          color={'#000000'}
                          textTransform={'capitalize'}
                        >
                          {item?.companyInfo?.name}
                        </Typography>
                        <Typography
                          fontSize={'14px'}
                          fontWeight={'400'}
                          fontFamily={'Poppins'}
                          color={'#000000'}
                        >
                          {item?.companyInfo?.address?.city ? (
                            <>
                              {item?.companyInfo?.address?.city},{' '}
                              {item?.companyInfo?.address?.state}
                            </>
                          ) : (
                            item?.companyInfo?.address?.state
                          )}
                        </Typography>
                      </Box>
                      <Box width={'100%'} display={'flex'} alignItems={'center'} gap={'10px'}>
                        <Rating
                          name="hover-feedback"
                          value={
                            typeof item?.averageReviewRating?.overall === 'number'
                              ? item?.averageReviewRating?.overall
                              : parseFloat(item?.averageReviewRating?.overall) || 0
                          }
                          precision={0.1}
                          sx={{
                            color: !item?.averageReviewRating?.overall
                              ? '#9E9E9E'
                              : // : item?.averageReviewRating?.overall >= 4.6
                              // ? '#3040CE'
                              item?.averageReviewRating?.overall >= 4
                              ? '#00BD57'
                              : item?.averageReviewRating?.overall >= 3
                              ? '#FFB800'
                              : '#FD3E14',

                            // item?.averageReviewRating?.overall >= 3 ? '#00BD57' : '#FD3E14',
                            fontSize: '20px',
                          }}
                          // size="small"
                          readOnly
                          // emptyIcon={<CustomStarBorderIcon fontSize="inherit" />}
                        />
                        <Typography
                          fontSize={'16px'}
                          fontWeight={'600'}
                          fontFamily={'Poppins'}
                          color={'#000000'}
                          sx={{
                            '@media (max-width: 768px)': {
                              fontSize: '18px',
                              fontWeight: '700',
                            },
                            '@media (max-width: 500px)': {
                              fontSize: '15px',
                              fontWeight: '600',
                            },
                          }}
                        >
                          {/* {item?.averageReviewRating?.overall > 0
                          ? Number(item?.averageReviewRating?.overall).toFixed(1)
                          : ''} */}
                          {item?.averageReviewRating?.overall > 0
                            ? (Math.floor(item?.averageReviewRating?.overall * 10) / 10).toFixed(1)
                            : ''}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      width={'100%'}
                      gap={'2%'}
                      marginTop={'4%'}
                    >
                      <img
                        src={!item?.complaint ? CheckmarkFilled : Complaint}
                        alt="Checkmark Filled"
                      />
                      <Typography
                        fontSize={'14px'}
                        fontWeight={'500'}
                        fontFamily={'Poppins'}
                        color={'#000000'}
                        sx={{
                          '@media (max-width: 768px)': {
                            fontWeight: '400',
                          },
                          '@media (max-width: 600px)': {
                            fontSize: '12px',
                          },
                        }}
                      >
                        {/* {obj?.tag2} */}
                        {!item?.complaint ? 'No Complaints' : 'Complaints'}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
              <Box width={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <Pagination
                  count={Math.ceil(companyData?.length / itemsPerPage)}
                  page={currentPage}
                  onChange={handlePageChange}
                  variant="outlined"
                  shape="rounded"
                  showFirstButton
                  showLastButton
                  boundaryCount={1} // Show one number at the start and end
                  siblingCount={0} // Show no sibling numbers around the current page
                  sx={{
                    marginTop: '2rem',
                    alignSelf: 'center',
                    '& .MuiPaginationItem-root': {
                      color: '#333333', // Custom color for pagination items
                      borderColor: '#E8E8E8', // Custom border color
                      fontFamily: 'SF UI Display',
                      fontWeight: 600,
                      fontSize: '13px',
                      '&.Mui-selected': {
                        backgroundColor: '#00BD57', // Background color when selected
                        color: '#ffffff', // Text color when selected
                      },
                      '&:hover': {
                        backgroundColor: '#DFFFED', // Hover background color
                      },
                    },
                  }}
                />
              </Box>
            </Box>
          ) : (
            <Box
              width={'100%'}
              marginTop={'3%'}
              display={'flex'}
              flexDirection={'column'}
              gap={'20px'}
            >
              <Typography
                fontSize={'40px'}
                fontWeight={'700'}
                fontFamily={'SF UI Display'}
                color={'#bac3ca'}
              >
                {loadingTopCompanies ? <LinearProgress color="inherit" /> : 'No Companies Found'}
              </Typography>
            </Box>
          )}
        </Box>
        <Box
          width={'70%'}
          height={'90vh'}
          sx={{
            '@media (max-width: 1024px)': {
              width: '100%',
              height: '50vh',
            },
          }}
        >
          {/* <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.835434509372!2d144.95373531561685!3d-37.81720974202162!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0xf577bb1fba9a874!2sEnvato!5e0!3m2!1sen!2sau!4v1485790614121"
            width="100%"
            height="700px"
            allowFullScreen
            loading="lazy"
          ></iframe> */}

          <MapWithMultipleMarkers companyData={companyData} />
        </Box>
      </Box>
    </Box>
  );
};

export default LocalMapView;
