import React, { FC, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Button, Checkbox, ListItemIcon, Typography } from '@mui/material';
import ServiceIcon from '../../../../assets/Phase1a/SignUp/ServiceIcon.png';
import { DataStructure } from '../../../../unAuth/phase1a/PersonalInformation.unAuth';
import InstallationServiceIcon from '../../../../assets/popups/serviceRightArrow.svg';
import request from '../../../../api/request';
import serviceFilter1 from '../../../../assets/serviceFilter/serviceFilter1.svg';
import serviceFilter2 from '../../../../assets/serviceFilter/serviceFilter2.svg';
import serviceFilter3 from '../../../../assets/serviceFilter/serviceFilter3.svg';
import serviceFilter4 from '../../../../assets/serviceFilter/serviceFilter4.svg';
import serviceFilter5 from '../../../../assets/serviceFilter/serviceFilter5.svg';

interface serviceProps {
  data: DataStructure;
  setData: React.Dispatch<React.SetStateAction<DataStructure>>;
}
const options = [
  {
    value: 'Full Service Companies',
    label: 'Full Service',
    color: '#FF5722',
    logo: 'fullserviceicon',
    image: serviceFilter1,
  },
  {
    value: 'Fabricators',
    label: 'Fabrication',
    color: '#E91E63',
    logo: 'fabricatorsicon',
    image: serviceFilter2,
  },
  {
    value: 'Installers',
    label: 'Installation',
    color: '#2196F3',
    logo: 'installerIcon',
    image: serviceFilter3,
  },
  {
    value: 'Service',
    label: 'Service',
    color: '#2196F3',
    logo: 'serviceIcon',
    image: serviceFilter4,
  },
  {
    value: 'Surveys',
    label: 'Survey',
    color: '#2196F3',
    logo: 'surveyIcon',
    image: serviceFilter5,
  },
];
const style = {
  width: '100%',
  height: '100%',
  bgcolor: 'background.paper',
  borderRadius: 5,
  p: 4,
  paddingRight: 3,
  paddingLeft: 3,
  // marginTop: '20%',
};

const Service: FC<serviceProps> = ({ data, setData }) => {
  const [selectedCategory, setSelectedCategory] = useState<any>(null);
  const [checkedSubcategories, setCheckedSubcategories] = useState<any>({});
  const [checkedCategories, setCheckedCategories] = useState<any>({});
  const [categories, setCategories] = useState<any>([]);

  // const handleCheckboxChange = (label: string): void => {
  //   setData((data) => ({
  //     ...data,
  //     service: [...data.services, label],
  //   }));
  // };
  const handleServiceChange = (service: any): void => {
    setData((prevData) => {
      let updatedServices;
      const existingServiceIndex = prevData?.services?.findIndex(
        (s: any) => s?.service === service?.value,
      );

      if (existingServiceIndex !== -1) {
        updatedServices = prevData?.services?.filter((s: any) => s?.service !== service?.value);
      } else {
        // If the first service object is empty, overwrite it
        if (prevData?.services[0]?.service === '') {
          updatedServices = [
            {
              service: service?.value,
              percentage: null,
              subService: [''],
              logo: service?.logo,
            },
          ];
        } else {
          // Add the service if it doesn't exist
          updatedServices = prevData?.services?.concat({
            service: service?.value,
            percentage: null,
            subService: [''],
            logo: service?.logo,
          });
        }
      }

      return { ...prevData, services: updatedServices };
    });
  };
  const getCategories = (): void => {
    request('get', `/companies/categories`)
      .then((res: any) => {
        // toast.success('Success');
        console.log('res searcchh', res?.categories);
        setCategories(res?.categories);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const isChecked = (service: string): boolean => {
    return data.services.some((s) => s.service === service);
  };
  const handleSubcategoryChange = (category: any, subcategory: any): void => {
    setCheckedSubcategories((prev: any) => ({
      ...prev,
      [category]: {
        ...prev[category],
        [subcategory]: !prev[category]?.[subcategory],
      },
    }));
  };
  const handleAddSubcategories = (): void => {
    if (!selectedCategory) return;

    const subcategories = checkedSubcategories[selectedCategory] || {};

    const hasCheckedSubcategories = Object.values(subcategories).some((checked) => checked);

    setCheckedCategories((prev: any) => ({
      ...prev,
      [selectedCategory]: hasCheckedSubcategories,
    }));

    setData((prevData: any) => {
      const updatedCategories = prevData.companyInfo.category.filter(
        (cat: any) => cat.name !== selectedCategory,
      );

      const newCategory = {
        name: selectedCategory,
        subCategory: Object.keys(subcategories).filter((subcat) => subcategories[subcat]),
      };

      return {
        ...prevData,
        companyInfo: {
          ...prevData.companyInfo,
          category: hasCheckedSubcategories
            ? [...updatedCategories, newCategory]
            : updatedCategories,
        },
      };
    });

    setSelectedCategory(null);
  };
  const isCategoryChecked = (category: string | number): boolean =>
    checkedCategories[category] || false;
  useEffect(() => {
    getCategories();
  }, []);
  return (
    <>
      {data?.companyInfo?.companyType === 'supplier' ||
      data?.companyInfo?.companyType === 'distributor' ? (
        <Box width={'100%'} margin={'4% 0'} height={'70vh'}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Box
              width="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              gap="5px"
            >
              {/* <img src={ServiceIcon} alt="" /> */}
              <Typography
                fontSize="24px"
                fontWeight="800"
                lineHeight="28.64px"
                fontFamily="SF UI Display"
                color=" #000000"
                id="modal-modal-title"
                margin="1% 0"
              >
                Category
              </Typography>

              <Typography
                fontFamily="SF UI Display"
                fontSize="16px"
                fontWeight="400"
                lineHeight="19.09px"
                color="#000000"
                marginTop="10px"
                id="modal-modal-title"
                mb={8}
              >
                Add all the categories your company offers.
              </Typography>
            </Box>
          </Box>
          <Box
            width="100%"
            sx={{
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}
            height="90%"
          >
            {selectedCategory === null ? (
              categories?.map((item: any, index: any) => (
                <Box
                  key={index}
                  borderRadius="10px"
                  border="solid 1px #E8E8E8"
                  padding="2%"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  marginBottom="1rem"
                  sx={{
                    cursor: 'pointer',
                    '&:hover': {
                      bgcolor: '#DFFFED',
                    },
                  }}
                >
                  <Box width="50%" display="flex" alignItems="center" gap="15px">
                    <Checkbox
                      checked={isCategoryChecked(item?.category)}
                      // onChange={() => {
                      //   handleServiceChange(item?.category);
                      // }}
                      sx={{
                        '&.Mui-checked': {
                          color: '#00BD57',
                        },
                      }}
                    />
                    <Typography
                      fontFamily="SF UI Display"
                      fontWeight="700"
                      fontSize="16px"
                      lineHeight="19.09px"
                    >
                      {item?.category}
                    </Typography>
                  </Box>
                  <Box
                    width="50%"
                    display="flex"
                    justifyContent="flex-end"
                    paddingRight="2%"
                    onClick={() => {
                      setSelectedCategory(item?.category);
                    }}
                  >
                    <img
                      src={InstallationServiceIcon}
                      alt=""
                      style={{ width: '24px', height: '24px' }}
                    />
                  </Box>
                </Box>
              ))
            ) : (
              <Box>
                <Typography
                  fontFamily="SF UI Display"
                  fontWeight="700"
                  fontSize="16px"
                  lineHeight="19.09px"
                  marginBottom="1rem"
                >
                  {selectedCategory}
                </Typography>
                {categories
                  ?.find((item: any) => item?.category === selectedCategory)
                  ?.subCategories?.map((subcategory: any, index: number, array: any[]) => (
                    <Box
                      key={index}
                      display="flex"
                      alignItems="center"
                      gap="15px"
                      marginBottom={index === array?.length - 1 ? '3rem' : '0.5rem'}
                    >
                      <Checkbox
                        checked={checkedSubcategories[selectedCategory]?.[subcategory] || false}
                        onChange={() => {
                          handleSubcategoryChange(selectedCategory, subcategory);
                        }}
                        sx={{
                          '&.Mui-checked': {
                            color: '#00BD57',
                          },
                        }}
                      />
                      <Typography
                        fontFamily="SF UI Display"
                        fontWeight="700"
                        fontSize="16px"
                        lineHeight="19.09px"
                      >
                        {subcategory}
                      </Typography>
                    </Box>
                  ))}
                <Button
                  fullWidth
                  variant="contained"
                  color="success"
                  onClick={handleAddSubcategories}
                  sx={{
                    position: 'fixed',
                    bottom: '5%',
                    width: '30%',
                    '@media (max-width: 500px)': {
                      width: '90%',
                    },
                  }}
                >
                  Add
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      ) : (
        <Box sx={style}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Box
              width="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              gap="5px"
            >
              <img src={ServiceIcon} alt="" />
              <Typography
                fontSize="24px"
                fontWeight="800"
                lineHeight="28.64px"
                fontFamily="SF UI Display"
                color=" #000000"
                id="modal-modal-title"
                // mb={8}
                margin="2% 0"
              >
                Services
              </Typography>
              <Typography
                fontSize="16px"
                fontWeight="400"
                lineHeight="19.09px"
                fontFamily="SF UI Display"
                color=" #000000"
                id="modal-modal-title"
                mb={8}
                sx={{
                  '@media (max-width: 400px)': {
                    fontSize: '14px',
                    mb: 5,
                  },
                }}
              >
                Add all the services your company offers.
              </Typography>
            </Box>
          </Box>
          <Box width="100%" sx={{ marginTop: '2%' }}>
            {options.map((option, index) => (
              <Box
                key={index}
                borderRadius="10px"
                border="solid 1px #E8E8E8"
                padding="2%"
                display="flex"
                bgcolor={isChecked(option.value) ? '' : ''}
                justifyContent="space-between"
                alignItems="center"
                marginBottom="1rem"
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    bgcolor: '#DFFFED',
                  },
                }}
                onClick={() => {
                  handleServiceChange(option);
                }} // Handle box click
              >
                <Box
                  width="50%"
                  display="flex"
                  alignItems="center"
                  gap="15px"
                  onChange={(event) => {
                    event.stopPropagation(); // Stop the event from propagating to the Box
                    handleServiceChange(option);
                  }}
                >
                  {/* <Checkbox
                sx={{
                  '&.Mui-checked': {
                    color: '#00BD57',
                  },
                }}
                onChange={() => {
                  handleCheckboxChange(option.label);
                }}
              /> */}
                  <Checkbox
                    checked={isChecked(option.value)}
                    onChange={() => {
                      handleServiceChange(option);
                    }}
                    sx={{
                      '&.Mui-checked': {
                        color: '#00BD57',
                      },
                    }}
                  />
                  <ListItemIcon>
                    <img
                      src={option.image}
                      style={{ width: '40px', height: '40px', margin: '0 5px' }}
                    />
                  </ListItemIcon>
                  <Typography
                    fontFamily="SF UI Display"
                    fontWeight="700"
                    fontSize="16px"
                    lineHeight="19.09px"
                    sx={{
                      '@media (max-width: 564px)': {
                        whiteSpace: 'pre',
                      },
                    }}
                  >
                    {option.label}
                  </Typography>
                </Box>
                {/* <Box width="50%" display="flex" justifyContent="flex-end" paddingRight="2%">
                  <img
                    src={InstallationServiceIcon}
                    alt=""
                    style={{ width: '24px', height: '24px' }}
                  />
                </Box> */}
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </>
  );
};

export default Service;
