// localcompany Pages

// import Dashboard from '../pages/localSignCompany/DashoardPage';
// import NationalDashboard from '../pages/localSignCompany/NationalReviewsPage';
import ReviewsDashoard from '../pages/localSignCompany/ReviewsPage';
// import LocalPageSetUp from '../layout/phase1a/LocalPageSetUp.layout';

// localCompany drwaer icons
import { ReactComponent as ReviewsIcon } from '../../src/assets/ReviewsIcon.svg';
// import { ReactComponent as SettingIcon } from '../../src/assets/setting.svg';
import { ReactComponent as BlackReviewsIcon } from '../../src/assets/Review.svg';
// import { ReactComponent as SettingImg } from '../../src/assets/settingimg.svg';
// import { ReactComponent as SettingImg } from '../../src/assets/settingimg_grey.svg';

interface IRoutes {
  coloredIcon: any;
  icon: any;
  component: any;
  path: string;
  name: string;
  layout: string;
}
export const LocalSignRoutes: IRoutes[] = [
  {
    icon: ReviewsIcon,
    coloredIcon: BlackReviewsIcon,
    component: ReviewsDashoard,
    path: '/ReviewsDashoard',
    name: 'Job Gallery',
    layout: '/localpageowner',
  },

  // {
  //   icon: SettingIcon,
  //   coloredIcon: SettingImg,
  //   component: LocalPageSetUp,
  //   path: '/SettingPagesetup',
  //   name: 'Settings',
  //   layout: '/localpageowner',
  // },
];
