import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, styled } from '@mui/material';
import ReviewStepper from '../../../components/phase1a/national/ReviewStepper.component';
// import { IReviewData } from '../../../types/user';
import CloseIcon from '../../../assets/CloseIcon.svg';
import ReviewIcon from '../../../assets/nationalSignCompany/reviewIcon.svg';
import ThumbsUp from '../../../assets/nationalSignCompany/thumbsupIcon.svg';
import Complaint from '../../../assets/nationalSignCompany/complaintIcon.svg';
import CheckCircleIcon from '../../../assets/check.svg';

interface ReviewModalProps {
  open: boolean;
  onClose: () => void;
  companyData?: any;
}
const imgStyle = {
  width: '100px',
  height: '100px',
};

const mediaQueryStyle = {
  '@media (max-width: 450px)': {
    width: '70px',
    height: '70px',
  },
};
const StyledCheckIcon = styled('img')({
  position: 'absolute',
  top: '8px',
  right: '10px',
  width: '31px',
  height: '31px',
  '@media (max-width: 450px)': {
    width: '20px',
    height: '20px',
    top: '5px',
  },
});
const StyledThumbsUpIcon = styled('img')({
  width: '55px',
  height: '55px',
  '@media (max-width: 450px)': {
    width: '35px',
    height: '35px',
  },
});
const StyledIcon = styled('img')({
  width: '55px',
  height: '55px',
  '@media (max-width: 450px)': {
    width: '35px',
    height: '35px',
  },
});
type BoxType = 'recommendation' | 'complaint';

const style = {
  position: 'absolute',
  maxHeight: '90vh',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '700px',
  bgcolor: '#ffffff',
  boxShadow: 24,
  borderRadius: '10px',
  p: 4,
  overflowY: 'auto',
  '@media (max-width: 780px)': {
    width: '580px',
    padding: '20px',
  },
  '@media (max-width: 650px)': {
    width: '400px',
  },
  '@media (max-width: 450px)': {
    width: '380px',
  },
  '@media (max-width: 420px)': {
    width: '320px',
  },
};
export interface Review {
  company?: string;
  review_type?: string;
  reliability?: number | undefined;
  communication?: number | undefined;
  work_quality?: number | undefined;
  lead_time?: number | undefined;
  feedback?: string;
  is_anonymous?: boolean;
  user: {
    avatar?: string;
    name?: string;
    designation?: string;
    company?: string;
    email?: string;
    job_title?: string;
  };
}
const ReviewModal: React.FC<ReviewModalProps> = ({ open, onClose, companyData }) => {
  const userJSON = localStorage.getItem('user');
  const userData = userJSON ? JSON.parse(userJSON) : '';
  const initialReviewState: Review = {
    company: companyData?._id,
    review_type: '',
    reliability: 0,
    communication: 0,
    work_quality: 0,
    lead_time: 0,
    feedback: '',
    is_anonymous: !userData,
    user: {
      avatar: '',
      name: '',
      designation: '',
      company: '',
      email: userData?.email ?? '',
      job_title: '',
    },
  };
  const [review, setReview] = React.useState<Review>(initialReviewState);
  const [selectedBox, setSelectedBox] = React.useState<BoxType | null>(null);
  const [isNextClicked, setIsNextClicked] = React.useState(false);
  console.log('review', review);
  const handleClose = (): void => {
    setSelectedBox(null);
    setIsNextClicked(false);
    setReview(initialReviewState);
    onClose();
  };

  const handleBoxClick = (box: BoxType): void => {
    setSelectedBox(box);
    setReview((prevUser) => ({
      ...prevUser,
      review_type: box,
    }));
  };

  const handleNextClick = (): void => {
    setIsNextClicked(true);
  };
  React.useEffect(() => {
    setReview((prevUser) => ({
      ...prevUser,
      company: companyData?._id,
    }));
  }, [companyData?._id]);
  React.useEffect(() => {
    setReview((prevReview) => ({
      ...prevReview,
      user: {
        ...prevReview?.user,
        email: userData?.email,
      },
    }));
  }, [userData?.email]);

  return (
    <Box>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <>
            <img
              src={CloseIcon}
              alt="Close"
              onClick={handleClose}
              style={{
                cursor: 'pointer',
                position: 'absolute',
                right: '3%',
                top: '3%',
                zIndex: 1,
              }}
            />

            {!isNextClicked ? (
              <Box
                height={'350px'}
                position={'relative'}
                sx={{
                  '@media (max-width: 650px)': {
                    height: '330px',
                  },
                  '@media (max-width: 450px)': {
                    height: '320px',
                  },
                }}
              >
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  gap={'10px'}
                  marginTop={'3%'}
                >
                  <Box
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    position="relative"
                  >
                    <img
                      src={ReviewIcon}
                      alt="Verification Logo"
                      style={{ ...imgStyle, ...mediaQueryStyle['@media (max-width: 450px)'] }}
                    />
                  </Box>
                  <Box width="100%" display="flex" justifyContent="center" alignItems="center">
                    <Typography
                      fontSize={'24px'}
                      fontWeight={'800'}
                      fontFamily={'SF UI Display'}
                      color={'#000000'}
                      lineHeight={'28px'}
                      id="modal-modal-title"
                      sx={{
                        '@media (max-width: 450px)': {
                          fontSize: '20px',
                        },
                      }}
                    >
                      Review {companyData?.companyInfo?.name}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Box
                    width={'100%'}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    gap={'5%'}
                    margin={'10% 0'}
                  >
                    <Box
                      width={'178px'}
                      height={'123px'}
                      padding={'5% 0'}
                      display={'flex'}
                      flexDirection={'column'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      gap={'20px'}
                      border={'1px solid #E8E8E8'}
                      borderRadius={'10px'}
                      position={'relative'}
                      sx={{
                        '@media (max-width: 650px)': {
                          width: '45%',
                        },
                      }}
                      onClick={(): void => {
                        handleBoxClick('recommendation');
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      {selectedBox === 'recommendation' && (
                        <StyledCheckIcon src={CheckCircleIcon} alt="Check" />
                      )}
                      <StyledThumbsUpIcon src={ThumbsUp} alt="ThumbsUp" />{' '}
                      <Typography
                        fontSize={'16px'}
                        fontWeight={'700'}
                        fontFamily={'SF UI Display'}
                        color={'#000000'}
                        lineHeight={'19px'}
                        sx={{
                          '@media (max-width: 450px)': {
                            fontSize: '10px',
                          },
                        }}
                      >
                        Recommendation
                      </Typography>
                    </Box>
                    <Box
                      width={'178px'}
                      height={'123px'}
                      padding={'5% 0'}
                      display={'flex'}
                      flexDirection={'column'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      gap={'20px'}
                      border={'1px solid #E8E8E8'}
                      borderRadius={'10px'}
                      position={'relative'}
                      sx={{
                        '@media (max-width: 650px)': {
                          width: '45%',
                        },
                      }}
                      onClick={(): void => {
                        handleBoxClick('complaint');
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      {selectedBox === 'complaint' && (
                        <StyledCheckIcon src={CheckCircleIcon} alt="Check" />
                      )}
                      <StyledIcon src={Complaint} alt="Complaint" />{' '}
                      <Typography
                        fontSize={'16px'}
                        fontWeight={'700'}
                        fontFamily={'SF UI Display'}
                        color={'#000000'}
                        lineHeight={'19px'}
                        sx={{
                          '@media (max-width: 450px)': {
                            fontSize: '10px',
                          },
                        }}
                      >
                        Complaint
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    width={'100%'}
                    display={'flex'}
                    justifyContent={'right'}
                    alignItems={'center'}
                    position={'absolute'}
                    bottom={'0'}
                  >
                    {selectedBox && (
                      <Button
                        sx={{
                          width: '86px',
                          bgcolor: !selectedBox ? '#DCDEDF' : '#00BD57',
                          fontSize: '16px',
                          fontWeight: '700',
                          fontFamily: 'SF UI Display',
                          lineHeight: '19px',
                          color: '#FFFFFF',
                          textTransform: 'capitalize',
                          padding: '2% 0',
                          '&:hover': {
                            bgcolor: '#00BD57',
                          },
                          '@media (max-width: 450px)': {
                            fontSize: '10px',
                            width: '10%',
                          },
                        }}
                        onClick={handleNextClick}
                      >
                        Next
                      </Button>
                    )}
                  </Box>
                </Box>
              </Box>
            ) : (
              <>
                <ReviewStepper
                  review={review}
                  setReview={setReview}
                  userData={userData}
                  companyData={companyData}
                />{' '}
              </>
            )}
          </>
        </Box>
      </Modal>
    </Box>
  );
};

export default ReviewModal;
