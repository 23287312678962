import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, Checkbox, Button, TextField, Divider } from '@mui/material';
import ReportReviewIcon from '../../../assets/Phase1a/DesignUpdate/ReportReviewIcon.svg';
import DislikeIcon from '../../../assets/Phase1a/DesignUpdate/dislikeReport.svg';
import CloseIcon from '../../../assets/Phase1a/DesignUpdate/CloseIconReport.png';
import MegaphoneReport from '../../../assets/Phase1a/DesignUpdate/megaphoneReport.png';
import request from '../../../api/request';
import { toast } from 'react-toastify';
import CustomToast from '../../../components/phase1a/common/CustomToast.component';

interface ReportReviewProps {
  open: boolean;
  handleClose: () => void;
  reviewData?: any;
}

interface Option {
  id: number;
  title: string;
  description: string;
  icon: string;
}

const ReportReview: React.FC<ReportReviewProps> = ({ open, handleClose, reviewData }) => {
  const [isStep1Checked, setIsStep1Checked] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [reportData, setReportData] = useState({
    report: {
      reason: {
        title: '',
        description: '',
      },
      details: '',
    },
    review_status: 'reported',
  });

  const [step, setStep] = useState(1);
  const [buttonText, setButtonText] = useState('Get started');
  const handleStep1CheckboxChange = (event: any): void => {
    setIsStep1Checked(event.target.checked);
  };
  const handleOptionSelection = (option: any): void => {
    setSelectedOption(option.id);
    setReportData((prevState) => ({
      ...prevState,
      report: {
        ...prevState.report,
        reason: {
          title: option.title,
          description: option.description,
        },
      },
    }));
  };
  const handleDetailsChange = (event: any): void => {
    const { value } = event.target;
    setReportData((prevState) => ({
      ...prevState,
      report: {
        ...prevState.report,
        details: value,
      },
    }));
  };

  const handleButtonClick = (): void => {
    if (step === 1) {
      setButtonText('Next');
      setStep(2);
    } else if (step === 2) {
      setStep(3);
      setButtonText('Submit'); // Change button text for step 3
    } else if (step === 3) {
      handleCloseReportModal(); // Close the modal after submission
    }
  };
  const handleSubmitReport = (): void => {
    /* eslint-disable @typescript-eslint/restrict-template-expressions */
    request('put', `/review/reviewFlag/${reviewData?._id}`, reportData)
      .then((res) => {
        console.log('review res23', res);
        handleCloseReportModal();
        toast(<CustomToast message={'Your report has been submitted'} type="success" />);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };
  const handleCloseReportModal = (): void => {
    setStep(1);
    setIsStep1Checked(false);
    setSelectedOption(null);
    setReportData((prevState) => ({
      ...prevState,
      report: {
        ...prevState.report,
        reason: {
          title: '',
          description: '',
        },
        details: '',
      },
    }));
    handleClose(); // Close the modal after submission
  };

  const options: Option[] = [
    {
      id: 1,
      title: 'Inappropriate Review',
      description:
        'This includes content that is hateful, by a competitor or company employee, damaging to your business without any basis or reveals confidential information.',
      icon: DislikeIcon,
    },
    {
      id: 2,
      title: 'Inaccurate Review',
      description:
        'This includes content that can be proven to be false or was written about another business.',
      icon: CloseIcon,
    },
    {
      id: 3,
      title: 'Promotional Review',
      description:
        'This includes content that promotes another business, unrelated products or is spam.',
      icon: MegaphoneReport,
    },
  ];

  useEffect(() => {
    console.log('reportData', reportData);
  }, [reportData]);

  return (
    <Modal
      open={open}
      onClose={handleCloseReportModal}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      BackdropProps={{
        sx: {
          backgroundColor: 'var(#0C111D)',
          opacity: 0.7,
          backdropFilter: 'blur(8px)', // Adjust the blur level as needed
        },
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: step === 1 ? 400 : 480,
          bgcolor: 'background.paper',
          boxShadow: 24,
          padding: step === 1 ? '20px 0' : '20px 30px',
          borderRadius: '12px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: '12px',
          '@media (max-width: 500px)': {
            width: '90%',
          },
        }}
      >
        {step === 1 && (
          <>
            <Box width={'84px'} height={'84px'}>
              <img
                src={ReportReviewIcon}
                alt="Report Review"
                style={{ width: '100%', height: '100%' }}
              />
            </Box>
            <Box
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              flexDirection={'column'}
              gap={'10px'}
              padding={'0 30px'}
            >
              <Typography
                fontFamily={'SF UI Display'}
                fontSize={'24px'}
                fontStyle={'normal'}
                fontWeight={700}
                color={'#101828'}
                lineHeight={'28px'}
                margin={'10px 0'}
                textAlign={'center'}
              >
                Report This Review
              </Typography>
              <Typography
                fontFamily={'Inter'}
                fontSize={'14px'}
                fontStyle={'normal'}
                fontWeight={400}
                color={'#000'}
                lineHeight={'20px'}
              >
                The report function is an important feature for our platform. We’re dedicated to
                making sure your company is treated fairly.
                <br />
                <br />
                However, consistent misuse and/or abuse of this feature may lead to limiting or
                suspension of your account.
              </Typography>
            </Box>
            <Box
              display={'flex'}
              width={'100%'}
              alignItems={'center'}
              gap={'8px'}
              pb={1}
              padding={'0 30px'}
            >
              <Checkbox
                checked={isStep1Checked}
                onChange={handleStep1CheckboxChange}
                sx={{
                  color: '#D0D5DD',
                  width: '16px',
                  '&.Mui-checked': {
                    color: '#00BD57',
                  },
                }}
              />
              <Typography
                fontSize={'14px'}
                fontWeight={'400'}
                fontFamily={'SF UI Display'}
                lineHeight={'20px'}
              >
                I understand and agree not to misuse this feature
              </Typography>
            </Box>
            <Divider sx={{ width: '100%', height: '1px', bgcolor: '#E4E7EC' }} />
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              width={'100%'}
              padding={'10px 30px 0 30px'}
            >
              <Button
                sx={{
                  width: '48%',
                  padding: '10px',
                  fontFamily: 'Inter',
                  textTransform: 'capitalize',
                  fontSize: '16px',
                  color: '#000',
                  border: 'solid 1px #D0D5DD',
                  borderRadius: '8px',
                  fontWeight: 600,
                  lineHeight: '24px',
                }}
                onClick={handleCloseReportModal}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  width: '48%',
                  padding: '10px',
                  fontFamily: 'SF UI Display',
                  textTransform: 'capitalize',
                  fontSize: '16px',
                  color: '#fff',
                  border: 'solid 1px #D0D5DD',
                  borderRadius: '8px',
                  bgcolor: isStep1Checked ? '#00BD57' : '#F7F7F7',
                  fontWeight: 600,
                  lineHeight: '24px',
                  '&:hover': {
                    bgcolor: isStep1Checked ? '#00BD57' : '#F7F7F7', // Same color on hover
                  },
                  '&:disabled': {
                    bgcolor: '#F7F7F7',
                    border: 'none',
                  },
                }}
                disabled={!isStep1Checked}
                onClick={handleButtonClick}
              >
                {buttonText}
              </Button>
            </Box>
          </>
        )}
        {step === 2 && (
          <>
            <Box width="100%">
              <Box width="100%" padding={'10px 0'}>
                <Typography
                  fontFamily={'SF UI Display'}
                  fontSize={'18px'}
                  fontStyle={'normal'}
                  fontWeight={600}
                  color={'#000'}
                  lineHeight={'28px'}
                >
                  Report Reasons
                </Typography>
                <Typography
                  fontFamily={'SF UI Display'}
                  fontSize={'14px'}
                  fontStyle={'normal'}
                  fontWeight={400}
                  color={'#000'}
                  lineHeight={'20px'}
                >
                  Choose the main reason you are reporting this review.
                </Typography>
              </Box>
              {options.map((option) => (
                <Box
                  key={option.id}
                  width="100%"
                  border={selectedOption === option.id ? 'solid 2px #00BD57' : 'solid 2px #D0D5DD'}
                  borderRadius={'12px'}
                  margin={'15px 0'}
                  padding={'16px'}
                  display={'flex'}
                  justifyContent={'space-between'}
                  onClick={() => {
                    handleOptionSelection(option);
                  }}
                >
                  <Box width={'37px'} height={'37px'} padding={'4px'}>
                    <img
                      src={option.icon}
                      alt={option.title}
                      style={{ width: '100%', height: '100%' }}
                    />
                  </Box>
                  <Box width={'75%'}>
                    <Typography
                      color={'black'}
                      fontFamily={'SF UI Display'}
                      fontSize={'16px'}
                      fontWeight={500}
                      lineHeight={'20px'}
                    >
                      {option.title}
                    </Typography>
                    <Typography
                      color={'black'}
                      fontFamily={'SF UI Display'}
                      fontSize={'14px'}
                      fontWeight={400}
                      lineHeight={'20px'}
                      marginTop={'2px'}
                    >
                      {option.description}
                    </Typography>
                  </Box>
                  <Box
                    width={'10%'}
                    display={'flex'}
                    justifyContent={'flex-end'}
                    alignItems={'flex-start'}
                  >
                    <Checkbox
                      checked={selectedOption === option.id}
                      onClick={() => {
                        handleOptionSelection(option);
                      }}
                      sx={{
                        color: '#D0D5DD',
                        '&.Mui-checked': {
                          color: '#00BD57',
                        },
                      }}
                    />
                  </Box>
                </Box>
              ))}
            </Box>
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              width={'100%'}
              pt={1}
            >
              <Button
                sx={{
                  width: '48%',
                  padding: '10px 0',
                  fontFamily: 'SF UI Display',
                  textTransform: 'capitalize',
                  fontSize: '16px',
                  color: '#000',
                  border: 'solid 1px #D0D5DD',
                  borderRadius: '8px',
                  fontWeight: 600,
                  lineHeight: '24px',
                }}
                onClick={handleCloseReportModal}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  width: '48%',
                  padding: '10px',
                  fontFamily: 'SF UI Display',
                  textTransform: 'capitalize',
                  fontSize: '16px',
                  color: '#fff',
                  border: 'solid 1px #D0D5DD',
                  borderRadius: '8px',
                  bgcolor: selectedOption ? '#00BD57' : '#F7F7F7',
                  fontWeight: 600,
                  lineHeight: '24px',
                  '&:hover': {
                    bgcolor: selectedOption ? '#00BD57' : '#F7F7F7', // Same color on hover
                  },
                  '&:disabled': {
                    bgcolor: '#F7F7F7',
                    border: 'none',
                  },
                }}
                disabled={!selectedOption}
                onClick={handleButtonClick}
              >
                {buttonText}
              </Button>
            </Box>
          </>
        )}
        {step === 3 && (
          <>
            <Box width={'100%'}>
              <Typography
                fontFamily={'SF UI Display'}
                fontSize={'18px'}
                fontStyle={'normal'}
                fontWeight={600}
                color={'#000'}
                lineHeight={'28px'}
              >
                Report Explanation{' '}
              </Typography>
              <Typography
                fontFamily={'SF UI Display'}
                fontSize={'14px'}
                fontStyle={'normal'}
                fontWeight={400}
                color={'#000'}
                lineHeight={'20px'}
              >
                Provide us more information on why you are reporting this review.{' '}
              </Typography>
            </Box>
            <Box width={'100%'} margin={'10px 0 15px 0'}>
              <TextField
                rows={4}
                multiline
                fullWidth
                sx={{
                  width: '100%',
                  fontSize: '16px',
                  fontWeight: '400',
                  lineHeight: '24px',
                  color: '#667085',
                  borderRadius: '8px',

                  '.MuiOutlinedInput-input': {
                    padding: '0px',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: '1px solid #D0D5DD',
                      borderRadius: '8px',
                      outline: 'none',
                    },
                    '&:hover fieldset': {
                      borderColor: '#D0D5DD',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#D0D5DD',
                    },
                    '&.MuiInputBase-root:focus-visible': {
                      borderColor: '#D0D5DD',
                    },
                  },
                }}
                id="details"
                variant="outlined"
                placeholder="Write your explanation here..."
                name="details"
                value={reportData?.report?.details}
                onChange={handleDetailsChange}
              />
            </Box>
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              width={'100%'}
            >
              <Button
                sx={{
                  width: '48%',
                  padding: '10px',
                  fontFamily: 'SF UI Display',
                  textTransform: 'capitalize',
                  fontSize: '16px',
                  color: '#000',
                  border: 'solid 1px #D0D5DD',
                  borderRadius: '8px',
                  fontWeight: 600,
                  lineHeight: '24px',
                }}
                onClick={handleCloseReportModal}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  width: '48%',
                  padding: '10px',
                  fontFamily: 'SF UI Display',
                  textTransform: 'capitalize',
                  fontSize: '16px',
                  color: '#fff',
                  // border: 'solid 1px #D0D5DD',
                  border: '2px rgba(255, 255, 255, 0.12)',
                  borderRadius: '8px',
                  bgcolor: !reportData?.report?.details ? '#F7F7F7' : '#00BD57',
                  fontWeight: 600,
                  lineHeight: '24px',
                  '&:hover': {
                    bgcolor: !reportData?.report?.details ? '#F7F7F7' : '#00BD57',
                  },
                }}
                disabled={!reportData.report.details}
                onClick={handleSubmitReport}
              >
                Submit
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default ReportReview;
