import React, { FC, useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import Routes from './routes/index';
// CSS Integration
import 'react-toastify/dist/ReactToastify.css';
import './app.css';
// import eventHandler from './utils/eventHandler';

const App: FC = () => {
  const [activeUser, setActiveUser] = useState<boolean>(false);
  const loggedInUser = localStorage.getItem('user');
  // useEffect(() => {
  //   eventHandler().finally(console.log);
  // }, []);

  useEffect(() => {
    if (loggedInUser) {
      setActiveUser(true);
    }
  }, []);
  return (
    <div className="App">
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={true}
        closeOnClick={false}
        pauseOnHover={true}
        draggable={true}
        closeButton={false} // Hide the default close button
        toastStyle={{
          border: '2px solid #000',
          borderRadius: '12px',
          boxShadow: '0px 4px 4px 0px #1018280D',
        }}
      />

      <Routes loggedIn={activeUser} />
    </div>
  );
};

export default App;
