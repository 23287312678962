import React, { FC, useEffect, useRef, useState } from 'react';
import PersonalInfoIcon from '../../../assets/Phase1a/SignUp/signUpiIcon.svg';
import {
  Avatar,
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
  Typography,
} from '@mui/material';
import { mediaUrl } from '../../../config';
import DefaultAvatar from '../../../assets/Phase1a/SignUp/ProfileavatarIcon.jpeg';
import CloseIconBg from '../../../assets/Phase1a/SignUp/CloseIcon.svg';
// import AddImageIcon from '../../../assets/Phase1a/SignUp/AddImageIcon.svg';
import request from '../../../api/request';
import { toast } from 'react-toastify';
import { teamMember } from '../../../types/user';
import EditCompany from './EditCompany';
import EditNationalCompany from './EditNationalCompany';
import EditSupplierCompany from './EditSupplierComapny';
import { UpdateStore } from '../../../context/store';
import EyeIcon from '../../../assets/eyeicon.svg';
import EyeOffIcon from '../../../assets/EyeoffIcon.svg';
import CustomToast from '../../../components/phase1a/common/CustomToast.component';

interface IAccountInfo {
  parsedUser: teamMember;
}

const AccountsInfo: FC<IAccountInfo> = ({ parsedUser }) => {
  const updateStore = UpdateStore();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [user, setUser] = useState<teamMember>(parsedUser);
  const [loading, setLoading] = useState<boolean>(true);
  const [categories, setCategories] = useState<any>([]);
  const [passwordData, setPasswordData] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  console.log(user, 'user');

  const handleAvatarClick = (): void => {
    fileInputRef.current?.click();
  };

  const handleRemoveImage = (): void => {
    setLoading(false);
    setUser((prevData) => ({
      ...prevData,
      photo: '',
    }));
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Reset the file input value
    }
  };

  const handleUpdateUser = (): void => {
    const body = {
      first_name: user?.first_name,
      last_name: user?.last_name,
      photo: user?.photo,
      job_title: user?.job_title,
    };
    // setLoading(true);
    /* eslint-disable @typescript-eslint/restrict-template-expressions */
    request('put', `/users/${user?._id}`, body)
      .then((res: any) => {
        // toast.success('Profile Updated Successfully');
        toast(<CustomToast message={'Profile Updated Successfully'} type="success" />);

        console.log('res', res);
        setUser((prev) => ({
          ...prev,
          first_name: res?.user?.first_name,
          last_name: res?.user?.last_name,
          photo: res?.user?.photo,
          job_title: res?.user?.job_title,
        }));

        parsedUser.first_name = res?.user?.first_name;
        parsedUser.last_name = res?.user?.last_name;
        parsedUser.photo = res?.user?.photo;
        parsedUser.job_title = res?.user?.job_title;
        localStorage.setItem('user', JSON.stringify(parsedUser));
        if (updateStore) {
          updateStore({ user: res?.user });
        }
        setLoading(true);
      })
      .catch((err) => {
        // toast.error(err?.response?.data?.message);
        toast(<CustomToast message={err?.response?.data?.message} type="error" />);

        setLoading(false);
        console.log('err', err);
      });
  };

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setLoading(false);
    const file = e.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append('photo', file);
      request('post', `/uploads`, formData)
        .then((res) => {
          const imageUrl = (res as string[])[0];

          setUser((prevData) => ({
            ...prevData,
            photo: imageUrl,
          }));
        })
        .catch(() => {
          console.error('Image upload error');
          // toast.error('error');
        });
    }
  };
  const getCategories = (): void => {
    request('get', `/companies/categories`)
      .then((res: any) => {
        // toast.success('Success');
        console.log('res searcchh', res?.categories);
        setCategories(res?.categories);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  useEffect(() => {
    getCategories();
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    if (name === 'oldPassword' || name === 'newPassword' || name === 'confirmPassword') {
      setLoading(true);
    } else {
      setLoading(false);
    }
    setPasswordData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setUser((prevUser) => {
      return {
        ...prevUser,
        [name]: value,
      };
    });
  };
  const compnayType = parsedUser?.company?.companyInfo?.companyType;
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const handleClickShowOldPassword = (): void => {
    setShowOldPassword((show) => !show);
  };
  const handleClickShowNewPassword = (): void => {
    setShowNewPassword((show) => !show);
  };
  const handleClickShowConfirmPassword = (): void => {
    setShowConfirmPassword((show) => !show);
  };
  const handleMouseDownPassword = (event: { preventDefault: () => void }): void => {
    event.preventDefault();
  };

  const handleUpdateClick = (): void => {
    setIsUpdating(true);
  };

  const handleSubmitClick = (): void => {
    // Handle password update logic here
    // ...
    request('put', `/auth/password/update`, passwordData)
      .then((res: any) => {
        // toast.success('Password Changed Succesfully');
        toast(<CustomToast message={'Password Changed Succesfully'} type="success" />);

        console.log('res searcchh', res.data);
        setPasswordData({
          oldPassword: '',
          newPassword: '',
          confirmPassword: '',
        });
      })
      .catch((err) => {
        console.log('err', err);
        // toast.error(err?.response?.data?.message);
        toast(<CustomToast message={err?.response?.data?.message} type="error" />);
      });
    console.log(passwordData, '2222');
  };

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      gap="10px"
      marginTop={'4%'}
    >
      <img src={PersonalInfoIcon} alt="" style={{ width: '77.5px', height: '77.5px' }} />
      <Typography
        fontFamily="SF UI Display"
        fontWeight={800}
        fontSize="24px"
        lineHeight="28.64px"
        textAlign="center"
      >
        Personal Information
      </Typography>
      <Typography
        fontFamily="SF UI Display"
        fontWeight={400}
        fontSize="16px"
        lineHeight="19.09px"
        textAlign="center"
      >
        Enter your details.
      </Typography>
      <Box
        width="80%"
        position="relative"
        display="flex"
        justifyContent="center"
        alignItems="center"
        gap={'10px'}
        flexDirection="column"
        sx={{
          '@media (max-width: 1024px)': {
            width: '90%',
          },
        }}
      >
        {/* Profile pic */}
        <Box width="100%" position="relative" display="flex" mt={5}>
          <Avatar
            onClick={handleAvatarClick}
            style={{ cursor: 'pointer', width: 100, height: 100 }}
            src={user?.photo ? `${mediaUrl}/${user?.photo}` : DefaultAvatar}
          />
          {user?.photo && (
            <img
              src={CloseIconBg}
              alt="Remove"
              onClick={handleRemoveImage}
              style={{
                position: 'absolute',
                top: 0,
                left: '71px',
                width: '30px',
                height: '30px',
                zIndex: 1,
                cursor: 'pointer',
              }}
            />
          )}
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleImageUpload}
            accept="image/*"
          />
        </Box>
        {/* Name */}
        <Box
          width="100%"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          margin="3% 0 0 0"
          sx={{
            '@media (max-width: 1024px)': {
              flexDirection: 'column',
            },
          }}
        >
          <Box
            width="48%"
            margin={'17px 0 19px 0'}
            gap={'14px'}
            display={'flex'}
            flexDirection={'column'}
            sx={{
              '@media (max-width: 1024px)': {
                width: '100%',
              },
            }}
          >
            <Typography
              fontFamily={'SF UI Display'}
              fontWeight={500}
              fontSize="16px"
              lineHeight="19.09px"
              // margin="2% 0"
            >
              First Name
            </Typography>
            <TextField
              fullWidth
              sx={{
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '24px',
                color: '#667085',
                borderRadius: '8px',
                '.MuiOutlinedInput-input': {
                  padding: '10px 14px',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '1px solid #D0D5DD',
                    outline: 'none',
                  },
                  '&:hover fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.MuiInputBase-root:focus-visible': {
                    borderColor: '#D0D5DD',
                  },
                },
              }}
              InputProps={{
                inputProps: {
                  maxLength: 20,
                },
              }}
              id="fname"
              variant="outlined"
              placeholder="First Name"
              name="first_name"
              value={user?.first_name}
              onChange={handleChange}
            />
          </Box>
          <Box
            width="48%"
            margin={'17px 0 19px 0'}
            gap={'14px'}
            display={'flex'}
            flexDirection={'column'}
            sx={{
              '@media (max-width: 1024px)': {
                width: '100%',
              },
            }}
          >
            <Typography
              fontFamily={'SF UI Display'}
              fontWeight={500}
              fontSize="16px"
              lineHeight="19.09px"
              // margin="2% 0"
            >
              Last Name
            </Typography>
            <TextField
              fullWidth
              sx={{
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '24px',
                color: '#667085',
                borderRadius: '8px',
                '.MuiOutlinedInput-input': {
                  padding: '10px 14px',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '1px solid #D0D5DD',
                    outline: 'none',
                  },
                  '&:hover fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.MuiInputBase-root:focus-visible': {
                    borderColor: '#D0D5DD',
                  },
                },
              }}
              InputProps={{
                inputProps: {
                  maxLength: 20,
                },
              }}
              id="lname"
              variant="outlined"
              placeholder="Last Name"
              name="last_name"
              value={user.last_name}
              onChange={handleChange}
            />
          </Box>
        </Box>
        {/* Password */}
        <Box
          width={'100%'}
          // margin={'12px 0'}
          // gap={'14px'}
          // display={'flex'}
          // flexDirection={'column'}
          // mt={2}
          sx={{
            '@media (max-width: 500px)': {
              width: '100%',
            },
          }}
        >
          <Box width={'100%'} display={'flex'} justifyContent={'space-between'}>
            <Typography
              fontFamily="SF UI Display"
              fontWeight={500}
              fontSize="16px"
              lineHeight="19.09px"
              // margin="2% 0"
            >
              {isUpdating ? 'Old Password' : 'Update Password'}
            </Typography>

            {isUpdating ? (
              <Typography
                fontFamily="SF UI Display"
                fontWeight={500}
                fontSize="16px"
                lineHeight="19.09px"
                // margin="2% 0"
                color={'rgba(0, 189, 87, 1)'}
                sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                onClick={handleSubmitClick}
              >
                Submit
              </Typography>
            ) : (
              <Typography
                fontFamily="SF UI Display"
                fontWeight={500}
                fontSize="16px"
                lineHeight="19.09px"
                // margin="2% 0"
                color={'rgba(0, 189, 87, 1)'}
                sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                onClick={handleUpdateClick}
              >
                Update
              </Typography>
            )}
          </Box>
          {!isUpdating && (
            <FormControl
              disabled
              sx={{
                mt: 2,
                width: '100%',
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '24px',
                color: '#667085',
                borderRadius: '12px',
                '.MuiOutlinedInput-input': {
                  padding: '10px 14px',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '1px solid #D0D5DD',
                    outline: 'none',
                  },
                  '&:hover fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.MuiInputBase-root:focus-visible': {
                    borderColor: '#D0D5DD',
                  },
                },
              }}
              variant="outlined"
            >
              <OutlinedInput
                type={showOldPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowOldPassword}
                      edge="end"
                    >
                      {showOldPassword ? (
                        <img src={EyeOffIcon} alt="." />
                      ) : (
                        <img src={EyeIcon} alt="." />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                placeholder="Enter password"
              />
            </FormControl>
          )}
          {isUpdating && (
            <>
              <Box margin={'2% 0'}>
                <FormControl
                  sx={{
                    width: '100%',
                    fontSize: '16px',
                    fontWeight: '400',
                    lineHeight: '24px',
                    color: '#667085',
                    borderRadius: '12px',
                    '.MuiOutlinedInput-input': {
                      padding: '10px 14px',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: '1px solid #D0D5DD',
                        outline: 'none',
                      },
                      '&:hover fieldset': {
                        borderColor: '#D0D5DD',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#D0D5DD',
                      },
                      '&.MuiInputBase-root:focus-visible': {
                        borderColor: '#D0D5DD',
                      },
                    },
                  }}
                  variant="outlined"
                >
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showOldPassword ? 'text' : 'password'}
                    name="oldPassword"
                    value={passwordData?.oldPassword}
                    onChange={handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowOldPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showOldPassword ? (
                            <img src={EyeOffIcon} alt="." />
                          ) : (
                            <img src={EyeIcon} alt="." />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    placeholder="Old Password"
                  />
                </FormControl>
              </Box>
              <>
                <Box width={'100%'} margin={'22px 0 '} display={'flex'} flexDirection={'column'}>
                  <Typography
                    fontFamily="SF UI Display"
                    fontWeight={500}
                    fontSize="16px"
                    lineHeight="19.09px"
                    // marginTop={'2%'}
                  >
                    Create New Password
                  </Typography>
                  <FormControl
                    sx={{
                      width: '100%',
                      fontSize: '16px',
                      fontWeight: '400',
                      lineHeight: '24px',
                      color: '#667085',
                      borderRadius: '12px',
                      '.MuiOutlinedInput-input': {
                        padding: '10px 14px',
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: '1px solid #D0D5DD',
                          outline: 'none',
                        },
                        '&:hover fieldset': {
                          borderColor: '#D0D5DD',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#D0D5DD',
                        },
                        '&.MuiInputBase-root:focus-visible': {
                          borderColor: '#D0D5DD',
                        },
                      },
                      mt: 2,
                    }}
                    variant="outlined"
                  >
                    <OutlinedInput
                      id="outlined-adornment-new-password"
                      type={showNewPassword ? 'text' : 'password'}
                      name="newPassword"
                      value={passwordData.newPassword}
                      onChange={handleChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowNewPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showNewPassword ? (
                              <img src={EyeOffIcon} alt="." />
                            ) : (
                              <img src={EyeIcon} alt="." />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      placeholder="New Password"
                    />
                  </FormControl>
                </Box>
                <Typography
                  fontFamily="SF UI Display"
                  fontWeight={500}
                  fontSize="16px"
                  lineHeight="19.09px"
                  marginTop={'2%'}
                >
                  Confirm New Password
                </Typography>
                <FormControl
                  sx={{
                    width: '100%',
                    fontSize: '16px',
                    fontWeight: '400',
                    lineHeight: '24px',
                    color: '#667085',
                    borderRadius: '12px',
                    '.MuiOutlinedInput-input': {
                      padding: '10px 14px',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: '1px solid #D0D5DD',
                        outline: 'none',
                      },
                      '&:hover fieldset': {
                        borderColor: '#D0D5DD',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#D0D5DD',
                      },
                      '&.MuiInputBase-root:focus-visible': {
                        borderColor: '#D0D5DD',
                      },
                    },
                    mt: 2,
                  }}
                  variant="outlined"
                >
                  <OutlinedInput
                    id="outlined-adornment-confirm-password"
                    type={showConfirmPassword ? 'text' : 'password'}
                    name="confirmPassword"
                    value={passwordData.confirmPassword}
                    onChange={handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <img src={EyeOffIcon} alt="." />
                          ) : (
                            <img src={EyeIcon} alt="." />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    placeholder="Confirm New Password"
                  />
                </FormControl>
              </>
            </>
          )}
        </Box>
        <Box
          width="100%"
          margin={'17px 0 19px 0'}
          gap={'14px'}
          display={'flex'}
          flexDirection={'column'}
          sx={{
            '@media (max-width: 1024px)': {
              width: '100%',
            },
          }}
        >
          <Typography
            fontFamily={'SF UI Display'}
            fontWeight={500}
            fontSize="16px"
            lineHeight="19.09px"
            // margin="2% 0"
            // sx={{
            //   '@media (max-width: 1024px)': {
            //     margin: '5% 0',
            //   },
            // }}
          >
            Job Title
          </Typography>
          <TextField
            fullWidth
            sx={{
              fontSize: '16px',
              fontWeight: '400',
              lineHeight: '24px',
              color: '#667085',
              borderRadius: '8px',
              '.MuiOutlinedInput-input': {
                padding: '10px 14px',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: '1px solid #D0D5DD',
                  outline: 'none',
                },
                '&:hover fieldset': {
                  borderColor: '#D0D5DD',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#D0D5DD',
                },
                '&.MuiInputBase-root:focus-visible': {
                  borderColor: '#D0D5DD',
                },
              },
            }}
            InputProps={{
              inputProps: {
                maxLength: 30,
              },
            }}
            id="job_title"
            variant="outlined"
            placeholder="Job Title"
            name="job_title"
            value={user?.job_title}
            onChange={handleChange}
          />
        </Box>
        <Button
          fullWidth
          sx={{
            backgroundColor:
              !user?.first_name?.length ||
              !user?.last_name?.length ||
              !user?.job_title?.length ||
              loading
                ? '#E0E0E0'
                : '#47B45F',
            fontFamily: 'SF UI Display',
            fontSize: '16px',
            fontWeight: '600',

            lineheight: '19.09px',
            textAlign: 'center',
            color: '#FFFFFF',
            borderRadius: '5px',
            textTransform: 'capitalize',
            marginTop: '20px',
            '&:hover': {
              bgcolor: '#00BD57',
            },
          }}
          disabled={
            !user?.first_name?.length ||
            !user?.last_name?.length ||
            !user?.job_title?.length ||
            loading
          }
          onClick={handleUpdateUser}
        >
          update{' '}
        </Button>
      </Box>
      {user?.type !== 'member' ? (
        <Box
          width={'100%'}
          sx={{
            '@media (max-width: 600px)': {
              width: '100%',
            },
          }}
        >
          {compnayType === 'local' && <EditCompany parsedUser={parsedUser} />}
          {compnayType === 'national' && <EditNationalCompany parsedUser={parsedUser} />}
          {(compnayType === 'supplier' || compnayType === 'distributor') && (
            <EditSupplierCompany parsedUser={parsedUser} categories={categories} />
          )}
        </Box>
      ) : (
        ''
      )}
    </Box>
  );
};

export default AccountsInfo;
