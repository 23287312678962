import React, { FC } from 'react';
import { Box, Checkbox, Typography } from '@mui/material';

interface Guideline {
  src: string;
  text: string;
}

interface ReviewSection1Props {
  companyData: any;
  isChecked: boolean;
  toggleCheckbox: () => void;
  guidelines: Guideline[];
}

const reviewSection1: FC<ReviewSection1Props> = ({
  companyData,
  isChecked,
  toggleCheckbox,
  guidelines,
}) => {
  return (
    <Box width={'100%'} margin={'20px 0 '}>
      <Typography
        fontSize={'24px'}
        fontWeight={'800'}
        fontFamily={'SF UI Display'}
        color={'#000000'}
        textTransform={'capitalize'}
      >
        Review {companyData?.companyInfo?.name}
      </Typography>
      <Typography
        fontSize={'16px'}
        fontWeight={'400'}
        fontFamily={'SF UI Display'}
        color={'#000000'}
        margin={'10px 0'}
        sx={{
          '@media (max-width: 500px)': {
            display: 'none',
          },
        }}
      >
        At SignWise, we’re dedicated to making sure your opinion is heard regardless of whether its
        positive or negative.
        <br />
        <br />
        In return, we require that all your reviews follow these guidelines:
      </Typography>
      <Typography
        fontSize={'16px'}
        fontWeight={'400'}
        fontFamily={'SF UI Display'}
        color={'#000000'}
        margin={'10px 0'}
        lineHeight={'18px'}
        display={'none'}
        sx={{
          '@media (max-width: 500px)': {
            display: 'block',
          },
        }}
      >
        At SignWise, we’re dedicated to making sure your opinion is heard regardless of whether its
        positive or negative. In return, we require that all your reviews follow these guidelines:
      </Typography>
      <Box
        width={'100%'}
        display={'flex'}
        flexWrap={'wrap'}
        justifyContent={'space-between'}
        margin={'30px 0'}
        sx={{
          '@media (max-width: 500px)': {
            margin: '15px 0',
          },
        }}
      >
        {guidelines?.map((guideline, index) => (
          <Box
            key={index}
            display={'flex'}
            width={'47%'}
            gap={'10px'}
            margin={'10px 0'}
            alignItems={'center'}
            sx={{
              '@media (max-width: 1024px)': {
                width: '100%',
              },
            }}
          >
            <Box
              width={'33px'}
              height={'33px'}
              sx={{
                '@media (max-width: 500px)': {
                  width: '25px',
                  height: '25px',
                },
              }}
            >
              <img style={{ width: '100%', height: '100%' }} src={guideline?.src} />
            </Box>
            <Typography
              width={'90%'}
              fontSize={'15px'}
              fontWeight={'500'}
              fontFamily={'SF UI Display'}
              color={'#000000'}
              lineHeight={'15px'}
            >
              {guideline?.text}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box
        width={'100%'}
        display={'flex'}
        gap={'10px'}
        alignItems={'center'}
        sx={{
          '@media (max-width: 1024px)': {
            width: 'fit-content',
            bgcolor: '#F7F7F7',
            borderRadius: '12px',
            padding: '15px 10px 15px 5px',
          },
          '@media (max-width: 500px)': {
            width: '100%',
            bgcolor: '#F7F7F7',
            borderRadius: '12px',
            padding: '15px 10px 15px 5px',
          },
        }}
      >
        <Checkbox
          checked={isChecked}
          onChange={toggleCheckbox} // Toggles the checkbox state
          sx={{
            color: '#D0D5DD',
            '&.Mui-checked': {
              color: '#00BD57', // Set the checked color to green
            },
          }}
        />
        <Typography
          fontSize={'16px'}
          fontWeight={'400'}
          fontFamily={'SF UI Display'}
          lineHeight={'20px'}
          color={'#000000'}
          sx={{
            '@media (max-width: 1024px)': {
              fontSize: '14px',
              fontWeight: '500',
              lineHeight: '16px',
            },
          }}
        >
          I understand the review guidelines and agree to follow them.
        </Typography>
      </Box>
    </Box>
  );
};

export default reviewSection1;
