import React, { FC, useEffect, useState } from 'react';
import { Autocomplete, Avatar, Box, Button, TextField, Typography, Checkbox } from '@mui/material';
import CompanyInfo from '../../../assets/homeimg.svg';
import UpoadImg from '../../../assets/uploadIcon.svg';
import DefaultAvatar from '../../../assets/avatar.svg';
import ProfileModal from '../../../Popups/phase1a/ProfileUpdate.popup';

import request from '../../../api/request';
import { toast } from 'react-toastify';
import { mediaUrl } from '../../../config';
// import { useNavigate } from 'react-router-dom';
import CloseIcon from '../../../assets/closeFilledIcon.svg';
import {
  IAddress,
  IAddressResponse,
  // ICategory,
  IResponseUser,
  teamMember,
} from '../../../types/user';
import RightArrow from '../../../assets/rightArrow_1.svg';
import CustomToast from '../../../components/phase1a/common/CustomToast.component';

interface IEditCompany {
  parsedUser: teamMember;
  categories: any;
}

const EditSupplierCompany: FC<IEditCompany> = ({ parsedUser, categories }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = (): void => {
    setModalOpen(true);
  };

  const handleModalClose = (): void => {
    setModalOpen(false);
  };
  const [user, setUser] = useState(parsedUser || null);

  const [allAddresses, setAllAddresses] = useState<IAddress[]>([]);
  const [name, setName] = useState('');
  const [data, setData] = useState({
    companyInfo: {
      name: user.company.companyInfo.name,
      address: {
        street: user.company.companyInfo.address.street,
        city: user.company.companyInfo.address.city,
        state: user.company.companyInfo.address.state,
        zip: user.company.companyInfo.address.zip,
        address: user.company.companyInfo.address.address,
        country: user.company.companyInfo.address.country,
      },
      phone: user.company.companyInfo.phone,
      formattedNo: user.company.companyInfo.phone,

      email: user.company.companyInfo.email,
      domain: user.company.companyInfo.domain,
      website: '',
      description: user.company.companyInfo.description,
      location: {
        type: 'Point',
        coordinates: [
          user.company.companyInfo.location.coordinates[0],
          user.company.companyInfo.location.coordinates[1],
        ],
      },
      logo: user.company.companyInfo.logo,
      companyType: 'supplier',
      category: user.company.companyInfo.category,
      verification: { status: true },
      owner: user._id,
    },
    profileCompleted: true,
  });
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('');
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedCategory, setSelectedCategory] = useState<any>(null);

  const [checkedSubcategories, setCheckedSubcategories] = useState<
    Record<string, Record<string, boolean>>
  >({});
  const [checkedCategories, setCheckedCategories] = useState<Record<string, boolean>>({});

  const formatPhoneNumber = (phoneNumber: string): string => {
    const cleaned = phoneNumber.replace(/\D/g, '');

    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
    if (match) {
      let formattedNumber = '';
      if (match[1]) {
        formattedNumber += `(${match[1]}`;
      }
      if (match[2]) {
        formattedNumber += `) ${match[2]}`;
      }
      if (match[3]) {
        formattedNumber += `-${match[3]}`;
      }
      return formattedNumber;
    }

    // Return as-is if format doesn't match
    return phoneNumber;
  };

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    const formattedValue = formatPhoneNumber(value);
    setFormattedPhoneNumber(formattedValue);
    handleChange(event);
  };
  useEffect(() => {
    const handleStorageEvent = (): void => {
      console.log('inside event');

      const userJSON = localStorage.getItem('user');
      if (userJSON) {
        console.log('first');
        setUser(JSON.parse(userJSON));
      } else {
        setUser(parsedUser);
      }
    };
    // Add event listener for 'storage' event
    window.addEventListener('storage', handleStorageEvent);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('storage', handleStorageEvent);
    };
  }, []);
  const handleGetAddress = (value: string): void => {
    setName(value);
    request<IAddressResponse>('get', `/address?text=${value}`)
      .then((response) => {
        console.log(response);
        setAllAddresses(response.results);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleAddressChange = (
    _event: React.SyntheticEvent<Element, Event>,
    value: IAddress,
  ): void => {
    setLoading(false);
    if (value) {
      setData({
        ...data,
        companyInfo: {
          ...data.companyInfo,
          address: {
            street: value.contexts.place,
            city: value.contexts.district,
            state: value.contexts.region,
            zip: value.contexts.postcode,
            address: value.place_name,
            country: value.contexts.country,
          },
          location: value.geometry,
        },
      });
    }
  };

  const isChecked = (category: string | number): boolean => checkedCategories[category] || false;

  const handleChange = (e: { target: { name: any; value: any } }): void => {
    const { name, value } = e.target;
    setLoading(false);

    setData((prevData) => {
      if (name.startsWith('companyInfo.address.')) {
        const addressField = name.split('.')[2];
        return {
          ...prevData,
          companyInfo: {
            ...prevData.companyInfo,
            address: {
              ...prevData.companyInfo.address,
              [addressField]: value,
            },
          },
        };
      } else if (name === 'serviceAreaDescription') {
        return {
          ...prevData,
          [name]: value,
        };
      } else if (name === 'domain') {
        const noSpacesValue = value.replace(/\s/g, '');
        return {
          ...prevData,
          companyInfo: {
            ...prevData.companyInfo,
            [name]: noSpacesValue,
          },
        };
      } else {
        return {
          ...prevData,
          companyInfo: {
            ...prevData.companyInfo,
            [name]: value,
          },
        };
      }
    });
  };

  // const nav = useNavigate();

  const handleRemoveImage = (): void => {
    setLoading(false);

    setData((prev) => ({
      ...prev,
      companyInfo: {
        ...prev.companyInfo,
        logo: '',
      },
    }));
  };

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setLoading(false);

    const file = e.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append('photo', file);
      request('post', `/uploads`, formData)
        .then((res) => {
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
          const imageUrl = (res as unknown as string[])[0];

          setData((prevData) => ({
            ...prevData,
            companyInfo: {
              ...prevData.companyInfo,
              logo: imageUrl,
            },
          }));
        })
        .catch(() => {
          console.error('Image upload error');
          // toast.error('error');
        });
    }
  };

  const handleAddSubcategories = (): void => {
    setLoading(false);

    if (!selectedCategory) return;

    const subcategories = checkedSubcategories[selectedCategory] || {};

    const hasCheckedSubcategories = Object.values(subcategories).some((checked) => checked);

    setCheckedCategories((prev: any) => ({
      ...prev,
      [selectedCategory]: hasCheckedSubcategories,
    }));

    setData((prevData: any) => {
      const updatedCategories = prevData?.companyInfo?.category?.filter(
        (cat: any) => cat.name !== selectedCategory,
      );

      const newCategory = {
        name: selectedCategory,
        subCategory: Object.keys(subcategories).filter((subcat) => subcategories[subcat]),
      };

      return {
        ...prevData,
        companyInfo: {
          ...prevData.companyInfo,
          category: hasCheckedSubcategories
            ? [...updatedCategories, newCategory]
            : updatedCategories,
        },
      };
    });

    setSelectedCategory(null);
  };

  const getUserDetails = (): void => {
    request<IResponseUser>('get', '/auth/userDetails')
      .then((res) => {
        console.log(res, 'details');
        localStorage.setItem('user', JSON.stringify(res.user));
        window.dispatchEvent(new Event('storage'));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubcategoryChange = (category: any, subcategory: any): void => {
    setLoading(false);

    setCheckedSubcategories((prev: any) => ({
      ...prev,
      [category]: {
        ...prev[category],
        [subcategory]: !prev[category]?.[subcategory],
      },
    }));
  };

  const handleSubmit = (): void => {
    if (!data?.companyInfo?.name) {
      // toast.error('Company name is required');
      toast(<CustomToast message={'Company name is required'} type="error" />);
      return;
    }
    if (!data?.companyInfo?.address.state) {
      // toast.error('Company address is required');
      toast(<CustomToast message={'Company address is required'} type="error" />);

      return;
    }

    if (!data?.companyInfo?.email) {
      // toast.error('Company email is required');
      toast(<CustomToast message={'Company email is required'} type="error" />);
      return;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailPattern.test(data.companyInfo.email)) {
      // toast.error('Invalid email format');
      toast(<CustomToast message={'Invalid email format'} type="error" />);
      return;
    }
    if (!data?.companyInfo?.domain) {
      // toast.error('Company domain is required');
      toast(<CustomToast message={'Company domain is required'} type="error" />);

      return;
    }

    setLoading(true);

    const verification = parsedUser.company.companyInfo.verification;
    if (verification && 'status' in verification) Object.assign(data.companyInfo, { verification });
    const owner = parsedUser.company.companyInfo.owner;
    if (owner) Object.assign(data.companyInfo, { owner });

    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    request('put', `/companies/${parsedUser.company._id}`, data)
      .then((res) => {
        // toast.success(`Company Updated Successfully`);
        // nav('/dashboard/home');
        // setLoading(false);
        console.log('res', res);
        // setData(initialState);
        getUserDetails();
        handleModalOpen();
      })
      .catch((err) => {
        setLoading(false);

        toast.error('Company already exists.', err);
        console.log('err', err);
      });
  };

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    validateForm();
  }, [data]);

  const validateForm = (): void => {
    if (
      !data.companyInfo.name ||
      !data.companyInfo.address.state ||
      !data.companyInfo.phone ||
      !data.companyInfo.email ||
      !data.companyInfo.domain
    ) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  };
  useEffect(() => {
    if (data?.companyInfo?.formattedNo) {
      setFormattedPhoneNumber(formatPhoneNumber(data.companyInfo.formattedNo));
    }
  }, [data?.companyInfo?.formattedNo]);

  useEffect(() => {
    if (parsedUser?.company?.companyInfo?.category) {
      const initialCheckedCategories: Record<string, boolean> = {};
      const initialCheckedSubcategories: Record<string, Record<string, boolean>> = {};

      parsedUser.company.companyInfo.category.forEach((category) => {
        initialCheckedCategories[category.name] = true;
        initialCheckedSubcategories[category.name] = {};

        category.subCategory.forEach((subCategory) => {
          initialCheckedSubcategories[category.name][subCategory] = true;
        });
      });

      setCheckedCategories(initialCheckedCategories);
      setCheckedSubcategories(initialCheckedSubcategories);
    }
  }, [parsedUser]);

  return (
    <Box
      width={'100%'}
      borderRadius={'16px'}
      //   border={'1px solid rgba(69, 66, 56, 0.10)'}
      padding={'36px'}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
    >
      {/* <Typography
        fontSize={'24px'}
        fontWeight={'600'}
        lineHeight={'20px'}
        fontFamily={'Inter'}
        color={'#000000'}
        marginBottom={'36px'}
      >
        Fill the details below:
      </Typography> */}
      <Box width={'80%'}>
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <img
            src={CompanyInfo}
            alt="Company Logo"
            style={{ width: '100px', marginBottom: '20px' }}
          />

          <Typography
            fontSize="24px"
            fontWeight="800"
            lineHeight="28.64px"
            fontFamily="SF UI Display"
            color="#000000"
            id="modal-modal-title"
          >
            Company Info
          </Typography>
          <Typography
            fontFamily="SF UI Display"
            fontSize="16px"
            fontWeight="400"
            lineHeight="19.09px"
            color="#000000"
            marginTop="10px"
            id="modal-modal-title"
          >
            Enter the company details
          </Typography>
        </Box>
        <Box display={'flex'} flexDirection={'column'}>
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'} margin={'3% 0'}>
            <Box
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'flex-start'}
              alignItems={'flex-start'}
              // bgcolor={'red'}
              position={'relative'}
            >
              <Avatar
                alt="Avatar"
                src={
                  /* eslint-disable @typescript-eslint/restrict-template-expressions */ data
                    ?.companyInfo?.logo
                    ? `${mediaUrl}/${data?.companyInfo?.logo}`
                    : DefaultAvatar
                }
                sx={{ width: '64px', height: '64px' }}
              />
              {data?.companyInfo?.logo && (
                <Box
                  width={'20px'}
                  display="flex"
                  justifyContent="center"
                  alignItems={'center'}
                  position="absolute"
                  right="5px"
                  top="-3px"
                  onClick={handleRemoveImage}
                  // bgcolor={'blue'}
                >
                  <img
                    src={CloseIcon}
                    alt=".."
                    style={{ width: '18px', height: '18px', cursor: 'pointer' }}
                  />
                </Box>
              )}
            </Box>
            <Box
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
              ml={2}
            >
              <input
                id="upload-button"
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={handleImageUpload}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={() => document.getElementById('upload-button')?.click()}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                  bgcolor: '#ffffff',
                  padding: '10px 16px',
                  border: '1px solid #D0D5DD',
                  borderRadius: '8px',
                  boxShadow: 'none',
                  '&:hover': {
                    bgcolor: '#FFFFFF',
                  },
                }}
              >
                <img src={UpoadImg} alt="UpoadImg" />
                <Typography
                  fontSize={'14px'}
                  fontWeight={'600'}
                  lineHeight={'20px'}
                  fontFamily={'SF UI Display'}
                  textTransform={'capitalize'}
                  color={'#344054'}
                >
                  Upload Company Logo
                </Typography>
              </Button>
            </Box>
          </Box>
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="start"
            gap="14px"
            margin="17px 0 19px 0"
          >
            <Typography
              sx={{
                fontFamily: 'SF UI Display',
                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '20px',
                color: '#000000',
              }}
            >
              Company Name{' '}
            </Typography>
            <TextField
              sx={{
                width: '100%',
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '24px',
                color: '#667085',
                borderRadius: '8px',
                '.MuiOutlinedInput-input': {
                  padding: '10px 14px',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '1px solid #D0D5DD',
                    outline: 'none',
                  },
                  '&:hover fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.MuiInputBase-root:focus-visible': {
                    borderColor: '#D0D5DD',
                  },
                },
              }}
              id="name"
              variant="outlined"
              placeholder="D’s Sign & Lighting LLC"
              name="name"
              value={data?.companyInfo?.name}
              onChange={handleChange}
              inputProps={{
                maxLength: 30,
              }}
            />
          </Box>
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="start"
            gap="14px"
            margin="17px 0 19px 0"
          >
            <Typography
              sx={{
                fontFamily: 'SF UI Display',

                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '20px',
                color: '#000000',
              }}
            >
              Company Address{' '}
            </Typography>

            <Autocomplete
              fullWidth
              sx={{
                '.MuiOutlinedInput-root': {
                  padding: '10px 14px',
                },
              }}
              value={parsedUser.company.companyInfo.address.address || name}
              freeSolo
              options={allAddresses}
              getOptionLabel={(option: string | IAddress) => {
                if (typeof option === 'object') {
                  return option.place_name;
                } else {
                  return option;
                }
              }}
              onChange={(event, value) => {
                console.log(value, 'value');
                if (value && typeof value !== 'string') handleAddressChange(event, value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="4311 Iris Dr SW, Baltimore, MD "
                  sx={{
                    width: '100%',
                    fontSize: '16px',
                    fontWeight: '400',
                    lineHeight: '24px',
                    color: '#667085',
                    borderRadius: '8px',
                    '.MuiOutlinedInput-input.MuiAutocomplete-input': {
                      padding: '0px',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: '1px solid #D0D5DD',
                        outline: 'none',
                      },
                      '&:hover fieldset': {
                        borderColor: '#D0D5DD',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#D0D5DD',
                      },
                      '&.MuiInputBase-root:focus-visible': {
                        borderColor: '#D0D5DD',
                      },
                    },
                  }}
                  id="select"
                  onChange={(e) => {
                    handleGetAddress(e.target.value);
                  }}
                  // inputProps={{
                  //   sx: {
                  //     '&::placeholder': {
                  //       fontSize: '16px',
                  //     },
                  //     '@media screen and (max-width: 1060px)': {
                  //       '&::placeholder': {
                  //         fontSize: '12px',
                  //       },
                  //     },
                  //   },
                  // }}
                  InputLabelProps={{
                    style: {
                      borderRadius: '50px',
                    },
                  }}
                />
              )}
            />
          </Box>
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="start"
            gap="14px"
            margin="17px 0 19px 0"
          >
            <Typography
              sx={{
                fontFamily: 'SF UI Display',

                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '20px',
                color: '#000000',
              }}
            >
              Phone Number
            </Typography>
            <TextField
              sx={{
                width: '100%',
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '24px',
                color: '#667085',
                borderRadius: '8px',
                '.MuiOutlinedInput-input': {
                  padding: '10px 14px',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '1px solid #D0D5DD',
                    outline: 'none',
                  },
                  '&:hover fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.MuiInputBase-root:focus-visible': {
                    borderColor: '#D0D5DD',
                  },
                },
              }}
              id="phone"
              variant="outlined"
              placeholder="(500) 234-5678"
              name="phone"
              value={formattedPhoneNumber}
              onChange={handlePhoneChange}
              inputProps={{
                pattern: '[0-9]*',
                maxLength: 14,
              }}
            />
          </Box>
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="start"
            gap="14px"
            margin="17px 0 19px 0"
          >
            <Typography
              sx={{
                fontFamily: 'SF UI Display',

                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '20px',
                color: '#000000',
              }}
            >
              Company Email
            </Typography>
            <TextField
              sx={{
                width: '100%',
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '24px',
                color: '#667085',
                borderRadius: '8px',
                '.MuiOutlinedInput-input': {
                  padding: '10px 14px',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '1px solid #D0D5DD',
                    outline: 'none',
                  },
                  '&:hover fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.MuiInputBase-root:focus-visible': {
                    borderColor: '#D0D5DD',
                  },
                },
              }}
              id="email"
              variant="outlined"
              placeholder="abc@xyz.com"
              name="email"
              value={data?.companyInfo?.email}
              onChange={handleChange}
            />
          </Box>
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="start"
            gap="14px"
            margin="17px 0 19px 0"
          >
            <Typography
              sx={{
                fontFamily: 'SF UI Display',

                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '20px',
                color: '#000000',
              }}
            >
              Company Domain
            </Typography>
            <TextField
              sx={{
                width: '100%',
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '24px',
                color: '#667085',
                borderRadius: '8px',
                '.MuiOutlinedInput-input': {
                  padding: '10px 14px',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '1px solid #D0D5DD',
                    outline: 'none',
                  },
                  '&:hover fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.MuiInputBase-root:focus-visible': {
                    borderColor: '#D0D5DD',
                  },
                },
              }}
              id="Domain"
              type="text"
              variant="outlined"
              placeholder="mysignco.com"
              name="domain"
              value={`www.${data?.companyInfo?.domain || ''}`}
              // onChange={handleChange}
              inputProps={{
                maxLength: 40,
                readOnly: true,
              }}
            />
          </Box>
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="start"
            gap="14px"
            margin="17px 0 19px 0"
          >
            <Typography
              sx={{
                fontFamily: 'SF UI Display',

                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '20px',
                color: '#000000',
              }}
            >
              Company Overview (Optional)
            </Typography>
            <TextField
              rows={4}
              multiline
              sx={{
                width: '100%',
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '24px',
                color: '#667085',
                borderRadius: '8px',

                '.MuiOutlinedInput-input': {
                  padding: '10px 14px',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '1px solid #D0D5DD',
                    outline: 'none',
                  },
                  '&:hover fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.MuiInputBase-root:focus-visible': {
                    borderColor: '#D0D5DD',
                  },
                },
              }}
              id="description"
              variant="outlined"
              placeholder="Enter description Here"
              name="description"
              value={data?.companyInfo?.description}
              onChange={handleChange}
            />
          </Box>
        </Box>
      </Box>

      <Box width={'80%'} margin={'4% 0'}>
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Box
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            gap="5px"
          >
            {/* <img src={ServiceIcon} alt="" /> */}
            <Typography
              fontSize="24px"
              fontWeight="800"
              lineHeight="28.64px"
              fontFamily="SF UI Display"
              color=" #000000"
              id="modal-modal-title"
              margin="1% 0"
            >
              Category
            </Typography>

            <Typography
              fontFamily="SF UI Display"
              fontSize="16px"
              fontWeight="400"
              lineHeight="19.09px"
              color="#000000"
              marginTop="10px"
              id="modal-modal-title"
              mb={8}
            >
              Add all the categories your company offers.
            </Typography>
          </Box>
        </Box>
        <Box width="100%">
          {selectedCategory === null ? (
            categories?.map((item: any, index: any) => (
              <Box
                key={index}
                borderRadius="10px"
                border="solid 1px #E8E8E8"
                padding="2%"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                marginBottom="1rem"
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    bgcolor: '#DFFFED',
                  },
                }}
              >
                <Box width="50%" display="flex" alignItems="center" gap="15px">
                  <Checkbox
                    checked={isChecked(item?.category)}
                    // onChange={() => {
                    //   handleServiceChange(item?.category);
                    // }}
                    sx={{
                      '&.Mui-checked': {
                        color: '#00BD57',
                      },
                    }}
                  />
                  <Typography
                    fontFamily="SF UI Display"
                    fontWeight="700"
                    fontSize="16px"
                    lineHeight="19.09px"
                  >
                    {item?.category}
                  </Typography>
                </Box>
                <Box
                  width="50%"
                  display="flex"
                  justifyContent="flex-end"
                  paddingRight="2%"
                  onClick={() => {
                    setSelectedCategory(item?.category);
                  }}
                >
                  <img src={RightArrow} alt="" style={{ width: '24px', height: '24px' }} />
                </Box>
              </Box>
            ))
          ) : (
            <Box>
              <Typography
                fontFamily="SF UI Display"
                fontWeight="700"
                fontSize="16px"
                lineHeight="19.09px"
                marginBottom="1rem"
              >
                {selectedCategory}
              </Typography>
              {categories
                ?.find((item: any) => item?.category === selectedCategory)
                ?.subCategories?.map((subcategory: any, index: number) => (
                  <Box
                    key={index}
                    display="flex"
                    alignItems="center"
                    gap="15px"
                    marginBottom="0.5rem"
                  >
                    <Checkbox
                      checked={checkedSubcategories[selectedCategory]?.[subcategory] || false}
                      onChange={() => {
                        handleSubcategoryChange(selectedCategory, subcategory);
                      }}
                      sx={{
                        '&.Mui-checked': {
                          color: '#00BD57',
                        },
                      }}
                    />
                    <Typography
                      fontFamily="SF UI Display"
                      fontWeight="700"
                      fontSize="16px"
                      lineHeight="19.09px"
                    >
                      {subcategory}
                    </Typography>
                  </Box>
                ))}
              <Button
                variant="contained"
                sx={{
                  color: '#ffffff',
                  textTransform: 'unset',
                  bgcolor: '#47B45F',
                  '&:hover': {
                    bgcolor: '#47B45F',
                  },
                }}
                onClick={handleAddSubcategories}
              >
                Add
              </Button>
            </Box>
          )}
        </Box>
      </Box>

      <Box
        width={'100%'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'right'}
        marginTop={'2%'}
      >
        <Button
          onClick={handleSubmit}
          disabled={isButtonDisabled || loading}
          sx={{
            bgcolor: isButtonDisabled || loading ? '#f0f0f0' : '#47B45F',
            color: '#ffffff',
            border: isButtonDisabled || loading ? 'none' : '1px solid #47B45F',
            borderRadius: '8px',
            padding: '10px 16px',
            minWidth: '20%',
            textTransform: 'capitalize',
            boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
            fontFamily: 'SF UI Display',
            fontSize: '16px',
            fontWeight: '600',
            lineHeight: '24px',
            '&:hover': {
              bgcolor: '#47B45F',
            },
          }}
        >
          Save & Update Company
        </Button>
      </Box>
      <ProfileModal open={modalOpen} handleClose={handleModalClose} />
    </Box>
  );
};

export default EditSupplierCompany;
