import React, { FC, useState } from 'react';
import { Box, Button, Divider, Rating, Typography, Avatar } from '@mui/material';
import reliabilityIcon from '../../../assets/dashboard/reliabilityIcon.svg';
import CommunicationIcon from '../../../assets/dashboard/communicationIcon.svg';
import QualityIcon from '../../../assets/compensation.svg';
import ResponseIcon from '../../../assets/payment.svg';
// import Work1 from '../../../assets/nationalSignCompany/work1.png';
import { ReactComponent as RequestQuoteIcon } from '../../../assets/message.svg';
import { ReactComponent as GradeOutlinedIcon } from '../../../assets/starimg.svg';
import { ReactComponent as FeedbackOutlinedIcon } from '../../../assets/file.svg';
import { ReactComponent as IosShareIcon } from '../../../assets/shareimg.svg';
import { ReactComponent as WEbIcon } from '../../../assets/website.svg';
// import Work2 from '../../../assets/nationalSignCompany/work2.png';
import MapImg from '../../../assets/nationalSignCompany/mapFrame.png';
// import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
// import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import HeaderSignCompany from '../../../components/phase1a/local/HeaderSignCompany.component';
import Logo from '../../../assets/nationalSignCompany/signLogo.svg';
// import VerifiedTag from '../../../assets/nationalSignCompany/verifiedTag.svg';
import GradeIcon from '@mui/icons-material/Grade';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ErrorIcon from '@mui/icons-material/Error';
// import VerificationModal from '../../../Popups/phase1a/local/VerificationModal.popups';
// import InstallattionModal from '../../../Popups/phase1a/local/installationModal.popups';
import ReviewModal from '../../../Popups/phase1a/national/ReviewModal.popups';
import Claimedicon from '../../../assets/claimed.svg';
import ContactPopup from '../../../Popups/phase1a/local/Contact.popups';
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps): JSX.Element {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box width={'100%'} padding={'2% 0'}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number): { id: string; 'aria-controls': string } {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 2,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    border: '1px solid #E0E1E2',
    backgroundColor: '#E0E1E2',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 2,
    backgroundColor: '#00BD57 ',
  },
}));

const data = [
  {
    iconSrc: reliabilityIcon,
    Icon: <GradeIcon fontSize="large" sx={{ color: '#00BD57' }} />,
    title: 'Professionalism',
    rating: '4.9',
    skeletonHeight: '21px',
  },
  {
    iconSrc: CommunicationIcon,
    Icon: <GradeIcon fontSize="large" sx={{ color: '#00BD57' }} />,
    title: 'Communication',
    rating: '4.9',
    skeletonHeight: '21px',
  },
  {
    iconSrc: QualityIcon,
    Icon: <GradeIcon fontSize="large" sx={{ color: '#00BD57' }} />,
    title: 'Compensation',
    rating: '4.9',
    skeletonHeight: '21px',
  },
  {
    iconSrc: QualityIcon,
    Icon: <GradeIcon fontSize="large" sx={{ color: '#00BD57' }} />,
    title: 'On-Time Payments',
    rating: '4.9',
    skeletonHeight: '21px',
  },
  {
    iconSrc: ResponseIcon,
    title: 'Payment Terms',
    rating: 'Net 30',
    skeletonHeight: '21px',
  },
];
const Highlightsdata = [
  {
    iconSrc: reliabilityIcon,
    Icon: <GradeIcon fontSize="large" sx={{ color: '#00BD57' }} />,
    title: 'Professionalism',
    rating: '4.9',
    skeletonHeight: '21px',
  },
  {
    iconSrc: CommunicationIcon,
    Icon: <GradeIcon fontSize="large" sx={{ color: '#00BD57' }} />,
    title: 'Communication',
    rating: '4.9',
    skeletonHeight: '21px',
  },
  {
    iconSrc: QualityIcon,
    Icon: <GradeIcon fontSize="large" sx={{ color: '#00BD57' }} />,
    title: 'Compensation',
    rating: '4.9',
    skeletonHeight: '21px',
  },
  {
    iconSrc: QualityIcon,
    Icon: <GradeIcon fontSize="large" sx={{ color: '#00BD57' }} />,
    title: 'On-Time Payments',
    rating: '4.9',
    skeletonHeight: '21px',
  },
  {
    iconSrc: ResponseIcon,
    title: 'Payment Terms',
    rating: 'Net 30',
    skeletonHeight: '21px',
  },
];

const buttonData = [
  { text: 'Contact Now', iconSrc: <RequestQuoteIcon />, action: 'contact' },
  { text: 'Recommend', iconSrc: <GradeOutlinedIcon /> },
  { text: 'File A Compaint', iconSrc: <FeedbackOutlinedIcon /> },
  { text: 'Website', iconSrc: <WEbIcon /> },

  { text: 'Share', iconSrc: <IosShareIcon /> },
];
const unclaimedbuttonData = [
  { text: 'Recommend', iconSrc: <GradeOutlinedIcon />, action: 'contact' },
  { text: 'File A Compaint', iconSrc: <FeedbackOutlinedIcon /> },

  { text: 'Share', iconSrc: <IosShareIcon /> },
];
const recommendationData = [
  {
    title: 'Installation Review',
    Date: 'Feb 18th - Apr 4th',
    overallRating: 4.9,
    data: [
      { title: 'Reliability', rating: 4.9, progress: 90 },
      { title: 'Communication', rating: 4.9, progress: 90 },
      { title: 'Work Quality', rating: 4.9, progress: 90 },
      { title: 'Lead Time', rating: 4.9, progress: 90 },
    ],
    feedback:
      '“We found D’s signs on Signwise and decided to try them out. It turned out that we made a great decision. Not only were they quick to respond whenever we reached out, but they were also proactive in keeping us up to date throughout the job. They finished on time and sent photos within 24 hours. Will definitely use again!”',
    avatar: '',
    Owner: 'Nick Rogers',

    company: 'D’s Sign & Lighting',
  },
  {
    title: 'Installation Review',
    Date: 'Feb 18th - Apr 4th',
    overallRating: 4.7,
    data: [
      { title: 'Reliability', rating: 4.7, progress: 90 },
      { title: 'Communication', rating: 4.5, progress: 90 },
      { title: 'Work Quality', rating: 4.8, progress: 90 },
      { title: 'Lead Time', rating: 4.9, progress: 90 },
    ],
    avatar: '',
    Owner: 'Project Manager',
    company: 'National Sign Company',
  },
];
const complaintData = [
  {
    title: 'Installation Project',
    status: 'Unverified Review',
    statusIcon: <ErrorIcon sx={{ color: 'red' }} />,
    overallRating: 3.1,
    data: [
      { title: 'Reliability', rating: 2, progress: 20 },
      { title: 'Communication', rating: 3.2, progress: 32 },
      { title: 'Work Quality', rating: 3.5, progress: 35 },
      { title: 'Lead Time', rating: 4, progress: 40 },
    ],
  },
];

const NationalSignCompany: FC = () => {
  // const [open, setOpen] = React.useState('');
  const [value, setValue] = React.useState(0);
  // const [openInstallationModal, setOpenInstallationModal] = useState(false);
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [showFullFeedback, setShowFullFeedback] = useState(false);
  const [openContactPopup, setOpenContactPopup] = useState(false);
  const [isClaimed, setIsClaimed] = useState(true);
  const handleToggleFeedback = (): void => {
    setShowFullFeedback((prev) => !prev);
  };

  // const handleCloseInstallationModal = (): void => {
  //   setOpenInstallationModal(false);
  // };
  const handleCloseReviewModal = (): void => {
    setOpenReviewModal(false);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number): void => {
    setValue(newValue);
  };

  // const handleClick = (item: React.SetStateAction<string>): void => {
  //   setOpen(item);
  // };

  const handleButtonClick = (action: string | undefined): void => {
    if (action === 'contact') {
      setOpenContactPopup(true);
    }
  };

  const handleClick = (): void => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    setIsClaimed;
  };
  const displayData = isClaimed ? data : Highlightsdata;

  const handleCloseContactPopup = (): void => {
    setOpenContactPopup(false);
  };

  return (
    <>
      <HeaderSignCompany listingGrid />
      <ReviewModal open={openReviewModal} onClose={handleCloseReviewModal} />
      <ContactPopup open={openContactPopup} onClose={handleCloseContactPopup} />

      <Box
        width="100%"
        padding={'3% 10%'}
        display="flex"
        justifyContent="space-between"
        marginTop={'80px'}
      >
        <Box width="67%">
          <Box width="100%" display="flex" gap={'5%'} alignItems={'center'}>
            <Box width="15%">
              <img src={Logo} alt="Logo" style={{ width: '100%' }} />
            </Box>
            <Box width="75%" display={'flex'} gap={'10px'} flexDirection={'column'}>
              <Typography
                fontSize="48px"
                fontFamily="SF UI Display"
                fontWeight="800"
                lineHeight="57px"
                color="#000000"
              >
                D’s Sign & Lighting LLC
              </Typography>
              <Typography
                fontSize="32px"
                fontFamily="SF UI Display"
                fontWeight="300"
                lineHeight="38px"
                color="#000000"
                onClick={(): void => {
                  handleClick();
                }}
              >
                Local Sign Company
              </Typography>
              <Box display="flex" gap="10px">
                <img src={Claimedicon} alt="Claimedicon" />
                <Typography
                  fontSize="16px"
                  fontFamily="SF UI Display"
                  fontWeight="600"
                  lineHeight="19.09px"
                  color="#000000"
                  letterSpacing={'0.0938em'}
                >
                  {isClaimed ? 'Claimed Page' : 'Unclaimed Page'}
                </Typography>
                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  sx={{ marginTop: '0', marginBottom: '0' }}
                />
                <Typography
                  fontSize="16px"
                  fontFamily="SF UI Display"
                  fontWeight="600"
                  lineHeight="19.09px"
                  color="#00BD57"
                >
                  2 Reviews
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            gap="15px"
            margin={'4% 0'}
            sx={{
              justifyContent: isClaimed ? 'initial' : 'center',
            }}
          >
            {(isClaimed ? buttonData : unclaimedbuttonData).map((item, index) => (
              <Button
                key={index}
                sx={{
                  border: '1px solid #E8E8E8',
                  color: 'black',
                  fontFamily: 'SF UI Display',
                  fontWeight: '600',
                  fontSize: '16px',
                  display: 'flex',
                  alignItems: 'center',

                  gap: '10px',
                  textTransform: 'capitalize',
                  padding: '5px 10px',
                  '&:hover': {
                    bgcolor: '#00BD57',
                    color: '#ffffff',
                    border: '1px solid transparent',
                  },
                }}
                onClick={(): void => {
                  handleButtonClick(item.action);
                }}
              >
                {item.iconSrc}

                {item.text}
              </Button>
            ))}
          </Box>
          <Box width="100%" mt={'6%'}>
            <Typography
              fontFamily="SF UI Display"
              fontWeight="800"
              fontSize="20px"
              lineHeight="23px"
              color="black"
            >
              Highlights
            </Typography>
            <Box width="100%" padding="5% 0" display="flex" alignItems="center" gap={'10px'}>
              {displayData.map((item, index) => (
                <Box
                  key={index}
                  width="16%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  gap="8px"
                >
                  <img src={item.iconSrc} alt="icon" style={{ width: '35px', height: '35px' }} />
                  <Box
                    width="100%"
                    display="flex"
                    justifyContent={'center'}
                    alignItems="center"
                    gap="3px"
                  >
                    {item?.Icon}
                    <Typography
                      fontFamily="SF UI Display"
                      fontWeight="700"
                      fontSize="32px"
                      lineHeight="39px"
                      color={'#00BD57 '}
                    >
                      {item?.rating}
                    </Typography>
                  </Box>
                  <Typography
                    fontFamily="SF UI Display"
                    fontSize="16px"
                    textAlign="center"
                    lineHeight="19px"
                    fontWeight="500"
                    color="black"
                  >
                    {item?.title}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
          {isClaimed ? (
            <Box width="94%" mt="1%">
              <Typography
                fontFamily="SF UI Display"
                fontWeight="800"
                fontSize="20px"
                lineHeight="23px"
                color="#000000"
              >
                Overview{' '}
              </Typography>
              <Box display="flex" alignItems="center" gap="30px" margin={'4% 0'}>
                <Typography
                  fontFamily="SF UI Display"
                  fontWeight="400"
                  fontSize="16px"
                  lineHeight="19.09px"
                  color="#000000"
                >
                  D’s Signs and Lighitng has been around for over 10 years. We offer custom tailored
                  install solutions to any national or retail client that needs are help all over
                  Arizona. We are good about communicating and do our best to make sure you are
                  always satisfied. If you have any questions about our services, don’t hesitate to
                  reach out.{' '}
                </Typography>
              </Box>
            </Box>
          ) : null}

          <Box width="100%" mt={'6%'}>
            <Box
              width={'100%'}
              margin={'2% 0 3% 0'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Typography
                fontFamily="SF UI Display"
                fontWeight="800"
                fontSize="20px"
                lineHeight="23px"
                color="black"
              >
                Reviews
              </Typography>

              <Button
                sx={{
                  fontFamily: 'SF UI Display',
                  fontSize: '16px',
                  fontWeight: '700',
                  lineHeight: '19px',
                  color: '#000000',
                  border: '1px solid #C4C4C4',
                  borderRadius: '5px',
                  width: '20%',
                  whiteSpace: 'nowrap',
                  textTransform: 'capitalize',
                  padding: '1.5% 0',
                }}
                onClick={(): void => {
                  setOpenReviewModal(true);
                }}
              >
                Submit Review
              </Button>
            </Box>
            <Box width="100%">
              <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: '2%' }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  TabIndicatorProps={{
                    style: {
                      height: '3px',
                      backgroundColor: '#00BD57',
                    },
                  }}
                  sx={{
                    minHeight: 0,
                  }}
                >
                  <Tab
                    label={`Recommendations (${recommendationData?.length})`}
                    {...a11yProps(0)}
                    sx={{
                      fontSize: '16px',
                      fontFamily: 'SF UI Display',
                      color: '#9A9A9A',
                      fontWeight: '500',
                      textTransform: 'capitalize',
                      padding: '0',
                      marginRight: '3%',
                      minHeight: 0,
                      lineHeight: '2',
                      '&.Mui-selected': { color: '#00BD57', fontWeight: '600' },
                    }}
                  />
                  <Tab
                    label={`Complaints (${complaintData?.length})`}
                    {...a11yProps(1)}
                    sx={{
                      fontSize: '16px',
                      fontFamily: 'SF UI Display',
                      color: '#9A9A9A',
                      fontWeight: '500',
                      textTransform: 'capitalize',
                      padding: '0',
                      minHeight: 0,
                      lineHeight: '2',
                      '&.Mui-selected': { color: '#00BD57', fontWeight: '600' },
                    }}
                  />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                {recommendationData.map((item, index) => (
                  <Box
                    key={index}
                    width={'100%'}
                    border={'1px solid #DCDEDF'}
                    borderRadius={'5px'}
                    marginBottom={'3%'}
                  >
                    <Box
                      width={'100%'}
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      padding={'1.5% 2%'}
                      borderBottom={'1px solid #DCDEDF'}
                    >
                      <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                        {/* <Avatar alt="Reviewer" src={item.avatar} /> */}
                        <Box display={'flex'} flexDirection={'column'}>
                          <Typography
                            fontFamily="SF UI Display"
                            fontWeight="700"
                            fontSize="18px"
                            lineHeight="21px"
                            color="#000000"
                          >
                            {item.title}
                          </Typography>
                          <Typography
                            fontFamily="SF UI Display"
                            fontWeight="400"
                            fontSize="16px"
                            lineHeight="19.09px"
                            color="#000000"
                          >
                            {item.Date}
                          </Typography>
                        </Box>
                      </Box>
                      <Box display={'flex'} gap={'10px'}>
                        <Avatar alt="Reviewer" src={item.avatar} />
                        <Box display={'flex'} flexDirection={'column'}>
                          <Typography
                            fontFamily="SF UI Display"
                            fontWeight="600"
                            fontSize="15px"
                            lineHeight="20px"
                            color="#000000"
                          >
                            {item.Owner}
                          </Typography>
                          <Typography
                            fontFamily="SF UI Display"
                            fontWeight="500"
                            fontSize="14px"
                            lineHeight="18px"
                            color="#606060"
                          >
                            {item.company}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box width={'100%'} display={'flex'}>
                      <Box
                        width={'30%'}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        flexDirection={'column'}
                        gap={'10px'}
                        padding={'2% 0'}
                        borderRight={'1px solid #DCDEDF'}
                      >
                        <Box display={'flex'} alignItems={'center'} gap={'5px'}>
                          <Typography
                            fontFamily="SF UI Display"
                            fontWeight="800"
                            fontSize="26px"
                            lineHeight="31px"
                            color="#000000"
                          >
                            {item.overallRating}
                          </Typography>
                          <Rating
                            value={item.overallRating}
                            readOnly
                            precision={0.1}
                            sx={{ color: '#00BD57' }}
                          />
                        </Box>
                        <Typography
                          fontFamily="SF UI Display"
                          fontWeight="400"
                          fontSize="20px"
                          lineHeight="24px"
                          color="#000000"
                        >
                          Overall Review Rating
                        </Typography>
                      </Box>
                      <Box
                        width={'70%'}
                        display={'flex'}
                        padding={'2%'}
                        flexWrap={'wrap'}
                        justifyContent={'space-between'}
                      >
                        {item?.data?.map((subItem, subIndex) => (
                          <Box key={subIndex} width={'47%'} margin={'2% 0'}>
                            <Box
                              width={'100%'}
                              display={'flex'}
                              alignItems={'center'}
                              justifyContent={'space-between'}
                            >
                              <Typography
                                fontFamily="SF UI Display"
                                fontWeight="700"
                                fontSize="16px"
                                lineHeight="19px"
                                color="#000000"
                              >
                                {subItem.title}
                              </Typography>
                              <Typography
                                fontFamily="SF UI Display"
                                fontWeight="500"
                                fontSize="16px"
                                lineHeight="19px"
                                color="#000000"
                              >
                                {subItem.rating}/5
                              </Typography>
                            </Box>
                            <Box width={'100%'} marginTop={'3%'}>
                              <BorderLinearProgress
                                variant="determinate"
                                value={subItem.progress}
                              />
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                    {item.feedback && (
                      <Box width={'100%'} borderTop={'1px solid #DCDEDF'} padding={'2%'}>
                        <Typography
                          fontFamily="SF UI Display"
                          fontWeight="700"
                          fontSize="16px"
                          lineHeight="19px"
                          color="#000000"
                        >
                          Feedback
                        </Typography>
                        <Typography
                          fontFamily="SF UI Display"
                          fontWeight="400"
                          fontSize="14px"
                          lineHeight="16px"
                          color="#000000"
                          marginTop="1.5%"
                        >
                          {!showFullFeedback && item?.feedback?.length > 200
                            ? `${item?.feedback.slice(0, 200)}...`
                            : item?.feedback}
                          {item?.feedback?.length > 200 && (
                            <Button
                              onClick={handleToggleFeedback}
                              sx={{
                                fontSize: '14px',
                                fontWeight: '600',
                                fontFamily: 'SF UI Display',
                                color: '#00BD57',
                                textTransform: 'capitalize',
                                padding: '0',
                                margin: '0 0 0 5px',
                                textDecoration: 'underline',
                              }}
                            >
                              {showFullFeedback ? 'Show Less' : 'Read Full Review'}
                            </Button>
                          )}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                ))}
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                {complaintData.map((item, index) => (
                  <Box
                    key={index}
                    width={'100%'}
                    border={'1px solid #DCDEDF'}
                    borderRadius={'5px'}
                    marginBottom={'3%'}
                  >
                    <Box
                      width={'100%'}
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      padding={'1.5% 2%'}
                      borderBottom={'1px solid #DCDEDF'}
                    >
                      <Typography
                        fontFamily="SF UI Display"
                        fontWeight="700"
                        fontSize="18px"
                        lineHeight="21px"
                        color="#000000"
                      >
                        {item?.title}
                      </Typography>
                      <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                        <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                          {item?.statusIcon}
                          <Typography
                            fontFamily="SF UI Display"
                            fontWeight="700"
                            fontSize="14px"
                            lineHeight="16px"
                            color="#000000"
                          >
                            {item?.status}
                          </Typography>
                        </Box>
                        <MoreHorizIcon sx={{ color: '#B7B5B5' }} fontSize="large" />
                      </Box>
                    </Box>
                    <Box width={'100%'} display={'flex'}>
                      <Box
                        width={'30%'}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        flexDirection={'column'}
                        gap={'10px'}
                        padding={'2% 0'}
                        borderRight={'1px solid #DCDEDF'}
                      >
                        <Box display={'flex'} alignItems={'center'} gap={'5px'}>
                          <Typography
                            fontFamily="SF UI Display"
                            fontWeight="800"
                            fontSize="26px"
                            lineHeight="31px"
                            color="#000000"
                          >
                            {item?.overallRating}
                          </Typography>
                          <Rating
                            value={item?.overallRating}
                            readOnly
                            precision={0.1}
                            sx={{ color: '#00BD57' }}
                          />
                        </Box>
                        <Typography
                          fontFamily="SF UI Display"
                          fontWeight="400"
                          fontSize="20px"
                          lineHeight="24px"
                          color="#000000"
                        >
                          Overall Review Rating
                        </Typography>
                      </Box>
                      <Box
                        width={'70%'}
                        display={'flex'}
                        padding={'2%'}
                        flexWrap={'wrap'}
                        justifyContent={'space-between'}
                      >
                        {item?.data?.map((item, index) => (
                          <Box key={index} width={'47%'} margin={'2% 0'}>
                            <Box
                              width={'100%'}
                              display={'flex'}
                              alignItems={'center'}
                              justifyContent={'space-between'}
                            >
                              <Typography
                                fontFamily="SF UI Display"
                                fontWeight="700"
                                fontSize="16px"
                                lineHeight="19px"
                                color="#000000"
                              >
                                {item?.title}
                              </Typography>
                              <Typography
                                fontFamily="SF UI Display"
                                fontWeight="500"
                                fontSize="16px"
                                lineHeight="19px"
                                color="#000000"
                              >
                                {item?.rating}/5
                              </Typography>
                            </Box>
                            <Box width={'100%'} marginTop={'3%'}>
                              <BorderLinearProgress variant="determinate" value={item?.progress} />
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                ))}
              </CustomTabPanel>
            </Box>
          </Box>
        </Box>

        <Box width="30%" display="flex" gap="20px" flexDirection="column">
          <>
            <Box width={'100%'} border="solid 1px #E8E8E8" borderRadius="10px" overflow={'hidden'}>
              <Box width="100%">
                <img
                  src={MapImg}
                  alt="map"
                  style={{ width: '100%', borderRadius: '10px 10px 0px 0px', objectFit: 'cover' }}
                />
              </Box>
              <Box
                padding="5% 3%"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  fontSize="16px"
                  fontWeight="600"
                  lineHeight="19px"
                  fontFamily="SF UI Display"
                >
                  Service States
                </Typography>
                <Typography
                  fontSize="16px"
                  fontWeight="300"
                  lineHeight="19px"
                  fontFamily="SF UI Display"
                >
                  Arizona
                </Typography>
              </Box>
            </Box>
          </>
        </Box>
      </Box>
    </>
  );
};

export default NationalSignCompany;
