import React, { FC } from 'react';
import { Box } from '@mui/material';

const SettingDashboardCompany: FC = () => {
  return (
    <>
      <Box
        width="96%"
        mt="3%"
        padding="1% 2.5% 2.5% 2.5%"
        height="90vh"
        bgcolor="white"
        overflow="auto"
        borderRadius="10px"
        display="flex"
        justifyContent="space-between"
        sx={{
          '@media (max-width: 800px)': {
            padding: '2% 2% 2% 2%',
            marginTop: '12%',
          },
        }}
      >
        {' '}
      </Box>
    </>
  );
};

export default SettingDashboardCompany;
