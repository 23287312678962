/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { FC, useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Rating,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import moment from 'moment';
import { mediaUrl } from '../../../config';
// import CompanyLogoRating from '../../../assets/CompanyRatingLogo.svg';
import Avatarimg from '../../../assets/Avatarimg.svg';
import LocationIcon from '../../../assets/Phase1a/DesignUpdate/locationIconUpdated.svg';
import ClanderIcon from '../../../assets/Phase1a/DesignUpdate/calanderIconUpdate.svg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import HelpIcon from '../../../assets/Phase1a/DesignUpdate/HelpIconUpdate.svg';
import ShareIcon from '../../../assets/Phase1a/DesignUpdate/ShareIconUpdate.svg';
import ReportIcon from '../../../assets/Phase1a/DesignUpdate/ReportIcon.svg';
import ResponseIcon from '../../../assets/Phase1a/DesignUpdate/responseIcon.svg';
import DeleteIcon from '../../../assets/Phase1a/DesignUpdate/deleteIcon.svg';
import ReportReview from '../../../Popups/phase1a/DesignUpdateModal/ReportReviewPopup';
import CardTopIcon from '../../../assets/Phase1a/DesignUpdate/DesignUpdateCardIcon.svg';
import CompanyDefault from '../../../assets/dummyimg.png';

// import ClaimedListing from '../../../Popups/phase1a/DesignUpdateModal/ClaimedListing.popup';
// import SubmitReview from '../../../Popups/phase1a/DesignUpdateModal/SubmitReviewPopup';
import ResponsePopup from '../../../Popups/phase1a/DesignUpdateModal/ResponsePopup';
import usaStatesAndCanadaProvinces from '../../../utils/states';
import request from '../../../api/request';
import { toast } from 'react-toastify';
import CustomToast from './CustomToast.component';
import { useNavigate } from 'react-router-dom';

interface ReviewCardProps {
  id?: string;
  item?: any;
  key?: any;
  claimStatus?: any;
  companyData?: any;
  pageType?: any;
  getReviews?: () => void;
  reply?: any;
  setReply?: (data: string) => void;
  setOpenSignInModal?: (isOpen: boolean) => void;
  setFlagModal?: (isOpen: boolean) => void;
  setReviewId?: (data: any) => any;
}
interface BubbleTextProps {
  bubbleColor?: string;
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 2,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    border: '1px solid #E0E1E2',
    backgroundColor: '#E0E1E2',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 2,
    backgroundColor: '#9E9E9E ',
  },
}));
const BubbleText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'bubbleColor',
})<BubbleTextProps>(({ bubbleColor }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: `translate(${bubbleColor === '#FF3D13' ? '-80%' : '-100%'}, -50%)`,
  backgroundColor: bubbleColor ?? '#fff',
  padding: '2px 10px',
  borderRadius: '9999px',
  // boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
  fontSize: '14px',
  whiteSpace: 'nowrap',
  display: 'flex',
  alignItems: 'center',
  color: '#FFF',
  textAlign: 'center',
  fontFamily: 'SF UI Display',
  fontWeight: '600',
}));
const ReviewCard: FC<ReviewCardProps> = ({
  id,
  item,
  key,
  companyData,
  pageType,
  getReviews,
  setOpenSignInModal,
  setFlagModal,
  setReviewId,
  claimStatus,
}) => {
  const navigate = useNavigate();
  const userJSON = localStorage.getItem('user');
  const userData = userJSON ? JSON.parse(userJSON) : '';
  console.log('userData', userData);
  const [likeCheck, setLikeCheck] = useState(false);

  const [expandedReplies, setExpandedReplies] = useState<any>({});
  const companyId = companyData?._id as string;
  const [loading, setLoading] = React.useState(false);

  const [showFullFeedback, setShowFullFeedback] = useState(false);
  const [response, setResponse] = React.useState<string>('');

  const handleToggleFeedback = (): void => {
    setShowFullFeedback((prev) => !prev);
  };
  function getStateAbbreviation(additional: string): string {
    if (!additional) return '';

    // Split the 'additional' string by comma and trim any whitespace
    const parts = additional.split(',').map((part: string) => part.trim());

    // Extract the city name (first part) and the state name (last part)
    const cityName = parts[0];
    const stateName = parts[parts?.length - 1];

    // Find the state or province in the array and get its abbreviation
    const state = usaStatesAndCanadaProvinces.find(
      (item: any) => item?.name?.toLowerCase() === stateName?.toLowerCase(),
    );

    // Return formatted string as "City, State Abbreviation"
    return state ? `${cityName}, ${state?.abbreviation}` : additional;
  }
  console.log('response', response);
  const handleReply = (review: any): void => {
    console.log('reviewID', review);
    setLoading(true);
    if (!response) {
      toast(<CustomToast message={'Field should not be empty'} type="error" />);

      setLoading(false);
      return;
    }
    setLoading(true);

    const data: any = { reply: response, company: companyId, email: review?.user?.email };
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    request<any>('put', `/review/reviewReply/${review?._id}`, data)
      .then((res) => {
        setResponse('');
        handleCloseModal();
        setLoading(false);

        toast(<CustomToast message={'Your response has been published!'} type="success" />);
        getReviews?.();
      })
      .catch((err) => {
        setResponse('');
        console.log('err', err);
        setLoading(false);
        toast(<CustomToast message={err?.response?.data?.message} type="error" />);

        toast.error(err?.response?.data?.message);
      });
  };
  const handleToggleReply = (index: any): void => {
    setExpandedReplies((prev: any) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };
  const isMobile = useMediaQuery('(max-width:1000px)');
  const getColor = (rating: any): any => {
    if (!rating) return '#9E9E9E';
    if (rating >= 4) return '#00BD57';
    if (rating >= 3) return '#FFB800';
    return '#FD3E14';
  };

  const getText = (value: any): any => {
    if (value >= 100) return 'Always'; // 80-100%
    if (value >= 80) return 'Often'; // 60-79%
    if (value >= 60) return 'Sometimes'; // 40-59%
    if (value >= 40) return 'Rarely'; // 20-39%
    return 'Never'; // 0-19%
  };
  const getBubbleColor = (value: number): string => {
    if (value >= 80) return '#00BD57';
    if (value >= 60) return '#FFB800';
    return '#FF3D13';
  };

  const progressValue = (item?.lead_time / 5) * 100;
  const bubbleText = getText(progressValue);
  const bubbleColor = getBubbleColor(progressValue);
  console.log('item', item);
  const [showSecondAnswer, setShowSecondAnswer] = useState(false);

  const handleToggleSecondAnswer = (): void => {
    if (!userData?._id) {
      setOpenSignInModal?.(true);
      return;
    }
    setShowSecondAnswer((prev) => !prev);
  };
  const feedbacksWithAnswers = Array.isArray(item?.feedback)
    ? item.feedback.filter((f: any) => f?.answer?.length)
    : [];
  const [helpfulCount, setHelpfulCount] = useState(1);

  const handleHelpfulClick = (): void => {
    setHelpfulCount(helpfulCount + 1);
  };
  const [open, setOpen] = useState(false);

  const handleOpen = (): void => {
    setOpen(true);
  };
  const handleClose = (): void => {
    setOpen(false);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = (): void => {
    setIsModalOpen(true);
  };
  const handleLike = (): void => {
    if (!userData?._id) {
      setOpenSignInModal?.(true);
      return;
    }
    const updatedLikedBy = [...(item?.likedBy || [])];
    if (!updatedLikedBy.includes(userData?._id)) {
      // Only add if not already liked
      updatedLikedBy.push(userData?._id);
    }
    const data: any = {
      likedBy: updatedLikedBy,
    };

    request<any>('patch', `review/like/${item?._id}`, data)
      .then((res) => {
        toast(<CustomToast message={'Review liked!'} type="success" />);
        getReviews?.();
        setLikeCheck(true);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const handleCloseModal = (): void => {
    setIsModalOpen(false);
  };
  const companyType = item?.company?.companyInfo?.companyType;
  const paramType =
    companyType === 'local'
      ? 'local-sign-company'
      : companyType === 'national'
      ? 'national-sign-company'
      : companyType === 'supplier'
      ? 'supplier'
      : 'distributor';
  const formatSearchString = (str: any): any => {
    if (!str) return ''; // Handle cases where str might be null or undefined
    const formattedStr = str.toLowerCase().replace(/\s+/g, '-').trim();
    return formattedStr;
  };
  const handleShareClick = (): void => {
    const companyName = formatSearchString(item?.company?.companyInfo?.name);

    const currentDomain = window.location.origin; // Get the current domain
    const reviewUrl = `${currentDomain}/${paramType}/${companyName}/${companyId}#review-${item?._id}`; // Scroll to the specific feedback using an anchor link

    const message = `Hey, check out the following feedback for ${item?.company?.companyInfo?.name}: ${reviewUrl}`;

    navigator.clipboard
      .writeText(message)
      .then(() => {
        console.log('Message copied to clipboard:', message);
        toast(<CustomToast message={'Link copied to clipboard!'} type="success" />);
      })
      .catch((err) => {
        console.error('Failed to copy message: ', err);
      });
  };
  useEffect(() => {
    if (userData?._id && item) {
      // Check if the user is the author or has already liked the review
      const isAuthor = userData?._id === item?.createdBy?._id;
      const hasLiked = item?.likedBy?.includes(userData?._id);

      // Disable the button if the user is the author or has already liked the review
      setLikeCheck(isAuthor || hasLiked);
    }
  }, [userData, item]); // Re-run when userData or item changes
  return (
    <Box
      key={key}
      width={'100%'}
      border={'1px solid #DCDEDF'}
      borderRadius={'5px'}
      marginBottom={'25px'}
      id={id}
    >
      <Box
        width={'100%'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        padding={'2%'}
        borderBottom={'1px solid #DCDEDF'}
        flexDirection={'row-reverse'}
        sx={{
          '@media (max-width: 1000px)': {
            flexDirection: 'column-reverse',
            alignItems: 'start',
            justifyContent: 'start',
          },
        }}
      >
        <Box display={'flex'} alignItems={'center'} gap={'10px'}>
          <Box display={'flex'} gap={'35px'} justifyContent={'center'} alignItems={'center'}>
            <Typography
              fontFamily="SF UI Display"
              fontWeight="400"
              fontSize="14px"
              lineHeight="20px"
              color="#000000"
              display={
                pageType === 1 || (pageType === 0 && userData?.type === 'member') ? 'none' : 'flex'
              }
              justifyContent={'center'}
              alignItems={'center'}
              gap={'10px'}
              sx={{
                '@media (max-width: 600px)': {
                  gap: '20px',
                },
              }}
            >
              <img src={LocationIcon} alt="." style={{ width: '16px', height: '21px' }} />
              {getStateAbbreviation(
                `${
                  item?.createdBy?.company?.companyInfo?.address?.place ??
                  item?.createdBy?.company?.companyInfo?.address?.city ??
                  item?.createdBy?.company?.companyInfo?.address?.region ??
                  item?.createdBy?.company?.companyInfo?.address?.street
                }, ${item?.createdBy?.company?.companyInfo?.address?.state}`,
              )}
            </Typography>
            <Typography
              fontFamily="SF UI Display"
              fontWeight="400"
              fontSize="14px"
              lineHeight="20px"
              color="#000000"
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              gap={'10px'}
            >
              <img src={ClanderIcon} alt="." style={{ width: '22px', height: '25px' }} />
              {moment(item?.createdAt).format('MMMM Do, YYYY')}
            </Typography>
          </Box>
        </Box>
        {pageType === 1 || (pageType === 0 && userData?.type === 'member') ? (
          <Box
            display={'flex'}
            alignItems={'center'}
            gap={'10px'}
            sx={{
              '@media (max-width: 1000px)': {
                margin: '20px 0',
                width: '100%',
              },
            }}
          >
            <Box width={'55px'} height={'55px'}>
              <Avatar
                src={
                  item?.company?.companyInfo?.logo
                    ? `${mediaUrl}/${item?.company?.companyInfo?.logo}`
                    : CompanyDefault
                }
                style={{ width: '100%', height: '100%', borderRadius: '10px' }}
              />
            </Box>

            <Box display={'flex'} flexDirection={'column'}>
              <Typography
                fontFamily="SF UI Display"
                fontWeight="600"
                fontSize="16px"
                lineHeight="20px"
                color="#000000"
                textTransform={'capitalize'}
                sx={{
                  '@media (max-width: 1000px)': {
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    gap: '10px',
                  },
                }}
              >
                {item?.company?.companyInfo?.name}
              </Typography>
              <Typography
                fontFamily="SF UI Display"
                fontWeight="400"
                fontSize="14px"
                lineHeight="20px"
                color="#505059"
                textTransform={'capitalize'}
                letterSpacing={'-0.24px'}
                sx={{
                  '@media (max-width: 1000px)': {
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    gap: '10px',
                  },
                }}
              >
                {item?.company?.companyInfo?.companyType} Review
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box
            display={'flex'}
            gap={'10px'}
            sx={{
              '@media (max-width: 1000px)': {
                margin: '20px 0',
                width: '100%',
              },
            }}
          >
            <Box width={'56px'} height={'55.6px'}>
              <Avatar
                src={item?.user?.avatar ? `${mediaUrl}/${item?.user?.avatar}` : Avatarimg}
                style={{ width: '100%', height: '100%' }}
              />
            </Box>

            <Box display={'flex'} flexDirection={'column'}>
              <Typography
                fontFamily="SF UI Display"
                fontWeight="600"
                fontSize="16px"
                lineHeight="20px"
                color="#000000"
                textTransform={'capitalize'}
                sx={{
                  '@media (max-width: 1000px)': {
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    gap: '10px',
                  },
                }}
              >
                {item?.user?.name?.length ? item?.user?.name : 'Verified User'}
              </Typography>
              <Typography
                fontFamily="SF UI Display"
                fontWeight="400"
                fontSize="14px"
                lineHeight="20px"
                color="#505059"
                textTransform={'capitalize'}
                letterSpacing={'-0.24px'}
                sx={{
                  '@media (max-width: 1000px)': {
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    gap: '10px',
                  },
                }}
              >
                {item?.user?.job_title?.length ? item?.user?.job_title : 'job title here'}
              </Typography>
              <Typography
                fontFamily="SF UI Display"
                fontWeight="400"
                fontSize="14px"
                lineHeight="20px"
                color="#505059"
                textTransform={'capitalize'}
                letterSpacing={'-0.24px'}
                sx={{
                  '@media (max-width: 1000px)': {
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    gap: '10px',
                  },
                }}
              >
                {item?.user?.company}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
      <Box
        width={'100%'}
        display={'flex'}
        sx={{
          '@media (max-width: 768px)': {
            flexDirection: 'column',
          },
        }}
      >
        <Box
          width={'30%'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          flexDirection={'column'}
          gap={'10px'}
          padding={'2% 0'}
          borderRight={'1px solid #DCDEDF'}
          sx={{
            '@media (max-width: 768px)': {
              borderRight: 'none',
              width: '100%',
              flexDirection: 'column-reverse',
              justifyContent: 'start',
              alignItems: 'start',
              padding: '2%',
            },
          }}
        >
          <Box
            display={'flex'}
            alignItems={'center'}
            gap={'5px'}
            sx={{
              '@media (max-width: 768px)': {
                flexDirection: 'row-reverse',
              },
            }}
          >
            <Typography
              fontFamily="SF UI Display"
              fontWeight="800"
              fontSize="26px"
              lineHeight="31px"
              color="#000000"
            >
              {typeof item?.overall_rating === 'number'
                ? (Math.floor(item?.overall_rating * 10) / 10).toFixed(1)
                : '--'}
            </Typography>
            <Rating
              value={item?.overall_rating}
              readOnly
              precision={0.1}
              sx={{
                color: !item?.overall_rating
                  ? '#9E9E9E'
                  : item?.overall_rating >= 4
                  ? '#00BD57'
                  : item?.overall_rating >= 3
                  ? '#FFB800'
                  : '#FD3E14',
              }}
            />
          </Box>
          <Typography
            fontFamily="SF UI Display"
            fontWeight="400"
            fontSize="20px"
            lineHeight="24px"
            color="#000000"
            sx={{
              '@media (max-width: 500px)': {
                fontSize: '14px',
                lineHeight: '16.71px',
                mt: 2,
              },
            }}
          >
            Overall Review Rating
          </Typography>
        </Box>
        <Box
          width={'70%'}
          display={'flex'}
          padding={'2%'}
          flexWrap={'wrap'}
          justifyContent={'space-between'}
          sx={{
            '@media (max-width: 768px)': {
              width: '100%',
              // display: 'grid',
              // gridTemplateColumns: '1fr 1fr',
              // gap: '25px',
            },
            '@media (max-width: 500px)': {
              borderTop: '1px solid #DCDEDF',
            },
          }}
        >
          <Box
            width={'47%'}
            margin={'2% 0'}
            // sx={{
            //   '@media (max-width: 768px)': {
            //     width: '98%',
            //     margin: '4% 0',
            //   },
            // }}
          >
            <Box
              width={'100%'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Typography
                fontFamily="SF UI Display"
                fontWeight="700"
                fontSize="16px"
                lineHeight="19px"
                color="#000000"
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '14px',
                  },
                }}
              >
                {companyData?.companyInfo?.companyType === 'local'
                  ? 'Reliability'
                  : companyData?.companyInfo?.companyType === 'national'
                  ? 'Professionalism'
                  : 'Quality'}
              </Typography>
              <Typography
                fontFamily="SF UI Display"
                fontWeight="500"
                fontSize="16px"
                lineHeight="19px"
                color="#000000"
              >
                {typeof item?.reliability === 'number'
                  ? Number.isInteger(item?.reliability)
                    ? item?.reliability.toString()
                    : (Math.floor(item?.reliability * 10) / 10).toFixed(1)
                  : '--'}
                /5
              </Typography>
            </Box>
            <Box width={'100%'} marginTop={'3%'}>
              <BorderLinearProgress
                variant="determinate"
                sx={{
                  [`& .${linearProgressClasses.bar}`]: {
                    backgroundColor: getColor(item?.reliability),
                  },
                }}
                value={(item?.reliability / 5) * 100}
              />
            </Box>
          </Box>
          <Box
            width={'47%'}
            margin={'2% 0'}
            // sx={{
            //   '@media (max-width: 768px)': {
            //     width: '98%',
            //   },
            // }}
          >
            <Box
              width={'100%'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Typography
                fontFamily="SF UI Display"
                fontWeight="700"
                fontSize="16px"
                lineHeight="19px"
                color="#000000"
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '14px',
                  },
                }}
              >
                {companyData?.companyInfo?.companyType === 'local'
                  ? 'Communication'
                  : companyData?.companyInfo?.companyType === 'national'
                  ? 'Communication'
                  : 'Customer Service'}
              </Typography>
              <Typography
                fontFamily="SF UI Display"
                fontWeight="500"
                fontSize="16px"
                lineHeight="19px"
                color="#000000"
              >
                {typeof item?.communication === 'number'
                  ? Number.isInteger(item?.communication)
                    ? item?.communication.toString()
                    : (Math.floor(item?.communication * 10) / 10).toFixed(1)
                  : '--'}
                /5
              </Typography>
            </Box>
            <Box width={'100%'} marginTop={'3%'}>
              <BorderLinearProgress
                variant="determinate"
                sx={{
                  [`& .${linearProgressClasses.bar}`]: {
                    backgroundColor: getColor(item?.communication),
                  },
                }}
                value={(item?.communication / 5) * 100}
              />
            </Box>
          </Box>
          <Box
            width={'100%'}
            display={'none'}
            sx={{
              '@media (max-width: 500px)': {
                display: 'block',
                margin: '2% 0',
              },
            }}
          >
            <Divider sx={{ width: '100%' }} />
          </Box>

          <Box
            width={'47%'}
            margin={'2% 0'}
            // sx={{
            //   '@media (max-width: 768px)': {
            //     width: '98%',
            //   },
            // }}
          >
            <Box
              width={'100%'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Typography
                fontFamily="SF UI Display"
                fontWeight="700"
                fontSize="16px"
                lineHeight="19px"
                color="#000000"
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '14px',
                  },
                }}
              >
                {companyData?.companyInfo?.companyType === 'local'
                  ? 'Work Quality'
                  : companyData?.companyInfo?.companyType === 'national'
                  ? 'Compensation'
                  : 'Cost'}
              </Typography>
              <Typography
                fontFamily="SF UI Display"
                fontWeight="500"
                fontSize="16px"
                lineHeight="19px"
                color="#000000"
              >
                {typeof item?.work_quality === 'number'
                  ? Number.isInteger(item?.work_quality)
                    ? item?.work_quality.toString()
                    : (Math.floor(item?.work_quality * 10) / 10).toFixed(1)
                  : '--'}
                /5
              </Typography>
            </Box>
            <Box width={'100%'} marginTop={'3%'}>
              <BorderLinearProgress
                sx={{
                  [`& .${linearProgressClasses.bar}`]: {
                    backgroundColor: getColor(item?.work_quality),
                  },
                }}
                variant="determinate"
                value={(item?.work_quality / 5) * 100}
              />
            </Box>
          </Box>
          <Box
            width={'47%'}
            margin={'2% 0'}
            // sx={{
            //   '@media (max-width: 768px)': {
            //     width: '98%',
            //   },
            // }}
          >
            <Box
              width={'100%'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Typography
                fontFamily="SF UI Display"
                fontWeight="700"
                fontSize="16px"
                lineHeight="19px"
                color="#000000"
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '14px',
                  },
                }}
              >
                {companyData?.companyInfo?.companyType === 'local'
                  ? 'On-Time Projects'
                  : companyData?.companyInfo?.companyType === 'national'
                  ? !isMobile
                    ? 'On-Time Payments'
                    : 'On-Time Pay'
                  : 'Recommendations'}
              </Typography>
              {companyData?.companyInfo?.companyType !== 'local' &&
                companyData?.companyInfo?.companyType !== 'national' && (
                  <Typography
                    fontFamily="SF UI Display"
                    fontWeight="500"
                    fontSize="16px"
                    lineHeight="19px"
                    color="#000000"
                  >
                    {typeof item?.lead_time === 'number'
                      ? Number.isInteger(item?.lead_time)
                        ? item?.lead_time.toString()
                        : (Math.floor(item?.lead_time * 10) / 10).toFixed(1)
                      : '--'}
                    /5
                  </Typography>
                )}
            </Box>
            {companyData?.companyInfo?.companyType === 'supplier' ||
            companyData?.companyInfo?.companyType === 'distributor' ? (
              <Box width={'100%'} marginTop={'3%'} position={'relative'}>
                <BorderLinearProgress
                  sx={{
                    [`& .${linearProgressClasses.bar}`]: {
                      backgroundColor: getColor(item?.lead_time),
                    },
                  }}
                  variant="determinate"
                  value={(item?.lead_time / 5) * 100}
                />
              </Box>
            ) : (
              <Box width={'100%'} marginTop={'3%'} position={'relative'}>
                <BorderLinearProgress
                  sx={{
                    [`& .${linearProgressClasses.bar}`]: {
                      backgroundColor: getColor(item?.lead_time),
                    },
                  }}
                  variant="determinate"
                  value={progressValue}
                />
                <BubbleText
                  bubbleColor={bubbleColor}
                  sx={{
                    left: `${progressValue}%`, // Position text according to progress
                    // backgroundColor: progressValue >= 90 ? '00BD57' : '#fff',
                  }}
                >
                  {bubbleText}
                </BubbleText>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      {/* {item.feedback && ( */}
      {/* questions answer */}
      <Box width="100%" borderTop="1px solid #DCDEDF">
        {feedbacksWithAnswers?.length > 0 && (
          <Box key={0} width="100%" padding="2%">
            <Typography
              fontFamily="SF UI Display"
              fontWeight="700"
              fontSize="16px"
              lineHeight="19px"
              color="#000000"
              sx={{
                '@media (max-width: 500px)': {
                  mt: 2,
                },
              }}
            >
              {feedbacksWithAnswers[0]?.question}
            </Typography>
            <Typography
              fontFamily="SF UI Display"
              fontWeight="400"
              fontSize="14px"
              lineHeight="16px"
              color="#000000"
              marginTop="1.5%"
            >
              {feedbacksWithAnswers[0]?.answer}
            </Typography>
          </Box>
        )}

        {feedbacksWithAnswers?.length > 1 && (
          <Box key={1} width="100%" padding="2%">
            <Typography
              fontFamily="SF UI Display"
              fontWeight="700"
              fontSize="16px"
              lineHeight="19px"
              color="#000000"
              sx={{
                '@media (max-width: 500px)': {
                  mt: 2,
                },
              }}
            >
              {feedbacksWithAnswers[1]?.question}
            </Typography>
            {showSecondAnswer && (
              <Typography
                fontFamily="SF UI Display"
                fontWeight="400"
                fontSize="14px"
                lineHeight="16px"
                color="#000000"
                marginTop="1.5%"
              >
                {feedbacksWithAnswers[1]?.answer}
              </Typography>
            )}
            {!showSecondAnswer && (
              <Button
                disabled={claimStatus}
                onClick={handleToggleSecondAnswer}
                sx={{
                  fontSize: '16px',
                  fontWeight: '600',
                  fontFamily: 'SF UI Display',
                  color: '#00BD57',
                  textTransform: 'capitalize',
                  padding: '0',
                  margin: '1% 0',
                  bgcolor: 'transparent',
                }}
              >
                Show Full Review <KeyboardArrowDownIcon />
              </Button>
            )}
          </Box>
        )}

        {feedbacksWithAnswers?.length > 2 &&
          showSecondAnswer &&
          feedbacksWithAnswers?.slice(2).map((f: any, index: number) => (
            <Box key={index + 2} width="100%" padding="2%">
              <Typography
                fontFamily="SF UI Display"
                fontWeight="700"
                fontSize="16px"
                lineHeight="19px"
                color="#000000"
                sx={{
                  '@media (max-width: 500px)': {
                    mt: 2,
                  },
                }}
              >
                {f?.question}
              </Typography>
              <Typography
                fontFamily="SF UI Display"
                fontWeight="400"
                fontSize="14px"
                lineHeight="16px"
                color="#000000"
                marginTop="1.5%"
              >
                {f?.answer}
              </Typography>
            </Box>
          ))}
      </Box>
      {item?.review_reply?.length > 0 && (
        <Box
          width={'100%'}
          borderTop={'1px solid #DCDEDF'}
          padding={'15px 15px 25px 15px'}
          bgcolor={'#F7F7F7'}
        >
          <Box display={'flex'} alignItems={'center'} gap={'5px'}>
            <img style={{ width: '29px', height: '26px' }} src={CardTopIcon} alt="icon" />
            <Typography
              fontFamily="SF UI Display"
              fontWeight="600"
              fontSize="16px"
              lineHeight="19px"
              color="#000000"
            >
              {companyData?.companyInfo?.name} Response:
            </Typography>
          </Box>

          <Typography
            key={key}
            fontFamily="SF UI Display"
            fontWeight="400"
            fontSize="14px"
            lineHeight="16px"
            color="#000000"
            marginTop="15px"
          >
            {/* {item?.review_reply[0]?.reply} */}
            {!expandedReplies[key] && item?.review_reply[0]?.reply?.length > 200
              ? `${item?.review_reply[0]?.reply?.slice(0, 200)}...`
              : `${item?.review_reply[0]?.reply}`}
            {item?.review_reply[0]?.reply?.length > 200 && (
              <Button
                disabled={claimStatus}
                onClick={() => {
                  handleToggleReply(key);
                }}
                sx={{
                  fontSize: '14px',
                  fontWeight: '600',
                  fontFamily: 'SF UI Display',
                  color: '#00BD57',
                  textTransform: 'capitalize',
                  padding: '0',
                  margin: '0 0 0 5px',
                  textDecoration: 'underline',
                }}
              >
                {expandedReplies[key] ? 'Show Less' : 'Read Full Reply'}
              </Button>
            )}
          </Typography>
        </Box>
      )}
      {}
      <Box
        width={'100%'}
        display={'flex'}
        justifyContent={'center'}
        alignContent={'center'}
        gap={'10px'}
        padding={'1% 0'}
        borderTop="1px solid #DCDEDF"
        sx={{
          '@media (max-width: 500px)': {
            gap: '0px',
          },
        }}
      >
        {pageType === 0 && userData?.type !== 'member' && (
          <Tooltip
            title="Only one response allowed per review."
            componentsProps={{
              tooltip: {
                sx: {
                  display: !item?.review_reply?.length ? 'none' : 'block',
                },
              },
            }}
          >
            <Box display="flex" alignItems="center" gap="15%">
              <Button
                disabled={item?.review_reply?.length > 0 || claimStatus}
                onClick={handleOpenModal}
                sx={{
                  color: 'black',
                  fontFamily: 'SF UI Display',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: 'normal',
                  textAlign: 'center',
                  width: '80%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '8px',
                  textTransform: 'capitalize',
                  '@media (max-width: 600px)': {
                    width: '30%',
                  },
                }}
              >
                <img
                  src={ResponseIcon}
                  alt="Response Icon"
                  style={{
                    width: '20px',
                    height: '20px',
                    opacity: item?.review_reply?.length > 0 || claimStatus ? 0.3 : 1,
                  }}
                />
                Response
              </Button>

              <ResponsePopup
                open={isModalOpen}
                handleClose={handleCloseModal}
                setResponse={setResponse}
                response={response}
                handleReply={handleReply}
                item={item}
                loading={loading}
              />
            </Box>
          </Tooltip>
        )}
        {pageType !== 0 && pageType !== 1 && (
          <Box display="flex" alignItems="center" gap="15%">
            <Button
              // onClick={handleHelpfulClick}
              disabled={likeCheck || claimStatus || !userData?.company?.companyInfo?.verification}
              onClick={handleLike}
              sx={{
                color: 'black',
                fontFamily: 'SF UI Display',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: 'normal',
                textAlign: 'center',
                width: '80%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '8px',
                textTransform: 'capitalize',
                '@media (max-width: 600px)': {
                  width: '30%',
                },
              }}
            >
              <img
                src={HelpIcon}
                alt="Helpful Icon"
                style={{
                  width: '20px',
                  height: '20px',
                  opacity:
                    likeCheck || claimStatus || !userData?.company?.companyInfo?.verification
                      ? 0.2
                      : 1,
                }}
              />
              Helpful
            </Button>
            <Box bgcolor={'#F7F7F7'} width="35%" borderRadius={'100%'}>
              <Typography
                width="100%"
                textAlign={'center'}
                color="#00BD57"
                fontSize={'14px'}
                fontFamily={'SF UI Display'}
                fontWeight={800}
              >
                {item?.likedBy?.length ?? 0}
              </Typography>
            </Box>
          </Box>
        )}
        {(pageType === 1 && userData?.type !== 'member') ||
        (pageType === 0 && userData?.type === 'member') ? (
          <Button
            disabled={claimStatus}
            onClick={() => {
              setFlagModal?.(true);
              setReviewId?.(item?._id);
            }}
            sx={{
              color: 'black',
              fontFamily: ' SF UI Display',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: 'normal',
              textAlign: 'center',
              width: '15%',
              display: 'flex',
              justifyContent: 'center',
              alignItem: 'center',
              gap: '8px',
              textTransform: 'capitalize',
              '@media (max-width: 600px)': {
                width: '30%',
              },
            }}
          >
            {' '}
            <img
              src={DeleteIcon}
              alt="."
              style={{ width: '20px', height: '20px', opacity: claimStatus ? 0.3 : 1 }}
            />{' '}
            Delete
          </Button>
        ) : (
          ''
        )}

        <Button
          sx={{
            color: 'black',
            fontFamily: ' SF UI Display',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: 'normal',
            textAlign: 'center',
            width: '15%',
            display: 'flex',
            justifyContent: 'center',
            alignItem: 'center',
            gap: '8px',
            textTransform: 'capitalize',
            '@media (max-width: 600px)': {
              width: '30%',
            },
          }}
          disabled={claimStatus}
          onClick={handleShareClick}
        >
          {' '}
          <img
            src={ShareIcon}
            alt="."
            style={{ width: '20px', height: '20px', opacity: claimStatus ? 0.3 : 1 }}
          />{' '}
          Share
        </Button>
        {pageType === 0 && userData?.type !== 'member' && (
          <Button
            disabled={claimStatus}
            onClick={handleOpen}
            sx={{
              color: 'black',
              fontFamily: ' SF UI Display',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: 'normal',
              textAlign: 'center',
              width: '15%',
              display: 'flex',
              justifyContent: 'center',
              alignItem: 'center',
              gap: '8px',
              textTransform: 'capitalize',
              '@media (max-width: 600px)': {
                width: '30%',
              },
            }}
          >
            {' '}
            <img
              src={ReportIcon}
              alt="."
              style={{ width: '20px', height: '20px', opacity: claimStatus ? 0.3 : 1 }}
            />{' '}
            Report
          </Button>
        )}

        <ReportReview open={open} handleClose={handleClose} reviewData={item} />
      </Box>
    </Box>
  );
};
export default ReviewCard;
