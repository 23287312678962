/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { mediaUrl } from '../../../config';
import Dummyimage from '../../../assets/dummyimg.png';
import { useNavigate } from 'react-router-dom';

const AccessTOKEN =
  'pk.eyJ1IjoiZmFsY29uaXQiLCJhIjoiY2x4MXI5MW85MGNxbzJoc2Z1enRsanBhZSJ9.d9xvqAxL0P4ToV5p5wsOuA';
mapboxgl.accessToken = AccessTOKEN;

interface MapWithMultipleMarkersProps {
  companyData?: any; // Accepts either an array of companies or a single company object
}

const MapWithMultipleMarkers: FC<MapWithMultipleMarkersProps> = ({ companyData }) => {
  const nav = useNavigate();
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const [markers, setMarkers] = useState<mapboxgl.Marker[]>([]);
  const formatSearchString = (str: string): string => {
    return str?.toLowerCase()?.replace(/\s+/g, '-')?.trim();
  };
  useEffect(() => {
    if (mapContainerRef?.current && companyData) {
      if (!companyData || (Array.isArray(companyData) && companyData.length === 0)) {
        // Render an empty map
        const map = new mapboxgl.Map({
          container: mapContainerRef.current,
          style: 'mapbox://styles/mapbox/streets-v11',
          center: [0, 0], // Center map at a default location
          zoom: 1, // Set a default zoom level
        });
        return () => {
          map.remove(); // Clean up map instance
        };
      }
      console.log('companyDatacompanyData', companyData);
      const map = new mapboxgl.Map({
        container: mapContainerRef?.current,
        style: 'mapbox://styles/mapbox/streets-v11',
        // center: getCenterCoordinates(companyData), // Centered at the company's coordinates or first company's coordinates in case of array
        // zoom: companyData?.length > 1 ? 2 : 10,
      });

      map?.on('load', () => {
        const coordinatesMap: Record<string, number> = {};
        const bounds = new mapboxgl.LngLatBounds();

        const newMarkers = Array?.isArray(companyData)
          ? companyData?.map((item: any) => {
              const { coordinates }: { coordinates: [number, number] } = item?.companyInfo
                ?.location || [0, 0];
              const coordinatesKey = coordinates?.toString();

              let markerColor = '#808080';
              if (!item?.averageReviewRating?.overall) {
                markerColor = '#9E9E9E';
              } // Default gray color for companies with no rating
              else if (item?.averageReviewRating?.overall >= 4) {
                markerColor = '#00BD57'; // Blue color for rating >= 4
              } else if (item?.averageReviewRating?.overall >= 3) {
                markerColor = '#FFB800';
              } else {
                markerColor = '#FD3E14';
              }

              // Offset the coordinates if there are multiple markers with the same coordinates
              let offset = 0;
              if (coordinatesMap[coordinatesKey] !== undefined) {
                coordinatesMap[coordinatesKey] += 1;
                offset = coordinatesMap[coordinatesKey] * 0.0001;
              } else {
                coordinatesMap[coordinatesKey] = 0;
              }

              const marker = new mapboxgl.Marker({
                color: markerColor,
              })?.setLngLat([coordinates[0] + offset, coordinates[1] + offset]);
              /* eslint-disable @typescript-eslint/restrict-template-expressions */

              const getStarRating = (rating: any) => {
                if (rating == null) return '0';

                const fullStars = Math.floor(rating);
                const halfStar = rating % 1 >= 0.5;
                const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

                // Determine color based on rating
                const color = rating >= 4 ? '#00BD57' : rating >= 3 ? '#FFB800' : '#FD3E14';

                let starsHtml = '';
                for (let i = 0; i < fullStars; i++) {
                  starsHtml += `<span style="color: ${color};">&#9733;</span>`;
                }
                if (halfStar) {
                  starsHtml += `<span style="color: ${color};">&#9733;</span>`;
                }
                for (let i = 0; i < emptyStars; i++) {
                  starsHtml += `<span class="empty" style="color: #9E9E9E;">&#9733;</span>`;
                }

                return `<div class="star-rating">${starsHtml}</div>`;
              };
              const companyName = item?.companyInfo?.name || '';
              const imageWidth = companyName.length > 22 ? '70px' : '50px';
              const popup = new mapboxgl.Popup({
                closeButton: true,
                closeOnClick: true,
              })?.setHTML(`<div id="redirectButton" style="
                  padding: 7px 25px ${companyName.length > 22 ? '4px' : '7px'} 7px; 
                  border-radius: 10px; 
                  width:100%; 
                  height:100%;
                  display: flex;
                  justify-content:flex-start;
                  align-item: center;
                  gap:12px;
                  cursor: pointer;
                  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
                 ">
                <div style="width:${imageWidth}; height:50px; border-radius:10px; overflow:hidden;">
<img
    src=${item?.companyInfo?.logo ? `${mediaUrl}/${item.companyInfo.logo}` : Dummyimage}
    style="width: 100%; height: 100% !important; border-radius:10px; object-fit: cover;"
    alt="Company Logo"
  />
</div>
          
          <div style="overflow: hidden; display: flex; flex-direction: column; justify-content: center;">
            <h3 style="font-size: 14px; font-family:SF UI Display; font-weight:600; line-height: 16.71px; text-transform:capitalize">${
              item.companyInfo.name
            }</h3>
            <div>
            <div style= "display:flex;  align-items:center;   justify-content: flex-start; gap:5px;">
            <h3 style="font-size: 12px; color:black; opacity:60%; font-family:SF UI Display; font-weight:500;"> ${
              item.averageReviewRating?.overall.toFixed(1) ?? '0.0'
            }
            </h3>
             <div style="display: flex; align-items: center; justify-content:center;">
             ${getStarRating(item.averageReviewRating?.overall ?? 0)}
               </div>
          </div>
          </div>
          </div>
              <style>
              .mapboxgl-popup-content{
              padding:0;
              border-radius:10px;
              }
                .mapboxgl-popup-close-button {
                  border: none;
                  outline: none;
                  display:none;
                }
                    .star-rating {
                    display:flex;
                    gap:1.9px;
                    }
                    .star-rating span {
                      font-size: 9.48px;
                    .star-rating span.empty {
                      color: #ddd;
                       font-size: 9.48px;
                    }
              </style>
            </div>`);
              // Add a click event listener to the button inside the popup
              popup.on('open', () => {
                const redirectButton = document.getElementById('redirectButton');
                const paramType =
                  item?.companyInfo?.companyType === 'local'
                    ? 'local-sign-company'
                    : item?.companyInfo?.companyType === 'national'
                    ? 'national-sign-company'
                    : item?.companyInfo?.companyType === 'supplier'
                    ? 'supplier'
                    : 'distributor';
                redirectButton?.addEventListener('click', () => {
                  nav(`/${paramType}/${formatSearchString(item?.companyInfo?.name)}`, {
                    state: {
                      searchID: item?._id,
                      type: item?.companyInfo?.companyType,
                      // search,
                      // coordinates: `[${locationParam ?? ''}]`,
                      // near: near !== '' ? near : '',
                      // searchTypo,
                    },
                  });
                  // window.location.href = `/${paramType}/${formatSearchString(
                  //   item?.companyInfo?.name,
                  // )}`;
                });
              });
              marker?.setPopup(popup); // Associate the popup with the marker

              marker?.addTo(map); // Add the marker to the map
              bounds?.extend([coordinates[0] + offset, coordinates[1] + offset]); // Extend the bounds to include the marker
              return marker;
            })
          : [
              (() => {
                const { coordinates }: { coordinates: [number, number] } = companyData?.companyInfo
                  ?.location || [0, 0];

                let markerColor = '#808080';
                if (!companyData?.averageReviewRating?.overall) {
                  markerColor = '#9E9E9E';
                } // Default gray color for companies with no rating
                else if (companyData?.averageReviewRating?.overall >= 4) {
                  markerColor = '#00BD57'; // Blue color for rating >= 4
                } else if (companyData?.averageReviewRating?.overall >= 3) {
                  markerColor = '#FFB800';
                } else {
                  markerColor = '#FD3E14';
                }

                const marker = new mapboxgl.Marker({
                  color: markerColor,
                })?.setLngLat(coordinates);
                const getStarRating = (rating: any) => {
                  if (rating == null) return '0';

                  const fullStars = Math.floor(rating);
                  const halfStar = rating % 1 >= 0.5;
                  const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

                  // Determine color based on rating
                  const color = rating >= 4 ? '#00BD57' : rating >= 3 ? '#FFB800' : '#FD3E14';

                  let starsHtml = '';
                  for (let i = 0; i < fullStars; i++) {
                    starsHtml += `<span style="color: ${color};">&#9733;</span>`;
                  }
                  if (halfStar) {
                    starsHtml += `<span style="color: ${color};">&#9733;</span>`;
                  }
                  for (let i = 0; i < emptyStars; i++) {
                    starsHtml += `<span class="empty" style="color: #9E9E9E;">&#9733;</span>`;
                  }

                  return `<div class="star-rating">${starsHtml}</div>`;
                };
                const companyName = companyData?.companyInfo?.name || '';
                const imageWidth = companyName.length > 22 ? '70px' : '50px';

                const popup = new mapboxgl.Popup()?.setHTML(
                  `<div style="
                  padding: 7px 25px ${companyName.length > 22 ? '4px' : '7px'} 7px;  
                  border-radius: 10px; 
                  width:100%; 
                  height:100%;
                  display: flex;
                  justify-content:flex-start;
                  align-item: center;
                  gap:12px;
                  cursor: pointer;
                  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
                 ">
                <div style="width:${imageWidth}; height: 50px; border-radius:10px; overflow:hidden"">
<img
    src=${
      companyData?.companyInfo?.logo ? `${mediaUrl}/${companyData.companyInfo.logo}` : Dummyimage
    }
    style="width: 100%; height: 100% !important; borderRadius: 10px, objectFit: cover;"
    alt="Company Logo"
  />
</div>
          
          <div style="overflow: hidden; display: flex; flex-direction: column; justify-content: center;">
            <h3 style="font-size: 14px; font-family:SF UI Display; font-weight:600; line-height: 16.71px; text-transform:capitalize">${
              companyData.companyInfo.name
            }</h3>
            <div>
            <div style= "display:flex;  align-items:center;   justify-content: flex-start;
    align-items: center; gap:5px;">
            <h3 style="font-size: 12px; color:black; opacity:60%; font-family:SF UI Display; line-height:14.32px; font-weight:500;"> ${
              companyData.averageReviewRating?.overall.toFixed(1) ?? '0.0'
            }
            </h3>
             <div style="display: flex; align-items: center; justify-content:center;">
             ${getStarRating(companyData.averageReviewRating?.overall ?? 0)}
               </div>
          </div>
          </div>
          </div>
              <style>
              .mapboxgl-popup-content{
              padding:0;
                            border-radius:10px;

              }
                .mapboxgl-popup-close-button {
                  border: none;
                  outline: none;
                  display:none;
                }
                      .star-rating {
                    display:flex;
                    gap:1.9px;
                    }
                    .star-rating span {
                      font-size: 9.48px;
                    .star-rating span.empty {
                      color: #ddd;
                       font-size: 9.48px;
                    }
              </style>
            </div>`,
                );
                marker?.setPopup(popup); // Associate the popup with the marker

                marker?.addTo(map); // Add the marker to the map
                bounds?.extend(coordinates); // Extend the bounds to include the marker
                return marker;
              })(),
            ];
        map?.fitBounds(bounds, { padding: 50, maxZoom: 15 }); // Fit the map to the bounds with some padding
        setMarkers(newMarkers);
      });

      // Cleanup function
      return () => {
        markers?.forEach((marker: any) => marker?.remove()); // Remove each marker from the map
        map?.remove(); // Remove the map instance
      };
    }
  }, [companyData]);

  // const getCenterCoordinates = (data: any): any => {
  //   if (Array.isArray(data) && data.length > 0) {
  //     return data[0].companyInfo?.location?.coordinates || [0, 0]; // Centered at the first company's coordinates
  //   } else if (data) {
  //     return data.companyInfo?.location?.coordinates || [0, 0]; // Centered at the single company's coordinates
  //   }
  //   return [0, 0]; // Default to [0, 0] if no valid data is provided
  // };

  return <div ref={mapContainerRef} style={{ width: '100%', height: '100%' }} />;
};

export default MapWithMultipleMarkers;
