import React, { FC } from 'react';
import { Box, Button, Typography } from '@mui/material';
import Success from '../../../assets/Phase1a/DesignUpdate/toastSuccess.svg';
import PermitIcon from '../../../assets/serviceFilter/serviceFilter1.svg';
import FabricatorIcon from '../../../assets/serviceFilter/serviceFilter2.svg';
import InstallerIcon from '../../../assets/serviceFilter/serviceFilter3.svg';
import ServiceIcon from '../../../assets/serviceFilter/serviceFilter4.svg';
import SurveyIcon from '../../../assets/serviceFilter/serviceFilter5.svg';
import PrintProduction from '../../../assets/serviceFilter/serviceFilter6.svg';
import PrintInstallation from '../../../assets/serviceFilter/serviceFilter7.svg';
import WrapInstallation from '../../../assets/serviceFilter/serviceFilter8.svg';
import { useNavigate } from 'react-router-dom';
interface companyServicesProps {
  account: any;
  companyId?: any;
  setAccount: React.Dispatch<React.SetStateAction<any>>;
  onNextClick?: () => void;
  handleCancelClick?: () => void;
}
const companyTypes = [
  {
    label: 'Sign Fabrication',
    value: 'Fabricators',
    icon: FabricatorIcon,
  },
  {
    label: 'Print Production',
    value: 'Production',
    icon: PrintProduction,
  },
  {
    label: 'Sign Installation',
    value: 'Installers',
    icon: InstallerIcon,
  },
  {
    label: 'Surveys',
    value: 'Surveys',
    icon: SurveyIcon,
  },
  {
    label: 'Permits',
    value: 'Permiters',
    icon: PermitIcon,
  },
  {
    label: 'Service',
    value: 'Service',
    icon: ServiceIcon,
  },
  {
    label: 'Print Installation',
    value: 'Print Installation',
    icon: PrintInstallation,
  },
  {
    label: 'Wrap Installation',
    value: 'Wrap Installation',
    icon: WrapInstallation,
  },
];
// Custom Toast Component
const CompanyServices: FC<companyServicesProps> = ({
  account,
  companyId,
  setAccount,
  onNextClick,
  handleCancelClick,
}) => {
  const handleServiceChange = (service: any): void => {
    setAccount((prevData: any) => {
      let updatedServices;
      const existingServiceIndex = prevData?.company?.services?.findIndex(
        (s: any) => s?.service === service?.value,
      );

      if (existingServiceIndex !== -1) {
        updatedServices = prevData?.company?.services?.filter(
          (s: any) => s?.service !== service?.value,
        );
      } else {
        // If the first service object is empty, overwrite it
        if (prevData?.company?.services[0]?.service === '') {
          updatedServices = [
            {
              service: service?.value,
              percentage: null,
              subService: [''],
              logo: service?.icon,
            },
          ];
        } else {
          // Add the service if it doesn't exist
          updatedServices = prevData?.company?.services?.concat({
            service: service?.value,
            percentage: null,
            subService: [''],
            logo: service?.icon,
          });
        }
      }

      return {
        ...prevData,
        company: {
          ...prevData?.company,
          services: updatedServices,
        },
      };
    });
  };
  const isChecked = (service: string): boolean => {
    return account?.company?.services?.some((s: any) => s.service === service);
  };

  const navigate = useNavigate();

  const handleNavigation = (): void => {
    navigate('/localpageowner/ReviewsDashoard');
  };

  return (
    <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
      <Box
        width={'55%'}
        borderRadius={'10px'}
        bgcolor={'#FFF'}
        boxShadow={'0px 1px 4px 0px rgba(148, 148, 148, 0.25)'}
        padding={'3%'}
        display={'flex'}
        justifyContent={'center'}
        sx={{
          '@media (max-width: 1200px)': {
            width: '60%',
          },
          '@media (max-width: 1024px)': {
            width: '70%',
          },
          '@media (max-width: 900px)': {
            width: '85%',
          },
          '@media (max-width: 768px)': {
            width: '100%',
            padding: '20px',
          },
        }}
      >
        <Box width={'100%'} display={'flex'} flexDirection={'column'} gap={'10px'}>
          <Typography
            color={'#00BD57'}
            fontFamily={'SF UI Display'}
            fontSize={'16px'}
            fontWeight={'700'}
            lineHeight={'normal'}
            textTransform={'uppercase'}
          >
            {/* Page Setup (2 of 5) */}
            Page Setup (1 of 4)
          </Typography>
          <Typography
            color={'#000000'}
            fontFamily={'SF UI Display'}
            fontSize={'24px'}
            fontWeight={'800'}
            lineHeight={'normal'}
            sx={{
              '@media (max-width: 600px)': {
                fontSize: '22px',
              },
            }}
          >
            {/* Got it! Now, add your company services. */}
            You’re Confirmed! Now, add your services.
          </Typography>
          <Typography
            color={'#000000'}
            fontFamily={'SF UI Display'}
            fontSize={'16px'}
            fontWeight={'400'}
            lineHeight={'20px'}
            sx={{
              '@media (max-width: 600px)': {
                fontSize: '15px',
              },
            }}
          >
            Help others’ find your company easier by adding{' '}
            <span style={{ fontWeight: '600', textDecoration: 'underline' }}>all</span> the services
            you currently offer.
          </Typography>
          <Box
            width={'100%'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            margin={'15px 0'}
            flexWrap={'wrap'}
          >
            {companyTypes?.map((item, index) => {
              return (
                <Box
                  key={index}
                  width={'48%'}
                  borderRadius={'12px'}
                  border={isChecked(item?.value) ? '2px solid #00BD57' : '1px solid #E4E7EC'}
                  bgcolor={'#FFF'}
                  boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}
                  padding={'10px 20px'}
                  display={'flex'}
                  alignItems={'center'}
                  gap={'15px'}
                  margin={'10px 0'}
                  position={'relative'}
                  sx={{
                    cursor: companyId?.length ? 'default' : 'pointer',
                    opacity: isChecked(item?.value) ? 1 : companyId?.length ? 0.5 : 1,
                    '@media (max-width: 600px)': {
                      width: '100%',
                    },
                  }}
                  onClick={() => {
                    if (companyId?.length) return;
                    handleServiceChange(item);
                  }} // Handle box click
                >
                  <Box
                    width={'48px'}
                    height={'48px'}
                    sx={{
                      '@media (max-width: 600px)': {
                        width: '38px',
                        height: '38px',
                      },
                    }}
                  >
                    <img style={{ width: '100%', height: '100%' }} src={item?.icon} />
                  </Box>
                  <Typography
                    color={'#101828'}
                    fontFamily={'SF UI Display'}
                    fontSize={'18px'}
                    fontWeight={'500'}
                    lineHeight={'32px'}
                    sx={{
                      '@media (max-width: 600px)': {
                        fontSize: '16px',
                      },
                    }}
                  >
                    {item?.label}
                  </Typography>
                  {isChecked(item?.value) && (
                    <Box width={'23px'} height={'23px'} position={'absolute'} right={'20px'}>
                      <img style={{ width: '100%', height: '100%' }} src={Success} alt="Success" />
                    </Box>
                  )}
                </Box>
              );
            })}
          </Box>
          <Box
            width={'100%'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{
              '@media (max-width: 600px)': {
                gap: '10px',
              },
            }}
          >
            <Button
              // disabled
              sx={{
                width: '25%',
                display: 'flex',
                padding: '10px 16px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '6px',
                borderRadius: '8px',
                border: '1px solid D0D5DD',
                background: '#fff',
                color: '#000000',
                fontFamily: 'SF UI Display',
                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '24px',
                margin: '10px 0',
                boxShadow:
                  'inset 0 0 0 1px rgba(16, 24, 40, 0.18), inset 0 -2px 0 rgba(16, 24, 40, 0.05), 0 1px 2px rgba(16, 24, 40, 0.05)',
                textTransform: 'capitalize',
                '&:hover': {
                  bgcolor: '#fff',
                },
                '@media (max-width: 600px)': {
                  width: '100%',
                },
              }}
              onClick={handleNavigation}
            >
              Cancel
            </Button>
            <Button
              sx={{
                width: '25%',
                display: 'flex',
                padding: '10px 16px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '6px',
                borderRadius: '8px',
                bgcolor: !account?.company?.services[0]?.service?.length ? '#F7F7F7' : '#00BD57',
                color: !account?.company?.services[0]?.service?.length ? '#B8B8B8' : '#fff',
                fontFamily: 'SF UI Display',
                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '24px',
                margin: '10px 0',
                textTransform: 'capitalize',
                '&:hover': {
                  bgcolor: !account?.company?.services[0]?.service?.length ? '#F7F7F7' : '#00BD57',
                },
                '@media (max-width: 600px)': {
                  width: '100%',
                },
              }}
              disabled={!account?.company?.services[0]?.service?.length}
              onClick={onNextClick}
            >
              Next
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CompanyServices;
