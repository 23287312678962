import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { TextField, Button, Typography, CircularProgress, Checkbox } from '@mui/material';
import { mediaUrl } from '../../../config';
import defaultCompany from '../../../assets/defaultCompany.png';
import request from '../../../api/request';
import { toast } from 'react-toastify';
import CustomToast from '../../../components/phase1a/common/CustomToast.component';

interface ContactPopupProps {
  open: boolean;
  onClose: () => void;
  companyData?: any;
}

const style = {
  position: 'absolute',
  Height: '85%',
  // maxHeight: '83vh',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '450px',
  border: 'none',
  bgcolor: '#ffffff',
  boxShadow: 24,
  borderRadius: '10px',
  // p: 3,
  // overflowY: 'auto',
  '@media (max-width: 780px)': {
    width: '580px',
    padding: '20px',
  },
  '@media (max-width: 650px)': {
    width: '460px',
  },
  '@media (max-width: 490px)': {
    width: '380px',
  },
  '@media (max-width: 420px)': {
    width: '320px',
  },
};
interface ContactProps {
  isAgreed: boolean;
  sender_company: string;
  job_title: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  company: string;
  message: string;
}

const ContactPopup: React.FC<ContactPopupProps> = ({ open, onClose, companyData }) => {
  const initialState: ContactProps = {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    company: companyData?._id,
    message: '',
    sender_company: '',
    job_title: '',
    isAgreed: false,
  };
  const [formData, setFormData] = React.useState<ContactProps>(initialState);
  const [loading, setLoading] = React.useState(false);
  const [formattedPhoneNumber, setFormattedPhoneNumber] = React.useState('');

  const formatPhoneNumber = (phoneNumber: string): string => {
    const cleaned = phoneNumber.replace(/\D/g, ''); // Remove non-numeric characters
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

    if (match) {
      let formattedNumber = '';
      if (match[1]) formattedNumber += `(${match[1]}`;
      if (match[2]) formattedNumber += `) ${match[2]}`;
      if (match[3]) formattedNumber += `-${match[3]}`;
      return formattedNumber;
    }

    return phoneNumber;
  };
  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    const formattedValue = formatPhoneNumber(value);
    setFormattedPhoneNumber(formattedValue);
    setFormData((prevFormData) => ({
      ...prevFormData,
      phone: value,
    }));
  };

  console.log('formData123', formData);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  // Validate and format the phone number
  const ValidateAndFormatNumber = (phoneNo: string): string | null => {
    // Regex to match the (XXX) XXX-XXXX format
    const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;

    if (!phoneRegex.test(phoneNo)) {
      return null; // Invalid if not in (XXX) XXX-XXXX format
    }

    return phoneNo; // Already formatted correctly
  };

  const handleSubmit = (event: any): void => {
    event.preventDefault();
    setLoading(true);

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData?.first_name?.length) {
      // toast.error('Enter First Name');
      toast(<CustomToast message={'Enter First Name'} type="error" />);

      setLoading(false);
      return;
    }
    if (!formData?.last_name?.length) {
      // toast.error('Enter Last Name');
      toast(<CustomToast message={'Enter Last Name'} type="error" />);

      setLoading(false);
      return;
    }
    if (!formData?.email?.length) {
      // toast.error('Enter Email');
      toast(<CustomToast message={'Enter Email'} type="error" />);

      setLoading(false);
      return;
    }
    if (!formData?.sender_company?.length) {
      // toast.error('Enter Company name');
      toast(<CustomToast message={'Enter Company Name'} type="error" />);

      setLoading(false);
      return;
    }
    if (!formData?.job_title?.length) {
      // toast.error('Enter job title');
      toast(<CustomToast message={'Enter job title'} type="error" />);

      setLoading(false);
      return;
    }

    if (!emailPattern.test(formData?.email)) {
      // toast.error('Enter Valid Email');
      toast(<CustomToast message={'Enter Valid Email'} type="error" />);
      setLoading(false);
      return;
    }
    // if (!formData?.sender_company?.length) {
    //   toast.error('Enter sender company name');
    //   setLoading(false);
    //   return;
    // }
    // if (!formData?.phone?.length) {
    //   toast.error('Enter Phone Number');
    //   setLoading(false);
    //   return;
    // }
    if (formData?.phone) {
      const formattedPhone = ValidateAndFormatNumber(formData.phone.trim());

      if (!formattedPhone) {
        toast(<CustomToast message={'Please enter a valid Phone Number.'} type="error" />);
        setLoading(false);
        return;
      }

      // Optional: Update formData with the formatted phone number
      formData.phone = formattedPhone;
    }
    // if (formData?.phone?.length !== 10) {
    //   toast.error('Phone Number must be 10 digits long.');
    //   setLoading(false);
    //   return;
    // }

    request('post', '/companies/contactUs', formData)
      .then(() => {
        toast(<CustomToast message={'Contact Request sent!'} type="success" />);
        setLoading(false);
        setFormData(initialState);
        setFormattedPhoneNumber('');
        onClose();
      })
      .catch((error: any) => {
        console.log(error);
        setLoading(false);
      });
  };
  React.useEffect(() => {
    setFormData({
      ...formData,
      company: companyData?._id,
    });
  }, [companyData?._id]);

  const isFormValid = (): any => {
    return (
      formData.first_name &&
      formData.last_name &&
      formData.email &&
      formData.sender_company &&
      formData.job_title
    );
  };

  return (
    <Box>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="contact-popup-title"
        aria-describedby="contact-popup-description"
        BackdropProps={{
          sx: {
            backgroundColor: 'var(#0C111D)',
            opacity: 0.7,
            backdropFilter: 'blur(8px)', // Adjust the blur level as needed
          },
        }}
      >
        <Box sx={style}>
          {/* <Box display={'flex'} justifyContent={'end'}>
            <img
              src={CloseIcon}
              alt="Close"
              onClick={onClose}
              style={{
                cursor: 'pointer',
                zIndex: 1,
              }}
            />
          </Box> */}
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'flex-start'}
            alignItems={'flex-start'}
            padding={'25px 25px 0 25px'}
            // bgcolor={'red'}
          >
            <Box width={'75px'} height={'75px'}>
              <img
                src={
                  /* eslint-disable @typescript-eslint/restrict-template-expressions */ companyData
                    ?.companyInfo?.logo
                    ? `${mediaUrl}/${companyData?.companyInfo?.logo}`
                    : defaultCompany
                }
                alt="Logo"
                style={{ width: '100%', height: '100%', borderRadius: '10px' }}
              />
            </Box>

            <Typography
              fontSize="18px"
              fontWeight={600}
              lineHeight="28px"
              fontFamily="SF UI Display"
              color=" #000000  "
              id="modal-modal-title"
              // marginBottom={'45px'}
              textTransform={'capitalize'}
              mt={1}
              sx={{
                '@media (max-width: 450px)': {
                  fontSize: '14px',
                },
              }}
            >
              {companyData?.companyInfo?.name}
            </Typography>
            <Typography
              fontSize="16px"
              fontWeight={400}
              lineHeight="20px"
              fontFamily="SF UI Display"
              color=" #000000  "
              id="modal-modal-title"
              marginBottom={'30px'}
              mt={1}
              sx={{
                '@media (max-width: 450px)': {
                  fontSize: '14px',
                },
              }}
            >
              Request more info on their products and/or services. {companyData?.companyInfo?.name}{' '}
              may contact you about your request.
            </Typography>
          </Box>
          <Box display={'flex'} flexDirection={'column'}>
            <form
              // onSubmit={handleSubmit}
              style={{
                overflowY: 'auto',
                overflowX: 'hidden',
                height: '43vh',
                msOverflowStyle: 'none',
                padding: '0 25px 0 25px',

                // scrollbarWidth: 'none',
              }}
            >
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                sx={{
                  '@media (max-width: 450px)': {
                    flexDirection: 'column',
                  },
                }}
              >
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  width="48%"
                  // bgcolor={'red'}
                  sx={{
                    '@media (max-width: 450px)': {
                      width: '100%',
                    },
                  }}
                >
                  <Typography
                    fontFamily="SF UI Display"
                    fontWeight={500}
                    fontSize={'14px'}
                    lineHeight={'20px'}
                    fontStyle={'normal'}
                  >
                    First Name
                  </Typography>
                  <TextField
                    fullWidth
                    name="first_name"
                    value={formData?.first_name}
                    onChange={handleChange}
                    inputProps={{ maxLength: 50 }}
                    sx={{
                      margin: '8px 0',
                      '.MuiOutlinedInput-input': {
                        padding: '7px 14px',
                        borderRadius: '8px',
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: '1px solid #D7D8DD',
                          outline: 'none',
                          borderRadius: '8px',
                        },
                        '&:hover fieldset': {
                          borderColor: '#D0D5DD',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#D0D5DD',
                        },
                        '&.MuiInputBase-root:focus-visible': {
                          borderColor: '#D0D5DD',
                        },
                      },
                    }}
                  />
                </Box>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  width={'48%'}
                  sx={{
                    '@media (max-width: 450px)': {
                      width: '100%',
                    },
                  }}
                >
                  <Typography
                    fontFamily="SF UI Display"
                    fontWeight={500}
                    fontSize={'14px'}
                    lineHeight={'20px'}
                    fontStyle={'normal'}
                  >
                    Last Name
                  </Typography>
                  <TextField
                    fullWidth
                    name="last_name"
                    value={formData?.last_name}
                    inputProps={{ maxLength: 50 }}
                    onChange={handleChange}
                    sx={{
                      margin: '8px 0',
                      '.MuiOutlinedInput-input': {
                        padding: '7px 14px',
                        borderRadius: '8px',
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: '1px solid #D7D8DD',
                          outline: 'none',
                          borderRadius: '8px',
                        },
                        '&:hover fieldset': {
                          borderColor: '#D0D5DD',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#D0D5DD',
                        },
                        '&.MuiInputBase-root:focus-visible': {
                          borderColor: '#D0D5DD',
                        },
                      },
                    }}
                  />
                </Box>
              </Box>
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                margin={'2.5% 0'}
                sx={{
                  '@media (max-width: 450px)': {
                    flexDirection: 'column',
                  },
                }}
              >
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  width="48%"
                  sx={{
                    '@media (max-width: 450px)': {
                      width: '100%',
                    },
                  }}
                >
                  <Typography
                    fontFamily="SF UI Display"
                    fontWeight={500}
                    fontSize={'14px'}
                    lineHeight={'20px'}
                    fontStyle={'normal'}
                  >
                    Email
                  </Typography>
                  <TextField
                    fullWidth
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    sx={{
                      margin: '8px 0',
                      '.MuiOutlinedInput-input': {
                        padding: '7px 14px',
                        borderRadius: '8px',
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: '1px solid #D7D8DD',
                          outline: 'none',
                          borderRadius: '8px',
                        },
                        '&:hover fieldset': {
                          borderColor: '#D0D5DD',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#D0D5DD',
                        },
                        '&.MuiInputBase-root:focus-visible': {
                          borderColor: '#D0D5DD',
                        },
                      },
                    }}
                  />
                </Box>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  width={'48%'}
                  sx={{
                    '@media (max-width: 450px)': {
                      width: '100%',
                    },
                  }}
                >
                  <Typography
                    fontFamily="SF UI Display"
                    fontWeight={500}
                    fontSize={'14px'}
                    lineHeight={'20px'}
                    fontStyle={'normal'}
                  >
                    Phone Number (Optional)
                  </Typography>
                  <TextField
                    fullWidth
                    name="phone"
                    inputProps={{ maxLength: 14 }}
                    value={formattedPhoneNumber}
                    onChange={handlePhoneChange}
                    sx={{
                      margin: '8px 0',
                      '.MuiOutlinedInput-input': {
                        padding: '7px 14px',
                        borderRadius: '8px',
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: '1px solid #D7D8DD',
                          outline: 'none',
                          borderRadius: '8px',
                        },
                        '&:hover fieldset': {
                          borderColor: '#D0D5DD',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#D0D5DD',
                        },
                        '&.MuiInputBase-root:focus-visible': {
                          borderColor: '#D0D5DD',
                        },
                      },
                    }}
                  />
                </Box>
              </Box>
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                sx={{
                  '@media (max-width: 450px)': {
                    flexDirection: 'column',
                  },
                }}
              >
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  width="48%"
                  sx={{
                    '@media (max-width: 450px)': {
                      width: '100%',
                    },
                  }}
                >
                  <Typography
                    fontFamily="SF UI Display"
                    fontWeight={500}
                    fontSize={'14px'}
                    lineHeight={'20px'}
                    fontStyle={'normal'}
                  >
                    Company
                  </Typography>
                  <TextField
                    fullWidth
                    name="sender_company"
                    value={formData?.sender_company}
                    onChange={handleChange}
                    inputProps={{ maxLength: 75 }}
                    sx={{
                      margin: '8px 0',
                      '.MuiOutlinedInput-input': {
                        padding: '7px 14px',
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: '1px solid #D7D8DD',
                          outline: 'none',
                          borderRadius: '8px',
                        },
                        '&:hover fieldset': {
                          borderColor: '#D0D5DD',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#D0D5DD',
                        },
                        '&.MuiInputBase-root:focus-visible': {
                          borderColor: '#D0D5DD',
                        },
                      },
                    }}
                  />
                </Box>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  width={'48%'}
                  sx={{
                    '@media (max-width: 450px)': {
                      width: '100%',
                    },
                  }}
                >
                  <Typography
                    fontFamily="SF UI Display"
                    fontWeight={500}
                    fontSize={'14px'}
                    lineHeight={'20px'}
                    fontStyle={'normal'}
                  >
                    Job Title
                  </Typography>
                  <TextField
                    fullWidth
                    name="job_title"
                    value={formData?.job_title}
                    inputProps={{ maxLength: 75 }}
                    onChange={handleChange}
                    sx={{
                      margin: '8px 0',
                      '.MuiOutlinedInput-input': {
                        padding: '7px 14px',
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: '1px solid #D7D8DD',
                          outline: 'none',
                          borderRadius: '8px',
                        },
                        '&:hover fieldset': {
                          borderColor: '#D0D5DD',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#D0D5DD',
                        },
                        '&.MuiInputBase-root:focus-visible': {
                          borderColor: '#D0D5DD',
                        },
                      },
                    }}
                  />
                </Box>
              </Box>

              <Typography
                fontFamily="SF UI Display"
                fontWeight={500}
                fontSize={'16px'}
                lineHeight={'19.09px'}
                marginTop={'8px'}
              >
                Message
              </Typography>
              <TextField
                fullWidth
                placeholder="Write your explanation here..."
                multiline
                rows={4}
                name="message"
                value={formData.message}
                onChange={handleChange}
                inputProps={{ maxLength: 400 }}
                sx={{
                  margin: '8px 0',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: '1px solid #D7D8DD',
                      outline: 'none',
                      borderRadius: '8px',
                    },
                    '&:hover fieldset': {
                      borderColor: '#D0D5DD',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#D0D5DD',
                    },
                    '&.MuiInputBase-root:focus-visible': {
                      borderColor: '#D0D5DD',
                    },
                  },
                }}
              />
              <Box
                display={'flex'}
                width={'100%'}
                gap={'8px'}
                pt={2}
                alignItems={'flex-start'}
                // ml={'1px'}
                mb={1}
              >
                <Checkbox
                  checked={formData?.isAgreed || false}
                  onChange={(e) => {
                    setFormData((prev) => ({
                      ...prev,
                      isAgreed: e.target.checked,
                    }));
                  }}
                  sx={{
                    color: '#D0D5DD',
                    width: '16px',
                    padding: '0',
                    margin: '0',
                    '&.Mui-checked': {
                      color: '#00BD57',
                    },
                    // '& .MuiSvgIcon-root':{
                    //   padding:'0 !important',
                    // display:'block !important'
                    // },
                    // '& .css-x4nmik':{
                    //   paddingTop:'0',

                    // }
                  }}
                />
                <Typography
                  fontSize={'16px'}
                  fontWeight={'400'}
                  fontFamily={'SF UI Display'}
                  lineHeight={'20px'}
                >
                  I understand{' '}
                  <span style={{ textTransform: 'capitalize' }}>
                    {companyData?.companyInfo?.name}
                  </span>{' '}
                  and Signwise may contact me about this request.
                </Typography>
              </Box>
            </form>
            <Box
              textAlign="right"
              mt={2}
              width={'100%'}
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              padding={'0 25px 30px 25px'}
            >
              <Button
                // variant="contained"
                // disabled={loading}
                onClick={onClose}
                sx={{
                  background: 'transparent',
                  textTransform: 'capitalize',
                  fontWeight: '600',
                  fontSize: '16px',
                  lineHeight: '19.09px',
                  fontFamily: 'SF UI Display',
                  width: '49%',
                  padding: '10px 16px',
                  border: 'solid 1px #D0D5DD',
                  color: 'black',
                  '&:hover': {
                    bgcolor: 'transparent',
                  },
                  // '@media (max-width: 450px)': {
                  //   fontSize: '10px',
                  //   width: '10%',
                  // },
                }}
              >
                Cancel
              </Button>
              <Button
                // variant="contained"
                disabled={!isFormValid() || !formData?.isAgreed}
                onClick={handleSubmit}
                sx={{
                  background: '#00BD57',
                  textTransform: 'capitalize',
                  fontWeight: '600',
                  fontSize: '16px',
                  lineHeight: '19.09px',
                  color: 'white',
                  fontFamily: 'SF UI Display',
                  padding: '10px 16px',
                  width: '49%',
                  '&:hover': {
                    bgcolor: '#00BD57',
                  },
                  '&:disabled': {
                    backgroundColor: '#F7F7F7',
                  },
                  // '@media (max-width: 450px)': {
                  //   fontSize: '10px',
                  //   width: '10%',
                  // },
                }}
              >
                {loading ? <CircularProgress size={15} color="inherit" /> : ' Submit '}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default ContactPopup;
