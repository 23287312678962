/* eslint-disable @typescript-eslint/no-base-to-string */
import React, { FC, useEffect, useState } from 'react';
import { Box, Divider, Typography } from '@mui/material';
// import Header from '../components/Header.component';
import Header from '../../components/Header/LocalSignHeader.component';
// import Star from '../assets/recommendedIcon.svg';
// import Complaint from '../assets/complaintIcon.svg';
// import Favourite from '../assets/favIcon.svg';
// import Share from '../assets/shareIcon.svg';
import GreenCheck from '../../assets/greenCheck.svg';
import BackArrow from '../../assets/arrowback.svg';
// import { ReactComponent as Check } from '../../assets/check1.svg';
// import { ReactComponent as Close } from '../../assets/close.svg';
import request from '../../api/request';
import { ILocal, ICompanyByIdResponse } from '../../types/user';
import { mediaUrl } from '../../config';
// import { toast } from 'react-toastify';
import Dummyimage from '../../assets/dummyimg.png';

const CompanyDetail: FC = () => {
  const userJSON = localStorage.getItem('user');
  const user = userJSON ? JSON.parse(userJSON) : undefined;
  // const [showWebsite, setShowWebsite] = useState(false);
  // const [showPhoneNumber, setShowPhoneNumber] = useState(false);
  // const [showEmailAddress, setShowEmailAddress] = useState(false);
  const [company, setCompany] = useState<ILocal>();
  const [linkedAccounts, setLinkedAccounts] = useState<any>();
  const handleBackClick = (): void => {
    window.history.back();
  };

  // const handleWebsiteClick = (): void => {
  //   setShowWebsite(true);
  // };

  // const handlePhoneNumberClick = (): void => {
  //   setShowPhoneNumber(true);
  // };

  // const handleEmailAddressClick = (): void => {
  //   setShowEmailAddress(true);
  // };
  const getLinkedAccounts = (): void => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    request('get', `/users/getFellowMembers/${user.company._id}`)
      .then((res: any) => {
        console.log('linkedAccounts res', res);
        setLinkedAccounts(res?.users);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };
  //   const handleApproved = (id: string, status: boolean): void => {
  //     request('patch', `/companies/${id}`, {
  //       approved: status,
  //     })
  //       .then(() => {
  //         toast.success('Status Updated');
  //         getCompnyById();
  //       })
  //       .catch((err) => {
  //         toast.error('Error');
  //         console.log('err', err);
  //       });
  //   };

  const getCompnyById = (): void => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    request<ICompanyByIdResponse>('get', `/companies/${user.company._id}`)
      .then((res) => {
        console.log(res);
        setCompany(res?.company);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const formatPhoneNumber = (phoneNumber: string | undefined) => {
    if (!phoneNumber) return '';
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phoneNumber;
  };
  useEffect(() => {
    if (user.company._id) {
      getCompnyById();
      getLinkedAccounts();
    }
  }, [user.company._id]);
  return (
    <>
      <Header />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection={'column'}
        width="100%"
        position={'relative'}
      >
        <Box
          display={'flex'}
          position={'absolute'}
          width={'30px'}
          left={'13%'}
          top={'1%'}
          gap={'5px'}
          onClick={handleBackClick}
          sx={{
            '@media (max-width: 1024px)': {
              left: '3%',
            },
          }}
        >
          <img style={{ width: '100%', cursor: 'pointer' }} src={BackArrow} alt="BackArrow" />
          <Typography
            fontSize={'14px'}
            fontWeight={'600'}
            fontFamily={'SF UI Display'}
            color={'#000000'}
          >
            Back{' '}
          </Typography>
        </Box>
        <Box
          width={'50%'}
          marginBottom={'2%'}
          borderRadius={'12px'}
          border={'1px solid #EAECF0'}
          bgcolor={'#FFF'}
          boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}
          minHeight={'50vh'}
          display={'flex'}
          flexDirection={'column'}
          margin={'1% 0'}
          padding={'2% 1%'}
          sx={{
            '@media (max-width: 1024px)': {
              width: '80%',
            },
            '@media (max-width: 768px)': {
              width: '75%',
            },
          }}
        >
          <Box width={'100%'} display={'flex'} alignItems={'center'} gap={'20px'}>
            <Box
              width={'130px'}
              sx={{
                '@media (max-width: 1024px)': {
                  width: '100px',
                },
                '@media (max-width: 600px)': {
                  width: '80px',
                },
              }}
            >
              {company?.companyInfo?.logo ? (
                <img
                  style={{ width: '100%' }}
                  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                  src={`${mediaUrl}/${company.companyInfo.logo}`}
                  alt="logo"
                />
              ) : (
                <img style={{ width: '100%' }} src={Dummyimage} alt="dummy logo" />
              )}
            </Box>
            <Box>
              <Typography
                fontSize={'44px'}
                fontWeight={'600'}
                fontFamily={'SF UI Display'}
                color={'#000000'}
                sx={{
                  '@media (max-width: 1024px)': {
                    fontSize: '32px',
                  },
                  '@media (max-width: 600px)': {
                    fontSize: '25px',
                  },
                }}
              >
                {company?.companyInfo.name}
              </Typography>
              <Typography
                fontSize={'16px'}
                fontWeight={'300'}
                color={'#454238'}
                fontFamily={'SF UI Display'}
              >
                {company?.companyInfo.description
                  ? company?.companyInfo.description
                  : 'No Description'}
              </Typography>
            </Box>
          </Box>
          {/* <Box
      width={'100%'}
      paddingLeft={'150px'}
      display={'flex'}
      alignItems={'center'}
      gap={'15px'}
      margin={'3% 0'}
    >
      <Button
        sx={{
          borderRadius: '5px',
          border: '1px solid #E8E8E8',
          background: '#FFF',
          display: 'flex',
          gap: '5px',
          textTransform: 'capitalize',
        }}
      >
        <img src={Star} alt="Star" />
        <Typography
          fontSize={'12px'}
          fontWeight={'600'}
          color={'#000000'}
          whiteSpace={'nowrap'}
        >
          Recommend
        </Typography>
      </Button>
      <Button
        sx={{
          borderRadius: '5px',
          border: '1px solid #E8E8E8',
          background: '#FFF',
          display: 'flex',
          gap: '5px',
          textTransform: 'capitalize',
        }}
      >
        <img src={Complaint} alt="Complaint" />
        <Typography
          fontSize={'12px'}
          fontWeight={'600'}
          color={'#000000'}
          whiteSpace={'nowrap'}
        >
          File A Compaint
        </Typography>
      </Button>
      <Button
        sx={{
          borderRadius: '5px',
          border: '1px solid #E8E8E8',
          background: '#FFF',
          display: 'flex',
          gap: '5px',
          textTransform: 'capitalize',
        }}
      >
        <img src={Favourite} alt="Favourite" />
        <Typography
          fontSize={'12px'}
          fontWeight={'600'}
          color={'#000000'}
          whiteSpace={'nowrap'}
        >
          Add to Network
        </Typography>
      </Button>
      <Button
        sx={{
          borderRadius: '5px',
          border: '1px solid #E8E8E8',
          background: '#FFF',
          display: 'flex',
          gap: '5px',
          textTransform: 'capitalize',
        }}
      >
        <img src={Share} alt="Share" />
        <Typography
          fontSize={'12px'}
          fontWeight={'600'}
          color={'#000000'}
          whiteSpace={'nowrap'}
        >
          Share
        </Typography>
      </Button>
    </Box> */}
          <Box
            width={'50%'}
            padding={'4% 0'}
            sx={{
              '@media (max-width: 1024px)': {
                width: '100%',
              },
            }}
          >
            <Typography
              fontFamily="SF UI Display"
              fontWeight={900}
              lineHeight={'23.87px'}
              color={'black'}
              fontSize={'20px'}
              mb={3}
            >
              Accounts Linked To:
            </Typography>
            {linkedAccounts?.map((item: any, index: number) => (
              <Box key={index} width={'100%'} display={'flex'} justifyContent={'space-between'}>
                <Box padding={'1% 0'} width={'50%'}>
                  <Typography
                    fontFamily={'Inter'}
                    fontSize={'14px'}
                    lineHeight={'20px'}
                    color="#344054"
                    fontWeight={600}
                  >
                    {item?.first_name} {item?.last_name}
                  </Typography>
                  <Typography
                    fontFamily={'Inter'}
                    fontSize={'14px'}
                    lineHeight={'20px'}
                    color="#475467"
                    fontWeight={400}
                  >
                    {item?.email}
                  </Typography>
                </Box>
                <Box
                  width={'50%'}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'flex-end'}
                >
                  <Box
                    color={item?.type === 'owner' ? '#067647' : '#175CD3'}
                    fontFamily={'Inter'}
                    fontSize={'12px'}
                    fontWeight={'400'}
                    lineHeight={'24px'}
                    bgcolor={item?.type === 'owner' ? '#ECFDF3' : '#EFF8FF'}
                    border={item?.type === 'owner' ? '1px solid #ABEFC6' : '1px solid #B2DDFF'}
                    display={'flex'}
                    alignItems={'center'}
                    gap={'4px'}
                    borderRadius={'50px'}
                    boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}
                    padding={'3px 12px'}
                    width={'fit-content'}
                    textTransform={'capitalize'}
                  >
                    {item?.type === 'owner' ? 'Page Owner' : 'Team Member'}
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
          {company?.companyInfo?.companyType === 'local' && (
            <Box width={'100%'} margin={'2% 0'}>
              <Typography fontSize={'20px'} fontWeight={'800'} color={'#000000'}>
                Services
              </Typography>
              <Box
                width={'100%'}
                display={'flex'}
                alignItems={'center'}
                gap={'20px'}
                margin={'3% 0'}
                sx={{
                  '@media (max-width: 1024px)': {
                    flexWrap: 'wrap',
                  },
                }}
              >
                {company?.services?.map((item, index) => {
                  return (
                    <Typography
                      key={index}
                      display={'flex'}
                      alignItems={'center'}
                      gap={'5px'}
                      fontSize={'16px'}
                      fontWeight={'700'}
                      color={'#000000'}
                      sx={{ textDecoration: 'underline' }}
                    >
                      <img src={GreenCheck} alt="Check" />
                      {item.service ?? 'testing'}
                    </Typography>
                  );
                })}
              </Box>
            </Box>
          )}
          {/* <Box width={'100%'} margin={'1% 0'}>
      <Typography fontSize={'20px'} fontWeight={'800'} color={'#000000'}>
        Equipments
      </Typography>
      <Box width={'100%'} display={'flex'} alignItems={'center'} gap={'20px'} margin={'3% 0'}>
        <Typography
          display={'flex'}
          alignItems={'center'}
          gap={'5px'}
          fontSize={'16px'}
          fontWeight={'700'}
          color={'#000000'}
        >
          <img src={GreenCheck} alt="Check" />
          Crane
        </Typography>
        <Typography
          display={'flex'}
          alignItems={'center'}
          gap={'5px'}
          fontSize={'16px'}
          fontWeight={'700'}
          color={'#000000'}
        >
          <img src={GreenCheck} alt="Check" />
          Bucket Truck
        </Typography>
        <Typography
          display={'flex'}
          alignItems={'center'}
          gap={'5px'}
          fontSize={'16px'}
          fontWeight={'700'}
          color={'#000000'}
        >
          <img src={GreenCheck} alt="Check" />
          Service Truck
        </Typography>
      </Box>
    </Box> */}
          <Box width="95%" border="solid 1px #E8E8E8" borderRadius="5px" padding="2% 4%">
            <Typography
              fontFamily="SF UI Display"
              fontWeight="800"
              fontSize="18px"
              lineHeight="23px"
              color="black"
            >
              Company Details
            </Typography>
            <Divider sx={{ bgcolor: '#EBEEF1', width: '100%', margin: '2% 0' }} />
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              padding="1% 0"
              sx={{
                '@media (max-width: 1024px)': {
                  gap: '15px',
                },
              }}
            >
              <Typography
                fontSize="14px"
                fontWeight="600"
                lineHeight="19px"
                fontFamily="SF UI Display"
              >
                Location
              </Typography>
              <Typography
                fontSize="16px"
                fontWeight="200"
                lineHeight="19px"
                fontFamily="SF UI Display"
              >
                {company?.companyInfo?.address?.address}
              </Typography>
            </Box>
            <Divider sx={{ bgcolor: '#EBEEF1', width: '100%', margin: '2% 0' }} />
            <Box display="flex" justifyContent="space-between" alignItems="center" padding="1% 0">
              <Typography
                fontSize="14px"
                fontWeight="600"
                lineHeight="19px"
                fontFamily="SF UI Display"
              >
                Domain
              </Typography>
              {/* {!showWebsite ? (
                <Button
                  onClick={handleWebsiteClick}
                  sx={{
                    padding: '0',
                    margin: '0',
                    textTransform: 'capitalize',
                    fontSize: '16px',
                    fontWeight: '500',
                    lineHeight: '19px',
                    fontFamily: 'SF UI Display',
                    color: '#00BD57',
                    cursor: 'pointer',
                    '&:hover': {
                      bgcolor: 'transparent',
                    },
                  }}
                >
                  View
                </Button>
              ) : ( */}
              <Typography
                fontSize="16px"
                fontWeight="200"
                lineHeight="19px"
                fontFamily="SF UI Display"
              >
                {company?.companyInfo.domain}
              </Typography>
              {/* )} */}
            </Box>
            <Divider sx={{ bgcolor: '#EBEEF1', width: '100%', margin: '2% 0' }} />
            <Box display="flex" justifyContent="space-between" alignItems="center" padding="1% 0">
              <Typography
                fontSize="14px"
                fontWeight="600"
                lineHeight="19px"
                fontFamily="SF UI Display"
              >
                Phone Number
              </Typography>
              {/* {!showPhoneNumber ? (
                <Button
                  onClick={handlePhoneNumberClick}
                  sx={{
                    padding: '0',
                    margin: '0',
                    textTransform: 'capitalize',
                    fontSize: '16px',
                    fontWeight: '500',
                    lineHeight: '19px',
                    fontFamily: 'SF UI Display',
                    color: '#00BD57',
                    cursor: 'pointer',
                    '&:hover': {
                      bgcolor: 'transparent',
                    },
                  }}
                >
                  Show
                </Button>
              ) : ( */}
              <Typography
                fontSize="16px"
                fontWeight="200"
                lineHeight="19px"
                fontFamily="SF UI Display"
              >
                {formatPhoneNumber(company?.companyInfo?.phone)}
              </Typography>
              {/* )} */}
            </Box>
            <Divider sx={{ bgcolor: '#EBEEF1', width: '100%', margin: '2% 0' }} />
            <Box display="flex" justifyContent="space-between" alignItems="center" padding="1% 0">
              <Typography
                fontSize="14px"
                fontWeight="600"
                lineHeight="19px"
                fontFamily="SF UI Display"
              >
                Email Address
              </Typography>
              {/* {!showEmailAddress ? (
                <Button
                  onClick={handleEmailAddressClick}
                  sx={{
                    padding: '0',
                    margin: '0',
                    textTransform: 'capitalize',
                    fontSize: '16px',
                    fontWeight: '500',
                    lineHeight: '19px',
                    fontFamily: 'SF UI Display',
                    color: '#00BD57',
                    cursor: 'pointer',
                    '&:hover': {
                      bgcolor: 'transparent',
                    },
                  }}
                >
                  Show
                </Button>
              ) : ( */}
              <Typography
                fontSize="16px"
                fontWeight="200"
                lineHeight="19px"
                fontFamily="SF UI Display"
              >
                {company?.companyInfo?.email}
              </Typography>
              {/* )} */}
            </Box>
          </Box>
          {company?.companyInfo?.companyType === 'local' && (
            <Box
              width="95%"
              border="solid 1px #E8E8E8"
              borderRadius="5px"
              padding="2% 4%"
              margin={'2% 0'}
            >
              <Typography
                fontFamily="SF UI Display"
                fontWeight="800"
                fontSize="18px"
                lineHeight="23px"
                color="black"
              >
                Service States
              </Typography>
              <Divider sx={{ bgcolor: '#EBEEF1', width: '100%', margin: '2% 0' }} />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                padding="1% 0"
                sx={{
                  '@media (max-width: 768px)': {
                    flexDirection: 'column',
                    alignItems: 'start',
                    justifyContent: 'start',
                    gap: '10px',
                  },
                }}
              >
                <Typography
                  fontSize="14px"
                  fontWeight="600"
                  lineHeight="19px"
                  fontFamily="SF UI Display"
                >
                  Location
                </Typography>
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  gap={'10px'}
                  sx={{
                    '@media (max-width: 768px)': {
                      flexWrap: 'wrap',
                    },
                  }}
                >
                  {company?.serviceArea?.map((area, index) => {
                    return (
                      <Typography
                        key={index}
                        fontSize="16px"
                        fontWeight="600"
                        lineHeight="19px"
                        fontFamily="SF UI Display"
                        borderRadius={'5px'}
                        border={'1px solid #000'}
                        bgcolor={'#D7D7D7'}
                        padding={'5px 10px'}
                      >
                        {area.name}
                      </Typography>
                    );
                  })}
                </Box>
              </Box>
              <Divider sx={{ bgcolor: '#EBEEF1', width: '100%', margin: '2% 0' }} />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default CompanyDetail;
