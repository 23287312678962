import React, { FC, useEffect, useRef, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
  Typography,
} from '@mui/material';
import 'react-phone-input-2/lib/style.css';
import HeaderSignCompany from '../../components/phase1a/local/Header.component';
import PersonalInfoIcon from '../../assets/Phase1a/SignUp/signUpiIcon.svg';
import CloseIconBg from '../../assets/Phase1a/SignUp/CloseIcon.svg';
// import AddImageIcon from '../../assets/Phase1a/SignUp/AddImageIcon.svg';
import DefaultAvatar from '../../assets/Phase1a/SignUp/ProfileavatarIcon.jpeg';

import ScreenTwo from '../../components/phase1a/local/signUp/CompanyType.component';
import ScreenThree from '../../components/phase1a/local/signUp/CompanyInfo.component ';
import Service from '../../components/phase1a/local/signUp/Service.component';
import { useLocation, useNavigate } from 'react-router-dom';
import ServiceArea from '../../components/phase1a/local/signUp/ServiceArea.component';
import request from '../../api/request';
import { toast } from 'react-toastify';
import { mediaUrl } from '../../config';
import AlertIcon from '../../assets/Phase1a/SignUp/RedalertIcon.svg';
import VisibilityOff from '../../assets/EyeoffIcon.svg';
import Visibility from '../../assets/eyeicon.svg';
import CustomToast from '../../components/phase1a/common/CustomToast.component';
// import ClaimPopup from '../../Popups/phase1a/local/Claim.popups';

interface Address {
  street: string;
  city: string;
  state: string;
  zip: string;
  address: string;
  country: string;
}

interface Location {
  type: string;
  coordinates: number[];
}

interface ServiceScreen {
  service: string;
  percentage: number | null;
  subService: string[];
  logo: string;
}

interface ServiceAreaScreen {
  name: string;
}

interface Category {
  _id: string;
  category: string;
  subCategories: string[];
  __v: number;
  createdAt: string;
  updatedAt: string;
}

interface CompanyInfo {
  name: string;
  address: Address;
  phone: string;
  email: string;
  formattedNo: string;
  website: string;
  domain: string;
  location: Location;
  logo: string;
  companyType: string;
  category: Category[];
  description: string;
}

export interface DataStructure {
  companyInfo: CompanyInfo;
  services: ServiceScreen[];
  serviceArea: ServiceAreaScreen[];
  serviceAreaDescription: string;
  profileCompleted: boolean;
}
export interface User {
  first_name: string;
  last_name: string;
  company: string;
  email: string;
  photo: string;
  job_title: string;
  claim?: boolean;
  password?: string;
}
// interface Company {
//   id: number;
//   name: string;
// }
interface FormErrors {
  phone_no: string;
}

const initialUser: User = {
  first_name: '',
  last_name: '',
  company: '',
  email: '',
  photo: '',
  job_title: '',
  claim: false,
  password: '',
};
const PersonalInformation: FC = () => {
  const location = useLocation();
  const { email } = location.state || {};
  const { res } = location.state || {};

  console.log('email', email);
  console.log('email res', res);
  const nav = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [screenId, setScreenId] = useState<number>(1);
  // const [searchCompany, setSearchCompany] = useState<Company[]>([]);
  const [domainCompany, setDomainCompany] = useState<any>([]);
  const [selectedCompany, setSelectedCompany] = useState<string>('');
  const [companyDetail, setCompanyDetail] = useState<any>();
  const [user, setUser] = useState<User>(initialUser);
  const [domain, setDomain] = useState<string>('');
  // const [openClaim, setOpenClaim] = useState(false);

  console.log('user', user);
  console.log('companyDetail', companyDetail);
  const [data, setData] = useState<DataStructure>({
    companyInfo: {
      name: '',
      address: {
        street: '',
        city: '',
        state: '',
        zip: '',
        address: '',
        country: '',
      },
      phone: '',
      formattedNo: '',
      email: '',
      website: '',
      domain: domain ?? '',
      description: '',
      location: {
        type: 'Point',
        coordinates: [],
      },
      logo: '',
      companyType: '',
      category: [],
    },
    services: [
      {
        service: '',
        percentage: null,
        subService: [''],
        logo: '',
      },
    ],
    serviceArea: [
      {
        name: '',
      },
    ],
    serviceAreaDescription: '',
    profileCompleted: true,
  });
  const [formErrors, setFormErrors] = useState<FormErrors>({ phone_no: '' });

  console.log('testingData', data);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;

    if (name === 'phone') {
      const numericInput = value.replace(/\D/g, '');

      setData((prevData) => ({
        ...prevData,
        companyInfo: {
          ...prevData.companyInfo,
          phone: numericInput,
          formattedNo: formatPhoneNumber(numericInput),
        },
      }));

      if (!ValidateNumber(numericInput)) {
        // toast.error('Please enter a valid Phone Number.');
        toast(<CustomToast message={'Please enter a valid Phone Number.'} type="error" />);
        // setFormErrors((prevErrors) => ({
        //   ...prevErrors,
        //   phone_no: 'Please enter a valid Phone Number.',
        // }));
      }
    } else if (name === 'domain') {
      const noSpacesValue = value.replace(/\s/g, '');
      setData((prevData) => ({
        ...prevData,
        companyInfo: {
          ...prevData.companyInfo,
          [name]: noSpacesValue,
        },
      }));
    } else {
      setData((prevData) => ({
        ...prevData,
        companyInfo: {
          ...prevData.companyInfo,
          [name]: value,
        },
      }));
    }
  };

  const handleChangeUser = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;

    if (name === 'phone') {
      const numericInput = value.replace(/\D/g, '');

      setUser((prevUser) => ({
        ...prevUser,
        companyInfo: {
          ...prevUser,
          phone: numericInput,
          formattedNo: formatPhoneNumber(numericInput),
        },
      }));

      if (!ValidateNumber(numericInput)) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          phone_no: 'Please enter a valid Phone Number.',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          phone_no: '',
        }));
      }
    } else {
      setUser((prevUser) => ({
        ...prevUser,
        [name]: value,
      }));
    }
  };

  const ValidateNumber = (phoneNo: string): boolean => {
    const phoneRegex = /^[0-9]+$/;
    return phoneRegex.test(phoneNo);
  };

  useEffect(() => {
    setUser((prevUser) => ({
      ...prevUser,
      email,
    }));
  }, [email]);
  useEffect(() => {
    if (typeof email === 'string' && email.includes('@')) {
      const emailDomain = email.substring(email.lastIndexOf('@') + 1);
      setDomain(emailDomain);
      setData((prevData) => ({
        ...prevData,
        companyInfo: {
          ...prevData.companyInfo,
          domain: emailDomain,
        },
      }));
    } else {
      setDomain('');
    }
  }, [email]);

  useEffect(() => {
    if (data?.companyInfo?.phone) {
      setData((prevData) => ({
        ...prevData,
        companyInfo: {
          ...prevData.companyInfo,
          formattedNo: data.companyInfo.phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3'),
        },
      }));
    }
  }, [data?.companyInfo?.phone]);

  const validateUser = (): void => {
    const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;

    const isValidEmail = emailRegex.test(user?.email);
    if (!user?.first_name?.length) {
      // toast.error('Enter First Name');
      toast(<CustomToast message={'Enter First Name'} type="error" />);
    } else if (!user?.last_name?.length) {
      // toast.error('Enter Last Name');
      toast(<CustomToast message={'Enter Last Name'} type="error" />);
    } else if (!user?.email?.length) {
      // toast.error('Enter Email');
      toast(<CustomToast message={'Enter Email'} type="error" />);
    } else if (!isValidEmail) {
      // toast.error('Enter Valid Email');
      toast(<CustomToast message={'Enter Valid Email'} type="error" />);
    } else if (!user?.company?.length) {
      // toast.error('Choose Company');
      toast(<CustomToast message={'Choose Company'} type="error" />);
    } else if (!user?.job_title.length) {
      // toast.error('Enter job title');
      toast(<CustomToast message={'Enter job title'} type="error" />);
    } else if (!user?.password?.length) {
      // toast.error('Enter Password');
      toast(<CustomToast message={'Enter Password'} type="error" />);
    } else if (user?.password?.length < 8) {
      // toast.error('Password must be at least 8 characters');
      toast(<CustomToast message={'Password must be at keast 8 characters'} type="error" />);
    }
    //  else if (!companyDetail?.companyInfo?.owner) {
    //   setUser((prevUser) => ({
    //     ...prevUser,
    //     claim: true,
    //   }));
    // }
    else {
      handleAddUser();
    }
  };
  const handleAddUser = (): void => {
    request('post', `/users`, user)
      .then((res) => {
        // toast.success('User Added Successfully');
        toast(<CustomToast message={'User Added Successfully'} type="success" />);
        console.log('res', res);
        if (res) {
          request('post', '/auth/generateOTP', { id: user?.email, userWithoutCompany: true })
            .then(() => {
              console.log('Form submitted with email:', email);
              /* eslint-disable @typescript-eslint/restrict-template-expressions */

              // toast.success(`OTP generated and sent successfully to ${email}`);
              toast(
                <CustomToast
                  message={`OTP generated and sent successfully to ${email}`}
                  type="success"
                />,
              );

              // setLoading(false);
              setUser(initialUser);
              setSelectedCompany('');

              nav('/otp', { state: { email } }); // Navigate to the OTP screen with the email
            })
            .catch((err) => {
              // setLoading(false);
              const errmessage = err?.response?.data?.message
                ? err?.response?.data?.message
                : 'User Not found';
              // toast.error(errmessage);
              toast(<CustomToast message={errmessage} type="error" />);
            });
        }
      })
      .catch((err) => {
        // toast.error(err?.response?.data?.message);
        toast(<CustomToast message={err?.response?.data?.message} type="error" />);
        console.log('err', err);
      });
  };
  const handleAvatarClick = (): void => {
    fileInputRef.current?.click();
  };

  const handleRemoveImage = (): void => {
    setUser((prevData) => ({
      ...prevData,
      photo: '',
    }));
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Reset the file input value
    }
  };

  const formatPhoneNumber = (phoneNumber: string): string => {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }

    return phoneNumber;
  };
  const handleNextClick = (): void => {
    const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    const domainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,7}$/;
    const isValidDomain = domainRegex.test(data?.companyInfo?.domain);

    if (screenId === 1) {
      if (!user?.first_name?.length) {
        // toast.error('Enter First Name');
        toast(<CustomToast message={'Enter First Name'} type="error" />);
      } else if (!user?.last_name?.length) {
        // toast.error('Enter Last Name');
        toast(<CustomToast message={'Enter Last Name'} type="error" />);
      } else if (!user?.job_title?.length) {
        // toast.error('Enter Job Title');
        toast(<CustomToast message={'Enter Job Title'} type="error" />);
      } else if (!user?.password?.length || user.password.length < 8) {
        // toast.error('Enter Password of at least 8 characters');
        toast(<CustomToast message={'Enter Password of at least 8 characters'} type="error" />);
      } else {
        setScreenId((prevScreenId) => prevScreenId + 1);
        return;
      }
    }
    if (screenId === 2) {
      if (!data?.companyInfo?.companyType) {
        // toast.error('Select Company Type');
        toast(<CustomToast message={'Select Company Type'} type="error" />);
      } else {
        setScreenId((prevScreenId) => prevScreenId + 1);
      }
    }
    if (screenId === 3) {
      if (!data?.companyInfo?.name) {
        // toast.error('Enter Company Name');
        toast(<CustomToast message={'Enter Company Name'} type="error" />);
      } else if (!data?.companyInfo?.address?.state) {
        // toast.error('Enter Company Address');
        toast(<CustomToast message={'Enter Company Address'} type="error" />);
      } else if (!data?.companyInfo?.email) {
        // toast.error('Enter Company Email');
        toast(<CustomToast message={'Enter Company Email'} type="error" />);
      } else if (!emailRegex.test(data?.companyInfo?.email)) {
        // toast.error('Enter Valid Email');
        toast(<CustomToast message={'Enter Valid Email'} type="error" />);
      } else if (!data?.companyInfo?.domain) {
        // toast.error('Enter Company Domain');
        toast(<CustomToast message={'Enter Company Domain'} type="error" />);
      } else if (!isValidDomain) {
        // toast.error('Enter Valid Domain Format');
        toast(<CustomToast message={'Enter Valid Domain Format'} type="error" />);
        // } else if (!data?.companyInfo?.description) {
        //   toast.error('Enter Company Description.');
      } else if (!data.companyInfo.phone.trim()) {
        // setFormErrors((prevErrors) => ({ ...prevErrors, phone_no: 'Phone Number is required.' }));
        // toast.error('Phone Number is required.');
        toast(<CustomToast message={'Phone Number is required.'} type="error" />);

        return;
      } else if (data.companyInfo.phone.length !== 10) {
        // setFormErrors((prevErrors) => ({
        //   ...prevErrors,
        //   phone_no: 'Phone Number must be 10 digits long.',
        // }));
        // toast.error('Phone Number must be 10 digits long.');
        toast(<CustomToast message={'Phone Number must be 10 digits long.'} type="error" />);

        return;
      } else {
        if (data?.companyInfo?.companyType === 'national') {
          const Nationaldata = {
            companyInfo: {
              name: data?.companyInfo?.name,
              address: {
                street: data?.companyInfo?.address?.street,
                city: data?.companyInfo?.address?.city,
                state: data?.companyInfo?.address?.state,
                zip: data?.companyInfo?.address?.zip,
                address: data?.companyInfo?.address?.address,
                country: data?.companyInfo?.address?.country,
              },
              phone: data?.companyInfo?.phone,
              email: data?.companyInfo?.email,
              website: data?.companyInfo?.website,
              domain: data?.companyInfo?.domain,
              description: data?.companyInfo?.description,
              // category: data?.companyInfo?.category,

              location: {
                type: 'Point',
                coordinates: data?.companyInfo?.location?.coordinates,
              },
              logo: data?.companyInfo?.logo,
              companyType: data?.companyInfo?.companyType,
            },
            serviceAreaDescription: '',
            profileCompleted: true,
          };
          request('post', `/companies`, Nationaldata)
            .then((res: any) => {
              // toast.success('Company Added Successfully');
              toast(<CustomToast message={'Company Added successfuly'} type="success" />);
              console.log('res', res);
              setSelectedCompany(res?.company?.companyInfo?.name);
              setCompanyDetail(res?.company);
              setUser((prevUser) => ({
                ...prevUser,
                company: res?.company?._id,
                claim: true,
              }));
              setScreenId(1);
            })
            .catch((err) => {
              // toast.error('error', err);
              console.log('err', err);
            });
          return;
        }
        setScreenId((prevScreenId) => prevScreenId + 1);
      }
    }
    if (screenId === 4) {
      if (
        data?.companyInfo?.companyType === 'supplier' ||
        data?.companyInfo?.companyType === 'distributor'
      ) {
        if (!data?.companyInfo?.category?.length) {
          // toast.error('Choose at least one category');
          toast(<CustomToast message={'Choose at least one category'} type="error" />);
          return;
        }
        const supplierData = {
          companyInfo: {
            name: data?.companyInfo?.name,
            address: {
              street: data?.companyInfo?.address?.street,
              city: data?.companyInfo?.address?.city,
              state: data?.companyInfo?.address?.state,
              zip: data?.companyInfo?.address?.zip,
              address: data?.companyInfo?.address?.address,
              country: data?.companyInfo?.address?.country,
            },
            phone: data?.companyInfo?.phone,
            email: data?.companyInfo?.email,
            website: data?.companyInfo?.website,
            domain: data?.companyInfo?.domain,
            description: data?.companyInfo?.description,
            category: data?.companyInfo?.category,

            location: {
              type: 'Point',
              coordinates: data?.companyInfo?.location?.coordinates,
            },
            logo: data?.companyInfo?.logo,
            companyType: data?.companyInfo?.companyType,
          },
          serviceAreaDescription: '',
          profileCompleted: true,
        };
        request('post', `/companies`, supplierData)
          .then((res: any) => {
            // toast.success('Company Added Successfully');
            toast(<CustomToast message={'Company Added successfully'} type="error" />);
            console.log('res', res);
            setSelectedCompany(res?.company?.companyInfo?.name);
            setCompanyDetail(res?.company);
            setUser((prevUser) => ({
              ...prevUser,
              company: res?.company?._id,
              claim: true,
            }));
            setScreenId(1);
          })
          .catch((err) => {
            // toast.error('error', err);
            console.log('err', err);
          });
        return;
      }
      if (!data?.services[0]?.service?.length) {
        // toast.error('Select At least One Service ');
        toast(<CustomToast message={'Select At least One Service'} type="error" />);
      } else {
        setScreenId((prevScreenId) => prevScreenId + 1);
      }
    }
    if (screenId === 5) {
      if (!data?.serviceArea[0]?.name?.length) {
        // toast.error('Select At least One Service Area');
        toast(<CustomToast message={'Select At least One Service Area'} type="error" />);
      } else if (!data?.serviceAreaDescription?.length) {
        // toast.error('Add the Service Area Details');
        toast(<CustomToast message={'Add the service Area Details'} type="error" />);
        console.log('data end', data);
      } else {
        request('post', `/companies`, data)
          .then((res: any) => {
            // toast.success('Success');
            toast(<CustomToast message={'Success'} type="success" />);
            console.log('res', res);
            setSelectedCompany(res?.company?.companyInfo?.name);
            setCompanyDetail(res?.company);
            setUser((prevUser) => ({
              ...prevUser,
              company: res?.company?._id,
              claim: true,
            }));
            setScreenId(1);
          })
          .catch((err) => {
            // toast.error('error', err);
            console.log('err', err);
          });
      }
      // nav('/');
      // return;
    }

    // setScreenId((prevScreenId) => prevScreenId + 1);
  };
  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const file = e.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append('photo', file);
      request('post', `/uploads`, formData)
        .then((res) => {
          const imageUrl = (res as string[])[0];

          setUser((prevData) => ({
            ...prevData,
            photo: imageUrl,
          }));
        })
        .catch(() => {
          console.error('Image upload error');
          // toast.error('error');
        });
    }
  };
  // function handleCloseClaimPopup(): void {
  //   setOpenClaim(false);
  // }
  // const handleClaim = (value: string): void => {
  //   if (value === 'yes') {
  //     setUser((prevData) => ({
  //       ...prevData,
  //       claim: true,
  //     }));
  //   } else {
  //     setUser((prevData) => ({
  //       ...prevData,
  //       claim: false,
  //     }));
  //   }
  //   // handleAddUser();
  //   handleCloseClaimPopup();
  // };
  const getEmailDomain = (): void => {
    /* eslint-disable @typescript-eslint/restrict-template-expressions */
    request('get', `/companies/searchByEmail/${email}`)
      .then((res: any) => {
        setDomainCompany(res?.companies);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getEmailDomain();
  }, [email]);

  useEffect(() => {
    setSelectedCompany(domainCompany[0]?.companyInfo?.name);
    setUser((prevUser) => ({
      ...prevUser,
      company: domainCompany[0]?._id,
      claim: false,
    }));
  }, [domainCompany[0]?._id]);
  // useEffect(() => {
  //   if (user?.claim !== undefined) {
  //     handleAddUser();
  //   }
  // }, [user?.claim]);
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = (): void => {
    setShowPassword((show) => !show);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
  };

  return (
    <>
      <HeaderSignCompany
        signUp
        onNextClick={handleNextClick}
        screenId={screenId}
        user={user}
        addUser={validateUser}
        data={data}
      />
      {/* <ClaimPopup open={openClaim} onClose={handleCloseClaimPopup} handleClaim={handleClaim} /> */}
      <Box
        width={'100vw'}
        display={'flex'}
        height={'100%'}
        justifyContent={'center'}
        sx={{ overflowY: 'scroll' }}
      >
        <Box
          width={'60%'}
          display={'flex'}
          // justifyContent={'center'}
          alignItems="center"
          paddingTop={'130px'}
          flexDirection={'column'}
          height={'100%'}
          sx={{
            '@media (max-width: 1024px)': {
              width: '75%',
            },
            '@media (max-width: 768px)': {
              width: '90%',
            },
          }}
        >
          {screenId === 1 && (
            <Box
              width="80%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              gap="10px"
              sx={{
                mb: 5,
                '@media (max-width: 1024px)': {
                  width: '100%',
                },
              }}
            >
              <img src={PersonalInfoIcon} alt="" style={{ width: '77.5px', height: '77.5px' }} />
              <Typography
                fontFamily="SF UI Display"
                fontWeight={800}
                fontSize="24px"
                lineHeight="28.64px"
                textAlign="center"
              >
                Personal Information
              </Typography>
              <Typography
                fontFamily="SF UI Display"
                fontWeight={400}
                fontSize="16px"
                lineHeight="19.09px"
                textAlign="center"
              >
                Enter your details.
              </Typography>
              <Box
                width="80%"
                position="relative"
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                sx={{
                  '@media (max-width: 1024px)': {
                    width: '100%',
                  },
                }}
              >
                {/* Profile pic */}
                <Box width="100%" position="relative" display="flex" mt={5}>
                  <Avatar
                    onClick={handleAvatarClick}
                    style={{ cursor: 'pointer', width: 100, height: 100 }}
                    src={user.photo ? `${mediaUrl}/${user.photo}` : DefaultAvatar}
                  />
                  {user?.photo && (
                    <img
                      src={CloseIconBg}
                      alt="Remove"
                      onClick={handleRemoveImage}
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: '71px',
                        width: '30px',
                        height: '30px',
                        zIndex: 1,
                        cursor: 'pointer',
                      }}
                    />
                  )}

                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleImageUpload}
                    accept="image/*"
                  />
                </Box>

                {/* Name */}
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  margin="3% 0 0 0"
                  sx={{
                    '@media (max-width: 768px)': {
                      flexDirection: 'column',
                    },
                  }}
                >
                  <Box
                    display={'flex'}
                    width="48%"
                    flexDirection={'column'}
                    gap={'8px'}
                    sx={{
                      '@media (max-width: 768px)': {
                        width: '100%',
                      },
                    }}
                  >
                    <Typography
                      fontFamily="SF UI Display"
                      fontWeight={500}
                      fontSize="16px"
                      lineHeight="19.09px"
                      margin="2% 0"
                    >
                      First Name
                    </Typography>
                    <TextField
                      fullWidth
                      sx={{
                        fontSize: '16px',
                        fontWeight: '400',
                        lineHeight: '24px',
                        color: '#667085',
                        borderRadius: '8px',
                        '.MuiOutlinedInput-input': {
                          padding: '10px 14px',
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: '1px solid #D0D5DD',
                            outline: 'none',
                          },
                          '&:hover fieldset': {
                            borderColor: '#D0D5DD',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#D0D5DD',
                          },
                          '&.MuiInputBase-root:focus-visible': {
                            borderColor: '#D0D5DD',
                          },
                        },
                      }}
                      InputProps={{
                        inputProps: {
                          maxLength: 20,
                        },
                      }}
                      id="fname"
                      variant="outlined"
                      placeholder="First Name"
                      name="first_name"
                      value={user?.first_name}
                      onChange={handleChangeUser}
                    />
                  </Box>
                  <Box
                    display={'flex'}
                    width="48%"
                    flexDirection={'column'}
                    gap={'8px'}
                    sx={{
                      '@media (max-width: 768px)': {
                        width: '100%',
                      },
                    }}
                  >
                    <Typography
                      fontFamily="SF UI Display"
                      fontWeight={500}
                      fontSize="16px"
                      lineHeight="19.09px"
                      margin="2% 0"
                    >
                      Last Name
                    </Typography>
                    <TextField
                      fullWidth
                      sx={{
                        fontSize: '16px',
                        fontWeight: '400',
                        lineHeight: '24px',
                        color: '#667085',
                        borderRadius: '8px',
                        '.MuiOutlinedInput-input': {
                          padding: '10px 14px',
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: '1px solid #D0D5DD',
                            outline: 'none',
                          },
                          '&:hover fieldset': {
                            borderColor: '#D0D5DD',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#D0D5DD',
                          },
                          '&.MuiInputBase-root:focus-visible': {
                            borderColor: '#D0D5DD',
                          },
                        },
                      }}
                      InputProps={{
                        inputProps: {
                          maxLength: 20,
                        },
                      }}
                      id="lname"
                      variant="outlined"
                      placeholder="Last Name"
                      name="last_name"
                      value={user.last_name}
                      onChange={handleChangeUser}
                    />
                  </Box>
                </Box>
                <Box
                  width={'100%'}
                  mt={2}
                  // sx={{
                  //   '@media (max-width: 600px)': {
                  //     width: '0%',
                  //   },
                  //   '@media (max-width: 500px)': {
                  //     width: '100%',
                  //   },
                  // }}
                >
                  <Typography
                    fontFamily="SF UI Display"
                    fontWeight={500}
                    fontSize="16px"
                    lineHeight="19.09px"
                    margin="2% 0"
                  >
                    Enter Password
                  </Typography>
                  <FormControl
                    sx={{
                      width: '100%',
                      fontSize: '16px',
                      fontWeight: '400',
                      lineHeight: '24px',
                      color: '#667085',
                      borderRadius: '12px',
                      '.MuiOutlinedInput-input': {
                        padding: '10px 14px',
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: '1px solid #D0D5DD',
                          outline: 'none',
                        },
                        '&:hover fieldset': {
                          borderColor: '#D0D5DD',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#D0D5DD',
                        },
                        '&.MuiInputBase-root:focus-visible': {
                          borderColor: '#D0D5DD',
                        },
                      },
                    }}
                    variant="outlined"
                  >
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                      value={user?.password}
                      onChange={handleChangeUser}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <img src={VisibilityOff} alt="." />
                            ) : (
                              <img src={Visibility} alt="." />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      placeholder="Password"
                    />
                  </FormControl>
                </Box>
                <Box display={'flex'} width="100%" flexDirection={'column'} margin="3% 0 0 0">
                  <Typography
                    fontFamily="SF UI Display"
                    fontWeight={500}
                    fontSize="16px"
                    lineHeight="19.09px"
                    margin="2% 0"
                  >
                    Job Title
                  </Typography>
                  <TextField
                    fullWidth
                    sx={{
                      fontSize: '16px',
                      fontWeight: '400',
                      lineHeight: '24px',
                      color: '#667085',
                      borderRadius: '8px',
                      '.MuiOutlinedInput-input': {
                        padding: '10px 14px',
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: '1px solid #D0D5DD',
                          outline: 'none',
                        },
                        '&:hover fieldset': {
                          borderColor: '#D0D5DD',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#D0D5DD',
                        },
                        '&.MuiInputBase-root:focus-visible': {
                          borderColor: '#D0D5DD',
                        },
                      },
                    }}
                    InputProps={{
                      inputProps: {
                        maxLength: 30,
                      },
                    }}
                    id="job_title"
                    variant="outlined"
                    placeholder="Job Title"
                    name="job_title"
                    value={user?.job_title}
                    onChange={handleChangeUser}
                  />
                </Box>
                {/* Company name */}
                <Box
                  display={'flex'}
                  width="100%"
                  flexDirection={'column'}
                  gap={'8px'}
                  marginTop={'15px'}
                >
                  <Typography
                    fontFamily="SF UI Display"
                    fontWeight="500"
                    fontSize="16px"
                    lineHeight="19.09px"
                    margin={'2% 0 1% 0'}
                    display={'flex'}
                    justifyContent={'flex-start'}
                    alignItems={'center'}
                    gap={'10px'}
                  >
                    Company Name
                    {!user?.company?.length && (
                      <img
                        src={AlertIcon}
                        alt="Company Image"
                        style={{ width: '20px', height: '20px' }}
                      />
                    )}
                  </Typography>
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    width={'100%'}
                    justifyContent={'space-between'}
                    border="solid 1px #D0D5DD"
                    borderRadius="5px"
                    padding="5px 5px 5px 14px"
                  >
                    {/* <Autocomplete
                      disableClearable
                      fullWidth
                      sx={{
                        '.MuiOutlinedInput-root': {
                          padding: '10px 14px',
                        },
                      }}
                      value={selectedCompany}
                      freeSolo
                      options={searchCompany}
                      getOptionLabel={(option: any) => {
                        if (typeof option === 'object') {
                          return option.companyInfo.name;
                        } else {
                          return option;
                        }
                      }}
                      onChange={(event, value) => {
                        console.log(value, 'value');
                        if (value && typeof value !== 'string') handleSelectCompany(event, value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Find Your Compnay"
                          sx={{
                            width: '100%',
                            fontSize: '16px',
                            fontWeight: '400',
                            lineHeight: '24px',
                            color: '#667085',
                            borderRadius: '8px',
                            '.MuiOutlinedInput-input.MuiAutocomplete-input': {
                              padding: '0px',
                            },
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                border: 'none',
                                outline: 'none',
                              },
                              '&:hover fieldset': {
                                borderColor: '#D0D5DD',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#D0D5DD',
                              },
                              '&.MuiInputBase-root:focus-visible': {
                                borderColor: '#D0D5DD',
                              },
                            },
                          }}
                          id="select"
                          onChange={handleSearch}
                          // inputProps={{
                          //   sx: {
                          //     '&::placeholder': {
                          //       fontSize: '16px',
                          //     },
                          //     '@media screen and (max-width: 1060px)': {
                          //       '&::placeholder': {
                          //         fontSize: '12px',
                          //       },
                          //     },
                          //   },
                          // }}
                          InputLabelProps={{
                            style: {
                              borderRadius: '50px',
                            },
                          }}
                        />
                      )}
                    /> */}
                    <TextField
                      fullWidth
                      disabled
                      // sx={{
                      //   '.MuiOutlinedInput-root': {
                      //     padding: '10px 14px',
                      //   },
                      // }}
                      value={selectedCompany}
                      // onChange={(event) => handleSearch(event.target.value)}
                      placeholder="No Company Exists"
                      sx={{
                        width: '100%',
                        fontSize: '16px',
                        fontWeight: '400',
                        lineHeight: '24px',
                        color: '#667085',
                        borderRadius: '8px',
                        '.MuiOutlinedInput-input': {
                          padding: '0px',
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                            outline: 'none',
                          },
                          '&:hover fieldset': {
                            borderColor: '#D0D5DD',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#D0D5DD',
                          },
                          '&.MuiInputBase-root:focus-visible': {
                            borderColor: '#D0D5DD',
                          },
                        },
                      }}
                      id="select"
                      InputLabelProps={{
                        style: {
                          borderRadius: '50px',
                        },
                      }}
                    />

                    <Button
                      sx={{
                        width: '25%',
                        backgroundColor: user?.company?.length ? '#E0E0E0' : '#47B45F',
                        fontFamily: 'SF UI Display',
                        fontSize: '16px',
                        fontWeight: '400',
                        lineheight: '19.09px',
                        textAlign: 'center',
                        color: '#FFFFFF',
                        borderRadius: '5px ',
                        textTransform: 'capitalize',
                        '&:hover': {
                          bgcolor: '#00BD57',
                        },
                        '@media (max-width: 768px)': {
                          width: '35%',
                        },
                      }}
                      disabled={!!user?.company?.length}
                      onClick={handleNextClick}
                    >
                      Add New
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}

          {screenId === 2 && (
            <Box
              width="80%"
              display={'flex'}
              // justifyContent={'center'}
              alignItems={'center'}
              flexDirection="column"
              gap="10px"
              sx={{
                '@media (max-width: 1024px)': {
                  width: '100%',
                },
              }}
            >
              <ScreenTwo data={data} setData={setData} />
            </Box>
          )}
          {/* screen 3 */}
          {screenId === 3 && (
            <Box
              width="80%"
              display={'flex'}
              // justifyContent={'center'}
              alignItems={'center'}
              flexDirection="column"
              gap="10px"
              sx={{
                '@media (max-width: 1024px)': {
                  width: '100%',
                },
              }}
            >
              {/* Add your next screen content here */}
              <ScreenThree
                data={data}
                formErrors={formErrors}
                setData={setData}
                handleChange={handleChange}
              />
            </Box>
          )}
          {/* screen 4 */}
          {screenId === 4 && (
            <Box
              width="80%"
              display={'flex'}
              justifyContent={'center'}
              // alignItems={'center'}
              flexDirection="column"
              gap="10px"
              sx={{
                '@media (max-width: 1024px)': {
                  width: '100%',
                },
              }}
            >
              {/* Add your next screen content here */}

              <Service data={data} setData={setData} />
            </Box>
          )}
          {/* screen 5 */}
          {screenId === 5 && (
            <Box
              width="90%"
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              flexDirection="column"
              gap="10px"
              sx={{
                '@media (max-width: 1024px)': {
                  width: '100%',
                },
              }}
            >
              {/* Add your next screen content here */}
              <ServiceArea data={data} setData={setData} />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default PersonalInformation;
