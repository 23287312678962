import React, { useState } from 'react';
import { Modal, Box, Typography, TextField, Button } from '@mui/material';
import ClaimedListingIcon from '../../../assets/Phase1a/DesignUpdate/ClaimedListingIcon.svg';
import { useNavigate } from 'react-router-dom';
// import { Link } from 'react-router-dom';
interface ClaimedListingProps {
  open: boolean;
  handleClose: () => void;
  setOpenSignInModal: (isOpen: boolean) => void;
  setOpenReviewLogin: (isOpen: boolean) => void;
}

const ClaimedListing: React.FC<ClaimedListingProps> = ({
  open,
  handleClose,
  setOpenSignInModal,
  setOpenReviewLogin,
}) => {
  const nav = useNavigate();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    if (!validateEmail(newEmail)) {
      setEmailError('Invalid email address');
    } else {
      setEmailError('');
    }
  };
  const handleSubmit = (): void => {
    // setLoading(true);

    if (validateEmail(email)) {
      // setIsSubmitted(true);
      // setLoading(false);

      console.log('Form submitted with email:', email);
      nav('/personalinformation', { state: { email } });
    } else {
      // setLoading(false);

      setEmailError('Invalid email address');
    }
  };
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      BackdropProps={{
        sx: {
          backgroundColor: 'var(#0C111D)',
          opacity: 0.7,
          backdropFilter: 'blur(8px)', // Adjust the blur level as needed
        },
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 3,
          borderRadius: '10px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: '12px',
          outline: 'none',
        }}
      >
        <Box
          width={'100%'}
          height={'208px'}
          bgcolor={'#F7F7F7'}
          borderRadius={'8px'}
          display={'flex'}
          justifyContent={'center'}
        >
          <img src={ClaimedListingIcon} alt="" style={{ height: '208px' }} />
        </Box>
        {/* typo */}
        <Box display={'flex'} flexDirection={'column'} gap={'15px'} mt={1}>
          <Typography
            color={'#000'}
            fontFamily={'SF UI Display'}
            fontSize={'24px'}
            fontStyle={'normal'}
            fontWeight={800}
            lineHeight={'28px'}
          >
            Your experience matters.
          </Typography>
          <Typography
            color={'#000'}
            fontFamily={'SF UI Display'}
            fontSize={'16px'}
            fontStyle={'normal'}
            fontWeight={400}
            lineHeight={'20px'}
          >
            Adding your opinion on <span style={{ fontWeight: 600 }}>ShopVox Express</span> helps
            the sign community make better decisions and purchases for their business.
          </Typography>
        </Box>
        {/* EMAIL */}
        <Box width={'100%'} mt={1}>
          <TextField
            required
            type="email"
            placeholder="Enter your email"
            value={email}
            name="email"
            onChange={handleEmailChange}
            error={!!emailError}
            onKeyPress={handleKeyPress}
            helperText={emailError}
            fullWidth
            sx={{
              width: '100%',
              fontSize: '16px',
              fontWeight: '400',
              lineHeight: '24px',
              color: 'rgba(215, 216, 221, 1)',
              borderRadius: '12px',
              '.MuiOutlinedInput-input': {
                // padding: ' 14px',
                padding: '10px 14px',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: '1px solid #D0D5DD',
                  outline: 'none',
                  borderRadius: '12px',
                },
                '&:hover fieldset': {
                  borderColor: '#D0D5DD',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#D0D5DD',
                },
                '&.MuiInputBase-root:focus-visible': {
                  borderColor: '#D0D5DD',
                },
              },
            }}
          />
        </Box>
        {/* buttons */}
        <Box width={'100%'} mt={1}>
          <Button
            disabled={!validateEmail(email)}
            onClick={handleSubmit}
            sx={{
              bgcolor: !validateEmail(email) ? '#B8B8B8' : '#00BD57',
              border: '2px soild rgba(255, 255, 255, 0.12)',
              borderRadius: '8px',
              padding: '10px 16px',
              width: '100%',
              color: '#fff',
              fontFamily: 'SF UI Display',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '24px',
              textTransform: 'capitalize',
              '&:hover': {
                bgcolor: '#00BD57', // Keep the same color on hover
              },
            }}
          >
            Create Your Account
          </Button>
          <Box display={'flex'} width={'100%'} gap={'5px'} justifyContent={'center'}>
            <Typography
              fontSize={'14px'}
              fontWeight={'400'}
              color={'#000000'}
              lineHeight={'20px'}
              // width={'58%'}
              textAlign={'center'}
              justifyContent={'center'}
              display={'flex'}
              gap={'5px'}
              marginTop={'3%'}
              fontFamily="SF UI Display"
              sx={{
                '@media (max-width: 900px)': {
                  width: '80%',
                },
                '@media (max-width: 500px)': {
                  width: '100%',
                },
              }}
            >
              Already have an account?
              <Box
                onClick={() => {
                  setOpenSignInModal(true);
                  setOpenReviewLogin(false);
                }}
              >
                <span
                  style={{
                    fontWeight: '600',
                    cursor: 'pointer',
                    color: 'rgba(0, 189, 87, 1)',
                  }}
                >
                  {' '}
                  Sign In
                </span>
              </Box>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ClaimedListing;
