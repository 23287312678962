import React, { FC, useState, useRef, useEffect } from 'react';
import Box from '@mui/material/Box';
import Companyinfo from '../../../../assets/companylogo.svg';
import { Avatar, TextField, Typography, Autocomplete } from '@mui/material';
import { DataStructure } from '../../../../unAuth/phase1a/PersonalInformation.unAuth';
import request from '../../../../api/request';
import { toast } from 'react-toastify';
import CloseIconBg from '../../../../assets/Phase1a/SignUp/CloseIcon.svg';

import { mediaUrl } from '../../../../config';
import DefaultAvatar from '../../../../assets/Phase1a/SignUp/avatar.svg';
import { IAddress, IAddressResponse } from '../../../../types/user';
import CustomToast from '../../common/CustomToast.component';

interface FormErrors {
  phone_no: string;
}
interface CompanyInfoProps {
  data: DataStructure;
  formErrors: FormErrors;
  setData: React.Dispatch<React.SetStateAction<DataStructure>>;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const style = {
  width: '100%',
  height: '100%',
  bgcolor: 'background.paper',
  borderRadius: 5,
  p: 4,
  paddingRight: 3,
  // marginTop: '100px',
  paddingLeft: 3,
  overflowX: 'hidden',
  overflowY: 'scroll', // Ensures vertical scroll is enabled
  // Custom scrollbar styles
  '::-webkit-scrollbar': {
    display: 'none', // Hides the scrollbar in WebKit browsers
  },
  scrollbarWidth: 'none', // Hides the scrollbar in Firefox
};

const CompanyInfo: FC<CompanyInfoProps> = ({ data, setData, formErrors, handleChange }) => {
  const [allAddresses, setAllAddresses] = useState<IAddress[]>([]);

  console.log(data, 'data');
  const [name, setName] = useState('');
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('');

  const handleAddressChange = (
    _event: React.SyntheticEvent<Element, Event>,
    value: IAddress,
  ): void => {
    if (value) {
      setData({
        ...data,
        companyInfo: {
          ...data?.companyInfo,
          address: {
            street: value.contexts.place,
            city: value.contexts.district,
            state: value.contexts.region,
            zip: value.contexts.postcode,
            address: value.place_name,
            country: value.contexts.country,
          },
          location: value.geometry,
        },
      });
    }
  };
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleGetAddress = (value: string): void => {
    setName(value);
    request<IAddressResponse>('get', `/address?text=${value}`)
      .then((response) => {
        console.log(response);
        setAllAddresses(response.results);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const file = e.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append('photo', file);
      request('post', `/uploads`, formData)
        .then((res) => {
          const imageUrl = (res as string[])[0]; // Assuming the response contains the image URL in `data.url`

          setData((prevData) => ({
            ...prevData,
            companyInfo: {
              ...prevData.companyInfo,
              logo: imageUrl,
            },
          }));
        })
        .catch(() => {
          console.error('Image upload error');
          // toast.error('Error uploading image');
          toast(<CustomToast message={'Error uploading image'} type="error" />);
        });
    }
  };

  const handleAvatarClick = (): void => {
    fileInputRef.current?.click();
  };

  const handleRemoveImage = (): void => {
    setData((prevData) => ({
      ...prevData,
      companyInfo: {
        ...prevData.companyInfo,
        logo: '',
      },
    }));

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };
  const formatPhoneNumber = (phoneNumber: string): string => {
    const cleaned = phoneNumber.replace(/\D/g, '');

    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
    if (match) {
      let formattedNumber = '';
      if (match[1]) {
        formattedNumber += `(${match[1]}`;
      }
      if (match[2]) {
        formattedNumber += `) ${match[2]}`;
      }
      if (match[3]) {
        formattedNumber += `-${match[3]}`;
      }
      return formattedNumber;
    }

    // Return as-is if format doesn't match
    return phoneNumber;
  };

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    const formattedValue = formatPhoneNumber(value);
    setFormattedPhoneNumber(formattedValue);
    handleChange(event);
  };
  useEffect(() => {
    if (data?.companyInfo?.formattedNo) {
      setFormattedPhoneNumber(formatPhoneNumber(data.companyInfo.formattedNo));
    }
  }, [data?.companyInfo?.formattedNo]);
  return (
    <Box sx={style}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        sx={
          {
            // '@media (max-width: 500px)': {
            //   marginTop: '36%',
            // },
          }
        }
      >
        <img
          src={Companyinfo}
          alt="Company Logo"
          style={{ width: '100px', marginBottom: '20px' }}
        />

        <Typography
          fontSize="24px"
          fontWeight="800"
          lineHeight="28.64px"
          fontFamily={'SF UI Display'}
          color=" #000000  "
          id="modal-modal-title"
        >
          Company Info
        </Typography>
        <Typography
          fontFamily={'SF UI Display'}
          fontSize="14px"
          fontWeight="400"
          lineHeight="19.09px"
          color=" #000000  "
          marginTop="10px"
          id="modal-modal-title"
        >
          Enter your business name and contact details.
        </Typography>
      </Box>
      <Box display={'flex'} flexDirection={'column'}>
        <Box
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          margin={'3% 0'}
          sx={{
            '@media (max-width: 768px)': {
              marginBottom: '30px',
            },
          }}
        >
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'flex-start'}
            alignItems={'flex-start'}
          >
            {/* <Avatar
              alt="Avatar"
              src={
                data?.companyInfo?.logo ? `${mediaUrl}/${data?.companyInfo?.logo}` : DefaultAvatar
              }
              sx={{ width: '64px', height: '64px' }}
            /> */}
            <Box width="100%" position="relative" display="flex" mt={5}>
              <Avatar
                onClick={handleAvatarClick}
                style={{ cursor: 'pointer', width: '64px', height: '64px' }}
                src={
                  data?.companyInfo?.logo ? `${mediaUrl}/${data?.companyInfo?.logo}` : DefaultAvatar
                }
              />
              {data?.companyInfo?.logo && (
                <img
                  src={CloseIconBg}
                  alt="Remove"
                  onClick={handleRemoveImage}
                  style={{
                    position: 'absolute',
                    top: '-7px',
                    left: '43px',
                    width: '30px',
                    height: '30px',
                    zIndex: 1,
                    cursor: 'pointer',
                  }}
                />
              )}

              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleImageUpload}
                accept="image/*"
              />
            </Box>
          </Box>
        </Box>
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          alignItems="start"
          justifyContent="start"
          gap="15px"
          margin="2px 0 30px 0"
        >
          <Typography
            sx={{
              fontFamily: 'SF UI Display',

              fontSize: '16px',
              fontWeight: '500',
              lineHeight: '20px',
              color: '#000000',
            }}
          >
            Company Name
          </Typography>
          <TextField
            sx={{
              width: '100%',
              fontSize: '16px',
              fontWeight: '400',
              lineHeight: '24px',
              color: '#667085',
              borderRadius: '8px',
              '.MuiOutlinedInput-input': {
                padding: '10px 14px',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: '1px solid #D0D5DD',
                  outline: 'none',
                },
                '&:hover fieldset': {
                  borderColor: '#D0D5DD',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#D0D5DD',
                },
                '&.MuiInputBase-root:focus-visible': {
                  borderColor: '#D0D5DD',
                },
              },
            }}
            InputProps={{
              inputProps: {
                maxLength: 30,
              },
            }}
            id="name"
            variant="outlined"
            placeholder="D’s Sign & Lighting LLC"
            name="name"
            value={data?.companyInfo?.name}
            onChange={handleChange}
            inputProps={{
              maxLength: 30,
            }}
          />
        </Box>

        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          alignItems="start"
          justifyContent="start"
          gap="15px"
          margin="2px 0 30px 0"
        >
          <Typography
            sx={{
              fontFamily: 'SF UI Display',
              fontSize: '16px',
              fontWeight: '500',
              lineHeight: '20px',
              color: '#000000',
            }}
          >
            Company Address
          </Typography>
          <Autocomplete
            fullWidth
            sx={{
              '.MuiOutlinedInput-root': {
                padding: '10px 14px',
              },
            }}
            value={name}
            freeSolo
            options={allAddresses}
            getOptionLabel={(option: string | IAddress) => {
              if (typeof option === 'object') {
                return option.place_name;
              } else {
                return option;
              }
            }}
            onChange={(event, value) => {
              console.log(value, 'value');
              if (value && typeof value !== 'string') handleAddressChange(event, value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="4311 Iris Dr SW, Baltimore, MD "
                sx={{
                  width: '100%',
                  fontSize: '16px',
                  fontWeight: '400',
                  lineHeight: '24px',
                  color: '#667085',
                  borderRadius: '8px',
                  '.MuiOutlinedInput-input.MuiAutocomplete-input': {
                    padding: '0px',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: '1px solid #D0D5DD',
                      outline: 'none',
                    },
                    '&:hover fieldset': {
                      borderColor: '#D0D5DD',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#D0D5DD',
                    },
                    '&.MuiInputBase-root:focus-visible': {
                      borderColor: '#D0D5DD',
                    },
                  },
                }}
                id="select"
                onChange={(e) => {
                  handleGetAddress(e.target.value);
                }}
                InputLabelProps={{
                  style: {
                    borderRadius: '50px',
                  },
                }}
              />
            )}
          />
        </Box>

        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          alignItems="start"
          justifyContent="start"
          gap="15px"
          margin="2px 0 30px 0"
        >
          <Typography
            sx={{
              fontFamily: 'SF UI Display',
              fontSize: '16px',
              fontWeight: '500',
              lineHeight: '20px',
              color: '#000000',
            }}
          >
            Company Phone Number
          </Typography>
          <TextField
            sx={{
              width: '100%',
              fontSize: '16px',
              fontWeight: '400',
              lineHeight: '24px',
              color: '#667085',
              borderRadius: '8px',
              '.MuiOutlinedInput-input': {
                padding: '10px 14px',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: '1px solid #D0D5DD',
                  outline: 'none',
                },
                '&:hover fieldset': {
                  borderColor: '#D0D5DD',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#D0D5DD',
                },
                '&.MuiInputBase-root:focus-visible': {
                  borderColor: '#D0D5DD',
                },
              },
            }}
            id="phone"
            variant="outlined"
            placeholder="(500) 234-5678"
            name="phone"
            value={formattedPhoneNumber}
            onChange={handlePhoneChange}
            inputProps={{
              pattern: '[0-9]*',
              maxLength: 14,
            }}
          />
        </Box>
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          alignItems="start"
          justifyContent="start"
          gap="15px"
          margin="2px 0 30px 0"
        >
          <Typography
            sx={{
              fontFamily: 'SF UI Display',
              fontSize: '16px',
              fontWeight: '500',
              lineHeight: '20px',
              color: '#000000',
            }}
          >
            Company Email
          </Typography>
          <TextField
            sx={{
              width: '100%',
              fontSize: '16px',
              fontWeight: '400',
              lineHeight: '24px',
              color: '#667085',
              borderRadius: '8px',
              '.MuiOutlinedInput-input': {
                padding: '10px 14px',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: '1px solid #D0D5DD',
                  outline: 'none',
                },
                '&:hover fieldset': {
                  borderColor: '#D0D5DD',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#D0D5DD',
                },
                '&.MuiInputBase-root:focus-visible': {
                  borderColor: '#D0D5DD',
                },
              },
            }}
            id="email"
            variant="outlined"
            placeholder="abc@xyz.com"
            name="email"
            value={data?.companyInfo?.email}
            onChange={handleChange}
          />
        </Box>
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          alignItems="start"
          justifyContent="start"
          gap="15px"
          margin="2px 0 30px 0"
        >
          <Typography
            sx={{
              fontFamily: 'SF UI Display',
              fontSize: '16px',
              fontWeight: '500',
              lineHeight: '20px',
              color: '#000000',
            }}
          >
            Company Domain
          </Typography>
          <TextField
            sx={{
              width: '100%',
              fontSize: '16px',
              fontWeight: '400',
              lineHeight: '24px',
              color: '#667085',
              borderRadius: '8px',
              '.MuiOutlinedInput-input': {
                padding: '10px 14px',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: '1px solid #D0D5DD',
                  outline: 'none',
                },
                '&:hover fieldset': {
                  borderColor: '#D0D5DD',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#D0D5DD',
                },
                '&.MuiInputBase-root:focus-visible': {
                  borderColor: '#D0D5DD',
                },
              },
            }}
            inputProps={{
              maxLength: 40,
              readOnly: true,
            }}
            id="domain"
            variant="outlined"
            placeholder="mysignco.com"
            name="domain"
            value={`www.${data?.companyInfo?.domain || ''}`}
            // onChange={handleChange}
          />
        </Box>
        {/* {data?.companyInfo?.companyType === 'supplier' && (
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="start"
            gap="14px"
            margin="2px 0 12px 0"
          >
            <Typography
              sx={{
                fontFamily: 'SF',
                fontSize: '16px',
                fontWeight: '500',
                lineHeight: '20px',
                color: '#000000',
              }}
            >
              Categories
            </Typography>
            <Autocomplete
              fullWidth
              options={category}
              getOptionLabel={(option: any) => option?.category ?? 'Choose Category'}
              value={data?.companyInfo?.category}
              // name="category"
              onChange={handleCategoryChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Enter Category"
                  variant="outlined"
                  sx={{
                    width: '100%',
                    fontSize: '16px',
                    fontWeight: '400',
                    lineHeight: '24px',
                    color: '#667085',
                    borderRadius: '8px',
                    '.MuiOutlinedInput-input': {
                      padding: '10px 14px',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: '1px solid #D0D5DD',
                        outline: 'none',
                      },
                      '&:hover fieldset': {
                        borderColor: '#D0D5DD',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#D0D5DD',
                      },
                      '&.MuiInputBase-root:focus-visible': {
                        borderColor: '#D0D5DD',
                      },
                    },
                  }}
                />
              )}
            />
          </Box>
        )} */}

        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          alignItems="start"
          justifyContent="start"
          gap="15px"
          margin="2px 0 30px 0"
        >
          <Typography
            sx={{
              fontFamily: 'SF UI Display',
              fontSize: '16px',
              fontWeight: '500',
              lineHeight: '20px',
              color: '#000000',
            }}
          >
            Company Overview (Optional)
          </Typography>
          <TextField
            rows={4}
            multiline
            sx={{
              width: '100%',
              fontSize: '16px',
              fontWeight: '400',
              lineHeight: '24px',
              color: '#667085',
              borderRadius: '8px',

              '.MuiOutlinedInput-input': {
                padding: '0px',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: '1px solid #D0D5DD',
                  outline: 'none',
                },
                '&:hover fieldset': {
                  borderColor: '#D0D5DD',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#D0D5DD',
                },
                '&.MuiInputBase-root:focus-visible': {
                  borderColor: '#D0D5DD',
                },
              },
            }}
            id="description"
            variant="outlined"
            placeholder="Enter Company overview"
            name="description"
            value={data?.companyInfo?.description}
            onChange={handleChange}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CompanyInfo;
