import React, { FC, useState } from 'react';
import { Box, Typography, TextField, Button, CircularProgress } from '@mui/material';
import Logo from '../../assets/SignWiselogo .svg';
import 'react-phone-input-2/lib/style.css';
import { Link, useNavigate } from 'react-router-dom';

const SignIn: FC = () => {
  const nav = useNavigate();

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  // const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  // console.log('object', isSubmitted);
  console.log('objecttt', email);

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    if (!validateEmail(newEmail)) {
      setEmailError('Invalid email address');
    } else {
      setEmailError('');
    }
  };

  const handleSubmit = (): void => {
    setLoading(true);

    if (validateEmail(email)) {
      // setIsSubmitted(true);
      setLoading(false);

      console.log('Form submitted with email:', email);
      nav('/personalinformation', { state: { email } });
    } else {
      setLoading(false);

      setEmailError('Invalid email address');
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <Box
      width={'100%'}
      display={'flex'}
      height={'100vh'}
      justifyContent={'center'}
      alignItems={'center'}
      bgcolor={'#FFFFFF'}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        padding={'30px'}
        borderRadius={'20px'}
        width={'700px'}
        gap={'20px'}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          gap={'40px'}
          width={'100%'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Box width={'319px'} display={'flex'} justifyContent={'center'}>
            <img src={Logo} alt="Logo" />
          </Box>

          <Typography
            fontSize={'16px'}
            fontWeight={'500'}
            color={'#000000'}
            width={'40%'}
            textAlign={'center'}
            fontFamily="SF UI Display"
            letterSpacing={'unset'}
            sx={{
              '@media (max-width: 600px)': {
                width: '80%',
              },
            }}
          >
            Create Your New Account.
          </Typography>
        </Box>

        <Box
          display={'flex'}
          flexDirection={'column'}
          width={'100%'}
          gap={'10px'}
          justifyContent={'center'}
          alignItems={'center'}
          marginTop={'4%'}
        >
          <Box
            width={'70%'}
            sx={{
              // '@media (max-width: 900px)': {
              //   width: '80%',
              // },
              '@media (max-width: 600px)': {
                width: '90%',
              },
              '@media (max-width: 500px)': {
                width: '100%',
              },
            }}
          >
            <TextField
              required
              type="email"
              placeholder="Email"
              value={email}
              name="email"
              onChange={handleEmailChange}
              error={!!emailError}
              onKeyPress={handleKeyPress}
              helperText={emailError}
              fullWidth
              sx={{
                width: '100%',
                margin: 'auto',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '1px solid #D0D5DD',
                    outline: 'none',
                  },
                },
              }}
              InputProps={{
                style: {
                  borderRadius: '8px',
                },
              }}
            />
          </Box>

          <Box
            width={'70%'}
            marginTop={'3%'}
            sx={{
              // '@media (max-width: 900px)': {
              //   width: '80%',
              // },
              '@media (max-width: 600px)': {
                width: '90%',
              },
              '@media (max-width: 500px)': {
                width: '100%',
              },
            }}
          >
            <Button
              fullWidth
              disabled={!validateEmail(email)}
              onClick={handleSubmit}
              sx={{
                padding: '16px 24px',
                bgcolor: validateEmail(email) ? 'black' : '#E0E0E0',
                borderRadius: '15px',
                color: 'white',
                textTransform: 'unset',
                fontSize: '14px',
                fontFamily: 'Poppins',
                fontWeight: '600',
                boxShadow: '0 3px 6px rgba(0, 0, 0, 0.2)',
                '&:hover': {
                  bgcolor: validateEmail(email) ? 'black' : '#E0E0E0',
                  color: 'white',
                },
              }}
            >
              {loading ? <CircularProgress size={15} color="inherit" /> : ' Continue '}{' '}
            </Button>
          </Box>

          <Typography
            fontSize={'14px'}
            fontWeight={'500'}
            color={'#000000'}
            // width={'58%'}
            textAlign={'center'}
            marginTop={'2%'}
            fontFamily="SF UI Display"
            sx={{
              '@media (max-width: 900px)': {
                width: '80%',
              },
              '@media (max-width: 500px)': {
                width: '100%',
                // marginTop: '4%',
              },
            }}
          >
            By continuing you indicate that you agree
            <br /> to SignWise&nbsp;
            <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>
              Terms & Conditions
            </span>
            &nbsp;and&nbsp;
            <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>Privacy Policy.</span>
          </Typography>
          <Typography
            fontSize={'16px'}
            fontWeight={'500'}
            color={'#000000'}
            width={'58%'}
            textAlign={'center'}
            marginTop={'5%'}
            fontFamily="SF UI Display"
            sx={{
              '@media (max-width: 900px)': {
                width: '80%',
              },
              '@media (max-width: 500px)': {
                width: '100%',
              },
            }}
          >
            Already have an account?
            <Link to="/login">
              <span
                style={{
                  fontWeight: '500',
                  cursor: 'pointer',
                  color: 'rgba(0, 189, 87, 1)',
                  textDecoration: 'underline',
                }}
              >
                {' '}
                Sign in here
              </span>
            </Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default SignIn;
