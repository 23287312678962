import React, { FC, useState } from 'react';
import { Avatar, Box, Button, Divider, Popover, TextField, Typography } from '@mui/material';
import logo from '../../../assets/SignWiselogo .svg';
import SettingIcon from '../../../assets/header/settingIcon.svg';
import SignOutIcon from '../../../assets/header/signoutIcon.svg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Link } from 'react-router-dom';
import { mediaUrl } from '../../../config';

interface HeaderSignCompanyProps {
  listingGrid?: boolean;
  signedIn?: boolean;
  search?: string;
  setSearch?: (data: any) => void;
}
const HeaderSignCompany: FC<HeaderSignCompanyProps> = ({
  listingGrid,
  signedIn,
  search,
  setSearch,
}) => {
  const userJSON = localStorage.getItem('user');
  const signIn = userJSON ? JSON.parse(userJSON) : '';
  const [profilePopupAnchor, setProfilePopupAnchor] = useState<HTMLDivElement | null>(null);

  // const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
  //   setSearch(event.target.value);
  // };
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearch?.(event.target.value);
  };
  console.log('search header', search);
  const handleAvatarClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    setProfilePopupAnchor(profilePopupAnchor ? null : event.currentTarget);
  };
  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      alignSelf="center"
      padding="1.5% 3%"
      position={'fixed'}
      bgcolor={'#ffffff'}
      height={'80px'}
      zIndex={'9'}
      top={'0'}
      borderBottom={'1px solid #EEEEEE'}
    >
      <Box width="100%" display="flex" gap={'10%'} alignItems="center">
        <Box width="10%">
          <img src={logo} alt="Logo" style={{ width: '100%', cursor: 'pointer' }} />
        </Box>
        {!signedIn && (
          <Box width="80%" display="flex" justifyContent="right" alignItems="center" gap={'1%'}>
            {listingGrid && (
              <Box display={'flex'} width={'60%'} height={'50px'}>
                <Box
                  display={'flex'}
                  width={'100%'}
                  borderBottom={'1px solid #D7D8DD'}
                  borderTop={'1px solid #D7D8DD'}
                  borderLeft={'1px solid #D7D8DD'}
                  sx={{
                    borderTopLeftRadius: '5px',
                    borderBottomLeftRadius: '5px',
                  }}
                >
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    width={'43%'}
                    borderRight={'1px solid #D7D8DD'}
                  >
                    <Typography
                      fontSize={'16px'}
                      fontWeight={'700'}
                      fontFamily={'SF UI Display'}
                      color={'#00BD57'}
                      padding={'0 0 0 3%'}
                    >
                      Find
                    </Typography>
                    <TextField
                      placeholder="Installers, Fabricators, Service..."
                      value={search}
                      onChange={handleSearchChange}
                      sx={{
                        width: '100%',
                        fontSize: '16px',
                        fontWeight: '400',
                        lineHeight: '24px',
                        color: '#667085',
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                            outline: 'none',
                          },
                          '& .MuiOutlinedInput-input': {
                            padding: '16.5px 0px',
                          },
                        },
                      }}
                      type="search"
                    />
                  </Box>
                  <Box display={'flex'} alignItems={'center'} width={'43%'}>
                    <Typography
                      fontSize={'16px'}
                      fontWeight={'700'}
                      fontFamily={'SF UI Display'}
                      color={'#00BD57'}
                      padding={'0 3%'}
                    >
                      Near
                    </Typography>
                    <TextField
                      placeholder="City, State, Province or Zip"
                      sx={{
                        width: '100%',
                        fontSize: '16px',
                        fontWeight: '400',
                        lineHeight: '24px',
                        color: '#667085',
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                            outline: 'none',
                          },
                        },
                      }}
                      type="search"
                    />
                  </Box>
                </Box>

                <Button
                  sx={{
                    width: '14%',
                    bgcolor: '#00BD57',
                    fontFamily: 'SF UI Display',
                    fontSize: '16px',
                    fontWeight: '600',
                    lineheight: '19.09px',
                    textAlign: 'center',
                    color: '#ffffff',
                    borderTopLeftRadius: '0',
                    borderBottomLeftRadius: '0',
                    textTransform: 'capitalize',
                    '&:hover': {
                      bgcolor: '#00BD57',
                    },
                  }}
                >
                  Search
                </Button>
              </Box>
            )}
            <Box
              display={'flex'}
              gap={'5%'}
              width={'40%'}
              justifyContent={'right'}
              alignItems={'center'}
            >
              <Typography
                fontFamily={'Poppins'}
                fontSize={'14px'}
                fontWeight={'400'}
                lineHeight={'21px'}
                textAlign={'center'}
                margin={'0 1%'}
                color={'#000000'}
              >
                Join Our Network
              </Typography>

              <Link to="/signin">
                <Button
                  sx={{
                    bgcolor: 'transparent',
                    borderRadius: '8px',
                    color: '#00BD57',
                    fontFamily: 'Poppins',
                    fontWeight: '700',
                    fontSize: '14px',
                    lineHeight: '16.71px',
                    textAlign: 'center',
                    textTransform: 'capitalize',
                    padding: '10px 1.5%',
                    '&:hover': {
                      bgcolor: 'transparent',
                    },
                  }}
                >
                  Log In
                </Button>
              </Link>
              <Link to="/signup">
                <Button
                  sx={{
                    bgcolor: 'transparent',
                    borderRadius: '8px',
                    color: '#00BD57',
                    fontFamily: 'Poppins',
                    fontWeight: '700',
                    fontSize: '14px',
                    lineHeight: '16.71px',
                    textAlign: 'center',
                    textTransform: 'capitalize',
                    border: 'solid #00BD57 1px',
                    padding: '10px 1.5%',
                    width: '100px',
                    '&:hover': {
                      bgcolor: 'transparent',
                    },
                  }}
                >
                  Sign Up
                </Button>
              </Link>
            </Box>
          </Box>
        )}

        {signedIn && (
          <Box
            width="80%"
            display="flex"
            justifyContent={listingGrid ? 'space-between' : 'right'}
            alignItems="center"
          >
            {listingGrid && (
              <Box
                display={'flex'}
                border={'1px solid #D7D8DD'}
                borderRadius={'5px'}
                width={'60%'}
                height={'50px'}
              >
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  width={'43%'}
                  borderRight={'1px solid #D7D8DD'}
                >
                  <Typography
                    fontSize={'16px'}
                    fontWeight={'700'}
                    fontFamily={'SF UI Display'}
                    color={'#00BD57'}
                    padding={'0 0 0 3%'}
                  >
                    Find
                  </Typography>
                  <TextField
                    placeholder="Installers, Fabricators, Service..."
                    value={search}
                    onChange={handleSearchChange}
                    sx={{
                      width: '100%',
                      fontSize: '16px',
                      fontWeight: '400',
                      lineHeight: '24px',
                      color: '#667085',
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: 'none',
                          outline: 'none',
                        },
                        '& .MuiOutlinedInput-input': {
                          padding: '16.5px 14px',
                        },
                      },
                    }}
                    type="search"
                  />
                </Box>
                <Box display={'flex'} alignItems={'center'} width={'43%'}>
                  <Typography
                    fontSize={'16px'}
                    fontWeight={'700'}
                    fontFamily={'SF UI Display'}
                    color={'#00BD57'}
                    padding={'0 3%'}
                  >
                    Near
                  </Typography>
                  <TextField
                    placeholder="City, State, Province or Zip"
                    sx={{
                      width: '100%',
                      fontSize: '16px',
                      fontWeight: '400',
                      lineHeight: '24px',
                      color: '#667085',
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: 'none',
                          outline: 'none',
                        },
                      },
                    }}
                    type="search"
                    // onChange={handleSearchChange}
                  />
                </Box>
                <Button
                  sx={{
                    width: '14%',
                    bgcolor: '#00BD57',
                    fontFamily: 'SF UI Display',
                    fontSize: '16px',
                    fontWeight: '600',
                    lineheight: '19.09px',
                    textAlign: 'center',
                    color: '#ffffff',
                    borderRadius: '0 5px 5px 0',
                    textTransform: 'capitalize',
                    '&:hover': {
                      bgcolor: '#00BD57',
                    },
                  }}
                >
                  Search
                </Button>
              </Box>
            )}
            <Box
              display="flex"
              alignItems="center"
              justifyContent={'right'}
              gap={'8%'}
              width={'15%'}
            >
              <Typography
                fontFamily={'Poppins'}
                fontSize={'15px'}
                fontWeight={'500'}
                lineHeight={'21px'}
                textAlign={'center'}
                display={'flex'}
                alignItems={'center'}
                gap={'2%'}
                whiteSpace={'nowrap'}
                margin={'0 1%'}
                color={'#000000'}
              >
                Resources
                <KeyboardArrowDownIcon />
              </Typography>
              <Avatar
                sx={{
                  cursor: 'pointer',
                  bgcolor: 'green',
                }}
                /* eslint-disable @typescript-eslint/restrict-template-expressions */
                src={signIn?.photo ? `${mediaUrl}/${signIn?.photo}` : `${signIn?.first_name}`}
                alt={signIn?.first_name}
                onClick={handleAvatarClick}
              />
              {profilePopupAnchor && (
                <Popover
                  open={Boolean(profilePopupAnchor)}
                  anchorEl={profilePopupAnchor}
                  onClose={() => {
                    setProfilePopupAnchor(null);
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  sx={{
                    '& .MuiPaper-root': {
                      borderRadius: '10px',
                      border: 'solid 1px #EAECF0',
                    },
                  }}
                >
                  <Box
                    width="263px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    borderRadius="10px"
                  >
                    <Box
                      width="100%"
                      p="0 4%"
                      m="4% 0"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Box width="20%">
                        <Avatar
                          sx={{
                            cursor: 'pointer',
                            bgcolor: 'green',
                          }}
                          /* eslint-disable @typescript-eslint/restrict-template-expressions */
                          src={
                            signIn?.photo ? `${mediaUrl}/${signIn?.photo}` : `${signIn?.first_name}`
                          }
                          alt={signIn?.first_name}
                        />
                      </Box>
                      <Box width="80%">
                        <Typography
                          fontFamily="SF UI Display"
                          fontSize="14px"
                          fontWeight="600"
                          lineHeight="20px"
                          color="#344054"
                        >
                          {signIn?.first_name}
                          {signIn?.last_name}
                        </Typography>
                        <Typography
                          fontFamily="SF UI Display"
                          fontSize="14px"
                          fontWeight="400"
                          lineHeight="20px"
                          color="#475467"
                        >
                          D’s Signs & Lighting LLC
                        </Typography>
                      </Box>
                    </Box>
                    <Divider sx={{ bgcolor: '#EBEEF1', width: '100%' }} />
                    <Box
                      width="100%"
                      p="0 4%"
                      m="4% 0"
                      display="flex"
                      alignItems="center"
                      gap="5px"
                    >
                      <img
                        src={SettingIcon}
                        alt=".."
                        style={{ width: '13.33px', height: '13.33px' }}
                      />
                      <Typography
                        fontFamily="SF UI Display"
                        fontWeight="500"
                        lineHeight="20px"
                        fontSize="14px"
                      >
                        Account Settings
                      </Typography>
                    </Box>
                    <Divider sx={{ bgcolor: '#EBEEF1', width: '100%' }} />
                    <Box
                      width="100%"
                      p="0 4%"
                      m="4% 0"
                      display="flex"
                      alignItems="center"
                      gap="5px"
                      sx={{
                        cursor: 'pointer',
                      }}
                    >
                      <img
                        src={SignOutIcon}
                        alt=".."
                        style={{ width: '13.33px', height: '13.33px' }}
                      />
                      <Typography
                        fontFamily="SF UI Display"
                        fontWeight="500"
                        lineHeight="20px"
                        fontSize="14px"
                        sx={{
                          cursor: 'pointer',
                        }}
                      >
                        Sign Out
                      </Typography>
                    </Box>
                  </Box>
                </Popover>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default HeaderSignCompany;
