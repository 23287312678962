import React, { FC, useState } from 'react';
import { Box, Button, Checkbox, CircularProgress, Typography } from '@mui/material';
import UncheckedIcon from '../../../assets/Un_Checkbox.svg'; // Replace with your image path
import CheckedIcon from '../../../assets/check_Checkbox.svg'; // Replace with your image path
interface verificationProps {
  account: any;
  loading?: any;
  companyId: any;
  setAccount: React.Dispatch<React.SetStateAction<any>>;
  onNextClick?: () => void;
  handleCancelClick?: () => void;
}

// Custom Toast Component

const Verification: FC<verificationProps> = ({
  account,
  loading,
  setAccount,
  companyId,
  onNextClick,
  handleCancelClick,
}) => {
  const [check, setCheck] = useState(false);
  return (
    <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
      <Box
        width={'50%'}
        borderRadius={'10px'}
        bgcolor={'#FFF'}
        boxShadow={'0px 1px 4px 0px rgba(148, 148, 148, 0.25)'}
        padding={'3%'}
        display={'flex'}
        justifyContent={'center'}
        sx={{
          '@media (max-width: 1200px)': {
            width: '60%',
          },
          '@media (max-width: 900px)': {
            width: '75%',
          },
          '@media (max-width: 768px)': {
            width: '85%',
            padding: '20px',
          },
          '@media (max-width: 600px)': {
            width: '100%',
          },
        }}
      >
        <Box width={'100%'} display={'flex'} flexDirection={'column'} gap={'10px'}>
          <Typography
            color={'#00BD57'}
            fontFamily={'SF UI Display'}
            fontSize={'16px'}
            fontWeight={'700'}
            lineHeight={'normal'}
            textTransform={'uppercase'}
          >
            {/* Page Setup{' '}
            {account?.company?.companyInfo?.companyType === 'local'
              ? '(5 of 5)'
              : account?.company?.companyInfo?.companyType === 'supplier' ||
                account?.company?.companyInfo?.companyType === 'distributor'
              ? '(4 of 4)'
              : '(3 of 3)'} */}
            Page Setup (4 of 4)
          </Typography>
          <Typography
            color={'#000000'}
            fontFamily={'SF UI Display'}
            fontSize={'24px'}
            fontWeight={'800'}
            lineHeight={'normal'}
            sx={{
              '@media (max-width: 600px)': {
                fontSize: '22px',
              },
            }}
          >
            Lastly, verify you&apos;re allowed to {companyId?.length ? 'claim' : 'create'} page.
          </Typography>
          <Typography
            color={'#000000'}
            fontFamily={'SF UI Display'}
            fontSize={'16px'}
            fontWeight={'400'}
            lineHeight={'20px'}
            sx={{
              '@media (max-width: 600px)': {
                fontSize: '15px',
              },
            }}
          >
            {companyId?.length ? 'Claiming' : 'Creating'} this page without permission may lead this
            company to take legal action against you for fraud.
          </Typography>
          <Box
            width={'100%'}
            display={'flex'}
            margin={'30px 0'}
            gap={'8px'}
            alignItems={'flex-start'}
          >
            <Checkbox
              icon={
                <img
                  src={UncheckedIcon}
                  alt="unchecked"
                  style={{
                    width: 16,
                    height: 16,
                  }}
                />
              }
              checkedIcon={
                <img
                  src={CheckedIcon}
                  alt="checked"
                  style={{
                    width: 16,
                    height: 16,
                  }}
                />
              }
              sx={{
                padding: '0',
                marginTop: '2px',
                '& img': {
                  '@media (max-width: 600px)': {
                    width: '30px !important',
                    height: '30px !important',
                  },
                },
                '@media (max-width: 500px)': {
                  marginTop: '-2px',
                },
              }}
              checked={check}
              onChange={(e) => {
                setCheck(e.target.checked);
              }}
              inputProps={{ 'aria-label': 'controlled-checkbox' }}
            />

            {/* <Checkbox
              sx={{
                padding: '0',
                color: '#D0D5DD', // Unchecked state color
                '&.Mui-checked': {
                  color: '#00BD57', // Checked state color
                },
              }}
              checked={check}
              color="success"
              onChange={(e) => {
                setCheck(e.target.checked); // Set the state to the current checked value
              }}
              inputProps={{ 'aria-label': 'controlled-checkbox' }} // Optional: Adds accessibility label
            /> */}

            <Typography
              color={'#000000'}
              fontFamily={'SF UI Display'}
              fontSize={'14px'}
              fontWeight={'400'}
              lineHeight={'20px'}
            >
              I certify that I am authorized to {companyId?.length ? 'claim' : 'create'} and manage
              this page on behalf of {account?.company?.companyInfo?.name ?? 'Company Name'}.
            </Typography>
          </Box>
          <Box
            width={'100%'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Button
              sx={{
                width: '100%',
                display: 'flex',
                padding: '10px 16px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '6px',
                borderRadius: '8px',
                bgcolor: !check ? '#F7F7F7' : '#00BD57',
                color: !check ? '#B8B8B8' : '#fff',
                fontFamily: 'SF UI Display',
                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '24px',
                margin: '10px 0',
                textTransform: 'capitalize',
                '&:hover': {
                  bgcolor: !check ? '#F7F7F7' : '#00BD57',
                },
              }}
              disabled={!check || loading}
              onClick={onNextClick}
            >
              {loading ? (
                <CircularProgress size={15} color="inherit" />
              ) : companyId?.length ? (
                'Claim Page'
              ) : (
                'Create Page'
              )}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Verification;
