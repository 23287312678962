import React, { FC } from 'react';
import { Box, Button, Typography } from '@mui/material';
import LocalType from '../../../assets/Phase1a/addBusiness/local-type-icon.svg';
import NationalType from '../../../assets/Phase1a/addBusiness/national-type-icon.svg';
import WholeType from '../../../assets/Phase1a/addBusiness/wholesaler-type-icon.svg';
import SupplierType from '../../../assets/Phase1a/addBusiness/supplier-type-icon.svg';

interface companyTypeProps {
  account: any;
  screenNumber?: any;
  companyId?: any;
  setAccount: React.Dispatch<React.SetStateAction<any>>;
  onNextClick?: () => void;
  handleCancelClick?: () => void;
}
const companyTypes = [
  {
    label: 'Local Sign Company',
    type: 'local',
    icon: LocalType,
  },
  {
    label: 'National Sign Company',
    type: 'national',
    icon: NationalType,
  },
  {
    label: 'Commercial Sign Distributor',
    type: 'distributor',
    icon: WholeType,
  },
  {
    label: 'Commercial Sign Supplier',
    type: 'supplier',
    icon: SupplierType,
  },
];
// Custom Toast Component
const CompanyType: FC<companyTypeProps> = ({
  account,
  companyId,
  screenNumber,
  setAccount,
  onNextClick,
  handleCancelClick,
}) => {
  return (
    <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
      <Box
        width={'55%'}
        borderRadius={'10px'}
        bgcolor={'#FFF'}
        boxShadow={'0px 1px 4px 0px rgba(148, 148, 148, 0.25)'}
        padding={'3%'}
        display={'flex'}
        justifyContent={'center'}
        sx={{
          '@media (max-width: 1428px)': {
            width: '60%',
          },
          '@media (max-width: 1200px)': {
            width: '70%',
          },
          '@media (max-width: 992px)': {
            width: '85%',
          },
          '@media (max-width: 768px)': {
            width: '100%',
            padding: '20px',
          },
        }}
      >
        <Box width={'100%'} display={'flex'} flexDirection={'column'} gap={'10px'}>
          <Typography
            color={'#00BD57'}
            fontFamily={'SF UI Display'}
            fontSize={'16px'}
            fontWeight={'700'}
            lineHeight={'normal'}
            textTransform={'uppercase'}
          >
            Page Setup{' '}
            {account?.company?.companyInfo?.companyType === 'local'
              ? '(1 of 5)'
              : account?.company?.companyInfo?.companyType === 'supplier' ||
                account?.company?.companyInfo?.companyType === 'distributor'
              ? '(1 of 4)'
              : '(1 of 3)'}
          </Typography>
          <Typography
            color={'#000000'}
            fontFamily={'SF UI Display'}
            fontSize={'24px'}
            fontWeight={'800'}
            lineHeight={'normal'}
            sx={{
              '@media (max-width: 600px)': {
                fontSize: '22px',
              },
            }}
          >
            You&apos;re confirmed!{' '}
            {companyId?.length ? 'Confirm your company type.' : 'Choose your company type.'}
          </Typography>
          <Typography
            color={'#000000'}
            fontFamily={'SF UI Display'}
            fontSize={'16px'}
            fontWeight={'400'}
            lineHeight={'20px'}
            sx={{
              '@media (max-width: 600px)': {
                fontSize: '15px',
              },
            }}
          >
            It’s important to label your company correctly so others can easily find you on our
            platform.
          </Typography>
          <Box
            width={'100%'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            margin={'15px 0'}
            flexWrap={'wrap'}
          >
            {companyTypes?.map((item, index) => {
              return (
                <Box
                  key={index}
                  width={'48%'}
                  borderRadius={'12px'}
                  border={
                    item?.type === account?.company?.companyInfo?.companyType
                      ? '2px solid #00BD57'
                      : '1px solid #E4E7EC'
                  }
                  bgcolor={'#FFF'}
                  boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}
                  padding={'25px 20px'}
                  display={'flex'}
                  flexDirection={'column'}
                  gap={'8px'}
                  margin={'10px 0'}
                  sx={{
                    cursor:
                      item?.type === account?.company?.companyInfo?.companyType
                        ? 'pointer'
                        : companyId?.length || item?.type === 'wholesaler'
                        ? 'not-allowed'
                        : 'pointer',
                    opacity:
                      item?.type === account?.company?.companyInfo?.companyType
                        ? 1
                        : companyId?.length || item?.type === 'wholesaler'
                        ? 0.5
                        : 1,
                    '@media (max-width: 600px)': {
                      width: '100%',
                    },
                  }}
                  onClick={(e) => {
                    if (companyId?.length || item?.type === 'wholesaler') return;
                    setAccount({
                      ...account,
                      company: {
                        ...account?.company,
                        companyInfo: {
                          ...account?.company.companyInfo,
                          companyType: item?.type,
                        },
                      },
                    });
                  }}
                >
                  <Box width={'48px'} height={'48px'}>
                    <img style={{ width: '100%', height: '100%' }} src={item?.icon} />
                  </Box>
                  <Typography
                    color={'#101828'}
                    fontFamily={'SF UI Display'}
                    fontSize={'18px'}
                    fontWeight={'500'}
                    lineHeight={'32px'}
                  >
                    {item?.label}
                  </Typography>
                </Box>
              );
            })}
          </Box>
          <Box
            width={'100%'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{
              '@media (max-width: 600px)': {
                gap: '10px',
              },
            }}
          >
            <Button
              sx={{
                width: '25%',
                display: 'flex',
                padding: '10px 16px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '6px',
                borderRadius: '8px',
                border: '1px solid D0D5DD',
                background: '#fff',
                color: '#000000',
                fontFamily: 'SF UI Display',
                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '24px',
                margin: '10px 0',
                boxShadow:
                  'inset 0 0 0 1px rgba(16, 24, 40, 0.18), inset 0 -2px 0 rgba(16, 24, 40, 0.05), 0 1px 2px rgba(16, 24, 40, 0.05)',
                textTransform: 'capitalize',
                '&:hover': {
                  bgcolor: '#fff',
                },
                '@media (max-width: 600px)': {
                  width: '100%',
                },
              }}
              disabled={screenNumber}
              onClick={handleCancelClick}
            >
              Cancel
            </Button>
            <Button
              sx={{
                width: '25%',
                display: 'flex',
                padding: '10px 16px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '6px',
                borderRadius: '8px',
                bgcolor: !account?.company?.companyInfo?.companyType?.length
                  ? '#F7F7F7'
                  : '#00BD57',
                color: !account?.company?.companyInfo?.companyType?.length ? '#B8B8B8' : '#fff',
                fontFamily: 'SF UI Display',
                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '24px',
                margin: '10px 0',
                textTransform: 'capitalize',
                '&:hover': {
                  bgcolor: !account?.company?.companyInfo?.companyType?.length
                    ? '#F7F7F7'
                    : '#00BD57',
                },
                '@media (max-width: 600px)': {
                  width: '100%',
                },
              }}
              disabled={!account?.company?.companyInfo?.companyType?.length}
              onClick={onNextClick}
            >
              Next
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CompanyType;
