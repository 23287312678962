import React, { FC } from 'react';
import { Grid, Box } from '@mui/material';
import SideBar from '../../components/phase1a/local/SettingSideBar.component';
import Header from '../../components/phase1a/local/SettingHeader.component';
import SettingPagesetup from '../../pages/SettingPagesetup.page';

const SettingPageSetupLayout: FC = () => {
  return (
    <Box overflow="hidden" width="100%" height="100vh">
      <Header />
      <Grid
        container
        sx={{
          display: 'flex',
          flexDirection: 'row',
          bgcolor: '#F5F3F3',
        }}
      >
        <SideBar />
        <Box
          width={window.innerWidth < 1000 ? '100%' : '75%'}
          bgcolor="#F5F3F3"
          padding={'10px'}
          flexDirection="column"
        >
          <SettingPagesetup />
        </Box>
      </Grid>
    </Box>
  );
};

export default SettingPageSetupLayout;
