import React, { FC } from 'react';
import { Box, Checkbox, Typography, Avatar } from '@mui/material';
import { mediaUrl } from '../../../config';
import UserAvatar from '../../../assets/Phase1a/DesignUpdate/userAvatar.svg';

interface CheckboxUserData {
  avatarSrc?: string;
  name: string;
  designation: string;
  companyInfo: string;
  job_title?: string;
}

interface ReviewSection2Props {
  step2Ref: any;
  selectedBox?: number | null;
  handleBoxClick: (index: number, item: CheckboxUserData) => void;
}

const reviewSection2: FC<ReviewSection2Props> = ({ step2Ref, selectedBox, handleBoxClick }) => {
  const userJSON = localStorage.getItem('user');
  const data = userJSON ? JSON.parse(userJSON) : '';

  return (
    <Box ref={step2Ref} width={'100%'} margin={'50px 0'}>
      <Typography
        fontSize={'20px'}
        fontWeight={'800'}
        fontFamily={'SF UI Display'}
        color={'#000000'}
        sx={{
          '@media (max-width: 500px)': {
            fontSize: '18px',
            lineHeight: 'normal',
          },
        }}
      >
        How would you like to post your review?
      </Typography>
      <Typography
        fontSize={'16px'}
        fontWeight={'400'}
        fontFamily={'SF UI Display'}
        color={'#000000'}
        margin={'10px 0'}
        sx={{
          '@media (max-width: 500px)': {
            lineHeight: '18px',
          },
        }}
      >
        Anyone can review a company publicly or{' '}
        <span style={{ fontWeight: '600', textDecoration: 'underline' }}>anonymously</span> in order
        to protect the business relationship.
      </Typography>
      <Box width={'100%'} margin={'40px 0'} display={'flex'} flexDirection={'column'} gap={'20px'}>
        <Box
          width={'100%'}
          borderRadius={'12px'}
          border={selectedBox === 1 ? '2px solid #00BD57;' : '1px solid #E4E7EC'}
          bgcolor={'#FFF'}
          padding={'20px'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          onClick={(): void => {
            handleBoxClick(1, {
              avatarSrc: data?.photo || '',
              /* eslint-disable @typescript-eslint/restrict-template-expressions */
              name: `${data?.first_name || ''} ${data?.last_name || ''}`,
              designation: `${data?.type === 'owner' ? 'Owner' : 'Team Member'}`,
              job_title: data?.job_title || '',
              companyInfo: data?.company?.companyInfo?.name || '',
            });
          }}
          sx={{ cursor: 'pointer' }}
        >
          <Box display={'flex'} alignItems={'center'} gap={'10px'}>
            <Avatar
              src={data?.photo ? `${mediaUrl}/${data?.photo}` : UserAvatar}
              sx={{
                width: '56px',
                height: '56px',
              }}
            />
            <Box>
              <Typography
                fontSize={'16px'}
                fontWeight={'600'}
                fontFamily={'SF UI Display'}
                color={'#000000'}
                lineHeight={'20px'}
                letterSpacing={'-0.24px'}
                textTransform={'capitalize'}
              >
                {`${data?.first_name} ${data?.last_name}`}
              </Typography>
              <Typography
                fontSize={'14px'}
                fontWeight={'400'}
                fontFamily={'SF UI Display'}
                color={'#505059'}
                lineHeight={'20px'}
                letterSpacing={'-0.24px'}
                textTransform={'capitalize'}
              >
                {data?.job_title}
              </Typography>
              <Typography
                fontSize={'14px'}
                fontWeight={'400'}
                fontFamily={'SF UI Display'}
                color={'#505059'}
                lineHeight={'20px'}
                letterSpacing={'-0.24px'}
                textTransform={'capitalize'}
              >
                {data?.company?.companyInfo?.name}
              </Typography>
            </Box>
          </Box>
          <Box
            width={'30%'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            sx={{
              '@media (max-width: 1024px)': {
                width: 'fit-content',
              },
            }}
          >
            <Typography
              fontSize={'14px'}
              fontWeight={'600'}
              fontFamily={'SF UI Display'}
              color={'#000000'}
              lineHeight={'20px'}
              borderRadius={'6px'}
              bgcolor={'#F7F7F7'}
              boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}
              padding={'10px 15px'}
              whiteSpace={'nowrap'}
              sx={{
                '@media (max-width: 1024px)': {
                  display: 'none',
                },
              }}
            >
              Public Review
            </Typography>
            <Checkbox
              checked={selectedBox === 1}
              sx={{
                display: 'none',
                color: '#000000',
                borderRadius: '6px',
                '& .MuiSvgIcon-root': {
                  fontSize: '24px', // Increase the size of the checkbox icon
                },
                '&.Mui-checked': {
                  color: '#00BD57', // Set the checked color to green
                },
                '@media (max-width: 1024px)': {
                  display: 'block',
                },
              }}
            />
          </Box>
        </Box>
        <Box
          width={'100%'}
          borderRadius={'12px'}
          border={selectedBox === 2 ? '2px solid #00BD57;' : '1px solid #E4E7EC'}
          bgcolor={'#FFF'}
          padding={'20px'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          onClick={(): void => {
            handleBoxClick(2, {
              avatarSrc: '',
              /* eslint-disable @typescript-eslint/restrict-template-expressions */
              name: '',
              designation: `${data?.type === 'owner' ? 'Owner' : 'Team Member'}`,
              job_title: data?.job_title || '',
              companyInfo: data?.company?.companyInfo?.name || '',
            });
          }}
          sx={{ cursor: 'pointer' }}
        >
          <Box display={'flex'} alignItems={'center'} gap={'10px'}>
            <Avatar
              src={UserAvatar}
              sx={{
                width: '56px',
                height: '56px',
              }}
            />
            <Box>
              <Typography
                fontSize={'16px'}
                fontWeight={'600'}
                fontFamily={'SF UI Display'}
                color={'#000000'}
                lineHeight={'20px'}
                letterSpacing={'-0.24px'}
                textTransform={'capitalize'}
              >
                Verified User
              </Typography>
              <Typography
                fontSize={'14px'}
                fontWeight={'400'}
                fontFamily={'SF UI Display'}
                color={'#505059'}
                lineHeight={'20px'}
                letterSpacing={'-0.24px'}
                textTransform={'capitalize'}
              >
                {data?.job_title}
              </Typography>
              <Typography
                fontSize={'14px'}
                fontWeight={'400'}
                fontFamily={'SF UI Display'}
                color={'#505059'}
                lineHeight={'20px'}
                letterSpacing={'-0.24px'}
                textTransform={'capitalize'}
              >
                {data?.company?.companyInfo?.name}
              </Typography>
            </Box>
          </Box>
          <Box
            width={'30%'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            sx={{
              '@media (max-width: 1024px)': {
                width: 'fit-content',
              },
            }}
          >
            <Typography
              fontSize={'14px'}
              fontWeight={'600'}
              fontFamily={'SF UI Display'}
              color={'#000000'}
              lineHeight={'20px'}
              borderRadius={'6px'}
              bgcolor={'#F7F7F7'}
              boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}
              padding={'10px 15px'}
              whiteSpace={'nowrap'}
              sx={{
                '@media (max-width: 1024px)': {
                  display: 'none',
                },
              }}
            >
              Company Review
            </Typography>
            <Checkbox
              checked={selectedBox === 2}
              sx={{
                display: 'none',

                color: '#000000',
                borderRadius: '6px',
                '& .MuiSvgIcon-root': {
                  fontSize: '24px', // Increase the size of the checkbox icon
                },
                '&.Mui-checked': {
                  color: '#00BD57', // Set the checked color to green
                },
                '@media (max-width: 1024px)': {
                  display: 'block',
                },
              }}
            />
          </Box>
        </Box>
        <Box
          width={'100%'}
          borderRadius={'12px'}
          border={selectedBox === 3 ? '2px solid #00BD57;' : '1px solid #E4E7EC'}
          bgcolor={'#FFF'}
          padding={'20px'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          onClick={(): void => {
            handleBoxClick(3, {
              avatarSrc: '',
              /* eslint-disable @typescript-eslint/restrict-template-expressions */
              name: '',
              designation: `${data?.type === 'owner' ? 'Owner' : 'Team Member'}`,
              job_title: data?.job_title || '',
              companyInfo: `${data?.company?.companyInfo?.companyType} Sign Company`,
            });
          }}
          sx={{ cursor: 'pointer' }}
        >
          <Box display={'flex'} alignItems={'center'} gap={'10px'}>
            <Avatar
              src={UserAvatar}
              sx={{
                width: '56px',
                height: '56px',
              }}
            />
            <Box>
              <Typography
                fontSize={'16px'}
                fontWeight={'600'}
                fontFamily={'SF UI Display'}
                color={'#000000'}
                lineHeight={'20px'}
                letterSpacing={'-0.24px'}
                textTransform={'capitalize'}
              >
                Verified User
              </Typography>
              <Typography
                fontSize={'14px'}
                fontWeight={'400'}
                fontFamily={'SF UI Display'}
                color={'#505059'}
                lineHeight={'20px'}
                letterSpacing={'-0.24px'}
                textTransform={'capitalize'}
              >
                {data?.job_title}
              </Typography>
              <Typography
                fontSize={'14px'}
                fontWeight={'400'}
                fontFamily={'SF UI Display'}
                color={'#505059'}
                lineHeight={'20px'}
                letterSpacing={'-0.24px'}
                textTransform={'capitalize'}
              >
                {`${data?.company?.companyInfo?.companyType}`} Sign Company
              </Typography>
            </Box>
          </Box>
          <Box
            width={'30%'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            sx={{
              '@media (max-width: 1024px)': {
                width: 'fit-content',
              },
            }}
          >
            <Typography
              fontSize={'14px'}
              fontWeight={'600'}
              fontFamily={'SF UI Display'}
              color={'#000000'}
              lineHeight={'20px'}
              borderRadius={'6px'}
              bgcolor={'#F7F7F7'}
              boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}
              padding={'10px 15px'}
              whiteSpace={'nowrap'}
              sx={{
                '@media (max-width: 1024px)': {
                  display: 'none',
                },
              }}
            >
              Anonymous Review
            </Typography>
            <Checkbox
              checked={selectedBox === 3}
              sx={{
                display: 'none',
                color: '#000000',
                borderRadius: '6px',
                '& .MuiSvgIcon-root': {
                  fontSize: '24px', // Increase the size of the checkbox icon
                },
                '&.Mui-checked': {
                  color: '#00BD57', // Set the checked color to green
                },
                '@media (max-width: 1024px)': {
                  display: 'block',
                },
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default reviewSection2;
