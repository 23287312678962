import React, { FC, useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  OutlinedInput,
  FormControl,
  IconButton,
  InputAdornment,
} from '@mui/material';
import Logo from '../../assets/SignWiselogo .svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Visibility from '../../assets/eyeicon.svg';
import VisibilityOff from '../../assets/EyeoffIcon.svg';
import request from '../../api/request';
import { toast } from 'react-toastify';
import { IAuthResponse } from '../../types/unAuth.type';
import { UpdateStore } from '../../context/store';
import CustomToast from '../../components/phase1a/common/CustomToast.component';
// import { VisibilityOff, Visibility } from '@mui/icons-material';

interface ILogin {
  id: string;
  // otp?: string;
  password?: string;
}
const SignInManually: FC = () => {
  const nav = useNavigate();
  const updateStore = UpdateStore();
  const location = useLocation();
  const { userEmail } = location.state || {};
  const [email, setEmail] = useState(userEmail);
  const [password, setPassword] = useState('');
  // const [emailError, setEmailError] = useState('');
  const [loading, setLoading] = React.useState(false);

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setPassword(value);
  };
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    // if (!validateEmail(newEmail)) {
    //   setEmailError('Invalid email address');
    // } else {
    //   setEmailError('');
    // }
  };

  const handleSubmit = (): void => {
    setLoading(true);

    if (!validateEmail(email)) {
      setLoading(false); // Ensure loading state is reset before returning
      toast(<CustomToast message={'Invalid email address'} type="error" />);
      return;
    }

    const id = email;

    const data = { id, password };

    request<IAuthResponse, ILogin>('post', '/auth/login', data)
      .then((res) => {
        localStorage.setItem('token', res.token);
        localStorage.setItem('user', JSON.stringify(res.user));
        if (res?.user) {
          updateStore?.({ user: res?.user });
        }
        setLoading(false);
        console.log('res?.user?.type', res?.user);
        // if (!res?.user?.company) {
        //   nav('/add-business', { state: { screenNumber: 4 } });
        //   toast(
        //     <CustomToast message={'Welcome back! Complete your Sign-up process.'} type="success" />,
        //   );
        // } else {
        if (
          (res?.user?.company?.companyInfo?.companyType === 'supplier' ||
            res?.user?.company?.companyInfo?.companyType === 'distributor') &&
          res?.user?.type === 'member'
        ) {
          nav('/localpageowner/SettingPagesetup');
        } else {
          nav('/localpageowner/ReviewsDashoard');
        }
        // toast.success('You have logged in successfully.');
        toast(<CustomToast message={'You have logged in successfully.'} type="success" />);
        // }
      })
      .catch(() => {
        setLoading(false);
        // toast.error('Invalid password');
        toast(<CustomToast message={'Invalid password'} type="error" />);
      });
  };
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = (): void => {
    setShowPassword((show) => !show);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
  };

  return (
    <Box
      width={'100%'}
      display={'flex'}
      height={'100vh'}
      justifyContent={'center'}
      alignItems={'center'}
      bgcolor={'#FFFFFF'}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        padding={'30px'}
        borderRadius={'20px'}
        gap={'20px'}
        width={'700px'}
        sx={{
          '@media (max-width: 1024px)': {
            width: '100%',
          },
        }}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          gap={'20px'}
          width={'100%'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Box width={'319px'} display={'flex'} justifyContent={'center'}>
            <img src={Logo} alt="Logo" />
          </Box>

          <Typography
            fontSize={'16px'}
            fontWeight={'500'}
            color={'#000000'}
            width={'40%'}
            textAlign={'center'}
            fontFamily="SF UI Display"
            sx={{
              '@media (max-width: 900px)': {
                width: '100%',
              },
            }}
          >
            Log in to access your account.
          </Typography>
        </Box>

        <Box
          display={'flex'}
          flexDirection={'column'}
          width={'100%'}
          gap={'10px'}
          justifyContent={'center'}
          alignItems={'center'}
          marginTop={'4%'}
        >
          <Box
            width={'70%'}
            sx={{
              '@media (max-width: 600px)': {
                width: '80%',
              },
              '@media (max-width: 500px)': {
                width: '100%',
              },
            }}
          >
            <TextField
              required
              type="email"
              placeholder="Email"
              value={email}
              name="email"
              onChange={handleEmailChange}
              // error={!!emailError}
              // helperText={emailError}
              fullWidth
              sx={{
                width: '100%',
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '24px',
                color: 'rgba(215, 216, 221, 1)',
                borderRadius: '12px',
                '.MuiOutlinedInput-input': {
                  // padding: ' 14px',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '1px solid #D0D5DD',
                    outline: 'none',
                    borderRadius: '12px',
                  },
                  '&:hover fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.MuiInputBase-root:focus-visible': {
                    borderColor: '#D0D5DD',
                  },
                },
              }}
              inputProps={{
                // maxLength: 40,
                readOnly: true,
              }}
            />{' '}
          </Box>
          {/* password */}
          <Box
            width={'70%'}
            sx={{
              '@media (max-width: 600px)': {
                width: '80%',
              },
              '@media (max-width: 500px)': {
                width: '100%',
              },
            }}
          >
            <FormControl
              sx={{
                width: '100%',
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '24px',
                color: '#667085',
                marginTop: '3%',
                borderRadius: '12px',
                '.MuiOutlinedInput-input': {
                  // padding: ' 14px',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '1px solid #D0D5DD',
                    outline: 'none',
                    borderRadius: '12px',
                  },
                  '&:hover fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.MuiInputBase-root:focus-visible': {
                    borderColor: '#D0D5DD',
                  },
                },
              }}
              variant="outlined"
            >
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={handlePasswordChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <img src={VisibilityOff} alt="." />
                      ) : (
                        <img src={Visibility} alt="." />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                placeholder="Password"
              />
            </FormControl>
          </Box>
          <Box
            width={'70%'}
            marginTop={'3%'}
            display={'flex'}
            flexDirection={'column'}
            gap={'20px'}
            sx={{
              '@media (max-width: 600px)': {
                width: '80%',
              },
              '@media (max-width: 500px)': {
                width: '100%',
              },
            }}
          >
            <Button
              fullWidth
              onClick={handleSubmit}
              sx={{
                padding: '16px 24px',
                bgcolor: 'black',
                borderRadius: '12px',
                color: 'white',
                textTransform: 'unset',
                fontSize: '14px',
                fontFamily: 'Poppins',
                fontWeight: '600',

                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                '&:hover': {
                  bgcolor: 'black',
                  color: 'white',
                },
              }}
            >
              {loading ? <CircularProgress size={15} color="inherit" /> : 'Log in'}
            </Button>
            <Typography
              textAlign={'center'}
              mt={3}
              fontSize={'16px'}
              fontWeight={'500'}
              color={'rgba(0, 0, 0, 1)'}
              marginTop={'20px'}
              fontFamily="SF UI Display"

              // sx={{
              //   '@media (max-width: 900px)': {
              //     width: '100%',
              //   },
              // }}
            >
              {`Don't have an account? `}
              <Link to="/signup">
                <span
                  style={{
                    fontWeight: '500',
                    cursor: 'pointer',
                    color: 'rgba(0, 189, 87, 1)',

                    textDecoration: 'underline',
                  }}
                >
                  Sign up here
                </span>
              </Link>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SignInManually;
