import React, { FC, useEffect, useRef, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import Dummyimage from '../../../assets/dummyimg.png';
import SearchIcon from '../../../assets/Phase1a/addBusiness/search-icon.svg';
// import DropDownImage from '../../../assets/DropDownicon.png';
import { IAddress } from '../../../types/user';
import request from '../../../api/request';
import { toast } from 'react-toastify';
import CustomToast from '../common/CustomToast.component';
import { mediaUrl } from '../../../config';
// import PhoneInput from 'react-phone-input-2';
// import 'react-phone-input-2/lib/style.css';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { SelectChangeEvent } from '@mui/material/Select';

interface companyDetailsProps {
  account: any;
  companyId?: any;
  setAccount: React.Dispatch<React.SetStateAction<any>>;
  onNextClick?: () => void;
  handleCancelClick?: () => void;
}

// Custom Toast Component
const CompanyDetails: FC<companyDetailsProps> = ({
  account,
  setAccount,
  companyId,
  onNextClick,
  handleCancelClick,
}) => {
  const [name, setName] = useState('');
  const [open, setOpen] = useState(false);
  // const [phone, setPhone] = React.useState('');
  const [countryCode, setCountryCode] = useState('US');
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('');
  const handleToggle = (): void => {
    setOpen(!open); // Toggle open/close state
  };
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [allAddresses, setAllAddresses] = useState<IAddress[]>([]);
  const handleAddressChange = (
    _event: React.SyntheticEvent<Element, Event>,
    value: IAddress,
  ): void => {
    console.log('value657', value);
    if (value) {
      setAccount({
        ...account,
        company: {
          ...account?.company,
          companyInfo: {
            ...account?.company?.companyInfo,
            address: {
              street: value.contexts.place,
              city: value.contexts.district,
              state: value.contexts.region,
              zip: value.contexts.postcode,
              address: value.place_name,
              country: value.contexts.country,
            },
            location: value.geometry,
          },
        },
      });
    }
    // else {
    //   setAccount({
    //     ...account,
    //     company: {
    //       ...account?.company,
    //       companyInfo: {
    //         ...account?.company?.companyInfo,
    //         address: {
    //           street: '',
    //           city: '',
    //           state: '',
    //           zip: '',
    //           address: '',
    //           country: '',
    //         },
    //         location: {
    //           type: 'Point',
    //           coordinates: [],
    //         },
    //       },
    //     },
    //   });
    // }
  };
  const handleGetAddress = (value: string): void => {
    setName(value);
    request('get', `/address?text=${value}`)
      .then((response: any) => {
        console.log(response);
        setAllAddresses(response.results);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleAvatarClick = (): void => {
    fileInputRef.current?.click();
  };

  // const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>): void => {
  //   const file = e.target.files?.[0];
  //   if (file) {
  //     const formData = new FormData();
  //     formData.append('photo', file);
  //     request('post', `/uploads`, formData)
  //       .then((res) => {
  //         const imageUrl = (res as string[])[0]; // Assuming the response contains the image URL in `data.url`
  //         setAccount(() => ({
  //           ...account,
  //           company: {
  //             ...account?.company,
  //             companyInfo: {
  //               ...account?.company?.companyInfo,
  //               logo: imageUrl,
  //             },
  //           },
  //         }));
  //       })
  //       .catch(() => {
  //         console.error('Image upload error');
  //         toast(<CustomToast message={'Error uploading image'} type="error" />);
  //       });
  //   }
  // };

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const img = new Image();
        img.onload = () => {
          if (img.width === 256 && img.height === 256) {
            // Valid image dimensions, proceed with upload
            const formData = new FormData();
            formData.append('photo', file);
            request('post', `/uploads`, formData)
              .then((res) => {
                const imageUrl = (res as string[])[0]; // Assuming the response contains the image URL in `data.url`
                setAccount(() => ({
                  ...account,
                  company: {
                    ...account?.company,
                    companyInfo: {
                      ...account?.company?.companyInfo,
                      logo: imageUrl,
                    },
                  },
                }));
              })
              .catch(() => {
                console.error('Image upload error');
                toast(<CustomToast message={'Error uploading image'} type="error" />);
              });
          } else {
            // Invalid image dimensions
            toast(<CustomToast message={'Image must be 256px x 256px'} type="error" />);
          }
        };
        if (typeof reader.result === 'string') {
          img.src = reader.result;
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const validateEmail = (email: string): boolean => {
    // Simple email validation regex pattern
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const formatPhoneNumber = (phoneNumber: string): string => {
    const cleaned = phoneNumber.replace(/\D/g, '');

    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
    if (match) {
      let formattedNumber = '';
      if (match[1]) {
        formattedNumber += `(${match[1]}`;
      }
      if (match[2]) {
        formattedNumber += `) ${match[2]}`;
      }
      if (match[3]) {
        formattedNumber += `-${match[3]}`;
      }
      return formattedNumber;
    }

    // Return as-is if format doesn't match
    return phoneNumber;
  };

  // const handlePhoneChange = (
  //   value: string,
  //   data: any,
  //   event: React.ChangeEvent<HTMLInputElement>,
  //   formattedValue: string,
  // ): void => {
  //   const formattedPhoneNumber = formatPhoneNumber(value);
  //   setFormattedPhoneNumber(formattedPhoneNumber);
  //   // handleChange(event);
  //   setAccount((prevState: any) => ({
  //     ...prevState,
  //     company: {
  //       ...prevState.company,
  //       companyInfo: {
  //         ...prevState.company?.companyInfo,
  //         phone: formattedValue,
  //       },
  //     },
  //   }));
  // };
  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    const formattedValue = formatPhoneNumber(value);
    setFormattedPhoneNumber(formattedValue);
    // Handle any additional state updates here
    setAccount((prevState: any) => ({
      ...prevState,
      company: {
        ...prevState.company,
        companyInfo: {
          ...prevState.company?.companyInfo,
          phone: formattedValue,
        },
      },
    }));
  };
  const handleCountryChange = (event: SelectChangeEvent<string>): void => {
    setCountryCode(event.target.value);
  };

  const validatePhoneNumber = (): boolean => {
    const phoneNumberPattern = /^\+1 \(\d{3}\) \d{3}-\d{4}$/;
    /* eslint-disable @typescript-eslint/restrict-template-expressions */
    return phoneNumberPattern.test(`+1 ${account?.company?.companyInfo?.phone}` || '');
  };
  useEffect(() => {
    if (companyId?.length) {
      const formattedValue = formatPhoneNumber(account?.company?.companyInfo?.phone);
      setFormattedPhoneNumber(formattedValue);
    }
  }, [companyId?.length]);
  console.log('phone: +1 (555) 000-0000 :', formattedPhoneNumber, '=', validatePhoneNumber());
  const buttonDisable = (): boolean => {
    if (companyId?.length) return false;
    return (
      !validateEmail(account?.company?.companyInfo?.email) ||
      !account?.company?.companyInfo?.location?.coordinates?.length ||
      !validatePhoneNumber() ||
      !account?.company?.companyInfo?.formation_year?.length ||
      !account?.company?.companyInfo?.formation_year?.length ||
      // !account?.company?.companyInfo?.licence?.length ||
      !account?.company?.companyInfo?.company_size?.length ||
      !account?.company?.companyInfo?.union_shop?.length ||
      !account?.company?.companyInfo?.available_for_work?.length ||
      !account?.company?.companyInfo?.responce_time?.length ||
      (account?.company?.services?.some((service: any) => service.service === 'Fabricators') &&
        !account?.company?.companyInfo?.ul_list)
    );
  };

  const selectRef = useRef<string | null>(null); // Ref for the currently open dropdown
  const [openDrop, setOpenDrop] = useState(false);

  const handleIconClick = (dropdownRef: any): any => {
    if (selectRef.current === dropdownRef) {
      setOpenDrop((prev) => !prev); // Toggle if the same dropdown is clicked
    } else {
      selectRef.current = dropdownRef; // Update to new dropdown
      setOpenDrop(true); // Open the new dropdown
    }
  };

  interface CustomDropdownIconProps {
    dropdownRef: string;
  }

  const CustomDropdownIcon: React.FC<CustomDropdownIconProps> = ({ dropdownRef }) => (
    <div
      onClick={(e) => {
        e.stopPropagation(); // Prevent Select from toggling on container click
        handleIconClick(dropdownRef);
      }}
      style={{
        marginRight: '14px',
        cursor: 'pointer',
      }}
    >
      {openDrop && selectRef.current === dropdownRef ? <ExpandLess /> : <ExpandMore />}
    </div>
  );

  return (
    <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
      <Box
        width={'50%'}
        borderRadius={'10px'}
        bgcolor={'#FFF'}
        boxShadow={'0px 1px 4px 0px rgba(148, 148, 148, 0.25)'}
        padding={'3% 1%'}
        display={'flex'}
        justifyContent={'center'}
        sx={{
          '@media (max-width: 1200px)': {
            width: '60%',
          },
          '@media (max-width: 1024px)': {
            width: '70%',
          },
          '@media (max-width: 900px)': {
            width: '85%',
          },
          '@media (max-width: 768px)': {
            width: '100%',
            padding: '20px 10px',
          },
        }}
      >
        <Box width={'100%'} display={'flex'} flexDirection={'column'} gap={'10px'}>
          <Typography
            color={'#00BD57'}
            fontFamily={'SF UI Display'}
            fontSize={'16px'}
            fontWeight={'700'}
            lineHeight={'normal'}
            textTransform={'uppercase'}
            padding={'0 3%'}
          >
            {/* Page Setup{' '}
            {account?.company?.companyInfo?.companyType === 'local'
              ? '(3 of 5)'
              : account?.company?.companyInfo?.companyType === 'supplier' ||
                account?.company?.companyInfo?.companyType === 'distributor'
              ? '(3 of 4)'
              : '(2 of 3)'} */}
            Page Setup (2 of 4)
          </Typography>
          <Typography
            color={'#000000'}
            fontFamily={'SF UI Display'}
            fontSize={'24px'}
            fontWeight={'800'}
            lineHeight={'normal'}
            padding={'0 3%'}
            sx={{
              '@media (max-width: 600px)': {
                fontSize: '22px',
              },
            }}
          >
            {/* Perfect! Enter all your company details. */}
            Got it! Next, add all your company details.
          </Typography>
          <Typography
            color={'#000000'}
            fontFamily={'SF UI Display'}
            fontSize={'16px'}
            fontWeight={'400'}
            lineHeight={'20px'}
            padding={'0 3%'}
            sx={{
              '@media (max-width: 600px)': {
                fontSize: '15px',
              },
            }}
          >
            Providing others with the information they need to contact you about all your services
            gives you access to higher quality leads.
          </Typography>
          <Box
            width={'100%'}
            display={'flex'}
            margin={'15px 0'}
            flexWrap={'wrap'}
            justifyContent={'space-between'}
            height={'28vh'}
            gap={'20px'}
            padding={'0 3%'}
            sx={{
              overflowY: 'scroll',
              '@media (max-width: 600px)': {
                minHeight: '35vh',
              },
            }}
          >
            <Box
              width={'100%'}
              display={'flex'}
              gap={'50px'}
              alignItems={'center'}
              sx={{
                '@media (max-width: 600px)': {
                  gap: '20px',
                },
              }}
            >
              <Box
                width={'75px'}
                height={'75px'}
                borderRadius={'10px'}
                overflow={'hidden'}
                onClick={() => {
                  if (companyId?.length) return;
                  handleAvatarClick();
                }}
              >
                <img
                  style={{ width: '100%', height: '100%' }}
                  src={
                    account?.company?.companyInfo?.logo
                      ? /* eslint-disable @typescript-eslint/restrict-template-expressions */
                        `${mediaUrl}/${account?.company?.companyInfo?.logo}`
                      : Dummyimage
                  }
                  alt="Company Logo"
                />
              </Box>
              {!companyId?.length && (
                <Box
                  display={'flex'}
                  alignItems={'start'}
                  justifyContent={'start'}
                  flexDirection={'column'}
                >
                  <Typography
                    color={'#00BD57'}
                    fontFamily={'Inter'}
                    fontSize={'14px'}
                    fontWeight={'600'}
                    lineHeight={'20px'}
                    padding={'0px 5px'}
                    sx={{
                      textDecoration: 'underline',
                      cursor: companyId?.length ? 'default' : 'pointer',
                    }}
                    onClick={() => {
                      if (companyId?.length) return;
                      handleAvatarClick();
                    }}
                    // onClick={triggerFileInput} // Trigger file input on click
                  >
                    Click to upload company logo
                  </Typography>
                  <Typography
                    color={'#9B9C9D'}
                    fontFamily={'Inter'}
                    fontSize={'12px'}
                    fontWeight={'400'}
                    lineHeight={'20px'}
                    padding={'0px 5px'}
                    sx={{
                      cursor: companyId?.length ? 'default' : 'pointer',
                    }}
                    onClick={() => {
                      if (companyId?.length) return;
                      handleAvatarClick();
                    }}
                  >
                    Recommended Size: 256px x 256px
                  </Typography>
                </Box>
              )}

              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={(e) => {
                  if (companyId?.length) return;
                  handleImageUpload(e);
                }}
                accept="image/*"
              />
            </Box>
            <Box width={'100%'}>
              <Typography
                color={'#000'}
                fontFamily={'SF UI Display'}
                fontSize={'14px'}
                fontWeight={'500'}
                lineHeight={'20px'}
                marginBottom={'6px'}
              >
                Company Address{' '}
                <span
                  style={{
                    // color: !account?.company?.companyInfo?.location?.coordinates?.length
                    //   ? 'red'
                    //   : '#00BD57',
                    color: '#00BD57',
                  }}
                >
                  *
                </span>
              </Typography>
              <Autocomplete
                fullWidth
                disabled={companyId?.length}
                sx={{
                  '.MuiOutlinedInput-root': {
                    padding: '10px 14px',
                  },
                }}
                value={account?.company?.companyInfo?.address?.address ?? name}
                freeSolo
                options={allAddresses}
                filterOptions={(options) => options}
                getOptionLabel={(option: string | IAddress) => {
                  if (typeof option === 'object') {
                    return option.place_name;
                  } else {
                    return option;
                  }
                }}
                onChange={(event, value) => {
                  console.log(value, 'value');
                  if (value === null || value === '') {
                    // Reset the state when the address is cleared
                    setAccount({
                      ...account,
                      company: {
                        ...account?.company,
                        companyInfo: {
                          ...account?.company?.companyInfo,
                          address: {
                            street: '',
                            city: '',
                            state: '',
                            zip: '',
                            address: '',
                            country: '',
                          },
                          location: {
                            type: 'Point',
                            coordinates: [],
                          },
                        },
                      },
                    });
                  } else if (value && typeof value !== 'string') {
                    handleAddressChange(event, value);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Search for your business address"
                    sx={{
                      width: '100%',
                      fontSize: '16px',
                      fontWeight: '400',
                      lineHeight: '24px',
                      color: '#667085',
                      fontFamily: 'SF UI Display',
                      borderRadius: '8px',
                      '.MuiOutlinedInput-input.MuiAutocomplete-input': {
                        padding: '0px',
                        fontFamily: 'SF UI Display',
                      },
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '8px',

                        '& fieldset': {
                          borderColor: '#e0e0e0', // Light border color
                          outline: 'none',
                        },
                        '&:hover fieldset': {
                          border: '1px solid #D0D5DD',
                        },
                        '&.Mui-focused fieldset': {
                          border: '1px solid #D0D5DD',
                        },
                        '&.MuiInputBase-root:focus-visible': {
                          border: '1px solid #D0D5DD',
                        },
                      },
                    }}
                    InputProps={{
                      ...params.InputProps, // Ensure merging of InputProps from params
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            src={SearchIcon}
                            alt="SearchIcon"
                            style={{ width: '24px', height: '24px' }}
                          />
                        </InputAdornment>
                      ),
                    }}
                    id="select"
                    onChange={(e) => {
                      handleGetAddress(e.target.value);
                      setAccount({
                        ...account,
                        company: {
                          ...account?.company,
                          companyInfo: {
                            ...account?.company?.companyInfo,
                            location: {
                              type: 'Point',
                              coordinates: [],
                            },
                          },
                        },
                      });
                    }}
                    InputLabelProps={{
                      style: {
                        borderRadius: '50px',
                      },
                    }}
                  />
                )}
              />
            </Box>
            {/* New Fields */}
            <Box
              width={'47%'}
              sx={{
                '@media (max-width: 600px)': {
                  width: '100%',
                },
              }}
            >
              <Typography
                color={'#000'}
                fontFamily={'SF UI Display'}
                fontSize={'14px'}
                fontWeight={'500'}
                lineHeight={'20px'}
                marginBottom={'6px'}
              >
                Company Formation Year{' '}
                <span
                  style={{
                    // color: !validateEmail(account?.company?.companyInfo?.email) ? 'red' : '#00BD57',
                    color: '#00BD57',
                  }}
                >
                  *
                </span>
              </Typography>
              <TextField
                disabled={companyId?.length}
                fullWidth
                variant="outlined"
                value={account?.company?.companyInfo?.formation_year}
                onChange={(e) => {
                  setAccount(() => ({
                    ...account,
                    company: {
                      ...account?.company,
                      companyInfo: {
                        ...account?.company?.companyInfo,
                        formation_year: e.target.value,
                      },
                    },
                  }));
                }}
                placeholder=""
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '8px', // Rounded corners for the whole input
                    backgroundColor: '#fff', // White background for input field
                    outline: 'none',
                    '& fieldset': {
                      borderColor: '#e0e0e0', // Light border color
                      outline: 'none',
                    },
                    '&:hover fieldset': {
                      border: '1px solid #D0D5DD',
                    },
                    '&.Mui-focused fieldset': {
                      border: '1px solid #D0D5DD',
                    },
                  },
                  '& .MuiInputBase-input': {
                    padding: '10px 14px !important', // Adjust padding to match visual spacing
                    backgroundColor: '#fff', // White background for the text input
                  },
                }}
              />
            </Box>
            <Box
              width={'47%'}
              sx={{
                '@media (max-width: 600px)': {
                  width: '100%',
                },
              }}
            >
              <Typography
                color={'#000'}
                fontFamily={'SF UI Display'}
                fontSize={'14px'}
                fontWeight={'500'}
                lineHeight={'20px'}
                marginBottom={'6px'}
              >
                Contractor License #{' '}
                {/* <span
                  style={{
                    // color: !validateEmail(account?.company?.companyInfo?.email) ? 'red' : '#00BD57',
                    color: '#00BD57',
                  }}
                >
                  *
                </span> */}
              </Typography>
              <TextField
                disabled={companyId?.length}
                fullWidth
                variant="outlined"
                value={account?.company?.companyInfo?.licence}
                onChange={(e) => {
                  setAccount(() => ({
                    ...account,
                    company: {
                      ...account?.company,
                      companyInfo: {
                        ...account?.company?.companyInfo,
                        licence: e.target.value,
                      },
                    },
                  }));
                }}
                placeholder=""
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '8px', // Rounded corners for the whole input
                    backgroundColor: '#fff', // White background for input field
                    outline: 'none',
                    '& fieldset': {
                      borderColor: '#e0e0e0', // Light border color
                      outline: 'none',
                    },
                    '&:hover fieldset': {
                      border: '1px solid #D0D5DD',
                    },
                    '&.Mui-focused fieldset': {
                      border: '1px solid #D0D5DD',
                    },
                  },
                  '& .MuiInputBase-input': {
                    padding: '10px 14px !important', // Adjust padding to match visual spacing
                    backgroundColor: '#fff', // White background for the text input
                  },
                }}
              />
            </Box>
            <Box
              width={'47%'}
              sx={{
                '@media (max-width: 600px)': {
                  width: '100%',
                },
              }}
            >
              <Typography
                color={'#000'}
                fontFamily={'SF UI Display'}
                fontSize={'14px'}
                fontWeight={'500'}
                lineHeight={'20px'}
                marginBottom={'6px'}
              >
                Company Size{' '}
                <span
                  style={{
                    // color: !validateEmail(account?.company?.companyInfo?.email) ? 'red' : '#00BD57',
                    color: '#00BD57',
                  }}
                >
                  *
                </span>
              </Typography>
              <Select
                // ref={selectRef}
                disabled={companyId?.length}
                fullWidth
                value={account?.company?.companyInfo?.company_size || ''}
                onChange={(e) => {
                  setAccount(() => ({
                    ...account,
                    company: {
                      ...account?.company,
                      companyInfo: {
                        ...account?.company?.companyInfo,
                        company_size: e.target.value,
                      },
                    },
                  }));
                }}
                displayEmpty
                variant="outlined"
                open={openDrop && selectRef.current === 'companySize'}
                onClose={() => {
                  setOpenDrop(false);
                }}
                onOpen={() => {
                  selectRef.current = 'companySize';
                  setOpenDrop(true);
                }}
                IconComponent={() => <CustomDropdownIcon dropdownRef="companySize" />}
                sx={{
                  borderRadius: '8px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '8px',
                    backgroundColor: '#fff',
                    '& fieldset': {
                      borderColor: '#e0e0e0',
                    },
                    '&:hover fieldset': {
                      border: '1px solid #D0D5DD',
                    },
                    '&.Mui-focused fieldset': {
                      border: '1px solid #D0D5DD',
                    },
                  },
                  '& .MuiSelect-select': {
                    color: account?.company?.companyInfo?.company_size ? 'inherit' : '#C0C0C0', // Change color based on value
                    padding: '10px 14px !important',
                  },
                }}
              >
                <MenuItem value="" disabled sx={{ display: openDrop ? 'none' : 'block' }}>
                  Select
                </MenuItem>

                <MenuItem value="Solo Operator">Solo Operator</MenuItem>
                <MenuItem value="2-4 employees">2-4 employees</MenuItem>
                <MenuItem value="5-10 employees">5-10 employees</MenuItem>
                <MenuItem value="10-30 employees">10-30 employees</MenuItem>
                <MenuItem value="30-50 employees">30-50 employees</MenuItem>
                <MenuItem value="50+ employees">50+ employees</MenuItem>
              </Select>
            </Box>
            <Box
              width={
                account?.company?.services?.some(
                  (service: any) => service.service === 'Fabricators',
                )
                  ? '20%'
                  : '47%'
              }
              sx={{
                '@media (max-width: 600px)': {
                  width: '100%',
                },
              }}
            >
              <Typography
                color={'#000'}
                fontFamily={'SF UI Display'}
                fontSize={'14px'}
                fontWeight={'500'}
                lineHeight={'20px'}
                marginBottom={'6px'}
              >
                Union Shop{' '}
                <span
                  style={{
                    // color: !validateEmail(account?.company?.companyInfo?.email) ? 'red' : '#00BD57',
                    color: '#00BD57',
                  }}
                >
                  *
                </span>
              </Typography>
              <Select
                disabled={companyId?.length}
                fullWidth
                value={account?.company?.companyInfo?.union_shop || ''}
                onChange={(e) => {
                  setAccount(() => ({
                    ...account,
                    company: {
                      ...account?.company,
                      companyInfo: {
                        ...account?.company?.companyInfo,
                        union_shop: e.target.value,
                      },
                    },
                  }));
                }}
                displayEmpty
                variant="outlined"
                // ref={selectRef} // Use the same ref
                open={openDrop && selectRef.current === 'unionShop'}
                onClose={() => {
                  setOpenDrop(false);
                }}
                onOpen={() => {
                  selectRef.current = 'unionShop';
                  setOpenDrop(true);
                }}
                IconComponent={() => <CustomDropdownIcon dropdownRef="unionShop" />}
                sx={{
                  borderRadius: '8px',

                  '& .MuiOutlinedInput-root': {
                    borderRadius: '8px',
                    backgroundColor: '#fff',
                    '& fieldset': {
                      borderColor: '#e0e0e0',
                    },
                    '&:hover fieldset': {
                      border: '1px solid #D0D5DD',
                    },
                    '&.Mui-focused fieldset': {
                      border: '1px solid #D0D5DD',
                    },
                  },
                  '& .MuiSelect-select': {
                    padding: '10px 14px !important',
                    color: account?.company?.companyInfo?.union_shop ? 'inherit' : '#C0C0C0', // Change color based on value
                  },
                }}
              >
                <MenuItem value="" disabled sx={{ display: openDrop ? 'none' : 'block' }}>
                  Select
                </MenuItem>
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </Box>
            {account?.company?.services?.some(
              (service: any) => service.service === 'Fabricators',
            ) && (
              <Box
                width={'20%'}
                sx={{
                  '@media (max-width: 600px)': {
                    width: '100%',
                  },
                }}
              >
                <Typography
                  color={'#000'}
                  fontFamily={'SF UI Display'}
                  fontSize={'14px'}
                  fontWeight={'500'}
                  lineHeight={'20px'}
                  marginBottom={'6px'}
                >
                  UL Listed{' '}
                  <span
                    style={{
                      // color: !validateEmail(account?.company?.companyInfo?.email) ? 'red' : '#00BD57',
                      color: '#00BD57',
                    }}
                  >
                    *
                  </span>
                </Typography>
                <Select
                  disabled={companyId?.length}
                  fullWidth
                  value={account?.company?.companyInfo?.ul_list || ''}
                  onChange={(e) => {
                    setAccount(() => ({
                      ...account,
                      company: {
                        ...account?.company,
                        companyInfo: {
                          ...account?.company?.companyInfo,
                          ul_list: e.target.value,
                        },
                      },
                    }));
                  }}
                  displayEmpty
                  variant="outlined"
                  open={openDrop && selectRef.current === 'ul_list'}
                  onClose={() => {
                    setOpenDrop(false);
                  }}
                  onOpen={() => {
                    selectRef.current = 'ul_list';
                    setOpenDrop(true);
                  }}
                  IconComponent={() => <CustomDropdownIcon dropdownRef="ul_list" />}
                  sx={{
                    borderRadius: '8px',

                    '& .MuiOutlinedInput-root': {
                      borderRadius: '8px',
                      backgroundColor: '#fff',
                      '& fieldset': {
                        borderColor: '#e0e0e0',
                      },
                      '&:hover fieldset': {
                        border: '1px solid #D0D5DD',
                      },
                      '&.Mui-focused fieldset': {
                        border: '1px solid #D0D5DD',
                      },
                    },
                    '& .MuiSelect-select': {
                      padding: '10px 14px !important',
                      color: account?.company?.companyInfo?.ul_list ? 'inherit' : '#C0C0C0', // Change color based on value
                    },
                  }}
                >
                  <MenuItem value="" disabled sx={{ display: openDrop ? 'none' : 'block' }}>
                    Select
                  </MenuItem>
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </Select>
              </Box>
            )}

            <Box
              width={'47%'}
              sx={{
                '@media (max-width: 600px)': {
                  width: '100%',
                },
              }}
            >
              <Typography
                color={'#000'}
                fontFamily={'SF UI Display'}
                fontSize={'14px'}
                fontWeight={'500'}
                lineHeight={'20px'}
                marginBottom={'6px'}
              >
                Currently Available For Work{' '}
                <span
                  style={{
                    // color: !validateEmail(account?.company?.companyInfo?.email) ? 'red' : '#00BD57',
                    color: '#00BD57',
                  }}
                >
                  *
                </span>
              </Typography>
              <Select
                disabled={companyId?.length}
                fullWidth
                value={account?.company?.companyInfo?.available_for_work || ''}
                onChange={(e) => {
                  setAccount(() => ({
                    ...account,
                    company: {
                      ...account?.company,
                      companyInfo: {
                        ...account?.company?.companyInfo,
                        available_for_work: e.target.value,
                      },
                    },
                  }));
                }}
                displayEmpty
                variant="outlined"
                open={openDrop && selectRef.current === 'available_for_work'}
                onClose={() => {
                  setOpenDrop(false);
                }}
                onOpen={() => {
                  selectRef.current = 'available_for_work';
                  setOpenDrop(true);
                }}
                IconComponent={() => <CustomDropdownIcon dropdownRef="available_for_work" />}
                sx={{
                  borderRadius: '8px',

                  '& .MuiOutlinedInput-root': {
                    borderRadius: '8px',
                    backgroundColor: '#fff',
                    '& fieldset': {
                      borderColor: '#e0e0e0',
                    },
                    '&:hover fieldset': {
                      border: '1px solid #D0D5DD',
                    },
                    '&.Mui-focused fieldset': {
                      border: '1px solid #D0D5DD',
                    },
                  },
                  '& .MuiSelect-select': {
                    padding: '10px 14px !important',
                    color: account?.company?.companyInfo?.available_for_work
                      ? 'inherit'
                      : '#C0C0C0', // Change color based on value
                  },
                }}
              >
                <MenuItem value="" disabled sx={{ display: openDrop ? 'none' : 'block' }}>
                  Select
                </MenuItem>
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </Box>
            <Box
              width={'47%'}
              sx={{
                '@media (max-width: 600px)': {
                  width: '100%',
                },
              }}
            >
              <Typography
                color={'#000'}
                fontFamily={'SF UI Display'}
                fontSize={'14px'}
                fontWeight={'500'}
                lineHeight={'20px'}
                marginBottom={'6px'}
              >
                Typical Response Time{' '}
                <span
                  style={{
                    // color: !validateEmail(account?.company?.companyInfo?.email) ? 'red' : '#00BD57',
                    color: '#00BD57',
                  }}
                >
                  *
                </span>
              </Typography>
              <Select
                disabled={companyId?.length}
                fullWidth
                value={account?.company?.companyInfo?.responce_time || ''}
                onChange={(e) => {
                  setAccount(() => ({
                    ...account,
                    company: {
                      ...account?.company,
                      companyInfo: {
                        ...account?.company?.companyInfo,
                        responce_time: e.target.value,
                      },
                    },
                  }));
                }}
                displayEmpty
                variant="outlined"
                open={openDrop && selectRef.current === 'responce_time'}
                onClose={() => {
                  setOpenDrop(false);
                }}
                onOpen={() => {
                  selectRef.current = 'responce_time';
                  setOpenDrop(true);
                }}
                IconComponent={() => <CustomDropdownIcon dropdownRef="responce_time" />}
                sx={{
                  borderRadius: '8px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '8px',
                    backgroundColor: '#fff',
                    '& fieldset': {
                      borderColor: '#e0e0e0',
                    },
                    '&:hover fieldset': {
                      border: '1px solid #D0D5DD',
                    },
                    '&.Mui-focused fieldset': {
                      border: '1px solid #D0D5DD',
                    },
                  },
                  '& .MuiSelect-select': {
                    padding: '10px 14px !important',
                    color: account?.company?.companyInfo?.responce_time ? 'inherit' : '#C0C0C0', // Change color based on value
                  },
                }}
              >
                <MenuItem value="" disabled sx={{ display: openDrop ? 'none' : 'block' }}>
                  Select
                </MenuItem>
                <MenuItem value="24hrs">24 Hours</MenuItem>
                <MenuItem value="48hrs">48 Hours</MenuItem>
                <MenuItem value="72hrs">72 Hours</MenuItem>
                <MenuItem value="96+hrs">96+ Hours</MenuItem>
              </Select>
            </Box>
            <Box
              width={'47%'}
              sx={{
                '@media (max-width: 600px)': {
                  width: '100%',
                },
              }}
            >
              <Typography
                color={'#000'}
                fontFamily={'SF UI Display'}
                fontSize={'14px'}
                fontWeight={'500'}
                lineHeight={'20px'}
                marginBottom={'6px'}
              >
                Company Phone{' '}
                <span
                  style={{
                    //  color: validatePhoneNumber() ? '#00BD57' : 'red'
                    color: '#00BD57',
                  }}
                >
                  *
                </span>
              </Typography>
              {/* <PhoneInput
                country={'us'}
                onlyCountries={['us', 'ca']}
                value={formattedPhoneNumber}
                onChange={handlePhoneChange}
                buttonStyle={{
                  background: 'white',
                  padding: '0 5px',
                }}
                inputProps={{
                  name: 'phone',
                  required: true,
                  autoFocus: true,
                }}
                containerStyle={{
                  width: '100%',
                  borderRadius: '8px',
                }}
                inputStyle={{
                  width: '100%',
                  fontSize: '16px',
                  borderRadius: '8px',
                  padding: '20px 14px 20px 55px',
                  borderColor: '#e0e0e0',
                }}
                dropdownStyle={{
                  borderRadius: '8px',
                }}
                placeholder="+1 (555) 000-0000"
                masks={{ us: '(...) ...-....', ca: '(...) ...-....' }} // Use the same format for US and Canada
              /> */}
              <Box
                display={'flex'}
                width={'100%'}
                border={'1px solid #D0D5DD'}
                borderRadius={'8px'}
                overflow={'hidden'}
              >
                <Select
                  disabled={companyId?.length}
                  value={countryCode}
                  onChange={handleCountryChange}
                  displayEmpty
                  open={open} // Control the open state
                  onClose={() => {
                    setOpen(false);
                  }} // Close when the dropdown loses focus
                  onOpen={() => {
                    setOpen(true);
                  }} // Open when clicked
                  inputProps={{ 'aria-label': 'Without label' }}
                  IconComponent={() => null} // Hide the default icon
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        sx={{
                          padding: '0', // Remove padding
                          '&.MuiIconButton-root': {
                            padding: '0', // Ensure padding is removed for this class
                            cursor: companyId?.length ? 'default' : 'pointer',
                          },
                        }}
                        onClick={() => {
                          if (companyId?.length) return;
                          handleToggle();
                        }} // Toggle dropdown when icon is clicked
                      >
                        {open ? (
                          <ExpandLess sx={{ opacity: companyId?.length ? 0.5 : 1 }} />
                        ) : (
                          <ExpandMore sx={{ opacity: companyId?.length ? 0.5 : 1 }} />
                        )}{' '}
                        {/* Toggle icon */}
                      </IconButton>
                    </InputAdornment>
                  }
                  sx={{
                    padding: '0 !important',
                    '& .MuiInputAdornment-root': {
                      marginLeft: 0, // Remove left margin from InputAdornment
                    },

                    '& .MuiSelect-select': {
                      padding: '0 0 0 10px !important', // Adjust padding
                    },
                    '& .MuiOutlinedInput-root': {
                      border: 'none', // Remove the border on hover and focus
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        border: 'none', // Remove border on hover
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: 'none', // Remove border on focus
                      },
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none', // Completely remove the outline
                    },
                  }}
                >
                  <MenuItem value="US">US</MenuItem>
                  <MenuItem value="CA">CA</MenuItem>
                </Select>
                <TextField
                  disabled={companyId?.length}
                  value={account?.company?.companyInfo?.phone}
                  onChange={handlePhoneChange}
                  placeholder="(555) 000-0000"
                  variant="outlined"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">+1</InputAdornment>,
                  }}
                  inputProps={{ maxLength: 14 }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '0', // Rounded corners for the whole input
                      backgroundColor: '#fff', // White background for input field
                      outline: 'none',
                      border: 'none',
                      padding: '0',
                      '& fieldset': {
                        borderColor: 'transparent', // Light border color
                        outline: 'none',
                      },
                      '&:hover fieldset': {
                        border: 'none',
                      },
                      '&.Mui-focused fieldset': {
                        border: 'none',
                      },
                      '&.Mui-disabled fieldset': {
                        border: 'none',
                      },
                    },
                    '& .MuiInputBase-input': {
                      padding: '10px 0px !important', // Adjust padding to match visual spacing
                      backgroundColor: '#fff', // White background for the text input
                    },
                  }}
                />
              </Box>

              {/* <TextField
                fullWidth
                disabled={companyId?.length}
                variant="outlined"
                placeholder="+1 (555) 000-0000"
                value={account?.company?.companyInfo?.phone}
                onChange={(e) => {
                  setAccount(() => ({
                    ...account,
                    company: {
                      ...account?.company,
                      companyInfo: {
                        ...account?.company?.companyInfo,
                        phone: e.target.value,
                      },
                    },
                  }));
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '8px', // Rounded corners for the whole input
                    backgroundColor: '#fff', // White background for input field
                    outline: 'none',
                    '& fieldset': {
                      borderColor: '#e0e0e0', // Light border color
                      outline: 'none',
                    },
                    '&:hover fieldset': {
                      border: '1px solid #D0D5DD',
                    },
                    '&.Mui-focused fieldset': {
                      border: '1px solid #D0D5DD',
                    },
                  },
                  '& .MuiInputBase-input': {
                    padding: '10px 14px !important', // Adjust padding to match visual spacing
                    backgroundColor: '#fff', // White background for the text input
                  },
                }}
              /> */}
            </Box>
            <Box
              width={'47%'}
              sx={{
                '@media (max-width: 600px)': {
                  width: '100%',
                },
              }}
            >
              <Typography
                color={'#000'}
                fontFamily={'SF UI Display'}
                fontSize={'14px'}
                fontWeight={'500'}
                lineHeight={'20px'}
                marginBottom={'6px'}
              >
                Customer Contact Email{' '}
                <span
                  style={{
                    // color: !validateEmail(account?.company?.companyInfo?.email) ? 'red' : '#00BD57',
                    color: '#00BD57',
                  }}
                >
                  *
                </span>
              </Typography>
              <TextField
                disabled={companyId?.length}
                fullWidth
                variant="outlined"
                value={account?.company?.companyInfo?.email}
                onChange={(e) => {
                  setAccount(() => ({
                    ...account,
                    company: {
                      ...account?.company,
                      companyInfo: {
                        ...account?.company?.companyInfo,
                        email: e.target.value,
                      },
                    },
                  }));
                }}
                placeholder="info@example.com"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '8px', // Rounded corners for the whole input
                    backgroundColor: '#fff', // White background for input field
                    outline: 'none',
                    '& fieldset': {
                      borderColor: '#e0e0e0', // Light border color
                      outline: 'none',
                    },
                    '&:hover fieldset': {
                      border: '1px solid #D0D5DD',
                    },
                    '&.Mui-focused fieldset': {
                      border: '1px solid #D0D5DD',
                    },
                  },
                  '& .MuiInputBase-input': {
                    padding: '10px 14px !important', // Adjust padding to match visual spacing
                    backgroundColor: '#fff', // White background for the text input
                  },
                }}
              />
            </Box>

            <Box width={'100%'}>
              <Typography
                color={'#000'}
                fontFamily={'SF UI Display'}
                fontSize={'14px'}
                fontWeight={'500'}
                lineHeight={'20px'}
                marginBottom={'6px'}
              >
                Company Website
              </Typography>
              <TextField
                fullWidth
                disabled={companyId?.length}
                variant="outlined"
                placeholder="example.com"
                onChange={(e) => {
                  setAccount(() => ({
                    ...account,
                    company: {
                      ...account?.company,
                      companyInfo: {
                        ...account?.company?.companyInfo,
                        website: e.target.value,
                      },
                    },
                  }));
                }}
                value={account?.company?.companyInfo?.website ?? ''}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Box
                        sx={{
                          backgroundColor: '#f5f5f5',
                          padding: '10px 16px',
                          display: 'flex',
                          alignItems: 'center',
                          borderTopLeftRadius: '12px',
                          borderBottomLeftRadius: '12px',
                          borderRight: '1px solid #D0D5DD',
                        }}
                      >
                        <Typography color={'#9e9e9e'} fontWeight={'500'} fontSize={'16px'}>
                          www.
                        </Typography>
                      </Box>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    overflow: 'hidden',
                    borderRadius: '8px', // Rounded corners for the whole input
                    backgroundColor: '#fff', // White background for input field
                    paddingLeft: '0', // Adjust padding to match visual spacing
                    outline: 'none',
                    '& fieldset': {
                      borderColor: '#e0e0e0', // Light border color
                      outline: 'none',
                    },
                    '&:hover fieldset': {
                      border: '1px solid #D0D5DD',
                    },
                    '&.Mui-focused fieldset': {
                      border: '1px solid #D0D5DD',
                    },
                  },
                  '& .MuiInputBase-input': {
                    padding: '10px 0 !important', // Adjust padding to match visual spacing
                    backgroundColor: '#fff', // White background for the text input
                  },
                }}
              />
            </Box>
            <Box width={'100%'}>
              <Typography
                color={'#000'}
                fontFamily={'SF UI Display'}
                fontSize={'14px'}
                fontWeight={'500'}
                lineHeight={'20px'}
                marginBottom={'6px'}
              >
                Company Overview
              </Typography>
              <TextField
                fullWidth
                multiline
                disabled={companyId?.length}
                rows={4}
                value={account?.company?.companyInfo?.description}
                onChange={(e) => {
                  setAccount(() => ({
                    ...account,
                    company: {
                      ...account?.company,
                      companyInfo: {
                        ...account?.company?.companyInfo,
                        description: e.target.value,
                      },
                    },
                  }));
                }}
                variant="outlined"
                placeholder="Tell us a little about your company..."
                sx={{
                  '& .MuiOutlinedInput-root': {
                    padding: '10px 14px !important', // Adjust padding to match visual spacing
                    borderRadius: '8px', // Rounded corners for the whole input
                    backgroundColor: '#fff', // White background for input field
                    outline: 'none',
                    '& fieldset': {
                      borderColor: '#e0e0e0', // Light border color
                      outline: 'none',
                    },
                    '&:hover fieldset': {
                      border: '1px solid #D0D5DD',
                    },
                    '&.Mui-focused fieldset': {
                      border: '1px solid #D0D5DD',
                    },
                  },
                  '& .MuiInputBase-input': {
                    // padding: '10px 14px !important', // Adjust padding to match visual spacing
                    backgroundColor: '#fff', // White background for the text input
                  },
                }}
              />
            </Box>
          </Box>
          <Box
            width={'100%'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            padding={'0 3%'}
            sx={{
              '@media (max-width: 600px)': {
                gap: '10px',
              },
            }}
          >
            <Button
              sx={{
                width: '25%',
                display: 'flex',
                padding: '10px 16px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '6px',
                borderRadius: '8px',
                border: '1px solid D0D5DD',
                background: '#fff',
                color: '#000000',
                fontFamily: 'SF UI Display',
                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '24px',
                margin: '10px 0',
                boxShadow:
                  'inset 0 0 0 1px rgba(16, 24, 40, 0.18), inset 0 -2px 0 rgba(16, 24, 40, 0.05), 0 1px 2px rgba(16, 24, 40, 0.05)',
                textTransform: 'capitalize',
                '&:hover': {
                  bgcolor: '#fff',
                },
                '@media (max-width: 600px)': {
                  width: '100%',
                },
              }}
              onClick={handleCancelClick}
            >
              Back
            </Button>
            <Button
              sx={{
                width: '25%',
                display: 'flex',
                padding: '10px 16px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '6px',
                borderRadius: '8px',
                bgcolor: buttonDisable() ? '#F7F7F7' : '#00BD57',
                color: buttonDisable() ? '#B8B8B8' : '#fff',
                fontFamily: 'SF UI Display',
                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '24px',
                margin: '10px 0',
                textTransform: 'capitalize',
                '&:hover': {
                  bgcolor: buttonDisable() ? '#F7F7F7' : '#00BD57',
                },
                '@media (max-width: 600px)': {
                  width: '100%',
                },
              }}
              disabled={buttonDisable()}
              onClick={onNextClick}
            >
              Next
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CompanyDetails;
