import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CardTopIcon from '../../../assets/Phase1a/DesignUpdate/DesignUpdateCardIcon.svg';
import {
  Button,
  TextField,
  // #Beta
  // , FormControl, MenuItem, Select
} from '@mui/material';
// import serviceOne from '../../../assets/Phase1a/DesignUpdate/serviceFilter1.svg';
// import serviceTwo from '../../../assets/Phase1a/DesignUpdate/serviceFilter2.svg';
// import serviceThree from '../../../assets/Phase1a/DesignUpdate/serviceFilter3.svg';
// import serviceFour from '../../../assets/Phase1a/DesignUpdate/serviceFilter4.svg';
// import serviceFive from '../../../assets/Phase1a/DesignUpdate/serviceFilter5.svg';
import request from '../../../api/request';
import { toast } from 'react-toastify';
import CustomToast from '../../../components/phase1a/common/CustomToast.component';

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 430,
  bgcolor: 'white',
  border: 'none',
  boxShadow: 24,
  padding: '24px',
  borderRadius: '12px',
  '@media (max-width: 500px)': {
    width: '90%',
  },
};

interface SubmitComReqProps {
  open: boolean;
  onClose: () => void;
  type?: string;
}
// #Beta
// const categories = [
//   { value: 'Software', label: 'Software' },
//   { value: 'Equipment', label: 'Equipment' },
//   { value: 'Materials', label: 'Materials' },
//   { value: 'Services', label: 'Services' },
// ];

// const services = [
//   { value: 'Full Service Companies', label: 'Full Service', image: serviceOne },
//   { value: 'Fabricators', label: 'Fabrication', image: serviceTwo },
//   { value: 'Installers', label: 'Installation', image: serviceThree },
//   { value: 'Service', label: 'Service', image: serviceFour },
//   { value: 'Surveys', label: 'Survey', image: serviceFive },
// ];

export default function SubmitComReq({ open, onClose, type }: SubmitComReqProps): JSX.Element {
  const userJSON = localStorage.getItem('user');
  const userData = userJSON ? JSON.parse(userJSON) : '';
  const initialFormData = {
    companyInfo: {
      name: '',
      website: '',
      category: [
        {
          name: '',
          subCategory: [''],
        },
      ], // This will be populated only if the company type is 'supplier' or 'distributor'
      companyType: type, // ['local', 'national', 'wholesaler', 'supplier']
    },
    services: [
      {
        service: '',
        percentage: null,
        subService: [''],
        logo: '',
      },
    ], // This array will contain objects with the 'service' field
    createdBy: userData?._id, // The logged-in user ID will be set here
  };
  const [formData, setFormData] = React.useState(initialFormData);
  // const [selectedCategory, setSelectedCategory] = React.useState('');

  // const [selectedService, setSelectedService] = React.useState('');
  // console.log('selectedService', selectedService);
  // Handle change event for text fields
  const [websiteError, setWebsiteError] = React.useState<string | null>(null);
  const handleChange = (e: any): void => {
    const { name, value } = e.target;

    // Regex for validating the website format (www.example.com)
    const websiteRegex = /^www\.[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
    if (name === 'website') {
      if (value && !websiteRegex.test(value)) {
        setWebsiteError('Please enter a valid website (e.g., www.mysignco.com)');
      } else {
        setWebsiteError(null);
      }
    }
    // Handle the change for category or service
    // #Beta
    // if (name === 'category') {
    //   const selected = categories.find((cat) => cat.value === value);
    //   setSelectedCategory(selected ? selected.label : '');

    //   // Update the formData with the new category structure
    //   setFormData((prevState: any) => ({
    //     ...prevState,
    //     companyInfo: {
    //       ...prevState.companyInfo,
    //       category: [
    //         {
    //           name: selected ? selected.label : '',
    //           subCategory: [], // You might need to update this based on additional logic
    //         },
    //       ],
    //     },
    //   }));
    // } else if (name === 'service') {
    //   const selected = services.find((serv) => serv.value === value);
    //   setSelectedService(selected ? selected.value : '');

    //   setFormData((prevState) => ({
    //     ...prevState,
    //     services: [
    //       {
    //         ...prevState.services[0],
    //         service: selected ? selected.value : '',
    //       },
    //     ],
    //   }));
    // } else {
    //   setFormData((prevState) => ({
    //     ...prevState,
    //     companyInfo: {
    //       ...prevState.companyInfo,
    //       [name]: value,
    //     },
    //   }));
    // }
    setFormData((prevState) => ({
      ...prevState,
      companyInfo: {
        ...prevState.companyInfo,
        [name]: value,
      },
    }));
  };
  const handleSubmit = (): void => {
    if (type === 'local') {
      request('post', `/companies/requests`, formData)
        .then((res) => {
          setFormData(initialFormData);
          onClose();
          // setSelectedService('');
          // setSelectedCategory('');
          toast(<CustomToast message={'Company Request sent!'} type="success" />);
          console.log('res', res);
        })
        .catch((err) => {
          toast(<CustomToast message={err?.response?.data?.message} type="error" />);

          console.log('err', err);
        });
    } else {
      request('post', `/companies/requests`, {
        companyInfo: {
          name: formData?.companyInfo?.name,
          website: formData?.companyInfo?.website,
          category: [
            {
              name: formData?.companyInfo?.category[0]?.name,
              subCategory: [''],
            },
          ], // This will be populated only if the company type is 'supplier' or 'distributor'
          companyType: formData?.companyInfo?.companyType, // ['local', 'national', 'wholesaler', 'supplier']
        },
        // This array will contain objects with the 'service' field
        createdBy: formData?.createdBy, // The logged-in user ID will be set here
      })
        .then((res) => {
          setFormData(initialFormData);
          onClose();
          // setSelectedService('');
          // setSelectedCategory('');
          toast(<CustomToast message={'Company Request sent!'} type="success" />);
          console.log('res', res);
        })
        .catch((err) => {
          toast(<CustomToast message={err?.response?.data?.message} type="error" />);
          console.log('err', err);
        });
    }
  };
  // Check if submit button should be disabled
  const isSubmitDisabled = !formData.companyInfo?.name;
  // #Beta
  // ||
  // (type !== 'national' &&
  //   (type === 'supplier' || type === 'distributor' ? !selectedCategory : !selectedService));

  React.useEffect(() => {
    if (userData?._id) {
      setFormData((prevState) => ({
        ...prevState,
        companyInfo: {
          ...prevState.companyInfo,
          companyType: type,
        },
        createdBy: userData?._id,
      }));
    }
  }, [userData?._id, type]);
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          width={'100%'}
          display={'flex'}
          justifyContent={'flex-start'}
          alignItems={'flex-start'}
        >
          <img
            src={CardTopIcon}
            alt="."
            style={{
              width: '51.3px',
              height: '46.4px',
              flexShrink: 0,
            }}
          />
        </Box>
        <Box margin={'3% 0'}>
          <Typography
            color={'black'}
            fontFamily={'SF UI Display'}
            fontSize={'18px'}
            fontStyle={'normal'}
            fontWeight={600}
            lineHeight={'28px'}
          >
            Submit Company Request
          </Typography>
          <Typography
            color={'black'}
            fontFamily={'SF UI Display'}
            fontSize={'16px'}
            fontStyle={'normal'}
            fontWeight={400}
            lineHeight={'20px'}
            mt={1}
          >
            We’re on a mission to give you access to the best choices out there. Help us get there
            faster.
          </Typography>
        </Box>
        {/* Company Name */}
        <Box width={'100%'} display={'flex'} justifyContent={'flex-start'} flexDirection={'column'}>
          <Typography
            fontSize={'14px'}
            fontWeight={'500'}
            fontFamily="SF UI Display"
            color={'#000'}
            padding={'3% 0'}
          >
            Company Name
          </Typography>
          <TextField
            sx={{
              width: '100%',
              fontSize: '16px',
              fontWeight: '400',
              lineHeight: '24px',
              color: '#667085',
              borderRadius: '10px',
              '.MuiOutlinedInput-input': {
                padding: '10px 14px',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: '1px solid #D0D5DD',
                  outline: 'none',
                  borderRadius: '10px',
                },
                '&:hover fieldset': {
                  borderColor: '#D0D5DD',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#D0D5DD',
                },
                '&.MuiInputBase-root:focus-visible': {
                  borderColor: '#D0D5DD',
                },
              },
            }}
            id="companyName"
            variant="outlined"
            name="name" // Set the name prop to target the specific field in the state
            value={formData?.companyInfo?.name} // Bind the input value to the state
            onChange={handleChange} // Pass the handleChange function
            inputProps={{ maxLength: 75 }}
          />
        </Box>
        {/* Company Website */}
        <Box
          width={'100%'}
          display={'flex'}
          justifyContent={'flex-start'}
          flexDirection={'column'}
          margin={'2% 0'}
        >
          <Typography
            fontSize={'14px'}
            fontWeight={'500'}
            fontFamily="SF UI Display"
            color={'#000'}
            padding={'3% 0'}
          >
            Company Website (Optional)
          </Typography>
          <TextField
            sx={{
              width: '100%',
              fontSize: '16px',
              fontWeight: '400',
              lineHeight: '24px',
              color: '#667085',
              borderRadius: '8px',
              '.MuiOutlinedInput-input': {
                padding: '10px 14px',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: '1px solid #D0D5DD',
                  outline: 'none',
                  borderRadius: '10px',
                },
                '&:hover fieldset': {
                  borderColor: '#D0D5DD',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#D0D5DD',
                },
                '&.MuiInputBase-root:focus-visible': {
                  borderColor: '#D0D5DD',
                },
              },
            }}
            variant="outlined"
            name="website" // Set the name prop to target the 'website' field in the state
            value={formData?.companyInfo?.website} // Bind the input value to the state
            onChange={handleChange} // Pass the handleChange function
            inputProps={{ maxLength: 30 }}
            error={!!websiteError}
            helperText={websiteError}
          />
        </Box>
        {/* Main Service or Main Category */}
        {/* #Beta */}
        {/* {type !== 'national' && (
          <Box>
            <Typography
              fontSize="14px"
              fontWeight="500"
              fontFamily="SF UI Display"
              color="#000"
              padding="3% 0"
            >
              {type === 'supplier' || type === 'distributor' ? 'Main Category' : 'Main Service'}
            </Typography>
            <FormControl
              fullWidth
              sx={{
                width: '100%',
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '24px',
                color: '#667085',
                borderRadius: '8px',
                '.MuiOutlinedInput-input': {
                  padding: '10px 14px',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '1px solid #D0D5DD',
                    outline: 'none',
                    borderRadius: '10px',
                  },
                  '&:hover fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.MuiInputBase-root:focus-visible': {
                    borderColor: '#D0D5DD',
                  },
                },
              }}
            >
              <Select
                labelId="select-label"
                id="select"
                name={type === 'supplier' || type === 'distributor' ? 'category' : 'service'}
                value={
                  type === 'supplier' || type === 'distributor' ? selectedCategory : selectedService
                }
                onChange={handleChange}
                displayEmpty
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: 150,
                      overflowY: 'auto',
                    },
                  },
                }}
              >
                <MenuItem value="">
                  {type === 'supplier' || type === 'distributor'
                    ? 'Select Category'
                    : 'Select Service'}
                </MenuItem>
                {type === 'supplier' || type === 'distributor'
                  ? categories.map((category) => (
                      <MenuItem key={category.value} value={category.value}>
                        {category.label}
                      </MenuItem>
                    ))
                  : services.map((service) => (
                      <MenuItem key={service.value} value={service.value}>
                        <Box display="flex" alignItems="center">
                          <img
                            src={service.image}
                            alt={service.label}
                            style={{ width: 24, height: 24, marginRight: 8 }}
                          />
                          {service.label}
                        </Box>
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
          </Box>
        )} */}

        <Box
          width={'100%'}
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          mt={4}
        >
          <Button
            sx={{
              width: '49%',
              fontFamily: 'SF UI Display',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '24px',
              fontSize: '16px',
              color: 'black',
              background: 'transparent',
              borderRadius: '8px',
              border: '1px solid #D0D5DD',
              textTransform: 'capitalize',
              padding: '10px var(--spacing-xl, 16px)',
              '&:hover': {
                background: '#F9FAFB',
              },
            }}
            onClick={() => {
              setFormData(initialFormData);
              onClose();
              // setSelectedService('');
              // setSelectedCategory('');
              setWebsiteError(null);
            }}
          >
            Cancel
          </Button>
          <Button
            sx={{
              width: '49%',
              fontFamily: 'SF UI Display',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '24px',
              fontSize: '16px',
              color: '#fff',
              borderRadius: '8px',
              border: '1px solid transparent',
              textTransform: 'capitalize',
              backgroundColor: '#00BD57',
              padding: '10px var(--spacing-xl, 16px)',
              '&:hover': {
                backgroundColor: '#00BD57',
              },
              '&:disabled': {
                backgroundColor: '#F7F7F7',
                color: '#B8B8B82',
              },
            }}
            disabled={isSubmitDisabled || !!websiteError}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
