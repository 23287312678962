import React, { FC, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import MapAreaCoverage from './MapAreaCoverage.component';
import { toast } from 'react-toastify';

interface serviceAreaProps {
  account: any;
  companyId?: any;
  setAccount: React.Dispatch<React.SetStateAction<any>>;
  onNextClick?: () => void;
  handleCancelClick?: () => void;
}

// Custom Toast Component
const PrettoSlider = styled(Slider)({
  marginTop: '8px',
  color: '#52af77',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&::before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    boxShadow: '0px 1px 4px 0px rgba(148, 148, 148, 0.25)',
    background: '#fff',
    color: '#000',
    padding: '3px 15px',
    border: '1px solid #D0D5DD',
    height: 32,
    borderRadius: '8px',
  },
});

const ServiceArea: FC<serviceAreaProps> = ({
  account,
  setAccount,
  companyId,
  onNextClick,
  handleCancelClick,
}) => {
  const [radiusInMiles, setRadiusInMiles] = useState(
    account?.company?.serviceArea[0]?.distance ?? 50,
  ); // Initial value
  const [boundaryCoordinates, setBoundaryCoordinates] = useState<any>(); // Initial value
  console.log('boundaryCoordinates', boundaryCoordinates);
  const handleNextCheck = (): void => {
    console.log('boundaryCoordinates', boundaryCoordinates);
    if (!boundaryCoordinates) {
      toast.error('Please select area range');
      return;
    }
    // Set the new coordinates in the setAccount state
    setAccount((prevAccount: any) => ({
      ...prevAccount,
      company: {
        ...prevAccount.company,
        serviceArea: [
          {
            location: {
              type: 'Polygon',
              coordinates: boundaryCoordinates, // Set the circle's coordinates
            },
            distance: radiusInMiles, // Set the distance if needed
          },
        ],
      },
    }));
    onNextClick?.();
  };
  return (
    <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
      <Box
        width={'50%'}
        borderRadius={'10px'}
        bgcolor={'#FFF'}
        boxShadow={'0px 1px 4px 0px rgba(148, 148, 148, 0.25)'}
        padding={'3%'}
        display={'flex'}
        justifyContent={'center'}
        sx={{
          '@media (max-width: 1200px)': {
            width: '60%',
          },
          '@media (max-width: 900px)': {
            width: '75%',
          },
          '@media (max-width: 768px)': {
            width: '85%',
            padding: '20px',
          },
          '@media (max-width: 600px)': {
            width: '100%',
          },
        }}
      >
        <Box width={'100%'} display={'flex'} flexDirection={'column'} gap={'10px'}>
          <Typography
            color={'#00BD57'}
            fontFamily={'SF UI Display'}
            fontSize={'16px'}
            fontWeight={'700'}
            lineHeight={'normal'}
            textTransform={'uppercase'}
          >
            {/* Page Setup (4 of 5) */}
            Page Setup (3 of 4)
          </Typography>
          <Typography
            color={'#000000'}
            fontFamily={'SF UI Display'}
            fontSize={'24px'}
            fontWeight={'800'}
            lineHeight={'normal'}
            sx={{
              '@media (max-width: 600px)': {
                fontSize: '22px',
              },
            }}
          >
            Almost done! Set your service area coverage.
          </Typography>
          <Typography
            color={'#000000'}
            fontFamily={'SF UI Display'}
            fontSize={'16px'}
            fontWeight={'400'}
            lineHeight={'20px'}
            sx={{
              '@media (max-width: 600px)': {
                fontSize: '15px',
              },
            }}
          >
            Your service are distance should cover anywhere you are willing to travel too or ship
            too. For custom coverage, email{' '}
            <span
              style={{
                color: '#00BD57',
                fontWeight: 400,
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              support@signwise.com
            </span>
          </Typography>
          <Box
            width={'100%'}
            display={'flex'}
            margin={'25px 0 15px 0'}
            justifyContent={'center'}
            alignItems={'center'}
            flexDirection={'column'}
          >
            <Box
              width={'244px'}
              height={'236px'}
              sx={{
                '@media (max-width: 600px)': {
                  width: '100%',
                },
              }}
            >
              <MapAreaCoverage
                account={account}
                setAccount={setAccount}
                radiusInMiles={radiusInMiles}
                setBoundaryCoordinates={setBoundaryCoordinates}
              />
            </Box>
            <Box
              width={'244px'}
              sx={{
                '@media (max-width: 600px)': {
                  width: '100%',
                },
              }}
            >
              <PrettoSlider
                valueLabelDisplay="auto"
                aria-label="pretto slider"
                value={radiusInMiles}
                min={1}
                max={150}
                onChange={(e, newValue) => {
                  if (companyId?.length) return;
                  setRadiusInMiles(newValue as number);
                  // setAccount((prevAccount: any) => ({
                  //   ...prevAccount,
                  //   company: {
                  //     ...prevAccount?.company,
                  //     serviceArea: prevAccount?.company?.serviceArea.map(
                  //       (area: any, index: number) => ({
                  //         ...area,
                  //         distance: index === 0 ? (newValue as number) : area.distance, // Assuming you're updating the first serviceArea item
                  //       }),
                  //     ),
                  //   },
                  // }));
                }}
                onChangeCommitted={(e, newValue) => {
                  if (companyId?.length) return;
                  // Optional: Handle the final value after the drag is completed if needed
                  setRadiusInMiles(newValue as number);
                }}
                valueLabelFormat={(value) => `${value} Miles`} // Customize label with "Miles"
              />
            </Box>
          </Box>
          <Box
            width={'100%'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{
              '@media (max-width: 600px)': {
                gap: '10px',
              },
            }}
          >
            <Button
              sx={{
                width: '25%',
                display: 'flex',
                padding: '10px 16px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '6px',
                borderRadius: '8px',
                border: '1px solid D0D5DD',
                background: '#fff',
                color: '#000000',
                fontFamily: 'SF UI Display',
                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '24px',
                margin: '10px 0',
                boxShadow:
                  'inset 0 0 0 1px rgba(16, 24, 40, 0.18), inset 0 -2px 0 rgba(16, 24, 40, 0.05), 0 1px 2px rgba(16, 24, 40, 0.05)',
                textTransform: 'capitalize',
                '&:hover': {
                  bgcolor: '#fff',
                },
                '@media (max-width: 600px)': {
                  width: '100%',
                },
              }}
              onClick={handleCancelClick}
            >
              Back
            </Button>
            <Button
              sx={{
                width: '25%',
                display: 'flex',
                padding: '10px 16px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '6px',
                borderRadius: '8px',
                bgcolor: !account?.company?.companyInfo?.location?.coordinates?.length
                  ? '#F7F7F7'
                  : '#00BD57',
                color: !account?.company?.companyInfo?.location?.coordinates?.length
                  ? '#B8B8B8'
                  : '#fff',
                fontFamily: 'SF UI Display',
                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '24px',
                margin: '10px 0',
                textTransform: 'capitalize',
                '&:hover': {
                  bgcolor: !account?.company?.companyInfo?.location?.coordinates?.length
                    ? '#F7F7F7'
                    : '#00BD57',
                },
                '@media (max-width: 600px)': {
                  width: '100%',
                },
              }}
              disabled={!account?.company?.companyInfo?.location?.coordinates?.length}
              onClick={handleNextCheck}
            >
              Next
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ServiceArea;
