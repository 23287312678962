import React, { FC } from 'react';
import { Box, Typography, Rating } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';

interface ReviewSection3Props {
  step3Ref: any;
  companyData: any;
  review: any;
  updateRating: (fieldName: string, newValue: number) => void;
}

const reviewSection3: FC<ReviewSection3Props> = ({
  step3Ref,
  companyData,
  review,
  updateRating,
}) => {
  const grayStar = <StarIcon style={{ color: '#A8A8A8' }} fontSize="inherit" />;

  return (
    <Box ref={step3Ref} width={'100%'} margin={'60px 0'}>
      <Typography
        fontSize={'20px'}
        fontWeight={'800'}
        fontFamily={'SF UI Display'}
        color={'#000000'}
        sx={{
          '@media (max-width: 500px)': {
            fontSize: '18px',
            lineHeight: 'normal',
          },
        }}
      >
        How would you rate {companyData?.companyInfo?.name}?
      </Typography>
      <Typography
        fontSize={'16px'}
        fontWeight={'400'}
        fontFamily={'SF UI Display'}
        color={'#000000'}
        margin={'10px 0'}
        sx={{
          '@media (max-width: 500px)': {
            lineHeight: '18px',
          },
        }}
      >
        Ratings are a quickest way to tell others about your experience.
      </Typography>
      <Box width={'100%'} margin={'40px 0'} display={'flex'} flexDirection={'column'} gap={'30px'}>
        <Box width={'100%'} display={'flex'} flexDirection={'column'} gap={'15px'}>
          <Box display={'flex'} width={'100%'} gap={'5px'}>
            <Typography
              fontSize={'18px'}
              fontWeight={'400'}
              fontFamily={'SF UI Display'}
              color={'#000000'}
            >
              1.
            </Typography>
            <Typography
              fontSize={'18px'}
              fontWeight={'400'}
              fontFamily={'SF UI Display'}
              color={'#000000'}
            >
              {`Rate their ${
                companyData?.companyInfo?.companyType === 'local'
                  ? 'reliability.'
                  : companyData?.companyInfo?.companyType === 'national'
                  ? 'Professionalism.'
                  : 'quality.'
              }`}
            </Typography>
          </Box>
          <Rating
            name="reliability"
            value={review?.reliability}
            onChange={(event, newValue) => {
              if (newValue !== null) {
                updateRating('reliability', newValue);
              }
            }}
            precision={0.5}
            sx={{
              fontSize: '51px',
              marginLeft: '15px',
              '@media (max-width: 1024px)': {
                fontSize: '40px',
              },
              '@media (max-width: 768px)': {
                fontSize: '33px',
              },
            }}
            emptyIcon={grayStar} // Custom gray star for empty rating
            icon={
              review?.reliability === 0 ? (
                grayStar
              ) : (
                <StarIcon fontSize="inherit" sx={{ color: '#00BD57' }} />
              )
            } // Gray filled stars if rating is 0
          />
        </Box>
        <Box width={'100%'} display={'flex'} flexDirection={'column'} gap={'15px'}>
          <Box display={'flex'} width={'100%'} gap={'5px'}>
            <Typography
              fontSize={'18px'}
              fontWeight={'400'}
              fontFamily={'SF UI Display'}
              color={'#000000'}
            >
              2.
            </Typography>
            <Typography
              fontSize={'18px'}
              fontWeight={'400'}
              fontFamily={'SF UI Display'}
              color={'#000000'}
            >
              {`Rate their ${
                companyData?.companyInfo?.companyType === 'local'
                  ? 'communication.'
                  : companyData?.companyInfo?.companyType === 'national'
                  ? 'communication.'
                  : 'customer service and support.'
              }`}
            </Typography>
          </Box>
          <Rating
            name="communication"
            value={review?.communication}
            onChange={(event, newValue) => {
              if (newValue !== null) {
                updateRating('communication', newValue);
              }
            }}
            precision={0.5}
            sx={{
              fontSize: '51px',
              marginLeft: '15px',
              '@media (max-width: 1024px)': {
                fontSize: '40px',
              },
              '@media (max-width: 768px)': {
                fontSize: '33px',
              },
            }}
            emptyIcon={grayStar} // Custom gray star for empty rating
            icon={
              review?.communication === 0 ? (
                grayStar
              ) : (
                <StarIcon fontSize="inherit" sx={{ color: '#00BD57' }} />
              )
            } // Gray filled stars if rating is 0
          />
        </Box>
        <Box width={'100%'} display={'flex'} flexDirection={'column'} gap={'15px'}>
          <Box display={'flex'} width={'100%'} gap={'5px'}>
            <Typography
              fontSize={'18px'}
              fontWeight={'400'}
              fontFamily={'SF UI Display'}
              color={'#000000'}
            >
              3.
            </Typography>
            <Typography
              fontSize={'18px'}
              fontWeight={'400'}
              fontFamily={'SF UI Display'}
              color={'#000000'}
            >
              {`Rate ${
                companyData?.companyInfo?.companyType === 'local'
                  ? 'the quality of their work.'
                  : companyData?.companyInfo?.companyType === 'national'
                  ? 'how well they pay.'
                  : 'their cost or value.'
              }`}
            </Typography>
          </Box>
          <Rating
            name="workQuality"
            value={review?.work_quality}
            onChange={(event, newValue) => {
              if (newValue !== null) {
                updateRating('work_quality', newValue);
              }
            }}
            precision={0.5}
            sx={{
              fontSize: '51px',
              marginLeft: '15px',
              '@media (max-width: 1024px)': {
                fontSize: '40px',
              },
              '@media (max-width: 768px)': {
                fontSize: '33px',
              },
            }}
            emptyIcon={grayStar} // Custom gray star for empty rating
            icon={
              review?.work_quality === 0 ? (
                grayStar
              ) : (
                <StarIcon fontSize="inherit" sx={{ color: '#00BD57' }} />
              )
            } // Gray filled stars if rating is 0
          />
        </Box>
        <Box
          width={'100%'}
          display={
            companyData?.companyInfo?.companyType === 'supplier' ||
            companyData?.companyInfo?.companyType === 'distributor'
              ? 'flex'
              : 'none'
          }
          flexDirection={'column'}
          gap={'15px'}
        >
          <Box display={'flex'} width={'100%'} gap={'5px'}>
            <Typography
              fontSize={'18px'}
              fontWeight={'400'}
              fontFamily={'SF UI Display'}
              color={'#000000'}
            >
              4.
            </Typography>
            <Typography
              fontSize={'18px'}
              fontWeight={'400'}
              fontFamily={'SF UI Display'}
              color={'#000000'}
            >
              How often do you recommend them?
            </Typography>
          </Box>
          <Rating
            name="leadTime"
            value={review?.lead_time}
            onChange={(event, newValue) => {
              if (newValue !== null) {
                updateRating('lead_time', newValue);
              }
            }}
            precision={0.5}
            sx={{
              fontSize: '51px',
              marginLeft: '15px',
              '@media (max-width: 1024px)': {
                fontSize: '40px',
              },
              '@media (max-width: 768px)': {
                fontSize: '33px',
              },
            }}
            emptyIcon={grayStar} // Custom gray star for empty rating
            icon={
              review?.lead_time === 0 ? (
                grayStar
              ) : (
                <StarIcon fontSize="inherit" sx={{ color: '#00BD57' }} />
              )
            } // Gray filled stars if rating is 0
          />
        </Box>
      </Box>
    </Box>
  );
};

export default reviewSection3;
