import React, { FC, useState } from 'react';
import { Box, Typography, TextField, Button, CircularProgress } from '@mui/material';
import Logo from '../../assets/SignWiselogo .svg';
import {
  //  Link,
  useNavigate,
} from 'react-router-dom';
import request from '../../api/request';
import { toast } from 'react-toastify';
import CustomToast from '../../components/phase1a/common/CustomToast.component';

const Login: FC = () => {
  const nav = useNavigate();

  const [email, setEmail] = useState('');
  // const [emailError, setEmailError] = useState('');
  const [loading, setLoading] = React.useState(false);

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    // if (!validateEmail(newEmail)) {
    //   setEmailError('Invalid email address');
    // } else {
    //   setEmailError('');
    // }
  };

  const handleSubmit = (): void => {
    setLoading(true);

    if (!validateEmail(email)) {
      setLoading(false); // Ensure loading state is reset before returning
      toast(<CustomToast message={'Invalid email address'} type="error" />);
      return;
    }

    if (!email) {
      // toast.error('Enter email');
      toast(<CustomToast message={'Enter email'} type="error" />);
      setLoading(false);

      return;
    }
    // if (validateEmail(email)) {
    request('post', '/auth/generateOTP', { id: email, userWithoutCompany: true })
      .then(() => {
        console.log('Form submitted with email:', email);
        // toast.success(`OTP generated and sent successfully to ${email}`);
        setLoading(false);
        nav('/otp', { state: { email } }); // Navigate to the OTP screen with the email
      })
      .catch((err) => {
        setLoading(false);
        const errmessage = err?.response?.data?.message
          ? err?.response?.data?.message
          : 'User Not found';
        // toast.error(errmessage);
        toast(<CustomToast message={errmessage} type="error" />);
      });
    // }
  };

  return (
    <Box
      width={'100%'}
      display={'flex'}
      height={'100vh'}
      justifyContent={'center'}
      alignItems={'center'}
      bgcolor={'#FFFFFF'}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        padding={'30px'}
        borderRadius={'20px'}
        gap={'20px'}
        width={'700px'}
        sx={{
          '@media (max-width: 1024px)': {
            width: '100%',
          },
        }}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          gap={'20px'}
          width={'100%'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Box width={'319px'} display={'flex'} justifyContent={'center'}>
            <img src={Logo} alt="Logo" />
          </Box>

          <Typography
            fontSize={'16px'}
            fontWeight={'500'}
            color={'#000000'}
            width={'40%'}
            textAlign={'center'}
            fontFamily="SF UI Display"
            sx={{
              '@media (max-width: 900px)': {
                width: '100%',
              },
            }}
          >
            Sign in to access your account.
          </Typography>
        </Box>

        <Box
          display={'flex'}
          flexDirection={'column'}
          width={'100%'}
          gap={'10px'}
          justifyContent={'center'}
          alignItems={'center'}
          marginTop={'4%'}
        >
          <Box
            width={'70%'}
            sx={{
              '@media (max-width: 600px)': {
                width: '80%',
              },
              '@media (max-width: 500px)': {
                width: '100%',
              },
            }}
          >
            <TextField
              required
              type="email"
              placeholder="Email"
              value={email}
              name="email"
              onChange={handleEmailChange}
              // error={!!emailError}
              // helperText={emailError}
              fullWidth
              sx={{
                width: '100%',
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '24px',
                color: '#667085',
                borderRadius: '12px',
                '.MuiOutlinedInput-input': {
                  // padding: ' 14px',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '1px solid #D0D5DD',
                    outline: 'none',
                    borderRadius: '12px',
                  },
                  '&:hover fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.MuiInputBase-root:focus-visible': {
                    borderColor: '#D0D5DD',
                  },
                },
              }}
              // InputProps={{
              //   inputProps: {
              //     maxLength: 20,
              //   },
              // }}
            />{' '}
          </Box>

          <Box
            width={'70%'}
            marginTop={'3%'}
            display={'flex'}
            flexDirection={'column'}
            gap={'20px'}
            sx={{
              '@media (max-width: 600px)': {
                width: '80%',
              },
              '@media (max-width: 500px)': {
                width: '100%',
              },
            }}
          >
            <Button
              fullWidth
              onClick={handleSubmit}
              sx={{
                padding: '16px 24px',
                bgcolor: 'black',
                borderRadius: '12px',
                color: 'white',
                textTransform: 'unset',
                fontSize: '14px',
                fontFamily: 'Poppins',
                fontWeight: '600',

                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                '&:hover': {
                  bgcolor: 'black',
                  color: 'white',
                },
              }}
            >
              {loading ? <CircularProgress size={15} color="inherit" /> : 'Log in '}
            </Button>
            {/* <Typography
              textAlign={'center'}
              mt={3}
              fontSize={'16px'}
              fontWeight={'500'}
              color={'black'}
              marginTop={'20px'}
              fontFamily="SF UI Display"
              // sx={{
              //   '@media (max-width: 900px)': {
              //     width: '100%',
              //   },
              // }}
            >
              {`Don't have an account? `}
              <Link to="/signup">
                <span
                  style={{
                    fontWeight: '500',
                    cursor: 'pointer',
                    color: 'rgba(0, 189, 87, 1)',
                    textDecoration: 'underline',
                  }}
                >
                  Sign up here
                </span>
              </Link>
            </Typography> */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
